import {
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  RowStyles,
  collapseColumn,
  column,
  emptyCell,
  numericColumn,
  useModal,
  useRightPane,
} from "@homebound/beam";
import { Price, priceCell } from "src/components";
import {
  BidPackageBidItemFragment,
  BidPackageDetailRequestFragment,
  DisplayNamedFragment,
  PageBidPackageDetailFragment,
} from "src/generated/graphql-types";
import { fail, groupBy } from "src/utils";
import { TradeCostData, sumTradeCostData } from "./BidsTab.utils";
import { EditBidRightPane } from "./EditBidRightPane";
import { ImportBidModal } from "./ImportBidModal";

type UnitBasedBidsTableProps = {
  bidPackage: PageBidPackageDetailFragment;
  searchFilter?: string;
};

export function UnitBasedBidsTable({ bidPackage, searchFilter }: UnitBasedBidsTableProps) {
  const { openModal } = useModal();
  const { openRightPane } = useRightPane();
  const requestsWithoutContracts = bidPackage.requests.filter((request) => !request.bidContract);
  function createBidFromCSV() {
    openModal({
      content: (
        <ImportBidModal
          requests={requestsWithoutContracts}
          bidPackageName={bidPackage.name}
          developmentIds={bidPackage.developments.map(({ id }) => id)}
          bidItemsIds={bidPackage.lineItems.flatMap((li) => li.bidItem.id)}
        />
      ),
    });
  }

  function onEditCost(bidItem: BidPackageBidItemFragment, tradePartnerId: string) {
    const bidPackageRequest = bidPackage.requests.find((r) => r.tradePartner.id === tradePartnerId);
    const bidContractLineItem = bidPackageRequest?.bidContract?.latestRevision.lineItems.find(
      (li) => li.bidItem?.id === bidItem.id,
    );
    if (!bidItem || !bidContractLineItem || !bidPackageRequest) {
      fail("Unable to find item or bid info");
    }
    openRightPane({
      content: (
        <EditBidRightPane
          bidContractLineItem={bidContractLineItem}
          tradePartnerName={bidPackageRequest.tradePartner.name}
          bidItem={bidItem}
        />
      ),
    });
  }

  const rows: GridDataRow<Row>[] = createRows(bidPackage);
  const columns: GridColumn<Row>[] = createColumns(
    bidPackage.requests,
    createBidFromCSV,
    requestsWithoutContracts,
    onEditCost,
  );

  return <GridTable columns={columns} filter={searchFilter} rows={rows} rowStyles={rowStyles} />;
}

const showIconButtonClass = "revealIconOnRowHover";

const cellBorderStyles = {
  // add border to split trade partner columns
  ...Css.addIn("& > div:nth-of-type(n + 5)", Css.bl.bcGray200.$).$,
  // Allow for hover showing the edit price button
  ...{
    [`.${showIconButtonClass} > *`]: Css.vh.$,
    [`:hover .${showIconButtonClass} > *`]: Css.vv.$,
  },
};

const rowStyles: RowStyles<Row> = {
  total: {
    cellCss: Css.py3.$,
  },
  head: { rowCss: cellBorderStyles },
  costCode: { rowCss: cellBorderStyles },
  item: { rowCss: cellBorderStyles },
  bidItem: { rowCss: cellBorderStyles },
};

function createRows(bidPackage: PageBidPackageDetailFragment): GridDataRow<Row>[] {
  const requests = bidPackage.requests;
  const tradeIds = requests.map((r) => r.tradePartner.id);
  const bidItemRows = bidPackage.lineItems.map(({ bidItem }) => ({
    kind: "bidItem" as const,
    id: bidItem.id,
    data: {
      bidItem,
      // For each bid item line, each trade could have bid at most once on it
      costData: requests.keyBy(
        (bpr) => bpr.tradePartner.id,
        (bpr) => {
          // find the BCLI for this bidItem
          const bcli = bpr.bidContract?.latestRevision.lineItems.find((li) => li.bidItem?.id === bidItem.id);
          // this is the unit cost on the BCLI
          return bcli?.totalCostInCents ?? 0;
        },
      ),
    },
  }));

  const itemGroupBy = groupBy(bidItemRows, (i) => i.data.bidItem.items[0].id);
  const itemRows = Object.keys(itemGroupBy).map((key) => {
    const children = itemGroupBy[key];
    const item = children[0].data.bidItem.items[0];
    return {
      kind: "item" as const,
      id: key,
      data: {
        item: item,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children,
    };
  });

  const costCodeGroupBy = groupBy(itemRows, (o) => o.children[0].data.bidItem.items[0].costCode.id);
  const costCodeRows = Object.keys(costCodeGroupBy).map((key, i) => {
    const children = costCodeGroupBy[key];
    return {
      kind: "costCode" as const,
      id: key,
      data: {
        costCode: children[0].data.item.costCode,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children: children,
    };
  });

  /*
    Not using the header/totals row type because it makes assumptions about placement, which we don't want to make.
    Using pin to prevent the total and head rows from being hidden when searching
  */
  return [
    {
      kind: "total" as const,
      id: "total",
      pin: "first",
      data: {},
    },
    { kind: "head" as const, id: "head", data: undefined, pin: "last" },
    ...costCodeRows,
  ];
}

type TotalRow = {
  kind: "total";
};
type HeaderRow = { kind: "head"; data: undefined };
type CostCodeRow = { kind: "costCode"; data: { costCode: DisplayNamedFragment; costData: TradeCostData } };
type ItemRow = { kind: "item"; data: { item: DisplayNamedFragment; costData: TradeCostData } };
type BidItemRow = { kind: "bidItem"; data: { bidItem: BidPackageBidItemFragment; costData: TradeCostData } };

type Row = HeaderRow | TotalRow | CostCodeRow | ItemRow | BidItemRow;

function createColumns(
  requests: BidPackageDetailRequestFragment[],
  createBidFromCSV: () => void,
  requestsWithoutContracts: BidPackageDetailRequestFragment[],
  onEditCost: (bidItem: BidPackageBidItemFragment, tradePartnerId: string) => void,
): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({
      total: () => ({
        content: (
          <div css={Css.df.w100.aic.gap2.$}>
            <div css={Css.xlSb.$}>Bid Total</div>
            <div css={Css.mla.$}>
              <Button
                variant="text"
                label="Add Bid"
                onClick={createBidFromCSV}
                disabled={requestsWithoutContracts.length === 0 && "All scope has existing bid"}
              />
            </div>
          </div>
        ),
        colspan: 6 + requests.length,
      }),
    }),
    column<Row>({
      total: emptyCell,
      head: "Name",
      costCode: ({ costCode }) => ({
        content: costCode.displayName,
        css: Css.smSb.$,
      }),
      item: ({ item }) => item.displayName,
      bidItem: ({ bidItem }) => bidItem.displayName,
    }),
    column<Row>({
      total: emptyCell,
      head: "Material Code",
      costCode: emptyCell,
      item: emptyCell,
      bidItem: ({ bidItem }) => bidItem?.parentMaterialVariant?.code,
      w: "120px",
    }),
    column<Row>({
      total: emptyCell,
      head: "UoM",
      costCode: emptyCell,
      item: emptyCell,
      bidItem: ({ bidItem }) => bidItem.unitOfMeasure.shortName,
      w: "67px",
    }),
    ...requests.map((request) => {
      return numericColumn<Row>({
        total: emptyCell,
        head: () => (
          <div css={Css.w100.df.aic.$}>
            <div css={Css.mra.$}>{request.tradePartner.name}</div>
          </div>
        ),
        costCode: ({ costData }) =>
          costData[request.tradePartner.id] ? (
            <div css={Css.sm.$}>
              <Price valueInCents={costData[request.tradePartner.id]} />
            </div>
          ) : (
            emptyCell
          ),
        item: ({ costData }) =>
          costData[request.tradePartner.id]
            ? priceCell({ valueInCents: costData[request.tradePartner.id] })
            : emptyCell,
        bidItem: ({ costData }, { row }) =>
          costData[request.tradePartner.id] ? (
            <>
              <div css={Css.mra.$} className={showIconButtonClass}>
                <IconButton
                  inc={2}
                  icon="pencil"
                  onClick={() => onEditCost(row.data.bidItem, request.tradePartner.id)}
                />
              </div>
              <Price valueInCents={costData[request.tradePartner.id]} />
            </>
          ) : (
            emptyCell
          ),
      });
    }),
  ];
}
