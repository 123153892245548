import {
  Button,
  Chip,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  Palette,
  RowStyles,
  Tooltip,
  collapseColumn,
  column,
  emptyCell,
  numericColumn,
  useModal,
} from "@homebound/beam";
import { Price, priceCell } from "src/components";
import { useFullscreenModal } from "src/components/useFullscreenModal";
import {
  BidContractType,
  BidPackageDetailRequestFragment,
  BidPackageItemTemplateItemFragment,
  NamedFragment,
  PageBidPackageDetailFragment,
} from "src/generated/graphql-types";
import { DropCodeCell } from "src/routes/developments/product-offerings/components/DropCodeCell";
import { fail, groupBy, pluralize } from "src/utils";
import { getOptionsKey } from "./BidsTab";
import { TradeCostData, sumTradeCostData } from "./BidsTab.utils";
import { AddProrationBidModal, EditProrationBidModal } from "./ProrateBidModal";
import { SelectProrationModal } from "./SelectProrationModal";

type PlanBasedBidsTableProps = {
  bidPackage: PageBidPackageDetailFragment;
  searchFilter?: string;
};

export function PlanBasedBidsTable({ bidPackage, searchFilter }: PlanBasedBidsTableProps) {
  const { openModal } = useModal();
  const { openFullscreen } = useFullscreenModal();

  const requestsWithoutContracts = bidPackage.requests.filter((request) => !request.bidContract);

  function createProratedBid() {
    openModal({
      content: (
        <SelectProrationModal
          requests={requestsWithoutContracts}
          bidPackageName={bidPackage.name}
          onContinue={(tradePartnerId, type) => {
            const request = requestsWithoutContracts.find((r) => r.tradePartner.id === tradePartnerId);
            if (!request) {
              fail("Unable to find request");
            }
            openFullscreen(
              <AddProrationBidModal
                newBidContractParams={{
                  bidRequestId: request.id,
                  developmentIds: bidPackage.developments.map(({ id }) => id),
                  tradePartnerId,
                  type,
                }}
                scopeLineItems={bidPackage.scopeLines.map((sl) => sl.itemTemplateItem)}
                tradePartnerName={request.tradePartner.name}
              />,
            );
          }}
        />
      ),
    });
  }

  function onEditProratedBid(bidContractRevisionId: string) {
    openFullscreen(
      <EditProrationBidModal
        bidContractRevisionId={bidContractRevisionId}
        scopeLineItems={bidPackage.scopeLines.map((sl) => sl.itemTemplateItem)}
      />,
    );
  }

  const rows = createRows(bidPackage);
  const columns = createColumns(bidPackage.requests, createProratedBid, requestsWithoutContracts, onEditProratedBid);
  return <GridTable columns={columns} filter={searchFilter} rows={rows} rowStyles={rowStyles} />;
}

const cellBorderStyles = {
  // add border to split trade partner columns
  ...Css.addIn("& > div:nth-of-type(n + 7)", Css.bl.bcGray200.$).$,
};

const rowStyles: RowStyles<Row> = {
  total: {
    cellCss: Css.py3.$,
  },
  head: { rowCss: cellBorderStyles },
  plan: { rowCss: cellBorderStyles },
  option: { rowCss: cellBorderStyles },
  iti: { rowCss: cellBorderStyles },
};

function createRows(bidPackage: PageBidPackageDetailFragment): GridDataRow<Row>[] {
  const requests = bidPackage.requests;
  const tradeIds = requests.map((r) => r.tradePartner.id);
  const itemRows = bidPackage.scopeLines.map(({ itemTemplateItem }) => ({
    kind: "iti" as const,
    id: itemTemplateItem.id,
    data: {
      item: itemTemplateItem,
      // For each materialized scope line, each trade could have bid at most once on it
      costData: requests.keyBy(
        (bpr) => bpr.tradePartner.id,
        (bpr) => {
          // if bpr.bidcontract.type is unit based then use the bid contract line item total cost
          if (bpr.bidContract?.type?.code === BidContractType.UnitBased) {
            const bcli = bpr.bidContract?.latestRevision.lineItems.find(
              (li) => li.bidItem?.id === itemTemplateItem.bidItem?.id,
            );
            // this is the unit cost on the BCLI
            return (bcli?.totalCostInCents ?? 0) * (itemTemplateItem.quantity || 1);
          }
          // for prorated bids we would want to use the itemtemplateitem cost in cents
          const revisionBcli = bpr.bidContract?.latestRevision.lineItems.find(
            (li) => li.itemTemplateItem?.id === itemTemplateItem.id,
          );
          // These are plan based iti therefore the total cost
          return revisionBcli?.totalCostInCents ?? 0;
        },
      ),
    },
  }));

  const optionGroupBy = groupBy(itemRows, (i) => getOptionsKey(i.data.item.options));
  const optionRows = Object.keys(optionGroupBy).map((key) => {
    const children = optionGroupBy[key];
    const { item } = children[0].data;
    return {
      kind: "option" as const,
      id: key,
      data: {
        options: item.options,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children,
    };
  });

  const planGroupBy = groupBy(optionRows, (o) => o.children[0].data.item.template.readyPlan!.id);
  const planRows = Object.keys(planGroupBy).map((key, i) => {
    const children = planGroupBy[key];
    return {
      kind: "plan" as const,
      id: key,
      data: {
        plan: children[0].children[0].data.item.template.readyPlan!,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children: children,
    };
  });

  /*
    Not using the header/totals row type because it makes assumptions about placement, which we don't want to make.
    Using pin to prevent the total and head rows from being hidden when searching
  */
  const optionTypesGrouped = bidPackage.scopeLines
    .flatMap((scopeLine) => scopeLine.itemTemplateItem.options)
    .unique()
    .groupBy((o) => o.type.id);

  const optionTypesCount = Object.entries(optionTypesGrouped).map(([, options]) => ({
    optionType: options[0].type.name,
    count: options.length,
  }));
  return [
    {
      kind: "total" as const,
      id: "total",
      data: {
        productOfferingCount: bidPackage.productOfferings.length,
        optionTypesCount,
      },
      pin: "first",
    },
    { kind: "head" as const, id: "head", data: undefined, pin: "last" },
    ...planRows,
  ];
}

type TotalRow = {
  kind: "total";
  data: {
    productOfferingCount: number;
    optionTypesCount: { optionType: string; count: number }[];
  };
};
type HeaderRow = { kind: "head"; data: undefined };
type PlanRow = { kind: "plan"; data: { plan: NamedFragment; costData: TradeCostData } };
type OptionRow = { kind: "option"; data: { options: NamedFragment[]; costData: TradeCostData } };
type ITIRow = { kind: "iti"; data: { item: BidPackageItemTemplateItemFragment; costData: TradeCostData } };

type Row = HeaderRow | TotalRow | PlanRow | OptionRow | ITIRow;

function createColumns(
  requests: BidPackageDetailRequestFragment[],
  createProratedBid: () => void,
  requestsWithoutContracts: BidPackageDetailRequestFragment[],
  onEditProratedBid: (bidContractRevisionId: string) => void,
): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({
      total: ({ productOfferingCount, optionTypesCount }) => ({
        content: (
          <div css={Css.df.w100.aic.gap2.$}>
            <div css={Css.xlSb.$}>Bid Total</div>
            <Chip text={`${productOfferingCount} ${pluralize(productOfferingCount, "Offering")}`} type="neutral" />
            {optionTypesCount.map(({ optionType, count }) => (
              <Chip key={optionType} text={`${count} ${pluralize(count, optionType)}`} type="neutral" />
            ))}
            <div css={Css.mla.$}>
              <Button
                variant="text"
                label="Add Bid"
                disabled={requestsWithoutContracts.length === 0 && "All scope has existing bid"}
                onClick={createProratedBid}
              />
            </div>
          </div>
        ),
        colspan: 6 + requests.length,
      }),
    }),
    column<Row>({
      total: emptyCell,
      head: "Code",
      plan: emptyCell,
      option: emptyCell,
      iti: ({ item }) => <DropCodeCell scope={item} />,
      w: "90px",
    }),
    column<Row>({
      total: emptyCell,
      head: "Name",
      plan: ({ plan }) => ({
        content: plan.name,
        css: Css.smSb.$,
      }),
      option: ({ options }) => options.map((o) => o.name).join(", ") || "Base",
      iti: ({ item }) => item.name,
    }),
    column<Row>({
      total: emptyCell,
      head: "Material Code",
      plan: emptyCell,
      option: emptyCell,
      iti: ({ item }) => item.bidItem?.parentMaterialVariant?.code,
      w: "120px",
    }),
    column<Row>({
      total: emptyCell,
      head: "Qty",
      plan: emptyCell,
      option: emptyCell,
      iti: ({ item }) => item.quantity,
      w: "53px",
    }),
    column<Row>({
      total: emptyCell,
      head: "UoM",
      plan: emptyCell,
      option: emptyCell,
      iti: ({ item }) => item.unitOfMeasure.shortName,
      w: "67px",
    }),
    ...requests.map((request) => {
      const isProratedBid =
        request.bidContract?.type?.code &&
        [BidContractType.GroupedByMaterial, BidContractType.GroupedByPlanAndOption].includes(
          request.bidContract?.type?.code,
        );

      return numericColumn<Row>({
        total: emptyCell,
        head: () => (
          <div css={Css.w100.df.aic.$}>
            <div css={Css.mra.$}>{request.tradePartner.name}</div>
            {isProratedBid && (
              <Tooltip title="Edit your prorated bid" placement="top">
                <IconButton
                  icon="estimate"
                  onClick={() => onEditProratedBid(request.bidContract!.latestRevision.id)}
                  color={Palette.Blue600}
                />
              </Tooltip>
            )}
          </div>
        ),
        plan: ({ costData }) =>
          costData[request.tradePartner.id] ? (
            <div css={Css.sm.$}>
              <Price valueInCents={costData[request.tradePartner.id]} />
            </div>
          ) : (
            emptyCell
          ),
        option: ({ costData }) =>
          costData[request.tradePartner.id]
            ? priceCell({ valueInCents: costData[request.tradePartner.id] })
            : emptyCell,
        iti: ({ costData }) =>
          costData[request.tradePartner.id] ? <Price valueInCents={costData[request.tradePartner.id]} /> : emptyCell,
      });
    }),
  ];
}
