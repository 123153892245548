import {
  ApolloError,
  ServerError,
  useLazyQuery as apolloUseLazyQuery,
  useMutation as apolloUseMutation,
  useQuery as apolloUseQuery,
} from "@apollo/client";
import { Button, Css, UseToastProps, useAutoSaveStatus, useToast } from "@homebound/beam";
import { useCallback, useEffect, useRef } from "react";

export type { LazyQueryHookOptions, MutationHookOptions, QueryHookOptions } from "@apollo/client";

/** Wraps the Apollo `useQuery` with default `onError` behavior. */
export const useQuery: typeof apolloUseQuery = function (query, options) {
  const { showToast } = useToast();
  const onError = useCallback((error: ApolloError) => toastOnError("query", showToast, error), [showToast]);
  return apolloUseQuery(query, { onError, ...options });
};

/** Wraps the Apollo `useQuery` with default `onError` behavior. */
export const useLazyQuery: typeof apolloUseLazyQuery = function (query, options) {
  const { showToast } = useToast();
  const onError = useCallback((error: ApolloError) => toastOnError("query", showToast, error), [showToast]);
  return apolloUseLazyQuery(query, { onError, ...options });
};

/**
 * Wraps the `Apollo.useMutation` hook with `useAutoSaveStatus` integration.
 *
 * This means that any mutation in the app will automatically register its
 * in-flight status with the right contextual auto-save indicator (e.g. if
 * a mutation is in a super drawer, and the super drawer has a custom/local
 * save indicator, then the mutation will trigger that indicator and not
 * a global/page-level indicator.
 */
export const useMutation: typeof apolloUseMutation = function (mutation, options) {
  const { showToast } = useToast();

  const onError = useCallback((error: ApolloError) => toastOnError("mutation", showToast, error), [showToast]);

  const { triggerAutoSave, resolveAutoSave } = useAutoSaveStatus();
  const triggered = useRef(false);

  const result = apolloUseMutation(mutation, { onError, ...options });
  const isLoading = result[1].loading;

  useEffect(() => {
    // Only trigger/resolve if we've changed our status
    if (isLoading !== triggered.current) {
      isLoading ? triggerAutoSave() : resolveAutoSave();
      triggered.current = isLoading;
    }
    return () => {
      // On unmount, if we have an outstanding auto save, resolve it
      triggered.current && resolveAutoSave();
    };
  }, [isLoading, triggerAutoSave, resolveAutoSave]);

  return result as any;
};

// An array of these message+locations+path keys is what the GraphQL spec (probably)
// says will be in the result["errors"] key (i.e. I have not actually read the spec,
// this is just observed behavior of our GraphQL server).
type GraphQLError = { message: string; locations: unknown; path: unknown };

function toastOnError(type: "query" | "mutation", showToast: UseToastProps["showToast"], error: ApolloError): void {
  const result = (error.networkError as ServerError)?.result as any;
  const errors = result?.errors as GraphQLError[] | undefined;

  // If the call failed for some not-our-backend's fault, i.e. maybe the cognito token
  // not refresh, still tell the user that they're operation/save didn't work.
  const message = !result || !errors || errors.length === 0 ? error.message : errors.map((e) => e.message).join(", ");

  // Show the toast
  showToast({ type: "error", message: generateErrorToastMessage(message) });

  // Trigger a console.error for DataDog RUM to report on.
  console.error(message);

  // If a component is invoking a mutation in an event callback like:
  //
  // const [saveAuthor] = useSaveAuthor();
  //
  // function onClick() {
  //   const { data } = await saveMutation(...)
  //   console.log(data.foo.bar);
  // }
  //
  // The default Apollo behavior is for that promise to be rejected, and so
  // the console.log will never happen. For our toastOnError instrumentation to
  // maintain the same behavior, we need to re-throw the error from our onError.
  if (type === "mutation") {
    throw error;
  }
}

function generateErrorToastMessage(message: string): React.ReactNode {
  if (message === "Graphql validation error") {
    return (
      <div css={Css.df.jcsb.aic.$}>
        <span>
          This version of Blueprint is out of date and your request could not be processed. You need to reload the page
          to continue.
        </span>
        <Button label="Reload" variant="secondary" onClick={() => window.location.reload()} />
      </div>
    );
  } else {
    return message;
  }
}
