import { BoundCheckboxField, Css, maybeTooltip } from "@homebound/beam";
import { ObjectConfig, useFormStates } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import {
  InputMaybe,
  TaskDetailsPage_PlanTaskFragment,
  TaskStatus,
  useDeletePlanTaskChecklistItemMutation,
  useSavePlanTaskChecklistItemMutation,
} from "src/generated/graphql-types";

type VerificationChecklistProps = {
  planTask: TaskDetailsPage_PlanTaskFragment;
};

type VerificationChecklistItem = {
  checklistItemId?: string;
  globalItemId: string;
  planTaskId: string;
  completed: InputMaybe<boolean>;
};

export function VerificationChecklist(props: VerificationChecklistProps) {
  const { planTask } = props;
  const [savePlanTaskChecklistItem] = useSavePlanTaskChecklistItemMutation();
  const [deletePlanTaskChecklistItem] = useDeletePlanTaskChecklistItemMutation();

  const { verificationItems, globalItemsById, taskIsComplete, maybeTooltipText } = useMemo(() => {
    const { checklistItems, globalChecklistItems, status } = planTask;
    const globalItemsById = globalChecklistItems.keyBy((item) => item.id);
    const checklistItemsByGlobalId = checklistItems.keyBy((item) => item.globalItem.id);
    const verificationItems: VerificationChecklistItem[] = globalChecklistItems.map((gptci) => ({
      globalItemId: gptci.id,
      checklistItemId: checklistItemsByGlobalId[gptci.id]?.id,
      planTaskId: planTask.id,
      completed: checklistItemsByGlobalId[gptci.id] !== undefined,
    }));

    const taskIsComplete = status.code === TaskStatus.Complete;
    const maybeTooltipText = taskIsComplete ? "Cannot modify checklist items on completed tasks" : undefined;

    return { verificationItems, globalItemsById, taskIsComplete, maybeTooltipText };
  }, [planTask]);

  // The formStates will be accessed by the VerificationChecklistItem's globalItemId
  const { getFormState } = useFormStates<VerificationChecklistItem>({
    config,
    getId: (item) => item.globalItemId,
    autoSave: async (formState) => {
      const { globalItemId, completed, checklistItemId } = formState;

      if (completed.value === true) {
        await savePlanTaskChecklistItem({
          variables: { input: { globalItemId: globalItemId.value, planTaskId: planTask.id } },
        });
      } else if (checklistItemId.value) {
        await deletePlanTaskChecklistItem({ variables: { input: { id: checklistItemId.value } } });
      }
      formState.commitChanges();
    },
  });
  return (
    <Observer>
      {() => (
        <div>
          <h2 css={Css.baseSb.gray800.mb2.$}>Verification Checklist</h2>
          {verificationItems.map((item) => {
            const fs = getFormState(item);
            return maybeTooltip({
              title: maybeTooltipText,
              children: (
                <div key={item.globalItemId} css={Css.df.jcsb.gray700.pt1.$}>
                  <BoundCheckboxField
                    label={globalItemsById[item.globalItemId].name}
                    field={fs.completed}
                    disabled={taskIsComplete}
                  />
                </div>
              ),
            });
          })}
        </div>
      )}
    </Observer>
  );
}

const config: ObjectConfig<VerificationChecklistItem> = {
  checklistItemId: { type: "value" },
  globalItemId: { type: "value" },
  planTaskId: { type: "value" },
  completed: { type: "value" },
};
