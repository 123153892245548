import { Checkbox, Css, Icon, IconKey, Tooltip } from "@homebound/beam";
import { FieldState, ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import {
  CommunicationChannel,
  CommunicationChannelDetail,
  InputMaybe,
  SaveUserCommunicationPreferenceInput,
  UserCommPreferences_UserCommunicationPreferenceFragment,
  useCommunicationChannelsQuery,
  useSaveUserCommunicationPreferenceMutation,
  useUserCommunicationPreferencesQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export type UserCommunicationPreferencesProps = {
  userId: string;
};

export function UserCommunicationPreferences({ userId }: UserCommunicationPreferencesProps) {
  const query = useUserCommunicationPreferencesQuery({ variables: { user: userId } });

  return queryResult(query, ({ userCommunicationPreferences }) => (
    <UserCommunicationPreferencesView userId={userId} userPreferences={userCommunicationPreferences} />
  ));
}

function UserCommunicationPreferencesView({
  userId,
  userPreferences,
}: {
  userId: string;
  userPreferences: UserCommPreferences_UserCommunicationPreferenceFragment | null | undefined;
}) {
  const { data: commChannelsQuery } = useCommunicationChannelsQuery({ nextFetchPolicy: "cache-first" });
  const [saveUserPreferencesMutation] = useSaveUserCommunicationPreferenceMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      userId,
      commentPreference: userPreferences?.commentPreference,
      tpAvailabilityRequestPreference: userPreferences?.tpAvailabilityRequestPreference,
    },
    autoSave: (preference) =>
      saveUserPreferencesMutation({
        variables: { input: preference.value },
      }),
  });

  const updateUserPreference = useCallback(
    (communication: ScheduleCommunication, code: CommunicationChannel, selected: boolean) => {
      const formValue = formState[communication].value;
      formState[communication].set(selected ? [...(formValue ?? []), code] : formValue?.filter((c) => c !== code));
    },
    [formState],
  );

  const sections = useMemo(
    () => [
      {
        icon: "task" as const,
        title: "Schedule",
        communications: [
          {
            description: "Request for your availability for a schedule task.",
            field: formState.tpAvailabilityRequestPreference,
            save: (code: CommunicationChannel, selected: boolean) =>
              updateUserPreference("tpAvailabilityRequestPreference", code, selected),
          },
          {
            description: "You are @ mentioned in a comment (only comments on schedule tasks).",
            field: formState.commentPreference,
            save: (code: CommunicationChannel, selected: boolean) =>
              updateUserPreference("commentPreference", code, selected),
          },
        ],
      },
    ],
    [formState.commentPreference, formState.tpAvailabilityRequestPreference, updateUserPreference],
  );

  return (
    <div css={Css.df.w100.h("70vh").fdc.aic.gap4.$}>
      <div css={Css.df.w75.fdr.aic.jcc.gap2.$}>
        <div css={Css.df.w75.$}>
          <span css={Css.xlSb.gray900.$}>Communication Preferences</span>
        </div>
        <div css={Css.df.w25.jcc.$}>
          {commChannelsQuery?.communicationChannels.map(({ name, code }) => (
            <span key={code} css={Css.df.jcc.wPx(100).sm.gray700.$}>
              {name}
            </span>
          ))}
        </div>
      </div>
      <div css={Css.df.w75.gap2.fdc.$}>
        {sections.map(({ title, icon, communications }) => (
          <CommunicationPreferenceSection
            key={title}
            title={title}
            icon={icon}
            communications={communications}
            communicationChannels={commChannelsQuery?.communicationChannels ?? []}
          />
        ))}
      </div>
    </div>
  );
}

const formConfig: ObjectConfig<SaveUserCommunicationPreferenceInput> = {
  userId: { type: "value", rules: [required] },
  commentPreference: { type: "value" },
  tpAvailabilityRequestPreference: { type: "value" },
};

type ScheduleCommunication = keyof Pick<
  SaveUserCommunicationPreferenceInput,
  "commentPreference" | "tpAvailabilityRequestPreference"
>;

type CommunicationPreference = {
  field: FieldState<InputMaybe<CommunicationChannel[]>>;
  description: string;
  disabledReason?: string;
  save: (code: CommunicationChannel, selected: boolean) => void;
};

type CommunicationPreferenceSectionProps = {
  title: string;
  icon: IconKey;
  communications: CommunicationPreference[];
  communicationChannels: CommunicationChannelDetail[];
};

function CommunicationPreferenceSection({
  title,
  icon,
  communications,
  communicationChannels,
}: CommunicationPreferenceSectionProps) {
  return (
    <div css={Css.df.w100.fg1.fdc.bshBasic.pl4.pt4.bgWhite.br8.$}>
      <div css={Css.df.gap2.$}>
        <Icon icon={icon} />
        <span css={Css.baseSb.gray900.$}>{title}</span>
      </div>
      <div css={Css.df.fdc.gap2.pl4.py4.$}>
        {communications.map((communication, i) => (
          <CommunicationPreferenceCard
            key={i}
            communication={communication}
            communicationChannels={communicationChannels}
          />
        ))}
      </div>
    </div>
  );
}

type CommunicationPreferenceCardProps = {
  communication: CommunicationPreference;
  communicationChannels: CommunicationChannelDetail[];
};

function CommunicationPreferenceCard({ communication, communicationChannels }: CommunicationPreferenceCardProps) {
  const { field, description, disabledReason, save } = communication;

  return (
    <div css={Css.df.$}>
      <span css={Css.df.w75.aic.$}>{description}</span>

      <div css={Css.df.w25.jcc.$}>
        {communicationChannels?.map(({ name, code }) => (
          <div key={code} css={Css.df.jcc.wPx(100).$}>
            <Tooltip title={disabledReason} disabled={!disabledReason}>
              <Observer>
                {() => (
                  <Checkbox
                    label={name}
                    // At least one comms channel must be selected
                    disabled={!!disabledReason || (field.value?.length === 1 && field.value?.includes(code))}
                    selected={field.value?.includes(code) ?? false}
                    onChange={(val) => save(code, val)}
                    checkboxOnly
                  />
                )}
              </Observer>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
}
