import {
  Css,
  GridTable,
  Icon,
  Palette,
  RowStyles,
  Tag,
  TagType,
  column,
  emptyCell,
  simpleHeader,
} from "@homebound/beam";
import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { createListViewScheduleUrl, createTaskDetailsPageUrl } from "src/RouteUrls";
import { FormattedDate, ProgressPill, formatDate } from "src/components";
import {
  DynamicSchedulesMilestoneSnapshot_PlanMilestoneSnapshotFragment,
  Maybe,
  PlanMilestoneDetailsPage_PlanMilestoneFragment,
  PlanMilestoneDetailsPage_PlanTaskFragment,
  TaskStatus,
  usePlanMilestoneDetailsPageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { projectsPath } from "src/routes/routesDef";
import { pluralize, queryResult } from "src/utils";
import { EmptyPlanMilestoneImage } from "../components/EmptyStateSvg";
import { ScheduleDateVariance } from "../components/ScheduleVariance";
import { ScheduleSnapshotsHookResult, useScheduleSnapshots } from "../components/useScheduleSnapshots";

export function MilestoneDetailsPage() {
  const { planMilestoneId } = useParams<{ planMilestoneId: string; projectId: string }>();
  const query = usePlanMilestoneDetailsPageQuery({ variables: { planMilestoneId: planMilestoneId } });
  return queryResult(query, ({ planMilestone }) => {
    return <MilestoneDetailsPageView planMilestone={planMilestone} />;
  });
}

type MilestoneDetailsPageViewProps = {
  planMilestone: PlanMilestoneDetailsPage_PlanMilestoneFragment;
};

export function MilestoneDetailsPageView({ planMilestone }: MilestoneDetailsPageViewProps) {
  const { name, planSchedule, progress } = planMilestone;
  return (
    <>
      <PageHeader
        title={name}
        breadcrumb={[
          { label: "Projects", href: projectsPath },
          {
            label: planSchedule.parent.buildAddress.street1,
            href: createListViewScheduleUrl(planSchedule.parent.id),
          },
        ]}
        left={<ProgressPill progress={progress} />}
      />
      <PlanMilestonePageDetailsContent planMilestone={planMilestone} parentId={planSchedule.parent.id} />
    </>
  );
}

function PlanMilestonePageDetailsContent({
  planMilestone,
  parentId,
}: {
  planMilestone: PlanMilestoneDetailsPage_PlanMilestoneFragment;
  parentId: string;
}) {
  const { allowingPlanTasks } = planMilestone;

  const { getTaskSnapshot, getPlanMilestoneSnapshot } = useScheduleSnapshots(parentId);

  const columns = useMemo(() => createColumns(getTaskSnapshot), [getTaskSnapshot]);
  const rows = useMemo(() => createRows(allowingPlanTasks), [allowingPlanTasks]);
  const history = useHistory();

  const rowStyles: RowStyles<Row> = useMemo(
    () => ({
      header: {
        cellCss: { ...Css.gray600.bgGray100.$ },
      },
      data: {
        cellCss: ({ data }) => {
          return data.status.code === TaskStatus.Complete
            ? Css.bgGray100.gray600.$
            : isTaskLowConfidence(data)
              ? Css.bgRed50.$
              : Css.bgWhite.$;
        },
        onClick: (row) => history.push(createTaskDetailsPageUrl(parentId, row.data.id)),
      },
    }),
    [history, parentId],
  );

  return (
    <>
      <PlanMilestoneOverview
        planMilestone={planMilestone}
        planMilestoneSnapshot={getPlanMilestoneSnapshot(planMilestone.id)}
      />
      <div css={Css.w100.h("calc(100vh - 160px)").$}>
        {allowingPlanTasks.nonEmpty ? (
          <GridTable as="virtual" columns={columns} rows={rows} rowStyles={rowStyles} />
        ) : (
          <EmptyMilestoneDetailsPage />
        )}
      </div>
    </>
  );
}

function PlanMilestoneOverview({
  planMilestone,
  planMilestoneSnapshot,
}: {
  planMilestone: PlanMilestoneDetailsPage_PlanMilestoneFragment;
  planMilestoneSnapshot: DynamicSchedulesMilestoneSnapshot_PlanMilestoneSnapshotFragment | undefined;
}) {
  const { durationInDays, estEndDate, estStartDate, confidence, allowingPlanTasks } = planMilestone;
  const data = useMemo(() => {
    // if we don't have tasks, just return a single "No Tasks" pie chart
    if (allowingPlanTasks.isEmpty) return [{ id: "No Tasks", label: "No Tasks", value: 100, color: Palette.Gray300 }];

    const { completedTasks, inProgressTasks, notStartedTasks } = getTaskStatusCounts(allowingPlanTasks);
    return [
      { id: "Not Started", label: "Not Started", value: notStartedTasks, color: Palette.Blue200 },
      { id: "In Progress", label: "In Progress", value: inProgressTasks, color: Palette.Blue500 },
      { id: "Complete", label: "Complete", value: completedTasks, color: Palette.Blue700 },
    ];
  }, [allowingPlanTasks]);
  return (
    <>
      <div css={Css.lgSb.mb1.$}>Overview</div>
      <div css={Css.df.jcsb.aic.pyPx(13).pxPx(36).bgWhite.br8.boxShadow("0px 0px 13px 0px rgba(0, 0, 0, 0.05)").mb2.$}>
        <div css={Css.df.hPx(100).wPx(280).$} data-testid="taskStatusChart">
          <ResponsivePie
            data={data}
            colors={{ datum: "data.color" }}
            innerRadius={0.5}
            enableArcLabels={false}
            enableArcLinkLabels={false}
          />
          <PlanMilestonePieChartLegend allowingPlanTasks={allowingPlanTasks} />
        </div>

        <div css={Css.df.gapPx(126).$}>
          <div css={Css.df.aic.gap1.$}>
            {confidence <= 50_00 && allowingPlanTasks.nonEmpty && (
              <Icon
                icon="errorCircle"
                color={Palette.Red600}
                xss={Css.mr1.$}
                data-testid="errorCircleIcon"
                tooltip="There is low confidence of this milestone being completed on its estimated end date."
              />
            )}
            <div css={Css.df.fdc.gapPx(4).$}>
              <span css={Css.xsMd.gray700.$}>Confidence</span>
              <PlanMilestoneConfidence confidence={confidence} />
            </div>
          </div>
          <div css={Css.df.fdc.gapPx(4).$}>
            <span css={Css.xsMd.gray700.$}>Actual Start</span>
            {/* ToDo: Update estStartDate when we have snapshot data */}
            {estStartDate ? (
              <FormattedDate date={estStartDate.date} dateFormatStyle="monthShort" xss={Css.baseSb.$} />
            ) : (
              "--"
            )}
          </div>
          <div css={Css.df.fdc.gapPx(4).$}>
            <span css={Css.xsMd.gray700.gap1.$}>Est. End</span>
            {estEndDate ? (
              <div css={Css.df.baseSb.$}>
                <FormattedDate date={estEndDate.date} dateFormatStyle="monthShort" xss={Css.baseSb.$} />
                <ScheduleDateVariance current={estEndDate} baseline={planMilestoneSnapshot?.estEndDate} />
              </div>
            ) : (
              "--"
            )}
          </div>
          <div css={Css.df.fdc.gapPx(4).$}>
            <span css={Css.xsMd.gray700.$}>Total Days</span>
            <span css={Css.baseSb.$}>
              {durationInDays === 0 ? "--" : `${durationInDays} ${pluralize(durationInDays, "day", "days")}`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: PlanMilestoneDetailsPage_PlanTaskFragment };

export type Row = HeaderRow | DataRow;

function createColumns(getTaskSnapshot: ScheduleSnapshotsHookResult["getTaskSnapshot"]) {
  return [
    column<Row>({
      header: { content: "Tasks in this Milestone", css: Css.lgSb.truncate.gray900.pl0.$ },
      data: ({ name }) => name,
      mw: "250px",
    }),
    column<Row>({
      header: emptyCell,
      data: (task) =>
        isTaskLowConfidence(task) ? (
          <Icon
            icon="errorCircle"
            data-testid="errorCircleIcon"
            color={Palette.Red600}
            tooltip="There is low confidence of this task being completed on its estimated end date."
          />
        ) : (
          emptyCell
        ),
      align: "right",
      mw: "20px",
    }),
    column<Row>({
      header: "Original Start",
      data: ({ id }) => {
        const startDate = getTaskSnapshot(id)?.startDate;
        return startDate ? formatDate(startDate, "monthShort") : "--";
      },
      mw: "50px",
    }),
    column<Row>({
      header: "Actual Start",
      data: ({ startDate, id }) => (
        <>
          {formatDate(startDate, "monthShort")}
          <ScheduleDateVariance current={startDate} baseline={getTaskSnapshot(id)?.startDate} />
        </>
      ),
      mw: "50px",
    }),
    column<Row>({
      header: "Original End",
      data: ({ id }) => {
        const endDate = getTaskSnapshot(id)?.endDate;
        return endDate ? formatDate(endDate, "monthShort") : "--";
      },
      mw: "50px",
    }),
    column<Row>({
      header: "Est. End",
      data: ({ endDate, id }) => (
        <>
          {formatDate(endDate, "monthShort")}{" "}
          <ScheduleDateVariance current={endDate} baseline={getTaskSnapshot(id)?.endDate} />
        </>
      ),
      mw: "50px",
    }),
    column<Row>({
      header: "Constraints",
      data: ({ constraintItems }) => constraintItems.length,
      mw: "50px",
    }),

    column<Row>({
      header: "Status",
      data: (task) => <Tag type={getPlanTaskStatusToTagTypeMapper(task)} text={task.status.name} />,
      mw: "50px",
    }),
  ];
}

function createRows(data: PlanMilestoneDetailsPage_PlanTaskFragment[]) {
  return [
    simpleHeader,
    ...data.map((pt) => ({
      id: pt.id,
      kind: "data" as const,
      data: pt,
    })),
  ];
}

function getTaskStatusCounts(tasks: PlanMilestoneDetailsPage_PlanTaskFragment[]) {
  const groupedByStatus = tasks.groupBy(({ status }) => status.code);
  const completed = groupedByStatus[TaskStatus.Complete] ?? [];
  const inProgress = groupedByStatus[TaskStatus.InProgress] ?? [];
  const notStarted = groupedByStatus[TaskStatus.NotStarted] ?? [];

  return { completedTasks: completed.length, inProgressTasks: inProgress.length, notStartedTasks: notStarted.length };
}

function PlanMilestoneConfidence({ confidence }: { confidence: Maybe<number> }) {
  if (!confidence) return "--";

  if (confidence >= 80_00) return <span css={Css.baseSb.$}>High</span>;
  if (confidence >= 50_00) return <span css={Css.baseSb.$}>Medium</span>;
  return <span css={Css.baseSb.$}>Low</span>;
}

function PlanMilestonePieChartLegend({
  allowingPlanTasks,
}: {
  allowingPlanTasks: PlanMilestoneDetailsPage_PlanTaskFragment[];
}) {
  return allowingPlanTasks.isEmpty ? (
    <div css={Css.df.w100.jcc.fdc.gapPx(4).tiny.$}>
      <div css={Css.df.aic.gap1.$}>
        <div css={Css.h1.w1.br100.bgGray300.$} />
        No Tasks
      </div>
    </div>
  ) : (
    <div css={Css.df.w100.jcc.fdc.gapPx(4).tiny.br.bw1.bcGray200.$}>
      <div css={Css.df.aic.gap1.$}>
        <div css={Css.h1.w1.br100.bgBlue700.$} />
        Tasks Complete
      </div>
      <div css={Css.df.aic.gap1.$}>
        <div css={Css.h1.w1.br100.bgBlue500.$} />
        Tasks In Progress
      </div>
      <div css={Css.df.aic.gap1.$}>
        <div css={Css.h1.w1.br100.bgBlue200.$} />
        Tasks Not Started
      </div>
    </div>
  );
}

function isTaskLowConfidence(task: PlanMilestoneDetailsPage_PlanTaskFragment) {
  return task.simulationProbability?.endDateBasisPoints && task.simulationProbability?.endDateBasisPoints <= 50_00;
}

function EmptyMilestoneDetailsPage() {
  return (
    <div data-testid="emptyState" css={Css.df.aic.jcc.fdc.bgGray200.br8.p4.h100.$}>
      <EmptyPlanMilestoneImage />
      <p css={Css.smMd.gray700.mt2.$}>No tasks have been added to this Milestone.</p>
    </div>
  );
}

function getPlanTaskStatusToTagTypeMapper(task: PlanMilestoneDetailsPage_PlanTaskFragment): TagType {
  if (task.status.code === TaskStatus.InProgress) return "info";
  if (task.status.code === TaskStatus.NotStarted) return "caution";
  if (task.status.code === TaskStatus.Complete) return "neutral";
  // If we don't have a status, show a warning tag
  return "warning";
}
