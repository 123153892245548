import {
  BoundNumberField,
  cardStyle,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  OpenDetailOpts,
  Palette,
  px,
  simpleHeader,
  Tag,
  TagType,
  Tooltip,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState, useFormStates } from "@homebound/form-state";
import { Maybe } from "graphql/jsutils/Maybe";
import { useCallback, useMemo } from "react";
import { CostSourceBoundSelectField } from "src/components/autoPopulateSelects/CostSourceBoundSelectField";
import {
  BidContractRevisionStatus,
  HomeownerSelectionInput,
  HomeownerSelectionOptionInput,
  HomeownerSelectionStatus,
  ProductAttributeType,
  SelectionOptions_HomeownerSelectionFragment,
  SelectionPricingMode,
  SelectionsTab_HomeownerSelectionFragment,
  SelectionsTab_PotentialBidItemFragment,
  SelectionsTab_ProductFragment,
  SelectionsTabOptionFragment,
  useSaveHomeownerSelectionOptionMutation,
  useSaveHomeownerUndecidedSelectionMutation,
} from "src/generated/graphql-types";
import { HomeownerSelectionOptionForm } from "src/routes/projects/selections/options/homeownerSelectionOptionDetails/HomeownerSelectionOptionForm";
import { deriveFromInput, setupPriceFields } from "src/routes/projects/selections/recalc";

type SelectionOptionsTableProps = {
  selection: SelectionsTab_HomeownerSelectionFragment;
  options: SelectionsTabOptionFragment[];
  potentialBidItems: SelectionsTab_PotentialBidItemFragment[];
  view: "currentSelection" | "selectionOptionsList";
};

export function SelectionOptionsTable({ selection, options, view, potentialBidItems }: SelectionOptionsTableProps) {
  const showUndecidedOption =
    (selection.status.code === HomeownerSelectionStatus.Undecided && view === "currentSelection") ||
    (selection.status.code !== HomeownerSelectionStatus.Undecided && view === "selectionOptionsList");
  const { openDrawerDetail } = useSuperDrawer();

  // Wrap this with useCallbacks to provide the variables+input
  const [saveOptionMutation] = useSaveHomeownerSelectionOptionMutation();
  const saveOption = useCallback(
    async (input: HomeownerSelectionOptionInput) => saveOptionMutation({ variables: { input } }),
    [saveOptionMutation],
  );
  const [saveSelectionMutation] = useSaveHomeownerUndecidedSelectionMutation();
  const saveSelection = useCallback(
    (input: HomeownerSelectionInput) => saveSelectionMutation({ variables: { input } }),
    [saveSelectionMutation],
  );

  // This formState manages the Undecided selection option
  const formState = useFormState({
    config: undecidedOptionFormConfig,
    init: { input: selection, map: mapUndecidedForm },
    async autoSave(hs) {
      // Don't submit markupPercentage to the server
      const { markupPercentage, ...rest } = hs.value;
      await saveSelection(rest);
      formState.commitChanges();
    },
    addRules(formState) {
      // Recalc cost/markup/price for undecided selection
      setupPriceFields(
        {
          unitPriceInCents: formState.undecidedUnitPriceInCents,
          unitCostInCents: formState.undecidedUnitCostInCents,
          markupPercentage: formState.markupPercentage,
        },
        { canEditPrice: selection.canEdit.allowed },
      );
    },
    readOnly: !selection.canEdit.allowed,
  });

  // This formState manages the selection options
  const { getFormState } = useFormStates({
    config: formConfig,
    map: mapSelectionOptionForm,
    getId: (o: SelectionsTabOptionFragment) => o.id,
    autoSave: async (option: ObjectState<SelectionOptionFormValue>) => {
      const { markupPercentage, ...rest } = option.changedValue;
      await saveOption({
        ...(markupPercentage && !rest.unitPriceInCents && { markupBasisPoints: markupPercentage * 100 }),
        ...rest,
        homeownerSelectionId: selection.id,
      });
      option.commitChanges();
    },
    addRules(formState) {
      // Recalc cost/markup/price for each selection
      setupPriceFields(
        {
          unitPriceInCents: formState.unitPriceInCents,
          unitCostInCents: formState.unitCostInCents,
          markupPercentage: formState.markupPercentage,
        },
        { canEditPrice: selection.canEdit.allowed },
      );
    },
  });

  const rows: GridDataRow<Row>[] = useMemo(
    () => [
      simpleHeader,
      // Don't show undecided option in the list if selection is undecided (it will be displayed as currently selected)
      ...(showUndecidedOption ? [{ kind: "undecided" as const, id: selection.id, data: selection, formState }] : []),
      ...(options?.map((o) => ({ kind: "data" as const, id: o.id, data: o, objectState: getFormState(o) })) || []),
    ],
    [options, formState, getFormState, selection, showUndecidedOption],
  );

  const columns = useMemo(
    () =>
      createColumns(
        saveOption,
        saveSelection,
        selection,
        openDrawerDetail,
        selection.selectionPricingMode === SelectionPricingMode.PriceDelta,
        view,
        potentialBidItems,
      ),
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDrawerDetail, saveOption, saveSelection, selection, view],
  );

  return (
    <GridTable
      fallbackMessage="No selection has been made"
      rows={rows}
      columns={columns}
      style={{
        ...cardStyle,
        cellCss: { ...cardStyle.cellCss, ...Css.aic.px1.$ },
        firstCellCss: { ...cardStyle.firstCellCss, ...Css.pl2.$ },
        lastCellCss: { ...cardStyle.lastCellCss, ...Css.pr2.$ },
      }}
    />
  );
}

type DataRow = { kind: "data"; objectState: ObjectState<SelectionOptionFormValue>; data: SelectionsTabOptionFragment };
type UndecidedRow = {
  kind: "undecided";
  data: SelectionOptions_HomeownerSelectionFragment;
  formState: ObjectState<UndecidedOptionFormValue>;
};
type HeaderRow = { kind: "header" };
type Row = HeaderRow | UndecidedRow | DataRow;

function createColumns(
  saveHso: (input: HomeownerSelectionOptionInput, option: SelectionOptionFormValue) => Promise<unknown>,
  saveHs: (input: HomeownerSelectionInput) => Promise<unknown>,
  selection: SelectionsTab_HomeownerSelectionFragment,
  openDrawerDetail: (opts: OpenDetailOpts) => void,
  showPricingType: boolean,
  view: string,
  potentialBidItems: SelectionsTab_PotentialBidItemFragment[],
): GridColumn<Row>[] {
  const productColumn = column<Row>({
    header: "Product",
    undecided: () => (
      <div css={Css.relative.$}>
        {selection.status.code === HomeownerSelectionStatus.Undecided && (
          <div css={Css.absolute.left1.right1.bottom1.tac.$}>
            <Tag
              data-testid="undecidedOptionTag"
              text="Selected"
              type="caution"
              // using negative margin to avoid text truncation
              xss={Css.ml(-1).mr(-1).$}
            />
          </div>
        )}
        <div css={Css.w(10).h(10).df.aic.jcc.$}>
          <UndecidedOptionPlaceHolder />
        </div>
      </div>
    ),
    data: (row) => {
      const isLocked = !selection.canEdit.allowed;
      const isFinalized = selection.status.code === HomeownerSelectionStatus.Finalized;

      return (
        <div css={Css.relative.$}>
          {row.isSelectedOption && (
            <div css={Css.absolute.left1.right1.bottom1.tac.$}>
              <Tag
                data-testid="selectedOptionTag"
                text={isLocked ? "Locked" : isFinalized ? "Finalized" : "Selected"}
                type={hsStatusToTagType[getTagType(isFinalized, isLocked)]}
                // using negative margin to avoid text truncation
                xss={Css.ml(-1).mr(-1).$}
              />
            </div>
          )}
          <div css={Css.w(10).h(10).df.aic.jcc.$}>
            <img src={row.product.images[0]?.downloadUrl} alt={row.product.name} css={Css.maxw100.maxh100.$} />
          </div>
        </div>
      );
    },
    w: "102px",
  });

  const nameColumn = column<Row>({
    header: "",
    undecided: "Undecided",
    data: (row) => {
      const finish = row.product.attributes
        .filter((attr) => attr.type === ProductAttributeType.Finish)
        .map((attr) => attr.value)
        .join(", ");
      const isModelDefined = row.product.sku && row.product.sku !== "NO SKU";
      return (
        <div>
          <div
            data-testid="name"
            css={Css.blue700.cursorPointer.$}
            onClick={() => {
              openDrawerDetail({
                content: (
                  <HomeownerSelectionOptionForm
                    homeownerSelectionId={row.homeownerSelection.id}
                    homeownerSelectionOptionId={row.id}
                    showPricingType={showPricingType}
                  />
                ),
              });
            }}
          >
            {row.name ?? ""}
          </div>
          {finish && (
            <div css={Css.xs.$} data-testid="finish">
              Finish: {finish}
            </div>
          )}
          {isModelDefined && (
            <div css={Css.mt(px(4)).xsMd.gray700.fw4.$} data-testid="modelNumber">
              Model: {row.product.sku}
            </div>
          )}
        </div>
      );
    },
    w: 3,
  });

  const costSourceColumn = column<Row>({
    header: "Cost Source",
    undecided: "--",
    data: (data, { row }) => {
      const developmentId = row.data.homeownerSelection.projectItem.project.cohort?.development?.id;
      const bidItemIds = row.data.product.bidItems?.map((bidItem) => bidItem.id) ?? [];
      const potentialBidItemIds = potentialBidItems.map((pbi) => pbi.id);
      return (
        <CostSourceBoundSelectField
          field={row.objectState.bidContractLineItemId}
          value={row.data.bidContractLineItem}
          // Only show BCLIs that are in the potential BCLIs
          filterFn={(bcli) => potentialBidItemIds.includes(bcli.bidItem!.id)}
          readOnly={
            !data.canEditPrice.allowed ||
            // There must be a bid item and development set for potential BCLIs to be fetched
            bidItemIds.isEmpty ||
            !developmentId
          }
          filter={{ developmentId, bidItemIds, status: [BidContractRevisionStatus.Signed] }}
          onSelect={(bcliId, selectedCostSource) => {
            if (!bcliId) {
              // Unset the BCLI if the cost source is manual
              row.objectState.set({ bidContractLineItemId: null });
            } else {
              // If a revision is selected, set the BCLI id and unit cost
              row.objectState.set({
                bidContractLineItemId: bcliId,
                unitCostInCents: selectedCostSource!.unitCostInCents,
              });
            }
          }}
        />
      );
    },
    w: 3,
  });

  const unitCostColumn = column<Row>({
    header: "Unit Cost",
    undecided: (_, { row }) => <BoundNumberField field={row.formState.undecidedUnitCostInCents} type="cents" />,
    data: (data, { row }) => (
      <BoundNumberField
        readOnly={!data.canEditPrice.allowed || !!row.objectState.bidContractLineItemId.value}
        field={row.objectState.unitCostInCents}
        type="cents"
      />
    ),
    w: 2,
  });

  const markupColumn = column<Row>({
    header: "Markup",
    undecided: (_, { row }) => (
      <BoundNumberField field={row.formState.markupPercentage} type="percent" numFractionDigits={2} truncate />
    ),
    data: (data, { row }) => (
      <BoundNumberField
        readOnly={!data.canEditPrice.allowed}
        field={row.objectState.markupPercentage}
        type="percent"
        numFractionDigits={2}
        truncate
      />
    ),
    w: 3,
  });

  const hoPriceColumn = column<Row>({
    header: "HO Price",
    undecided: (_, { row }) => <BoundNumberField field={row.formState.undecidedUnitPriceInCents} type="cents" />,
    data: (data, { row }) => (
      <BoundNumberField readOnly={!data.canEditPrice.allowed} field={row.objectState.unitPriceInCents} type="cents" />
    ),
    w: 2,
  });

  const selectionActionsColumn = column<Row>({
    header: "",
    undecided: (row) =>
      view === "selectionOptionsList" && (
        <div css={Css.jcfe.$}>
          <Tooltip title="Select for Homeowner" delay={0} placement="top">
            <div>
              <IconButton
                icon="check"
                data-testid="undecidedOptionCheck"
                disabled={!selection.canEdit.allowed}
                onClick={async () => {
                  await saveHs({
                    id: row.id,
                    selectedOptionId: null,
                    status: HomeownerSelectionStatus.Undecided,
                  });
                }}
                color={Palette.Gray500}
              />
            </div>
          </Tooltip>
        </div>
      ),
    data: (data, { row }) => (
      <SelectionActions row={data} objectState={row.objectState} selection={selection} saveHso={saveHso} />
    ),
    w: 3,
  });

  return [
    productColumn,
    nameColumn,
    costSourceColumn,
    unitCostColumn,
    markupColumn,
    hoPriceColumn,
    selectionActionsColumn,
  ];
}

const formConfig: ObjectConfig<SelectionOptionFormValue> = {
  id: { type: "value" },
  name: { type: "value" },
  markupPercentage: { type: "value" },
  unitPriceInCents: { type: "value" },
  unitCostInCents: { type: "value" },
  bidContractLineItemId: { type: "value" },
  product: { type: "value" },
};

export type SelectionOptionFormValue = Pick<
  HomeownerSelectionOptionInput,
  "id" | "unitPriceInCents" | "unitCostInCents" | "bidContractLineItemId"
> & {
  name: Maybe<string>;
  markupPercentage: Maybe<number>;
  product: Maybe<SelectionsTab_ProductFragment>;
};

type UndecidedOptionFormValue = Pick<
  HomeownerSelectionInput,
  "id" | "undecidedUnitCostInCents" | "undecidedUnitPriceInCents"
> & {
  markupPercentage: Maybe<number>;
};

const undecidedOptionFormConfig: ObjectConfig<UndecidedOptionFormValue> = {
  id: { type: "value" },
  undecidedUnitCostInCents: { type: "value" },
  undecidedUnitPriceInCents: { type: "value" },
  markupPercentage: { type: "value" },
};

function mapSelectionOptionForm(o: SelectionsTabOptionFragment): SelectionOptionFormValue {
  const { id, name, unitCostInCents, unitPriceInCents, product, bidContractLineItem } = o;
  const { markupPercentage } = deriveFromInput({ quantity: 1, unitCostInCents, unitPriceInCents });
  return {
    id,
    name,
    markupPercentage,
    unitCostInCents,
    unitPriceInCents,
    product,
    bidContractLineItemId: bidContractLineItem?.id,
  };
}

function mapUndecidedForm(hs: SelectionsTab_HomeownerSelectionFragment): UndecidedOptionFormValue {
  const { id, undecidedUnitCostInCents, undecidedUnitPriceInCents } = hs;
  const { markupPercentage } = deriveFromInput({
    unitCostInCents: undecidedUnitCostInCents,
    unitPriceInCents: undecidedUnitPriceInCents,
    quantity: 1,
  });
  return { id, undecidedUnitCostInCents, undecidedUnitPriceInCents, markupPercentage };
}

type HomeownerSelectionStatusLike = "finalized" | "selected" | "locked";

function getTagType(isFinalized: boolean, isLocked: boolean): HomeownerSelectionStatusLike {
  if (isLocked) {
    return "locked";
  }
  if (!isLocked && isFinalized) {
    return "finalized";
  }
  return "selected";
}

const hsStatusToTagType: Record<HomeownerSelectionStatusLike, TagType> = {
  selected: "caution",
  finalized: "info",
  locked: "success",
};

function UndecidedOptionPlaceHolder() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.406 6.08601L12.406 2.08601C12.146 1.97101 11.853 1.97101 11.593 2.08601L2.593 6.08601C2.573 6.09501 2.559 6.11001 2.539 6.12101C2.511 6.13501 2.481 6.14401 2.455 6.16101C2.433 6.17601 2.416 6.19501 2.395 6.21101C2.367 6.23201 2.34 6.25501 2.315 6.27901C2.274 6.31801 2.238 6.36001 2.205 6.40501C2.185 6.43301 2.164 6.45801 2.146 6.48601C2.115 6.53901 2.092 6.59401 2.07 6.65101C2.061 6.67801 2.047 6.70301 2.039 6.73001C2.015 6.81701 2 6.90701 2 7.00001V17C2 17.396 2.232 17.753 2.594 17.914L11.594 21.914C11.724 21.972 11.862 22 12 22C12.139 22 12.273 21.961 12.402 21.904L12.406 21.914L21.406 17.914C21.768 17.753 22 17.396 22 17V7.00001C22 6.60401 21.768 6.24701 21.406 6.08601ZM12 4.09501L18.538 7.00001L12 9.90501L10.692 9.32401L5.463 7.00001L12 4.09501ZM4 16.351V8.53901L11 11.65V19.461L4 16.351ZM13 19.461V11.65L20 8.53901V16.351L13 19.461Z"
        fill="#8D8D8D"
      />
    </svg>
  );
}

type SelectionActionsProps = {
  row: SelectionsTabOptionFragment;
  objectState: ObjectState<SelectionOptionFormValue>;
  saveHso: (input: HomeownerSelectionOptionInput, option: SelectionOptionFormValue) => Promise<unknown>;
  selection: SelectionsTab_HomeownerSelectionFragment;
};

function SelectionActions({ row, objectState, saveHso, selection }: SelectionActionsProps) {
  return (
    <div css={Css.df.jcfe.$}>
      <Tooltip title={row.isRecommendedOption ? "Recommended" : "Recommend"} delay={0} placement="top">
        <div>
          <IconButton
            icon="thumbsUp"
            onClick={() =>
              saveHso(
                {
                  id: objectState.id.value,
                  homeownerSelectionId: selection.id,
                  isRecommendedOption: !row.isRecommendedOption,
                },
                objectState.value,
              )
            }
            color={row.isRecommendedOption ? Palette.Gray900 : Palette.Gray500}
            disabled={!selection.canEdit.allowed}
          />
        </div>
      </Tooltip>
      <Tooltip title={row.isHomeownerVisible ? "Hide from Homeowner" : "Show to Homeowner"} delay={0} placement="top">
        <div>
          <IconButton
            icon="eyeball"
            disabled={row.isSelectedOption || row.isRecommendedOption || !selection.canEdit.allowed}
            onClick={() =>
              saveHso(
                {
                  id: objectState.id.value,
                  homeownerSelectionId: selection.id,
                  isHomeownerVisible: !row.isHomeownerVisible,
                },
                objectState.value,
              )
            }
            color={row.isHomeownerVisible ? Palette.Gray900 : Palette.Gray500}
          />
        </div>
      </Tooltip>
      <Tooltip
        title={row.isSelectedOption ? "Unselect for Homeowner" : "Select for Homeowner"}
        delay={0}
        placement="top"
      >
        <div>
          <IconButton
            icon="check"
            onClick={() =>
              saveHso(
                {
                  id: objectState.id.value,
                  homeownerSelectionId: selection.id,
                  isSelectedOption: !row.isSelectedOption,
                },
                objectState.value,
              )
            }
            color={row.isSelectedOption ? Palette.Gray900 : Palette.Gray500}
            disabled={!selection.canEdit.allowed}
          />
        </div>
      </Tooltip>
    </div>
  );
}
