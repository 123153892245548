import {
  BoundNumberField,
  Button,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  Icon,
  IconButton,
  numericColumn,
  OnRowSelect,
  Palette,
  selectColumn,
  SelectField,
  simpleHeader,
  Tag,
  Tooltip,
  useComputed,
} from "@homebound/beam";
import { useMemo } from "react";
import { priceCell, priceTotal } from "src/components";
import {
  BillEditor_ProjectItemFragment,
  BillEditor_TaskFragment,
  BillType,
  CommitmentStatus,
  ProjectAutocomplete_ProjectFragment,
  useBillEditor_ProjectItemsQuery,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { calcLineItemValues, ComputedTotals } from "src/routes/projects/bills/BillEditorV2";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { commitmentStatusToTagTypeMapper, fail, formatCentsToPrice } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { ListFieldState, ObjectState } from "src/utils/formState";
import { openNewTab } from "src/utils/window";
import { BillFormLineItem, CreateBillFormState } from "../BillEditorV3";
import { OtherBillCell } from "./OtherBillCell";

export type BillLineItemsTableProps = {
  tableApi: GridTableApi<Row>;
  formState: CreateBillFormState;
  isEdit: boolean;
  onUpdateLineItemAmount: () => void;
};

export function BillLineItemsTable(props: BillLineItemsTableProps) {
  const { tableApi, formState, isEdit, onUpdateLineItemAmount } = props;
  const billId = formState.id.value!;
  const field = formState.lineItems;
  const isCredit = !!formState.isTradePartnerCredit.value;
  const type = formState.type.value!;
  const project = formState.project.value;

  const { data } = useBillEditor_ProjectItemsQuery({
    variables: {
      filter: { projectStage: formState.value.project?.stages.map((ps) => ps.id), excludeFromPurchaseOrders: true },
    },
    skip: type !== BillType.Overhead,
    fetchPolicy: "cache-first",
  });
  const [showAddLineItem, toggleAddLineItem] = useToggle();

  const totalsRowData: ComputedTotals = useComputed(
    () =>
      field.rows.reduce(
        (acc, li) => {
          const { otherBilledInCents, thisBilledInCents, unBilledInCents } = calcLineItemValues(li, isCredit);
          return {
            otherBilledInCents: acc.otherBilledInCents + otherBilledInCents,
            thisBilledInCents: acc.thisBilledInCents + thisBilledInCents,
            unBilledInCents: acc.unBilledInCents + unBilledInCents,
            costChangeInCents: acc.costChangeInCents + (li.costChangeInCents.value || 0),
            revisedApprovedBudgetInCents:
              acc.revisedApprovedBudgetInCents + (li.revisedApprovedBudgetInCents?.value || 0),
          };
        },
        {
          thisBilledInCents: 0,
          unBilledInCents: 0,
          otherBilledInCents: 0,
          costChangeInCents: 0,
          revisedApprovedBudgetInCents: 0,
        },
      ),
    [field],
  );

  const filteredProjectItems = useMemo(
    () =>
      data?.projectItems?.filter(
        (pi) =>
          // Exclude already selected project items and filter by project stage
          !field.rows.map((row) => row.projectItemId.value).includes(pi.id) &&
          (!field.rows.first || field.rows.first.value.projectStage?.id === pi.projectStage.id),
      ) ?? [],
    [data, field.rows],
  );

  // Update amountInCents to the available unbilled amount when a row is selected
  const handleRowSelect: OnRowSelect<Row> = {
    lineItem: (data, isSelected, { row }) => {
      if (isSelected && !row.data.amountInCents.value) {
        row.data.amountInCents.set(calcLineItemValues(data, isCredit).unBilledInCents);
      }
      onUpdateLineItemAmount();
    },
  };

  return (
    <div>
      {type === BillType.Overhead && (
        <div>
          {field.rows.first && (
            <div css={Css.red700.df.sm.cgPx(3).mb2.$}>
              <div css={Css.mya.$}>
                <Icon icon="infoCircle" inc={2} />
              </div>
              Results are filtered by the selected project stage: {field.rows.first.value.projectStage?.stage.name}
            </div>
          )}
          <div css={Css.df.mb2.$}>
            <div css={Css.smBd.gray600.$}>Line Items</div>
            <div css={Css.mla.pr1.$}>
              <Button label="+ Add Line Item" disabled={showAddLineItem} onClick={toggleAddLineItem} variant="text" />
            </div>
          </div>
        </div>
      )}
      <GridTable
        api={tableApi}
        onRowSelect={handleRowSelect}
        columns={createColumns(
          field,
          isCredit,
          type,
          filteredProjectItems,
          toggleAddLineItem,
          onUpdateLineItemAmount,
          project,
          billId,
        )}
        rows={createRows(field.rows, totalsRowData, type, showAddLineItem, isEdit)}
        style={{ bordered: true, allWhite: true }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type LineItemRow = { kind: "lineItem"; data: CreateBillFormState["lineItems"]["rows"][0]; initSelected?: boolean };
type AddLineItemRow = { kind: "addLineItem"; id: string; data: undefined };
type ParentRow = { kind: "parent"; data: { name: string; status?: CommitmentStatus } };
type TotalRow = {
  kind: "total";
  data: {
    thisBilledInCents: number;
    otherBilledInCents: number;
    unBilledInCents: number;
    costChangeInCents: number;
    revisedApprovedBudgetInCents?: number;
  };
};
export type Row = HeaderRow | LineItemRow | AddLineItemRow | ParentRow | TotalRow;

function createRows(
  lineItems: readonly ObjectState<BillFormLineItem>[],
  totalsRowData: ComputedTotals,
  type: BillType,
  showAddLineItem: boolean,
  isEdit: boolean,
): GridDataRow<Row>[] {
  const groupedParentsPOs =
    type === BillType.Standard ? lineItems.groupBy((r) => String(r.owner.accountingNumber.value)) : {};

  const rows = groupedParentsPOs.toKeys().nonEmpty
    ? groupedParentsPOs.toKeys().map((acctNumber) => {
        const childItemRows = groupedParentsPOs[acctNumber];
        const isCo = childItemRows.first?.owner.id.value?.startsWith("cco");

        return {
          kind: "parent" as const,
          id: String(acctNumber),
          data: {
            name: isCo ? `CO# ${acctNumber}` : `PO# ${acctNumber}`,
            status: childItemRows.first?.owner.status?.value,
          },
          children: childItemRows.map((r) => ({
            kind: "lineItem" as const,
            id: r.commitmentLineItemId.value!,
            data: r,
            initSelected: isEdit,
          })),
        };
      })
    : lineItems.map((row) => ({
        kind: "lineItem" as const,
        id:
          row.commitmentLineItemId.value ??
          row.projectItemId.value ??
          fail("commitmentLineItem or projectItem ID is required"),
        data: row,
      }));

  const addRow = { kind: "addLineItem" as const, id: "add", data: undefined };

  return [
    simpleHeader,
    ...rows,
    ...(showAddLineItem ? [addRow] : []),
    { kind: "total" as const, id: "total", data: totalsRowData },
  ];
}

function createColumns(
  field: ListFieldState<BillFormLineItem>,
  isCredit: boolean,
  type: BillType,
  projectItems: BillEditor_ProjectItemFragment[],
  toggleAddLineItem: (force?: unknown) => void,
  onUpdateLineItemAmount: () => void,
  project: ProjectAutocomplete_ProjectFragment,
  billId: string,
): GridColumn<Row>[] {
  const label = isCredit ? "Credit" : "Bill";

  return [
    ...(type === BillType.Standard
      ? [
          collapseColumn<Row>({ lineItem: emptyCell }),
          selectColumn<Row>({ w: "32px", total: emptyCell }),
          column<Row>({
            header: () => "Project Item",
            parent: (data) => ({
              content: (
                <div css={Css.xsBd.df.cg1.$}>
                  <div>{data.name}</div>
                  {data.status && <Tag type={commitmentStatusToTagTypeMapper[data.status]} text={data.status} />}
                </div>
              ),
              colspan: 2,
            }),
            lineItem: ({ displayName }) => displayName.value,
            addLineItem: emptyCell,
            total: () => ({ alignment: "right", content: "Total:" }),
          }),
          column<Row>({
            header: () => "Task",
            parent: emptyCell,
            lineItem: ({ task }) => (task.value ? allocationCell(task.value, project) : undefined),
            addLineItem: emptyCell,
            total: emptyCell,
          }),
          numericColumn<Row>({
            header: () => "Committed",
            parent: emptyCell,
            lineItem: ({ costChangeInCents }) => priceCell({ id: "cost", valueInCents: costChangeInCents.value }),
            total: () => emptyCell,
            addLineItem: emptyCell,
          }),
          numericColumn<Row>({
            header: () => "Revised Budget",
            parent: emptyCell,
            lineItem: (row) => priceCell({ valueInCents: row.revisedApprovedBudgetInCents?.value ?? 0 }),
            total: () => emptyCell,
            addLineItem: emptyCell,
          }),
          numericColumn<Row>({
            header: () => "Other Bills",
            parent: emptyCell,
            lineItem: (row) => ({
              content: <OtherBillCell billId={billId} row={row} />,
            }),
            total: () => emptyCell,
            addLineItem: emptyCell,
          }),
          numericColumn<Row>({
            header: () => `${label} Amount`,
            parent: emptyCell,
            lineItem: ({ amountInCents }) => (
              <BoundNumberField
                field={amountInCents}
                onChange={(val) => {
                  amountInCents.set(val);
                  onUpdateLineItemAmount();
                }}
                type="cents"
              />
            ),
            addLineItem: emptyCell,
            total: (row) => priceTotal({ valueInCents: row.thisBilledInCents, id: "amountTotal" }),
          }),
          numericColumn<Row>({
            header: () => "Unbilled",
            parent: emptyCell,
            lineItem: (data) =>
              priceCell({
                id: "unbilled",
                valueInCents: calcLineItemValues(data, isCredit).unBilledInCents,
              }),
            addLineItem: emptyCell,
            total: (row) => priceTotal({ valueInCents: row.unBilledInCents, id: "unbilledTotal" }),
          }),
        ]
      : [
          column<Row>({
            header: () => "Project Item",
            parent: ({ name }) => ({ content: <div css={Css.xsBd.$}>{name}</div> }),
            lineItem: ({ displayName }) => displayName.value,
            addLineItem: () => (
              <SelectField
                value={undefined}
                label="add project item"
                labelStyle="hidden"
                getOptionLabel={(o) => `${o.displayName} - ${formatCentsToPrice(o.uncommittedBudgetAmountInCents)}`}
                getOptionValue={(o) => o.id}
                onSelect={(_, pi) => {
                  if (pi) {
                    field.add({
                      projectItemId: pi.id,
                      amountInCents: pi.uncommittedBudgetAmountInCents,
                      ...pi,
                      bills: pi.bills,
                    });
                    toggleAddLineItem();
                    onUpdateLineItemAmount();
                  }
                }}
                options={projectItems}
              />
            ),
            total: () => ({ alignment: "left", content: "Total:" }),
          }),
          numericColumn<Row>({
            header: () => "Available Budget",
            parent: emptyCell,
            lineItem: (row) => priceCell({ valueInCents: row.uncommittedBudgetAmountInCents.value }),
            addLineItem: emptyCell,
            total: emptyCell,
          }),
          numericColumn<Row>({
            header: () => `${label} Amount`,
            parent: emptyCell,
            lineItem: ({ amountInCents }) => (
              <BoundNumberField
                field={amountInCents}
                onChange={(val) => {
                  amountInCents.set(val);
                  onUpdateLineItemAmount();
                }}
                type="cents"
              />
            ),
            addLineItem: emptyCell,
            total: (row) => priceTotal({ valueInCents: row.thisBilledInCents, id: "amountTotal" }),
          }),
          numericColumn<Row>({
            header: () => "Unbilled",
            parent: emptyCell,
            lineItem: (data) =>
              priceCell({
                id: "unbilled",
                valueInCents: calcLineItemValues(data, isCredit).unBilledInCents,
              }),
            addLineItem: emptyCell,
            total: (row) => priceTotal({ valueInCents: row.unBilledInCents, id: "unbilledTotal" }),
          }),
          column<Row>({
            header: emptyCell,
            parent: emptyCell,
            lineItem: (row) => (
              <IconButton
                icon="trash"
                color={Palette.Gray600}
                onClick={() => {
                  field.remove(row.value);
                  toggleAddLineItem();
                  onUpdateLineItemAmount();
                }}
              />
            ),
            addLineItem: emptyCell,
            total: emptyCell,
            w: "50px",
          }),
        ]),
  ];
}

function allocationCell(task: BillEditor_TaskFragment, project: ProjectAutocomplete_ProjectFragment): GridCellContent {
  const taskUrl = project.stages && createProjectScheduleUrl(project.id, project.stages.last?.stage.code, task.id);

  return {
    content: () => (
      <div
        data-testid={`task-cell-${task.id}`}
        onClick={() => openNewTab(taskUrl)}
        css={Css.cursorPointer.onHover.tdu.blue600.$}
      >
        <Tooltip
          title={
            <div>
              <div>Status: {task.status.name}</div>
              <div>Start date: {task.startDate ? formatWithYear(new DateOnly(task.startDate)) : "-"}</div>
              {task.completedAt && <div>Completed at: {formatWithYear(new DateOnly(task.completedAt))}</div>}
            </div>
          }
          placement="top"
        >
          <div css={Css.blue600.$}>{task.name}</div>
        </Tooltip>
      </div>
    ),
    value: task.id,
  };
}
