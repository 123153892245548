import {
  BoundTextAreaField,
  Button,
  Css,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  BillType,
  SaveScheduleTasksMutation,
  TaskBillModal_ProtoBillFragment,
  TaskDetailPaneDocument,
  useTaskBillModal_ProtoBillsQuery,
  useTaskBillModal_SaveBillsMutation,
} from "src/generated/graphql-types";
import { isDefined, pluralize, queryResult } from "src/utils";
import { useQueryParam } from "use-query-params";

import { FetchResult } from "@apollo/client";
import { columns, formConfig, mapToForm, mapToInput, Row, rows } from "./utils";

/**
 * Task bill modal that allows bills to be autocreated & released based on:
 * 1. Marking tasks as `completed` - items that have been allocated to the task can be selected to intiate billing.
 * 2. Selection of a task's specific trade partner to pay - allocated, unbilled task items can be selected to initiate bill
 * to a trade w/out requring full task completion.
 *
 * Currently supports 2 bill types:
 * 1. Deffered - Allows tasks to be marked complete without sending bill immediately to the trade (`complete w/out pay` CTA)
 * 2. Immediate -  Upon task completion or selecting a trade to pay, sends bill immediately for trade approval (`release bill` CTA)
 *
 * If a task's trade partner has an existing draft-deffered/draft-immediate bill that has not yet been sent,
 * then all newly selected line items via the modal for that trade will be added to the trade's matching bill type, up until EOD.
 * Each trade can technically have up to 2 consolidated bills per day, a deferred bill and immediate bill
 *
 * -Auto immediate bills are sent to trades nightly.
 * -Auto deferred bills require a PM's release manually. Upon manual release we send the bill(s) to the trade as part of the nightly job.
 *
 */

export type TaskBillModalProps = {
  scheduleTaskId: string | undefined;
  /**
   * Since a task can be tied to multiple allocated items & trades,
   * Payment to specific trade(s) before task completion is allowed.
   * This can be triggered via clicking on one of the task's trade partners in the task detail pane/ passing tradePartnerId
   */
  tradePartnerId?: string | undefined;
  // Function to handle task save mutation, _after_ the saveBills is completed in the modal
  handleTaskSave?: () => Promise<FetchResult<SaveScheduleTasksMutation>>;
  /**
   * Function to set the result of the task save mutation
   * so that we can provide the result data to the taskbillContext provider and consuming components
   */
  setContextTaskResult?: React.Dispatch<React.SetStateAction<FetchResult<SaveScheduleTasksMutation>>>;
  skipModal?: boolean;
  /**
   * Generic function to call if modal is skipped or completed
   */
  onComplete?: () => Promise<void>;
};

export function TaskBillModal({
  scheduleTaskId,
  tradePartnerId,
  handleTaskSave,
  setContextTaskResult,
  onComplete,
}: TaskBillModalProps) {
  const query = useTaskBillModal_ProtoBillsQuery({
    variables: { scheduleTaskId: scheduleTaskId ?? "" },
    skip: !scheduleTaskId,
  });
  return queryResult(query, (data) => (
    <TaskBillModalView
      protoBills={data.scheduleTask.protoBills}
      tradePartnerId={tradePartnerId}
      handleTaskSave={handleTaskSave}
      setContextTaskResult={setContextTaskResult}
      onComplete={onComplete}
    />
  ));
}

export function TaskBillModalView({
  protoBills,
  tradePartnerId,
  handleTaskSave,
  setContextTaskResult,
  onComplete,
}: Omit<TaskBillModalProps, "scheduleTaskId"> & {
  /**
   * Per each schedule task, protoBills returns:
   * 1. tradePartners: All tradePartners associated to allocated items for the task.
   * 2. commitmentLineItems: The tradepartners' clis for the task.
   * 3. existingBills: All existing draft immediate/deferred bills, belonging to the trade, created at the start of day for tasks.
   *
   * Used to find a trade's draft autogenerated bill & the billed/unbilled task items
   */
  protoBills: TaskBillModal_ProtoBillFragment[];
}) {
  const [taskId] = useQueryParam<string>("taskId");
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [saveTaskBills] = useTaskBillModal_SaveBillsMutation();
  const tid = useTestIds({});
  const allowPayBeforeTaskCompletion = isDefined(tradePartnerId);
  // If a tradeId has been passed to the modal, show only bills for that trade
  const tradeBills = useMemo(
    () => protoBills.filter(({ tradePartner }) => !tradePartnerId || tradePartner.id === tradePartnerId),
    [protoBills, tradePartnerId],
  );
  const tableApi = useGridTableApi<Row>();
  const selectedRows = useComputed(() => tableApi.getSelectedRows("li"), [tableApi]);
  const billableRows = useComputed(() => tableApi.getVisibleRows("li"), [tableApi]).filter(
    (row) => row.data.lineItems.pendingUnbilledInCents.value !== 0,
  );
  const deselectedItems = useComputed(
    () => billableRows.filter((r) => !selectedRows.includes(r)),
    [billableRows, selectedRows],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: tradeBills,
      map: (tradeBills) => mapToForm(tradeBills, taskId),
    },
  });

  const internalNote = useComputed(
    () => formState.bills.rows.first?.internalNote.value,
    [formState.bills.rows.first?.internalNote],
  );
  const needsDefermentReason =
    deselectedItems.nonEmpty && !isDefined(internalNote) && "Deferment reason is required for deselected items";
  const title = allowPayBeforeTaskCompletion
    ? `Confirm payment amounts to send to the following trades for completed work:`
    : ` Confirm payment amounts to send to the following trades for completed work. Deselected items will be added to
an outstanding bill for the trade:`;

  const onSave = async () => {
    const maybeCurrentTask = tradeBills.first?.commitmentLineItems
      .map((li) => li.projectItem.task)
      .find((tsk) => tsk?.id === taskId);
    const currentProjectId = tradeBills.first?.commitmentLineItems.first?.projectItem.project.id;

    // Save the `C2P bills` based on modal selections
    const saveBillsResult = await saveTaskBills({
      variables: {
        input: {
          bills: mapToInput(formState, tradeBills, deselectedItems, tradePartnerId),
        },
      },
      onCompleted: async (saveBillRes) => {
        if (handleTaskSave) {
          // If a task save mutation is provided, call it after the bills have been saved
          const saveTaskResult = await handleTaskSave();
          if (setContextTaskResult) {
            // Set the result of the taskSave mutation to the context to be used by the component that opened the modal
            setContextTaskResult(saveTaskResult);
          }
        }
        onComplete && (await onComplete());
      },
      // If we've initiated auto bills from a task detail pane,
      // Update the task detail cache after `saveTaskBills` mutation, to show the latest bill details via `refetchQueries`
      // C2P TODO: If needed update this to work for other schedule parent types (template schedules)
      // Right now this just assumes schedule type is project
      refetchQueries: [
        ...(isDefined(maybeCurrentTask?.id)
          ? [
              {
                query: TaskDetailPaneDocument,
                variables: {
                  taskId: maybeCurrentTask?.id,
                  parentId: currentProjectId,
                  parentsWithTradePartners: [currentProjectId],
                  isTemplate: false,
                },
              },
            ]
          : []),
      ],
    });

    if (saveBillsResult.data) {
      saveBillsResult.data.saveBills.bills.forEach((b) =>
        b.type.code === BillType.Deferred
          ? triggerNotice({
              message: `Open Bill #${b.tradePartnerNumber} for ${b.tradePartner.name} created`,
            })
          : triggerNotice({
              message: `Bill #${b.tradePartnerNumber} sent to ${b.tradePartner.name} for Approval`,
            }),
      );
      closeModal();
    }
  };

  return (
    <>
      <ModalHeader>
        Issue&nbsp;
        {pluralize(tradeBills.length, "bill")}
      </ModalHeader>
      <ModalBody>
        <div css={Css.gray700.sm.mb2.$} {...tid.title}>
          {title}
        </div>
        <div css={Css.df.fdc.$}>
          <GridTable
            api={tableApi}
            rows={rows(formState)}
            columns={columns(selectedRows, tid, isDefined(tradePartnerId))}
            style={{ allWhite: true, bordered: false }}
          />
        </div>
        {deselectedItems.nonEmpty && (
          <div>
            <div css={Css.df.fdr.gap1.aic.baseSb.$}>
              <div>{deselectedItems.length} unbilled</div>
              <div>{pluralize(deselectedItems.length, "item")}</div>
            </div>

            <div css={Css.xs.gray700.mt3.mb2.$}>
              Provide reasoning for completing this task and not issuing payment for deselected items (Deselected items
              will be added to an outstanding bill for the trade).
            </div>
            {/* Per design, although this modal can initiate multiple deferred bills at a time, there is only 1
            textfield for a deferment reason. The assumption is if bills are deferred together, one reason can be
            applied to all bills. The boundTextfield here is configured against the first bill in the modal. We then
            extract the internalNote val from the  first bill & apply it to all bills when shaping the input  */}
            {!!formState.bills.rows.first && (
              <BoundTextAreaField field={formState.bills.rows.first.internalNote} labelStyle="hidden" />
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        {!allowPayBeforeTaskCompletion && (
          <Button
            label="Complete without Payment"
            variant="secondary"
            onClick={onSave}
            disabled={
              (selectedRows.nonEmpty &&
                "Enabled when all items are deselected. Individually deselected items will be added to outstanding bills.") ||
              needsDefermentReason ||
              (mapToInput(formState, tradeBills, deselectedItems, tradePartnerId).isEmpty &&
                "All items have been billed")
            }
          />
        )}
        <Button
          label="Release Bill"
          variant="primary"
          onClick={onSave}
          disabled={
            (!allowPayBeforeTaskCompletion && selectedRows.isEmpty && "No items selected for immediate release") ||
            needsDefermentReason
          }
        />
      </ModalFooter>
    </>
  );
}
