import { Button, Chip, Css, HbLoadingSpinner, Palette, Tooltip } from "@homebound/beam";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetState } from "react-use";
import {
  createDesignCatalogUrl,
  createDesignPackageAddProductUrl,
  createDesignPackageSlotDetailUrl,
} from "src/RouteUrls";
import { Icon, SearchBox } from "src/components";
import {
  Order,
  useItivPlaceholderRequirementsQuery,
  usePossibleBidItemSelectionsForItivQuery,
  useProductItivCurrentSelectionsQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { DesingPackageSearchParams } from "src/routes/routesDef";
import { fail } from "src/utils";
import { h100ExclHdr } from "../../DesignPackageConfigurator";
import { useDesignPackageConfiguratorContext } from "../../DesignPackageConfiguratorContext";
import { ProductSearchFilters } from "./ProductSearchFilters";

/** This page can be render in 2 modes. Placeholder-only (Create Mode) or Placeholder+Product (Edit Mode). */
export function ProductSearchPage() {
  const { placeholderItivId, productItivId } = useParams<DesingPackageSearchParams>();
  const { designPackage } = useDesignPackageConfiguratorContext();
  const history = useHistory();

  // Pull in the Placeholder requirements (like `36in Gas Range`) and possible Attribute Filters (like Finish, Installation Type, etc.)
  const query = useItivPlaceholderRequirementsQuery({
    variables: { placeholderItivId: placeholderItivId ?? fail("placeholderItivId is required") },
  });

  const placeholderIti = query.data?.itemTemplateItemVersion.scope;
  const requirementMavs = placeholderIti?.materialVariant?.materialAttributeValues ?? [];

  // Load in the Rows of possible MaterialVariants that match the Placeholder requirements and Attribute Filters.
  const [mavFilters, setMavFilters] = useSetState<Record<string, string[]>>({});
  const [brandFilter, setBrandFilter] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const { data, loading: isLoadingPossibilities } = usePossibleBidItemSelectionsForItivQuery({
    variables: {
      filter: {
        itemTemplateItemVersionId: placeholderItivId!,
        attributeIds: Object.values(mavFilters).flat(),
        brandIds: brandFilter.nonEmpty ? brandFilter : undefined,
        search,
      },
      orderBy: { lastUsedAt: Order.Desc },
    },
  });
  const materialVariants = data?.materialVariantsForPlaceholderIti ?? [];

  // Only used for highlighting the currently-selected MaterialVariant. productItivId is also drilled through to the next component for "Edit" mode.
  const productIti = useProductItivCurrentSelectionsQuery({
    variables: { productItivId: productItivId! },
    skip: !productItivId,
  }).data?.itemTemplateItemVersion;

  return (
    <div css={{ ...h100ExclHdr, ...Css.w100.$ }}>
      <PageHeader
        title="Product Search"
        breadcrumb={[
          { href: createDesignCatalogUrl(), label: "Design Packages" },
          { href: createDesignCatalogUrl(designPackage.id), label: designPackage.name },
        ]}
        backButton={() => history.goBack()}
        right={
          <Button
            label="Add New Product"
            onClick={createDesignPackageAddProductUrl(
              designPackage.id ?? fail("Design Package ID is required"),
              placeholderItivId,
              productItivId!,
            )}
          />
        }
      />
      <div css={Css.df.h100.w100.$}>
        <ProductSearchFilters
          placeholderIti={placeholderIti}
          brandFilter={brandFilter}
          setBrandFilter={setBrandFilter}
          mavFilters={mavFilters}
          setMavFilters={setMavFilters}
        />
        <div css={Css.h100.w100.px2.oya.$}>
          <div css={Css.df.fdc.gap2.$}>
            <div css={Css.df.jcsb.aie.$}>
              <div css={Css.df.fdc.$}>
                {placeholderIti && <h1 css={Css.xlSb.$}>{placeholderIti.item.name}</h1>}
                {!isLoadingPossibilities && (
                  <span css={Css.smMd.gray700.$}>{`${materialVariants.length} results found`}</span>
                )}
              </div>
              <SearchBox
                onSearch={setSearch}
                clearable
                debounceDelayInMs={150}
                placeholder="Search within results..."
              />
            </div>
            <div css={Css.df.gap1.mb1.$}>
              {requirementMavs.map((mav) => (
                <Chip key={mav.id} text={`${mav.dimension.name}: ${mav.value}`} compact />
              ))}
            </div>
          </div>
          <div css={Css.df.fdc.gap2.base.$}>
            {isLoadingPossibilities ? (
              <HbLoadingSpinner />
            ) : (
              <div css={Css.dg.gtc("repeat(auto-fill, 275px)").gar("390px").gap2.$}>
                <div
                  /* The "Empty Product Slot" row/card/selection */
                  css={{
                    ...Css.bgWhite.hPx(350).bshBasic.df.fdc.gap2.p2.aic.cursorPointer.br4.$,
                    ...Css.onHover.bshHover.bgGray50.$,
                    // if no-selection is the currently-assigned option, highlight it
                    ...Css.if(!!productIti?.isDisabledBidItem).ba.bcBlue200.bgBlue50.$,
                  }}
                  onClick={() =>
                    history.push(
                      createDesignPackageSlotDetailUrl(designPackage.id, placeholderItivId, productItivId, "noBidItem"),
                    )
                  }
                >
                  <div /* pseudo-img */ css={Css.h100.w100.objectCover.oh.$}>
                    <div css={Css.h100.w100.df.fdc.aic.jcc.gap1.tac.ba.bcGray500.bgGray50.gray700.br4.$}>
                      <Icon icon="remove" color={Palette.Gray700} inc={4} />
                      <div css={Css.baseSb.df.fdc.aic.$}>
                        Make this an <br />
                        empty Product Slot
                        <Tooltip
                          title={`
                          Sometimes a product type isn't offered with certain Package Options (e.g., no refrigerator
                          with the Essential package). In these cases, tell Design Packages to leave it empty!
                        `}
                        >
                          <Icon icon="infoCircle" color={Palette.Gray700} inc={2} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                {materialVariants.map((mv) => (
                  <div
                    key={mv.id}
                    css={{
                      ...Css.bgWhite.br8.p2.df.fdc.gap2.relative.hPx(350).cursorPointer.bshBasic.onHover.bshHover.$,
                      // if this BI is the currently-assigned BI, highlight it
                      ...Css.if(mv.defaultBidItem.id === productIti?.bidItem?.id).ba.bcBlue200.bgBlue50.$,
                    }}
                    onClick={() =>
                      history.push(
                        createDesignPackageSlotDetailUrl(
                          designPackage.id,
                          placeholderItivId,
                          productItivId,
                          mv.defaultBidItem.id,
                        ),
                      )
                    }
                  >
                    <div css={Css.relative.hPx(250).maxhPx(250).f1.br4.$}>
                      <img
                        alt={`${mv.displayName}-product`}
                        src={mv.images.first?.asset?.previewUrl ?? PRODUCT_FALLBACK_IMG_URL}
                        css={Css.absolute.h100.w100.p1.br4.objectCover.$}
                      />
                    </div>
                    <Tooltip title={mv.displayName}>
                      <div css={Css.df.fdc.gap1.oh.$}>
                        <div css={Css.smMd.lineClamp(2).$}>{mv.displayName}</div>
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
