import { Css, LoadingSkeleton, ScrollableContent } from "@homebound/beam";
import { BryntumGantt, BryntumProjectModel } from "@homebound/schedules-v2-gantt";
import { endOfDay } from "date-fns";
import { useMemo, useRef } from "react";
import { useParams } from "react-router";
import { DynamicSchedulesGantt_PlanTaskFragment, useDynamicSchedulesGanttQuery } from "src/generated/graphql-types";
import { pluralize, queryResult } from "src/utils";
import { DateOnly, formatMonthDay, formatWithShortYear } from "src/utils/dates";
import { renderToString } from "src/utils/renderToString";
import { FilterSubHeader, useDynamicSchedulesFilter } from "../components/DynamicSchedulesFilterModal";

export function DynamicSchedulesGantt() {
  const { projectId } = useParams<{ projectId: string }>();

  const { queryFilter } = useDynamicSchedulesFilter();

  const query = useDynamicSchedulesGanttQuery({
    variables: { filter: { scheduleParent: [projectId], ...queryFilter } },
  });

  return (
    <div data-testid="ganttViewContent" css={Css.h100.$}>
      <FilterSubHeader scheduleParentId={projectId} />
      {queryResult(query, {
        data: ({ planTasks }) => <GanttView planTasks={planTasks.entities} />,
        loading: () => <LoadingSkeleton rows={20} columns={3} />,
        // Maybe find a way to dynamically set this for when filters change vs. responding to existing task updates
        showLoading: "always",
      })}
    </div>
  );
}

type GanttTask = {
  id: string;
  name: string;
  startDate: DateOnly;
  endDate: Date;
  manuallyScheduled: boolean;
  internalEndDate: DateOnly;
  internalStartDate: DateOnly;
  internalDuration: number;
};

function GanttView({ planTasks }: { planTasks: DynamicSchedulesGantt_PlanTaskFragment[] }) {
  const projectModelRef = useRef<BryntumProjectModel>(null);
  const ganttRef = useRef<BryntumGantt>(null);

  const taskData = useMemo(() => prepareTaskData(planTasks), [planTasks]);

  return (
    <>
      <BryntumProjectModel ref={projectModelRef} hoursPerDay={8} calendar="business" tasks={taskData} />
      <ScrollableContent omitBottomPadding>
        <div css={{ ...Css.h100.$, ...ganttStyleOverrides }}>
          <BryntumGantt
            ref={ganttRef}
            project={projectModelRef}
            viewPreset="weekAndDayLetter"
            cellEditFeature={{ disabled: true }}
            readOnly
            columns={[
              { type: "name", width: 300 },
              {
                text: "Dates",
                width: 110,
                field: "id",
                renderer: ({ record }: { record: GanttTask }) => {
                  return `${formatMonthDay(record.internalStartDate)}-${formatMonthDay(record.internalEndDate)}`;
                },
              },
              { text: "Duration", width: 70, field: "internalDuration" },
            ]}
            taskMenuFeature={false}
            projectLinesFeature={{ disabled: true }}
            timeRangesFeature={{ showCurrentTimeLine: { name: "Today" } }}
            rowReorderFeature={{ disabled: true }}
            barMargin={0}
            rowHeight={40}
            labelsFeature={{ top: { field: "name" }, disabled: false }}
            // Do not allow zooming in to less than a day time interval
            maxZoomLevel={10}
            taskTooltipFeature={{ template: taskTooltipMarkup }}
          />
        </div>
      </ScrollableContent>
      {/* {!isTemplate && <TaskColorLegend title="Color legend:" />} */}
    </>
  );
}

function prepareTaskData(planTasks: DynamicSchedulesGantt_PlanTaskFragment[]): GanttTask[] {
  return planTasks
    .map((t) => ({
      id: t.id,
      startDate: t.startDate,
      // Placing the endDate at the very end of the day coupled with the `hoursPerDay` & `calendar=business` settings
      // allow the library to show the task spanning the full day even though 1 day tasks start and end on the same day
      // https://forum.bryntum.com/viewtopic.php?t=17386
      endDate: endOfDay(t.endDate),
      name: t.name,
      manuallyScheduled: true,
      // The date and duration fields are mutated by the gantt library scheduler so we can pass in
      // custom internal_ fields to get access to known unmodified/correct values
      internalEndDate: t.endDate,
      internalStartDate: t.startDate,
      internalDuration: t.durationInDays,
    }))
    .sortByKey("startDate");
}

const ganttStyleOverrides = {
  ...Css
    // Chart Body
    .addIn(".b-gantt-body-wrap", Css.brt4.$)
    // Grid Header
    .addIn(".b-grid-header-container .b-sch-timeaxiscolumn", Css.bgWhite.$)
    // Column Header
    .addIn(".b-grid-header-container", Css.bgWhite.$)
    // selectedRow
    .addIn(".b-grid-row.b-selected:not(.b-group-row)", Css.bgBlue50.$)
    // Row Hover
    .addIn(
      ".b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row.b-hover",
      Css.bgBlue100.$,
    )
    // Row Hover Selected
    .addIn(
      ".b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row.b-hover.b-selected",
      Css.bgBlue100.$,
    )
    // Column Data
    .addIn(".b-tree-leaf-cell > .b-tree-cell-inner, .b-grid-cell", Css.xsMd.gray800.$)
    // Column Headers
    .addIn(".b-grid-header-text-content", Css.add("textTransform", "none").xsMd.gray800.$)
    // Today Marker Container
    .addIn(".b-grid-header .b-sch-timerange.b-sch-current-time", Css.bgBlue700.ttc.br4.px2.left("-36px").$)
    // Today Marker Label
    .addIn(".b-grid-header .b-sch-timerange label", Css.smMd.$)
    // Today Marker VerticalLine
    .addIn(".b-timeline-subgrid .b-sch-current-time", Css.bcBlue700.$)
    // Task Name Label
    .addIn(".b-sch-label.b-sch-label-top", Css.asb.xsSb.blue600.$)
    // Standard Task Bar
    .addIn(".b-gantt-task", Css.bgBlue100.br4.$)
    // Hide Tree Icons
    .addIn(".b-tree-icon", Css.dn.$).$,
};

export function taskTooltipMarkup({ taskRecord }: { taskRecord: GanttTask }) {
  const { internalStartDate, name, internalEndDate, internalDuration } = taskRecord;
  return renderToString(
    <div css={Css.mwPx(200).$}>
      <div css={Css.smSb.$}>{name}</div>
      <table css={Css.w100.$}>
        <tbody>
          <tr>
            <td>Start:</td>
            <td>{formatWithShortYear(internalStartDate)}</td>
          </tr>
          <tr>
            <td>End:</td>
            <td>{formatWithShortYear(internalEndDate)}</td>
          </tr>
          <tr>
            <td>Duration:</td>
            <td>
              {internalDuration} {pluralize(internalDuration, "day")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>,
  );
}
