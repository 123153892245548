import {
  BoundDateField,
  BoundNumberField,
  ButtonMenu,
  Css,
  GridDataRow,
  GridRowLookup,
  GridTable,
  Icon,
  IconButton,
  Loader,
  ModalProps,
  Palette,
  actionColumn,
  column,
  dateColumn,
  emptyCell,
  getTableStyles,
  simpleHeader,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { isSameDay } from "date-fns";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DayOfWeek,
  DraftModeEnumDetails_EnumDetailsFragment,
  DraftPlanTaskInput,
  InputMaybe,
  Maybe,
  ScheduleDraftMode_PlanTaskFragment,
  SchedulingExclusionDatesFragment,
  TaskStatus,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { ScheduleDraftModePlanTaskSetDaysModal } from "src/routes/projects/dynamic-schedules/draft-mode/ScheduleDraftModePlanTaskSetDaysModal";
import { isDefined, newComparator, noop } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { BooleanParam, useQueryParams } from "use-query-params";
import { TaskStatusSelect } from "../../schedule-v2/components/TaskStatusSelect";
import { DayOfWeekDayPicker } from "../calendar/dynamicSchedulesCalendarUtils";
import { PlanScheduleConfidence } from "../components/PlanScheduleConfidence";
import { PlanTaskProgress } from "../components/PlanTaskProgress";
import { PlanTaskConfidenceHeader } from "../list-view/DynamicSchedulesList";
import { ScheduleContainer } from "../utils";
import { DebugTooltip } from "./DebugTooltip";
import { ScheduleDraftModeDelayFlagModal } from "./ScheduleDraftModeDelayFlagModal";
import { ScheduleDraftModeDeleteFlagModal } from "./ScheduleDraftModeDeleteFlagModal";
import { UserAddedScheduleFlagsInput, useDraftScheduleStore } from "./scheduleDraftStore";

type DraftScheduleTableProps = {
  tasks: ScheduleDraftMode_PlanTaskFragment[];
  enumDetails: DraftModeEnumDetails_EnumDetailsFragment;
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
  loading: boolean;
};

export function DraftScheduleTable(props: DraftScheduleTableProps) {
  const { tasks, enumDetails, schedulingExclusionDates, loading } = props;

  const [{ debug }] = useQueryParams({ debug: BooleanParam });
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setUserAddedDelayFlags = useDraftScheduleStore((state) => state.setUserAddedScheduleFlags);
  const lastUpdatedTaskId = useDraftScheduleStore((state) => state.lastUpdatedTaskId);
  const { triggerNotice } = useSnackbar();
  const { openModal } = useModal();

  const api = useGridTableApi<Row>();

  const { getFormState } = useFormStates<FormInput, ScheduleDraftMode_PlanTaskFragment>({
    config: formConfig,
    map: (task) => mapToForm(task),
    getId: (t) => t.id,
    autoSave: async (fs) => {
      // Though we have implemented our own draft-mode specific autoSave logic, we still want the formState to reset the "dirty"/"touched" states
      // as if we were using the default autoSave behavior. This ensures we don't get caught will stale input values when user updates an input which later
      // gets overridden by a subsequent change/server response.
      fs.commitChanges();
    },
  });

  const getTaskFormState = useCallback(
    (task: ScheduleDraftMode_PlanTaskFragment) =>
      getFormState(task, {
        readOnly: task.status.code === TaskStatus.Complete,
      }),
    [getFormState],
  );

  const onTaskDelete = useCallback(
    (task: ScheduleDraftMode_PlanTaskFragment) => {
      setDraftTaskChanges([{ id: task.id, delete: true }]);
      triggerNotice({ message: "1 task deleted" });
    },
    [setDraftTaskChanges, triggerNotice],
  );
  const styles = getTableStyles({ allWhite: true });
  const activeRowId = useMemo(() => (lastUpdatedTaskId ? `task_${lastUpdatedTaskId}` : undefined), [lastUpdatedTaskId]);
  const lookup = useRef<GridRowLookup<Row>>();
  const rows = useMemo(() => createRows(sortTasks(tasks)), [tasks]);

  const columns = useMemo(
    () =>
      createColumns(
        getTaskFormState,
        openModal,
        debug,
        onTaskDelete,
        setUserAddedDelayFlags,
        setDraftTaskChanges,
        loading,
        enumDetails,
        schedulingExclusionDates,
      ),
    [
      getTaskFormState,
      openModal,
      debug,
      onTaskDelete,
      setUserAddedDelayFlags,
      setDraftTaskChanges,
      loading,
      enumDetails,
      schedulingExclusionDates,
    ],
  );

  useEffect(() => {
    // Scroll to the first active task
    if (lookup.current) {
      const currentList = lookup.current.currentList();
      const firstActive = currentList.find((r) => r.kind === "task" && r.data.status.code !== TaskStatus.Complete);
      if (firstActive) api.scrollToIndex(currentList.indexOf(firstActive));
    }
  }, [lookup, api]);

  useEffect(() => {
    // Scroll to the last updated task
    if (lastUpdatedTaskId) {
      const index = rows.findIndex((r) => r.id === lastUpdatedTaskId);
      if (index !== -1) {
        api.scrollToIndex(index);
      }
    }
  }, [lastUpdatedTaskId, rows, api]);

  return (
    <ScheduleContainer>
      <GridTable
        rowStyles={{
          task: { cellCss: (task) => Css.if(task.data.status.code === TaskStatus.Complete).bgGray200.$ },
        }}
        as="virtual"
        api={api}
        columns={columns}
        rows={rows}
        stickyHeader={true}
        activeRowId={activeRowId}
        style={{
          ...styles,
          rootCss: {
            ...styles.rootCss,
            ...Css.pbPx(50).$,
          },
        }}
        rowLookup={lookup}
      />
    </ScheduleContainer>
  );
}

function createColumns(
  getFormState: GetFormState,
  openModal: (props: ModalProps) => void,
  debugMode: Maybe<boolean>,
  onTaskDelete: (task: ScheduleDraftMode_PlanTaskFragment) => void,
  setUserAddedDelayFlags: (input: UserAddedScheduleFlagsInput[]) => void,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  loading: boolean,
  enumDetails: DraftModeEnumDetails_EnumDetailsFragment,
  schedulingExclusionDates: SchedulingExclusionDatesFragment[],
) {
  const holidayExcludedDates = schedulingExclusionDates.map((exclusionDate) => exclusionDate.date);

  return [
    actionColumn<Row>({
      header: emptyCell,
      task: (task) => <IconCell task={task} loading={loading} />,
      w: "48px",
    }),
    column<Row>({
      header: "Name",
      task: ({ name }) => name,
      mw: "100px",
      w: 3,
    }),
    column<Row>({
      header: "Start",
      task: (task) => (
        <StartDateInput
          task={task}
          getFormState={getFormState}
          loading={loading}
          holidayExcludedDates={holidayExcludedDates}
        />
      ),
      w: "180px",
    }),
    dateColumn<Row>({
      header: "End",
      task: (task) => (
        <EndDateInput
          task={task}
          getFormState={getFormState}
          loading={loading}
          holidayExcludedDates={holidayExcludedDates}
        />
      ),
      w: "120px",
    }),
    column<Row>({
      header: "Duration",
      task: (task) => <DurationInput task={task} getFormState={getFormState} loading={loading} />,
      w: "150px",
    }),
    column<Row>({
      header: "Progress",
      task: (task) => <PlanTaskProgress task={task} />,
      mw: "120px",
    }),
    column<Row>({
      header: () => <PlanTaskConfidenceHeader />,
      task: (task) => (
        <PlanScheduleConfidence
          probabilityBasisPoints={task.simulationProbability?.endDateBasisPoints}
          isComplete={task.status.code === TaskStatus.Complete}
          entityType="task"
        />
      ),
      w: "90px",
    }),
    column<Row>({
      header: "Trade Scheduled",
      task: (task) => <TradeScheduledCell task={task} />,
      mw: "120px",
      w: 2,
    }),
    column<Row>({
      header: "Status",
      task: (task) => (
        <StatusInput task={task} getFormState={getFormState} loading={loading} enumDetails={enumDetails} />
      ),
      w: "150px",
    }),
    actionColumn<Row>({
      header: emptyCell,
      task: (task) => (
        <>
          <DebugTooltip debugMode={debugMode ?? false} task={task} />
          <ButtonMenu
            data-testid="draftTaskActions"
            trigger={{ icon: "verticalDots" }}
            items={[
              {
                label: "Scheduling Settings",
                onClick: () => {
                  openModal({
                    content: (
                      <ScheduleDraftModePlanTaskSetDaysModal
                        planTask={task}
                        setDraftTaskChanges={setDraftTaskChanges}
                      />
                    ),
                  });
                },
              },
              {
                label: "Delete",
                disabled: disableBasedOnPotentialOperation(task.canDelete),
                onClick: () => onTaskDelete(task),
              },
              {
                label: "Add Delay Flag",
                onClick: () =>
                  openModal({
                    content: (
                      <ScheduleDraftModeDelayFlagModal
                        planTask={task}
                        getFormState={getFormState}
                        setUserAddedScheduleFlags={setUserAddedDelayFlags}
                      />
                    ),
                  }),
                disabled: task.status.code === TaskStatus.Complete && "Task is marked as complete",
              },
            ]}
          />
        </>
      ),
      w: debugMode ? "80px" : "48px",
    }),
  ];
}

function createRows(tasks: ScheduleDraftMode_PlanTaskFragment[]): GridDataRow<Row>[] {
  const [completedTasks, remainingTasks] = tasks.partition((t) => t.status.code === TaskStatus.Complete);
  const sortedTasks = [...completedTasks.sortByKey("startDate"), ...remainingTasks.sortByKey("startDate")];
  return [
    simpleHeader,
    ...sortedTasks.map((t) => ({
      id: t.id,
      kind: "task" as const,
      data: t,
    })),
  ];
}

type HeaderRow = { kind: "header" };
type TaskRow = { kind: "task"; id: string; data: ScheduleDraftMode_PlanTaskFragment };

type Row = HeaderRow | TaskRow;

export type FormInput = Pick<
  DraftPlanTaskInput,
  "id" | "knownDurationInDays" | "status" | "customWorkableDaysOfWeek"
> & {
  isManuallyScheduled: boolean;
  earliestStartDate: Maybe<Date>;
  desiredEndDate: Maybe<Date>;
  flagReasonId: InputMaybe<string>;
};

export type GetFormState = (row: ScheduleDraftMode_PlanTaskFragment) => ObjectState<FormInput>;
type InputFieldProps = {
  getFormState: GetFormState;
  task: ScheduleDraftMode_PlanTaskFragment;
  loading: boolean;
  enumDetails?: DraftModeEnumDetails_EnumDetailsFragment;
  holidayExcludedDates: DateOnly[];
};

export const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  isManuallyScheduled: { type: "value" },
  earliestStartDate: { type: "value" },
  knownDurationInDays: {
    type: "value",
    rules: [({ value }) => (value && value > 0 ? undefined : "Task duration must be greater than 0")],
  },
  desiredEndDate: { type: "value" },
  flagReasonId: { type: "value" },
  status: { type: "value" },
  customWorkableDaysOfWeek: { type: "value", strictOrder: false },
};

function IconCell({ task, loading }: Pick<InputFieldProps, "task" | "loading">) {
  const { openModal } = useModal();
  const userAddedDelayFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const removeUserAddedScheduleFlags = useDraftScheduleStore((state) => state.removeUserAddedScheduleFlags);

  if (loading) return <Loader size="xs" />;

  const hasUserAddedDelayFlags = userAddedDelayFlags.some((flag) => flag.taskId === task.id);

  if (!hasUserAddedDelayFlags) return null;

  return (
    <IconButton
      icon="flag"
      color={Palette.Red700}
      onClick={() =>
        openModal({
          content: (
            <ScheduleDraftModeDeleteFlagModal
              removeUserAddedScheduleFlags={removeUserAddedScheduleFlags}
              userAddedDelayFlags={userAddedDelayFlags.filter((flag) => flag.taskId === task.id)}
            />
          ),
        })
      }
    />
  );
}

/* Note: It is known that this component triggers a react warning for a bad setState action. This is a TODO FIXME but it does not cause issues on the page */
function StartDateInput({ getFormState, task, loading, holidayExcludedDates }: InputFieldProps) {
  const [isHovered, setIsHovered] = useState(false);
  const onPointerEnter = useCallback(() => setIsHovered(true), []);
  const onPointerLeave = useCallback(() => setIsHovered(false), []);

  const disabledDays = useMemo(() => {
    return [{ dayOfWeek: getCustomDisabledWorkingDays(task.customWorkableDays) }, ...holidayExcludedDates];
  }, [holidayExcludedDates, task.customWorkableDays]);
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);

  // Use the value from formState within the component to ensure the UI updates immediately on click
  const isManuallyScheduled = useComputed(() => os.isManuallyScheduled.value, [os.isManuallyScheduled]);

  const { triggerNotice } = useSnackbar();
  const tids = useTestIds({}, "startDateInput");

  const { iconTooltip, showIcon, iconColor } = useMemo(() => {
    return {
      iconTooltip: isManuallyScheduled
        ? "Unpinning the task date will allow it to auto-move when other tasks are moved. If predecessors have already moved, the task may move immediately when unpinned."
        : "Pin Current Start Date",
      showIcon: task.status.code !== TaskStatus.Complete && (isManuallyScheduled || isHovered),
      iconColor: isManuallyScheduled ? Palette.Blue600 : Palette.Gray700,
    };
  }, [isManuallyScheduled, isHovered, task.status.code]);

  const onDateChange = useCallback(
    (newValue?: Date) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value
      if (isSameDay(newValue, task.startDate)) return;

      // Update the form state so we show the change to the user immediately
      os.earliestStartDate.set(newValue);

      setDraftTaskChanges([
        {
          id: task.id,
          earliestStartDate: new DateOnly(newValue),
          // Current ask is to auto "pin" the task whenever a date change occurs, we may in the future bring back the more
          // nuanced behavior that was originally spec'd that allows a user to set "earliestStart" without fixing that date
          isManuallyScheduled: true,
        },
      ]);
    },
    [setDraftTaskChanges, task.id, task.startDate, os],
  );

  /** Toggle between "pinning" the current start date and unsetting an existing pinned date */
  const onPinClick = useCallback(() => {
    os.isManuallyScheduled.set(!task.isManuallyScheduled);
    setDraftTaskChanges([
      {
        id: task.id,
        earliestStartDate: task.isManuallyScheduled ? null : task.startDate,
        isManuallyScheduled: !task.isManuallyScheduled,
      },
    ]);
    triggerNotice({
      message: `Task '${task.name}' has been ${task.isManuallyScheduled ? "unpinned" : "pinned"} `,
    });
  }, [task, setDraftTaskChanges, triggerNotice, os]);

  return (
    <div css={Css.wPx(120).relative.$} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave} {...tids}>
      <BoundDateField
        field={os.earliestStartDate}
        onBlur={noop}
        hideCalendarIcon
        disabledDays={disabledDays}
        onChange={onDateChange}
        borderless
        disabled={loading}
      />
      <div css={Css.absolute.right0.topPx(2).$}>
        {showIcon && (
          <IconButton
            icon="pin"
            onClick={onPinClick}
            color={iconColor}
            tooltip={iconTooltip}
            disabled={loading}
            {...tids.pinButton}
          />
        )}
      </div>
    </div>
  );
}

function DurationInput({ getFormState, task, loading }: Omit<InputFieldProps, "holidayExcludedDates">) {
  const { id, knownDurationInDays } = task;
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue: number) => {
        setDraftTaskChanges([{ id, knownDurationInDays: newValue }]);
      }, 500),
    [id, setDraftTaskChanges],
  );

  const onChange = useCallback(
    (newValue?: number) => {
      if (!isDefined(newValue) || newValue < 1) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === knownDurationInDays) return;

      // Keep the form state updated immediately
      os.knownDurationInDays.set(newValue);
      // Debounce the change so multi-digit duration entries don't trigger multiple updates
      debouncedOnChange(newValue);
    },
    [knownDurationInDays, os.knownDurationInDays, debouncedOnChange],
  );

  return (
    <div css={Css.wPx(100).$}>
      <BoundNumberField field={os.knownDurationInDays} onChange={onChange} type="days" disabled={loading} />
    </div>
  );
}

/* Note: It is known that this component triggers a react warning for a bad setState action. This is a TODO FIXME but it does not cause issues on the page */
function EndDateInput({ getFormState, task, loading, holidayExcludedDates }: InputFieldProps) {
  const disabledDays = useMemo(() => {
    return [
      { dayOfWeek: getCustomDisabledWorkingDays(task.customWorkableDays) },
      { before: task.startDate.date },
      ...holidayExcludedDates,
    ];
  }, [holidayExcludedDates, task.customWorkableDays, task.startDate.date]);
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);

  const onDateChange = useCallback(
    (newValue?: Date) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value
      if (isSameDay(newValue, task.endDate)) return;

      // Update the form state so we show the change to the user immediately
      os.desiredEndDate.set(newValue);

      setDraftTaskChanges([{ id: task.id, desiredEndDate: new DateOnly(newValue) }]);
    },
    [setDraftTaskChanges, task.id, task.endDate, os],
  );

  return (
    <div css={Css.pr1.$}>
      <BoundDateField
        field={os.desiredEndDate}
        onBlur={noop}
        hideCalendarIcon
        disabledDays={disabledDays}
        onChange={onDateChange}
        borderless
        disabled={loading}
      />
    </div>
  );
}

function StatusInput({ getFormState, task, loading, enumDetails }: Omit<InputFieldProps, "holidayExcludedDates">) {
  const { id, status } = task;
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const onSelect = useCallback(
    (newValue?: TaskStatus) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === status.code) return;

      // Keep the form state updated immediately
      os.status.set(newValue);
      setDraftTaskChanges([{ id, status: newValue }]);
    },
    [status.code, os.status, setDraftTaskChanges, id],
  );

  return (
    <TaskStatusSelect
      hideLabel
      options={enumDetails?.taskStatus ?? []}
      canComplete={task.canComplete}
      canStart={task.canStart}
      statusField={os.status}
      onSelect={onSelect}
      disabled={loading}
    />
  );
}

function TradeScheduledCell({ task }: { task: ScheduleDraftMode_PlanTaskFragment }) {
  const { tradePartnerStatus, tradePartner } = task;

  const isConfirmedOrComplete = useMemo(
    () => [TradePartnerTaskStatus.Confirmed, TradePartnerTaskStatus.CompletedJob].includes(tradePartnerStatus.code),
    [tradePartnerStatus.code],
  );

  if (!tradePartner) return null;

  return (
    <div css={Css.df.gap1.jcsb.aic.$} data-testid="tradePartner">
      <div css={Css.df.jcc.aic.mwPx(20).$}>
        <Icon
          icon={isConfirmedOrComplete ? "checkCircle" : "errorCircle"}
          color={isConfirmedOrComplete ? Palette.Gray600 : Palette.Orange600}
          tooltip={tradePartnerStatus.name}
          data-testid="tradePartnerStatusIcon"
          inc={2.3}
        />
      </div>
      <span css={Css.xsMd.gray800.lineClamp2.$}>{tradePartner.name}</span>
    </div>
  );
}

function mapToForm(task: ScheduleDraftMode_PlanTaskFragment): FormInput {
  const { id, isManuallyScheduled, startDate, endDate, knownDurationInDays, status, customWorkableDays } = task;
  return {
    id,
    isManuallyScheduled: isManuallyScheduled ?? false,
    earliestStartDate: new DateOnly(startDate),
    desiredEndDate: new DateOnly(endDate),
    knownDurationInDays: knownDurationInDays ?? task.durationInDays,
    flagReasonId: null,
    status: status.code,
    customWorkableDaysOfWeek: customWorkableDays,
  };
}

// Tasks are sorted by start date, however we may have multiple tasks starting on the same date
// So we then introduce a secondary sort by task name so the ordering is stable
function sortTasks(tasks: ScheduleDraftMode_PlanTaskFragment[]) {
  return [...tasks].sort((a, b) => {
    const compareKey = isSameDay(a.startDate, b.startDate) ? "name" : "startDate";
    const compareFunc = newComparator<ScheduleDraftMode_PlanTaskFragment>((t) => t[compareKey]);

    return compareFunc(a, b);
  });
}

/* NOTE: `planTask.customWorkableDays` resolves to default array of Mon-Fri when stored value in db is null */
function getCustomDisabledWorkingDays(planTaskCustomWorkingDays: DayOfWeek[]) {
  return DayOfWeek.toValues()
    .filter((day) => !planTaskCustomWorkingDays.includes(day))
    .map((day) => DayOfWeekDayPicker[day]);
}
