import {
  BoundSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import {
  AddEditItemModal_MvPageInfoFragment,
  CostType,
  MaterialType,
  MaterialVariantsFilter,
  useAddEditItemModal_CreateLocationMutation,
  useAddEditItemModal_GetMaterialVariantsLazyQuery,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { fail } from "src/utils";
import { AddEditItemLocationsField } from "./AddEditItemLocationsField";
import {
  AddEditItemModalDataViewProps,
  AddEditItemObjectState,
  BoundTaskSelectField,
  CommonFields,
  FormMaterialVariant,
  ItivData,
  PublishedTakeoffBanner,
  saveAddEditItem,
  useAddEditItemModalFormState,
  withGetItivQuery,
} from "./utils";

export const AddEditMaterialItemModal = withGetItivQuery(AddEditMaterialItemModalDataView);

function AddEditMaterialItemModalDataView(props: AddEditItemModalDataViewProps) {
  const { itemTemplateId, initialItivData, takeoffsManagerStore } = props;
  const [saveLocation] = useAddEditItemModal_CreateLocationMutation();

  const { saveItiv, itemTemplate } = takeoffsManagerStore.getState();
  const { closeModal, addCanClose } = useModal();
  const isEditing = !!initialItivData?.id;
  const readOnly = itemTemplate.canEditLineItems.allowed === false;

  const formState = useAddEditItemModalFormState({
    initialItivData,
    readOnly,
    costType: CostType.Materials,
  });

  addCanClose(() => {
    // eslint-disable-next-line no-restricted-globals
    return formState.dirty ? confirm("You have unsaved changes. Are you sure you want to close?") : true;
  });

  const planPackageId = itemTemplate.readyPlan?.id ?? fail("Unable to determinate plan package id");

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>{readOnly ? "Material Details" : isEditing ? "Edit Material" : "Add Material"}</span>
          </ModalHeader>
          <ModalBody>
            {readOnly && <PublishedTakeoffBanner />}
            <div css={Css.df.fdc.gap2.pb3.$}>
              <MaterialFields
                planPackageId={planPackageId}
                itemTemplateId={itemTemplateId}
                initialItivData={initialItivData}
                readOnly={readOnly}
                formState={formState}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {readOnly ? (
              <Button label="Close" onClick={closeModal} />
            ) : (
              <>
                <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
                <Button
                  label="Save"
                  data-testid="saveBtn"
                  disabled={!formState.valid}
                  onClick={async () => {
                    await saveAddEditItem({
                      formState,
                      itemTemplateId,
                      planPackageId,
                      saveItiv,
                      saveLocation,
                    });
                    closeModal();
                  }}
                  size="lg"
                />
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type MaterialFieldsProps = {
  planPackageId: string;
  itemTemplateId: string;
  initialItivData?: ItivData;
  readOnly: boolean;
  formState: ObjectState<AddEditItemObjectState>;
};

function MaterialFields({ planPackageId, itemTemplateId, initialItivData, readOnly, formState }: MaterialFieldsProps) {
  const setDefaults = useCallback(
    (selectedMaterialVariant: FormMaterialVariant | undefined) => {
      // Default the item UOM, even if a value had been specified
      if (selectedMaterialVariant?.listing?.item.unitOfMeasure?.id) {
        formState.unitOfMeasureId.set(selectedMaterialVariant?.listing?.item.unitOfMeasure?.id);
        formState.unitOfMeasure.set(selectedMaterialVariant?.listing?.item.unitOfMeasure);
      }
      // Default the item
      if (!formState.itemId.value) {
        formState.itemId.set(selectedMaterialVariant?.listing?.item.id);
      }
      // Default the item task
      if (
        formState.locationId.value &&
        formState.materialVariantId.value &&
        !formState.taskId.value &&
        selectedMaterialVariant?.listing?.item?.planTaskMappings?.nonEmpty
      ) {
        const taskMappings = selectedMaterialVariant?.listing?.item?.planTaskMappings;
        const exactTaskMatch = taskMappings.find((ptm) => ptm.location?.id === formState.locationId.value);
        const wildCardLocationMatch = taskMappings.find((ptm) => !ptm.location);
        const defaultTask = exactTaskMatch ?? wildCardLocationMatch;

        if (defaultTask) {
          formState.taskId.set(defaultTask?.task.id);
          formState.task.set(defaultTask?.task);
        }
      }
    },
    [formState],
  );
  // When a material cost type is selected then...
  const onMaterialSelect = useCallback(
    (id: string | undefined, selectedMaterialVariant: FormMaterialVariant | undefined) => {
      // Set the material variant on the line
      formState.materialVariantId.set(id);
      formState.materialVariant.set(selectedMaterialVariant);

      // We set the defaults
      setDefaults(selectedMaterialVariant);
    },
    [formState, setDefaults],
  );

  const materialVariantOptions = useLazyMaterialVariants(formState, readOnly);

  return (
    <>
      <BoundSelectField
        label="Material*"
        field={formState.materialVariantId}
        nothingSelectedText="Please select a material"
        // FIXME: Lets clean up this interaction, if we can, post pilot
        // Only allow reselection of the material if its `Add New`
        options={
          !formState.isNewEntity && formState.materialVariant.value?.displayName
            ? [formState.materialVariant.value].compact()
            : materialVariantOptions
        }
        getOptionLabel={(o) => `${o.code} - ${o.displayName}`}
        getOptionValue={(o) => o.id}
        placeholder="Select material"
        onSelect={onMaterialSelect}
        disabled={disableBasedOnPotentialOperation(initialItivData?.canEditMaterial)}
      />
      <AddEditItemLocationsField
        planPackageId={planPackageId}
        newLocationNameField={formState.newLocationName}
        field={formState.locationId}
        onSelect={(locationId: string | undefined, location) => {
          formState.locationId.set(locationId);
          formState.location.set(location);
          formState.materialVariant.value && setDefaults(formState.materialVariant.value);
        }}
        disabled={disableBasedOnPotentialOperation(initialItivData?.canEditLocation)}
      />
      <BoundTaskSelectField
        selectedTask={initialItivData?.task}
        readOnly={readOnly}
        formState={formState}
        onSelect={(taskId, task) => {
          formState.taskId.set(taskId);
          formState.task.set(task);
        }}
      />
      <CommonFields
        itemTemplateId={itemTemplateId}
        readOnly={readOnly}
        formState={formState}
        disabled={{
          options: disableBasedOnPotentialOperation(initialItivData?.canEditOptions),
          quantity: disableBasedOnPotentialOperation(initialItivData?.canEditQuantity),
        }}
      />
    </>
  );
}

function useLazyMaterialVariants(formState: ObjectState<AddEditItemObjectState>, readOnly: boolean) {
  const materialVariantsFilter: MaterialVariantsFilter = {
    type: [MaterialType.Construction],
    itemId: !formState.isNewEntity && formState.itemId.value ? [formState.itemId.value] : undefined,
    includeArchived: false,
  };

  const [loadMaterialVariants] = useAddEditItemModal_GetMaterialVariantsLazyQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });

  const selectedItemId = useComputed(() => formState.itemId.value, [formState.itemId.value]);
  const selectedUomId = useComputed(() => formState.unitOfMeasureId.value, [formState.unitOfMeasureId.value]);
  const [materialVariantPageInfo, setMaterialVariantPageInfo] = useState<
    AddEditItemModal_MvPageInfoFragment | undefined
  >();
  const [materialVariantOptions, setMaterialVariantOptions] = useState<FormMaterialVariant[]>([]);

  // when itemId or unitOfMeasureId changes, reset the material variant options page info
  // so the new filter is used
  useEffect(() => {
    // don't reset if we haven't loaded anything yet
    if (!!materialVariantPageInfo && materialVariantOptions.length === 0) {
      return;
    }

    setMaterialVariantPageInfo(undefined);
    setMaterialVariantOptions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemId, selectedUomId]);

  // load all material variants, page by page
  useEffect(() => {
    if (readOnly || (!!materialVariantPageInfo && !materialVariantPageInfo.hasNextPage)) {
      return;
    }
    void (async () => {
      const response = await loadMaterialVariants({
        variables: {
          filter: materialVariantsFilter,
          page: {
            offset: materialVariantPageInfo?.nextPage?.offset ?? 0,
          },
        },
      });

      if (response.data?.materialVariants.entities) {
        setMaterialVariantOptions([
          ...(materialVariantOptions ?? []),
          ...response.data.materialVariants.entities.map(({ materialAttributeValues, ...rest }) => ({
            ...rest,
            materialAttributeValues: materialAttributeValues?.map(({ value: _value, ...other }) => ({
              ...other,
              _value,
            })),
          })),
        ]);
      }

      if (response.data?.materialVariants.pageInfo) {
        setMaterialVariantPageInfo(response.data.materialVariants.pageInfo);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialVariantPageInfo, materialVariantPageInfo?.nextPage?.offset]);

  return materialVariantOptions;
}
