import { Css, FilterDefs, Filters, multiFilter, useFilter, useModal, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { DevelopmentId, ReadyPlanId } from "@homebound/graphql-service-factories";
import { useMemo, useState } from "react";
import { createPlanDetailsUrl } from "src/RouteUrls";
import { SearchBox } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  AddOptionsGlobalOptionTypeFragment,
  useAddOptionsMetadataQuery,
  useAddOptionsPageQuery,
  useSaveReadyPlanMutation,
} from "src/generated/graphql-types";
import { OptionDetailsTable } from "src/routes/developments/plan-and-options/components/OptionDetailsTable";
import { EditOptionsHeader } from "src/routes/developments/plan-and-options/options/components/EditOptionsHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { addRpoConfig, mapReadyPlanToFormState, saveReadyPlanOptionsProgramData } from "../components/utils";
import { scrollable } from "./utils";

export function OptionDetailsStep({ developmentId, id }: { developmentId: DevelopmentId; id: ReadyPlanId }) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { data: metadata } = useAddOptionsMetadataQuery();
  const query = useAddOptionsPageQuery({ variables: { id } });
  const [saveRp] = useSaveReadyPlanMutation();
  const globalOptionTypes = useMemo(() => query.data?.globalOptionTypes ?? [], [query.data?.globalOptionTypes]);

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<OptionTypeFilter> = useMemo(() => {
    const option = multiFilter({
      label: "Filter by",
      options: globalOptionTypes,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { option };
  }, [globalOptionTypes]);
  const { filter, setFilter } = useFilter({ filterDefs });

  const optionsTypes: AddOptionsGlobalOptionTypeFragment[] = useMemo(() => {
    const options = globalOptionTypes.map(({ id }) => id);
    return globalOptionTypes.filter(
      (got) =>
        !got.isSpecLevel && (!filter?.option?.length ? options.includes(got.id) : filter.option.includes(got.id)),
    );
  }, [globalOptionTypes, filter.option]);

  const formState = useFormState({
    config: addRpoConfig,
    init: {
      query: query,
      map: ({ readyPlan }) => mapReadyPlanToFormState(readyPlan),
    },
  });

  const onSave = async () => {
    // Save Plan Details
    const toSaveReadyPlanDetails = formState.changedValue;
    // Check if any change other than the id exists to execute the save
    const realChanges = Object.keys(toSaveReadyPlanDetails).find((k) => k !== "id");
    if (realChanges && query.data?.readyPlan) {
      await saveReadyPlanOptionsProgramData(formState, query.data.readyPlan, saveRp);
    }

    triggerNotice({
      message: `Your Ready Plan Options have been updated!`,
    });

    closeModal();
  };

  return (
    <StepLayout
      header={
        <EditOptionsHeader
          title="Option Details"
          tooltip="Decide if any of the options that you selected in the previous step will add or subtract square footage or bedrooms, bathrooms, garages, or carport."
          subtitle="Fill out details for the ReadyPlanOptions below, then click Save."
        />
      }
      body={
        <div css={Css.pt3.pb0.df.fdc.fg1.$}>
          <TableActions onlyRight>
            <div css={Css.df.gap1.jcfe.$}>
              <Filters<OptionTypeFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
              <SearchBox onSearch={setSearchFilter} clearable />
            </div>
          </TableActions>

          <div css={Css.w100.add(scrollable).$}>
            <OptionDetailsTable
              formState={formState}
              searchFilter={searchFilter}
              optionsTypes={optionsTypes}
              metadata={metadata}
              filter={{}}
              showActions={false}
            />
          </div>
          <NextStepButton
            label="Save & Finish"
            disabled={false}
            onClick={onSave}
            onCloseReturnUrl={createPlanDetailsUrl(developmentId, id)}
            exitButton={{
              variant: "secondary",
              label: "Save & Exit",
              onClick: onSave,
            }}
          />
        </div>
      }
    />
  );
}

type OptionTypeFilter = { option: string[] };
