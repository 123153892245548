import {
  BoundMultiSelectField,
  BoundSwitchField,
  Button,
  ButtonMenu,
  cardStyle,
  CollapseToggle,
  column,
  Css,
  dragHandleColumn,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  insertAtIndex,
  multiFilter,
  MultiSelectField,
  recursivelyGetContainingRow,
  SelectToggle,
  Switch,
  Tag,
  useComputed,
  useFilter,
  useGridTableApi,
  useSnackbar,
} from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import { DevelopmentId, ReadyPlanId } from "@homebound/graphql-service-factories";
import { useEffect, useMemo, useState } from "react";
import { Icon, SearchBox } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  AddReadyPlanOptionFragment,
  GlobalOptionTypeStatus,
  useAddOptionsPageQuery,
  useLocationsQuery,
  useSaveReadyPlanOptionGroupsMutation,
  useSaveReadyPlanOptionsMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { EditOptionsHeader } from "src/routes/developments/plan-and-options/options/components/EditOptionsHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { createPlanDetailsUrl } from "src/RouteUrls";
import {
  ConfigureReadyPlanOption,
  ConfigureReadyPlanOptionsForm,
  configureRpoConfig,
  toSaveOptionsConfiguration,
} from "../components/utils";

export function ConfigureOptionsStep({ developmentId, id }: { developmentId: DevelopmentId; id: ReadyPlanId }) {
  const { triggerNotice } = useSnackbar();
  const [saveRpos] = useSaveReadyPlanOptionsMutation();
  const [saveRpoGroups] = useSaveReadyPlanOptionGroupsMutation();
  const onSave = async () => {
    // Save Option Configuration
    if (formState.readyPlanOptions.dirty && rpQuery.data?.readyPlan) {
      await toSaveOptionsConfiguration(formState, saveRpos, saveRpoGroups);
    }

    triggerNotice({
      message: `Your Ready Plan Options Configuration has been updated!`,
    });
  };

  const rpQuery = useAddOptionsPageQuery({ variables: { id } });
  const optionsTypes = useMemo(
    () =>
      rpQuery.data?.globalOptionTypes.filter(
        (got) => got.globalOptionsCount > 0 && got.status !== GlobalOptionTypeStatus.Archived,
      ) ?? [],
    [rpQuery.data?.globalOptionTypes],
  );

  const locationsQuery = useLocationsQuery();

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<OptionTypeFilter> = useMemo(() => {
    const option = multiFilter({
      label: "Type",
      options: optionsTypes,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    const location = multiFilter({
      label: "Location",
      options: locationsQuery?.data?.locations ?? [],
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { option, location };
  }, [locationsQuery?.data?.locations, optionsTypes]);

  const { filter, setFilter } = useFilter({ filterDefs });
  const [showArchived, setShowArchived] = useToggle(true);
  const [collapsedGroups, setCollapsedGroups] = useState(false);

  const formState = useFormState({
    config: configureRpoConfig,
    init: {
      query: rpQuery,
      map: ({ readyPlan }) => ({
        readyPlanOptions: readyPlan.options.map((o) => ({
          fragment: o,
          id: o.id,
          name: o.name,
          active: o.active,
          globalOptionId: o.globalOption.id,
          readyPlanId: readyPlan.id,
          locationId: o.location?.id,
          optionTypeId: o.type.id,
          isElevation: o.type.isElevation,
          globalTypeName: o.type.name,
          globalOptionCode: o.globalOption.code,
          globalOptionDescription: o.description,
          globalOptionLocation: o.location,
          optionGroup: o.optionGroup,
          optionDefaultsIfIds: o.optionDefaultsIf.map(({ id }) => id),
          optionPrerequisiteIds: o.optionPrerequisites.map(({ id }) => id),
          optionConflictIds: o.optionConflicts.map(({ id }) => id),
          optionConflictChildIds: o.optionConflictChildren.map(({ id }) => id),
          order: o.order,
        })),
      }),
    },
  });

  return (
    <StepLayout
      header={
        <EditOptionsHeader title="Configure Options" subtitle="Configure the selected options, then click Save." />
      }
      body={
        <div css={Css.pt3.pb0.df.fdc.fg1.$}>
          <TableActions>
            <div css={Css.df.gap1.jcfe.$}>
              <SearchBox onSearch={setSearchFilter} clearable />
              <Filters<OptionTypeFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
              <Switch label={"Show Archived"} selected={showArchived} onChange={setShowArchived} />
            </div>
            <div css={Css.df.aic.gap2.$}>
              <Button
                onClick={() => setCollapsedGroups(!collapsedGroups)}
                icon={collapsedGroups ? "expand" : "collapse"}
                label={`${collapsedGroups ? "Expand" : "Collapse"} Groups`}
                variant="secondary"
              />
              <ButtonMenu
                items={[
                  {
                    label: "Remove Options",
                    onClick: () => {
                      // TODO
                    },
                  },
                ]}
                trigger={{ label: "Actions" }}
              />
            </div>
          </TableActions>

          <div css={Css.w100.fg1.bgWhite.p4.$}>
            <ConfigureOptionsTable
              collapsedGroups={collapsedGroups}
              formState={formState}
              readyPlanOptions={rpQuery?.data?.readyPlan?.options}
              filter={filter}
              searchFilter={searchFilter}
              showArchived={showArchived}
            />
          </div>

          <NextStepButton
            label="Save & Continue"
            disabled={false}
            onClick={onSave}
            onCloseReturnUrl={createPlanDetailsUrl(developmentId, id)}
            exitButton={{
              variant: "secondary",
              label: "Save & Exit",
              onClick: onSave,
            }}
          />
        </div>
      }
    />
  );
}

function ConfigureOptionsTable({
  formState,
  readyPlanOptions = [],
  filter,
  searchFilter = "",
  showArchived,
  collapsedGroups,
}: ConfigureOptionsTableProps) {
  const rpos = useComputed(() => formState.readyPlanOptions.rows, [formState]);

  // technically this gets group order for every rpo, rather than every group
  // but since we're only using it to trigger a re-render it doesn't really matter
  const groupOrders = useComputed(() => rpos.flatMap((rpo) => rpo.optionGroup.order.value), [rpos]);
  const rpoOrders = useComputed(() => rpos.map((rpo) => rpo.order.value), [rpos]);

  const rows: GridDataRow<Row>[] = useMemo(
    () => createRows(rpos, filter, showArchived),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rpos, filter, showArchived, groupOrders, rpoOrders],
  );

  const api = useGridTableApi<Row>();

  useEffect(() => {
    const [collapsed, expanded] = rows.partition((r) => api.isCollapsedRow(r.id));
    const target = collapsedGroups ? expanded : collapsed;
    target.forEach((r) => api.toggleCollapsedRow(r.id));
  }, [api, collapsedGroups, rows]);

  return (
    <GridTable
      api={api}
      rows={rows}
      columns={createColumns(readyPlanOptions, formState)}
      filter={searchFilter}
      style={tableStyles}
      rowStyles={rowStyles}
      as="virtual"
      onRowDrop={onRowDropHandler(rows)}
    />
  );
}

export enum Columns {
  // collapse,
  Select,
  Name,
  Type,
  DefaultOf,
  Prerequisites,
  Conflicts,
}

function createColumns(
  rpos: AddReadyPlanOptionFragment[],
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
): GridColumn<Row>[] {
  return [
    dragHandleColumn<Row>({}),
    column<Row>({
      id: `${Columns.Name}`,
      header: "Name",
      parent: (rpo, row) => ({
        content: () => (
          <div css={Css.df.aic.gap2.$}>
            <CollapseToggle row={row.row} />
            <SelectToggle id={row.row.id} />
            <div css={Css.df.if(rpo.optionGroup.isSingleOptionGroup.value).fdc.$}>
              {rpo.optionGroup.isMultiOptionGroup.value && <Icon icon="archive" xss={Css.mrPx(5).$} />}
              <span css={Css.lgSb.$}>{rpo.optionGroup.value?.name}</span>
              {rpo.optionGroup.isSingleOptionGroup.value && (
                <div css={Css.df.gap1.aic.$}>
                  <div css={Css.xs.$}>{rpo.value.globalOptionCode}</div>
                  <Tag
                    text={rpo.active.value ? "Active" : "Archived"}
                    type={rpo.active.value ? "success" : "warning"}
                  />
                  {rpo.globalOptionLocation.value?.name && (
                    <Tag text={rpo.globalOptionLocation.value?.name} type="neutral" />
                  )}
                </div>
              )}
            </div>
          </div>
        ),
        value: `${rpo.optionGroup.value?.name} ${rpo.value.globalOptionCode}`,
      }),
      child: (rpo, { row }) => ({
        content: () => (
          <div css={Css.df.aic.gap2.$}>
            <SelectToggle id={row.id} />
            <div css={Css.df.fdc.$}>
              <span css={Css.baseSb.$}>{rpo.value.name}</span>
              <div css={Css.df.gap1.aic.$}>
                <span css={Css.xs.$}>{rpo.value.globalOptionCode}</span>
                <Tag text={rpo.active.value ? "Active" : "Archived"} type={rpo.active.value ? "success" : "warning"} />
                {rpo.globalOptionLocation.value?.name && (
                  <Tag text={rpo.globalOptionLocation.value.name} type="neutral" />
                )}
              </div>
            </div>
          </div>
        ),
        value: `${rpo.value.name} ${rpo.value.globalOptionCode}`,
        css: Css.sm.$,
      }),
      w: "700px",
    }),
    column<Row>({
      id: `${Columns.Type}`,
      header: "Type",
      parent: (rpo) => ({
        content: () => (
          <>
            {rpo.globalTypeName.value && (
              <Tag
                text={rpo.globalTypeName.value}
                type={rpo.optionGroup.isSingleOptionGroup.value ? undefined : "info"}
                xss={rpo.optionGroup.isSingleOptionGroup.value ? Css.bgPurple200.$ : undefined}
              />
            )}
          </>
        ),
        value: rpo.globalTypeName.value,
      }),
      child: (rpo) => ({
        content: () => (
          <div css={Css.df.fdc.gap1.$}>
            {rpo.optionGroup.value?.name && (
              <div>
                <Tag text={rpo.optionGroup.value?.name} type="info" />
              </div>
            )}
            {rpo.value?.globalTypeName && (
              <div>
                <Tag text={rpo.value?.globalTypeName} xss={Css.bgPurple200.$} />
              </div>
            )}
          </div>
        ),
        value: rpo.value.globalTypeName,
      }),
    }),
    column<Row>({
      id: `${Columns.DefaultOf}`,
      header: "Default of",
      parent: (rpo) =>
        // show same default of select if group only has 1 member
        rpo.optionGroup.value?.isSingleOptionGroup
          ? {
              content: () => <DefaultOfSelectField rpo={rpo} rpos={rpos} />,
              value: rpo.optionDefaultsIfIds.value?.toString(),
            }
          : emptyCell,
      child: (rpo) => ({
        content: () => <DefaultOfSelectField rpo={rpo} rpos={rpos} />,
        value: rpo.optionDefaultsIfIds.value?.toString(),
      }),
    }),
    column<Row>({
      id: `${Columns.Prerequisites}`,
      header: "Prerequisites",
      parent: (rpo) =>
        // show same prereq select if group only has 1 member
        rpo.optionGroup.value?.isSingleOptionGroup
          ? {
              content: () => <PrerequisitesSelectField rpo={rpo} rpos={rpos} />,
              value: rpo.optionPrerequisiteIds.value?.toString(),
            }
          : emptyCell,
      child: (rpo) => ({
        content: () => <PrerequisitesSelectField rpo={rpo} rpos={rpos} />,
        value: rpo.optionPrerequisiteIds.value?.toString(),
      }),
    }),
    column<Row>({
      id: `${Columns.Conflicts}`,
      header: "Conflicts",
      parent: (rpo) =>
        rpo.optionGroup.value?.isSingleOptionGroup
          ? {
              content: () => <ConflictsSelectField rpo={rpo} rpos={rpos} formState={formState} />,
              value: rpo.optionConflictIds.value?.toString(),
            }
          : {
              content: () => (
                <BoundSwitchField
                  label="Required"
                  field={rpo.optionGroup.required}
                  compact
                  labelStyle="centered"
                  tooltip="Indicates whether this option group is required for the Ready Plan to be valid."
                />
              ),
              value: rpo.optionGroup.value?.required,
              alignment: "right",
            },
      child: (rpo) => ({
        content: () => <ConflictsSelectField rpo={rpo} rpos={rpos} formState={formState} />,
        value: rpo.optionConflictIds.value?.toString(),
      }),
    }),
  ];
}

function createRows(
  rpos: readonly ObjectState<ConfigureReadyPlanOption>[],
  filter: OptionTypeFilter,
  showArchived: boolean,
): GridDataRow<Row>[] {
  // Group by option type
  const optionGroups = rpos
    .filter((rop) => filter?.location?.includes(rop.locationId.value ?? "") ?? true)
    .filter(
      (rpo) => (showArchived || rpo.active.value) && (filter.option?.includes(rpo.optionTypeId.value ?? "") ?? true),
    )
    .groupBy((o) => o.optionGroup.value?.id ?? "");
  // sort by group order
  return Object.entries(optionGroups)
    .sort(
      ([optionGroupIdA, rposA], [optionGroupIdB, rposB]) =>
        (rposA[0].optionGroup.value?.order ?? Number.POSITIVE_INFINITY) -
        (rposB[0].optionGroup.value?.order ?? Number.POSITIVE_INFINITY),
    )
    .map(([optionGroupId, rpos]) => {
      return {
        kind: "parent" as const,
        data: rpos[0],
        id: optionGroupId,
        draggable: true,
        children: rpos[0].optionGroup.isMultiOptionGroup.value
          ? rpos
              .flatMap((rpo) => ({
                kind: "child" as const,
                id: `${rpo.id.value}`,
                data: rpo,
                draggable: true,
              }))
              .sortBy((rpo) => rpo.data.order.value ?? Number.POSITIVE_INFINITY)
          : undefined,
      };
    });
}

function onRowDropHandler(rows: GridDataRow<Row>[]) {
  return function (draggedRow: GridDataRow<Row>, droppedRow: GridDataRow<Row>, indexOffset: number) {
    // insert dragged row in the index of dropped row + indexOffset
    // this pushes everything else lower, then set order to index for everything from dragged row onwards

    let tempRows = [...rows];
    const foundRowContainer = recursivelyGetContainingRow(draggedRow.id, tempRows)!;
    if (!foundRowContainer) {
      console.error("Could not find row array for row", draggedRow);
      return;
    }
    if (!foundRowContainer.array.some((row) => row.id === droppedRow.id)) {
      console.error("Could not find dropped row in row array", droppedRow);
      return;
    }
    // remove dragged row
    const draggedRowIndex = foundRowContainer.array.findIndex((r) => r.id === draggedRow.id);
    const reorderRow = foundRowContainer.array.splice(draggedRowIndex, 1)[0];

    const droppedRowIndex = foundRowContainer.array.findIndex((r) => r.id === droppedRow.id);

    // we also need the parent row so we can set the newly inserted array
    if (foundRowContainer.parent && foundRowContainer.parent?.children) {
      foundRowContainer.parent.children = [
        ...insertAtIndex(foundRowContainer.parent?.children, reorderRow, droppedRowIndex + indexOffset),
      ];
    } else {
      tempRows = [...insertAtIndex(tempRows, reorderRow, droppedRowIndex + indexOffset)];
    }

    function recursivelySetOrderToIndex(rowArray: GridDataRow<Row>[]) {
      // if it's a child, set the rpo order
      // if it's a group, set the optionGroup order
      for (let i = 0; i < rowArray.length; i++) {
        if (rowArray[i].kind === "parent") {
          rowArray[i].data?.optionGroup.order.set(i + 1);
          recursivelySetOrderToIndex(rowArray[i].children ?? []);
        }
        if (rowArray[i].kind === "child") {
          rowArray[i].data?.order.set(i + 1);
          recursivelySetOrderToIndex(rowArray[i].children ?? []);
        }
      }
    }

    if (foundRowContainer.parent?.children) {
      recursivelySetOrderToIndex(foundRowContainer.parent?.children);
    } else {
      recursivelySetOrderToIndex(tempRows);
    }
  };
}

type RpoSelectFieldProps = {
  rpo: ObjectState<ConfigureReadyPlanOption>;
  rpos: AddReadyPlanOptionFragment[];
};

function DefaultOfSelectField({ rpo, rpos }: RpoSelectFieldProps) {
  return (
    <BoundMultiSelectField
      field={rpo.optionDefaultsIfIds}
      options={mapToRpoSelectFieldOptions(rpo, rpos)}
      placeholder="Default of"
      compact
    />
  );
}

function PrerequisitesSelectField({ rpo, rpos }: RpoSelectFieldProps) {
  return (
    <BoundMultiSelectField
      field={rpo.optionPrerequisiteIds}
      options={mapToRpoSelectFieldOptions(rpo, rpos)}
      placeholder="Prerequisites"
      compact
    />
  );
}

function ConflictsSelectField(props: RpoSelectFieldProps & { formState: ObjectState<ConfigureReadyPlanOptionsForm> }) {
  const { rpo, rpos, formState } = props;
  // We're showing both parent and child conflicts together since the relationship is bidirectional.
  // For example, if A conflicts with B, then B also conflicts with A.
  const ids = (rpo.optionConflictIds.value ?? []).concat(rpo.optionConflictChildIds.value ?? []);
  return (
    <MultiSelectField
      label={"Option Conflicts"}
      labelStyle={"hidden"}
      values={ids}
      onSelect={(newIds) => updateRpoConflicts(newIds, ids, rpo, formState)}
      options={mapToRpoSelectFieldOptions(rpo, rpos)}
      getOptionValue={(o) => o.id}
      getOptionLabel={(o) => o.name}
      placeholder="Conflicts"
      compact
    />
  );
}

function mapToRpoSelectFieldOptions(rpo: ObjectState<ConfigureReadyPlanOption>, rpos: AddReadyPlanOptionFragment[]) {
  return rpos
    .filter((opt) => opt.optionGroup.id !== rpo.optionGroup.value?.id)
    .sortBy((opt) => opt.name)
    .map((opt) => ({
      id: opt.id,
      name: opt.displayName,
    }));
}

// Updates all affected RPO rows when a conflict is added or removed.
// Both newIds and oldIds are a combination of parent and child conflicts (i.e. optionConflictIds and optionConflictChildIds).
function updateRpoConflicts(
  newIds: string[],
  oldIds: string[],
  rpo: ObjectState<ConfigureReadyPlanOption>,
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
) {
  // Since this function is only called when a single conflict is added or removed,
  // we can safely use the ID array lengths to determine which action happened.
  if (newIds.length > oldIds.length) {
    const idsToAdd = newIds.filter((id) => !oldIds.includes(id));
    rpo.optionConflictIds.set(rpo.optionConflictIds.value?.concat(idsToAdd) ?? idsToAdd);
    formState.readyPlanOptions.rows
      .filter((opt) => opt.id.value && idsToAdd.includes(opt.id.value))
      .forEach((opt) =>
        opt.optionConflictChildIds.set(opt.optionConflictChildIds.value?.concat(rpo.id.value!) ?? [rpo.id.value!]),
      );
  } else if (newIds.length < oldIds.length) {
    const idsToRemove = oldIds.filter((id) => !newIds.includes(id));
    // Since we don't know if the conflict was removed from the parent or child conflicts, we need to set both
    rpo.optionConflictIds.set(rpo.optionConflictIds.value?.filter((id) => !idsToRemove.includes(id)) ?? []);
    rpo.optionConflictChildIds.set(rpo.optionConflictChildIds.value?.filter((id) => !idsToRemove.includes(id)) ?? []);
    formState.readyPlanOptions.rows
      ?.filter((opt) => opt.id.value && idsToRemove.includes(opt.id.value))
      .forEach((opt) => {
        opt.optionConflictIds.set(opt.optionConflictIds?.value?.filter((id) => id !== rpo.id.value!) ?? []);
        opt.optionConflictChildIds.set(opt.optionConflictChildIds?.value?.filter((id) => id !== rpo.id.value!) ?? []);
      });
  }
}

type HeaderRow = { kind: "header"; id: string; data: undefined };

type ParentRow = {
  kind: "parent";
  id: string;
  data: ObjectState<ConfigureReadyPlanOption>;
};

type ChildRow = { kind: "child"; id: string; data: ObjectState<ConfigureReadyPlanOption> };

type Row = HeaderRow | ParentRow | ChildRow;

type OptionTypeFilter = { option?: string[]; location?: string[] };
type ConfigureOptionsTableProps = {
  formState: ObjectState<ConfigureReadyPlanOptionsForm>;
  readyPlanOptions?: AddReadyPlanOptionFragment[];
  searchFilter?: string;
  filter: OptionTypeFilter;
  showArchived: boolean;
  collapsedGroups: boolean;
};

const tableStyles = {
  ...cardStyle,
  cellCss: {
    ...cardStyle.cellCss,
    ...Css.aic.pPx(12).$,
  },
  presentationSettings: {
    borderless: false,
  },
};

const rowStyles = { child: { rowCss: Css.bgGray100.$, cellCss: Css.bgGray100.$ } };
