import { Css } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import {
  SavePlanTaskInput,
  TaskDetailsPage_PlanTaskFragment,
  usePlanTaskDetailsDocumentsQuery,
} from "src/generated/graphql-types";
import { TaskDocuments } from "../../schedule-v2/detail-pane/TaskDocuments";
import { Bills } from "./Bills";
import { CostAllocations } from "./CostAllocations";
import { PurchaseOrders } from "./PurchaseOrders";
import { TaskDetailCard, TaskDetailCardType } from "./TaskDetailCard";
import { VerificationChecklist } from "./VerificationChecklist";

type ReferenceCardProps = {
  task: TaskDetailsPage_PlanTaskFragment;
  formState: ObjectState<SavePlanTaskInput>;
};

export function ReferenceCard(props: ReferenceCardProps) {
  const { task, formState } = props;
  const { data } = usePlanTaskDetailsDocumentsQuery({ variables: { parentId: task.schedule.parent.id } });
  return (
    <TaskDetailCard gridColumn={2} gridRow={2} cardType={TaskDetailCardType.Reference}>
      <h2 css={Css.xlBd.mb4.$}>Reference</h2>
      <div css={Css.bcGray300.bb.pb3.pt3.$}>
        <TaskDocuments
          parentId={task.schedule.parent.id}
          documentTypes={data?.documentTypes ?? []}
          documents={data?.documents ?? []}
          formState={formState}
          task={task}
          onPlanTask
          taskStatus={task.status.code}
        />
      </div>
      <PurchaseOrders projectItems={task.projectItems} />
      <CostAllocations projectItems={task.projectItems} />
      <Bills bills={task.billLineItems.flatMap((bli) => bli.bill)} />
      {!task.globalChecklistItems.isEmpty && (
        <>
          <div css={Css.my3.bt.bcGray300.$} />
          <VerificationChecklist planTask={task} />
        </>
      )}
    </TaskDetailCard>
  );
}
