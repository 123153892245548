import {
  Button,
  ButtonMenu,
  ButtonModal,
  Checkbox,
  Css,
  DateRange,
  DateRangeField,
  FieldGroup,
  FormLines,
  MenuItem,
  SelectField,
} from "@homebound/beam";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  createCalendarViewScheduleUrl,
  createGanttViewScheduleUrl,
  createListViewScheduleUrl,
  createMilestoneViewScheduleUrl,
} from "src/RouteUrls";
import { SearchBox } from "src/components";
import { DateOperation, PlanTasksFilter, TaskStatus, TradePartnerTaskStatus } from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { StringParam, useQueryParams } from "use-query-params";
import { useQueryStorage } from "../../schedule-v2/table/filterUtils";
import { DynamicScheduleView, useScheduleRouteMatch } from "../utils";

export enum TaskGroupBy {
  None,
  Stage,
}

export enum TradePartnerStatusFilter {
  All = "All Trade Statuses",
  NotScheduled = "Trade Not Scheduled",
  Scheduled = "Trade Scheduled",
}

type DynamicSchedulesFilterModalWrapperProps<TFilter> = {
  onClose: VoidFunction;
  filter: TFilter;
  setFilter: (filter: TFilter) => void;
  additionalFilters: ReactNode;
  scheduleView: DynamicScheduleView;
};

export function DynamicSchedulesFilterModalWrapper<
  TFilter extends { startDateRange?: DateRange; endDateRange?: DateRange },
>({ onClose, filter, setFilter, additionalFilters }: DynamicSchedulesFilterModalWrapperProps<TFilter>) {
  const [modalFilter, setModalFilter] = useState<TFilter>({ ...mapToState(filter) });

  const onSubmit = useCallback(() => {
    setFilter({ ...(modalFilter satisfies TFilter) });
    onClose();
  }, [modalFilter, setFilter, onClose]);

  return (
    <FormLines>
      <FieldGroup>
        <DateRangeField
          value={modalFilter.startDateRange}
          onChange={(val) => setModalFilter((filter) => ({ ...filter, startDateRange: val }))}
          label="Start Date Range"
          compact
        />
        <DateRangeField
          value={modalFilter.endDateRange}
          onChange={(val) => setModalFilter((filter) => ({ ...filter, endDateRange: val }))}
          label="Finish Date Range"
          compact
        />
      </FieldGroup>
      {additionalFilters}
      <div css={Css.df.gap1.jcfe.$}>
        <Button variant="tertiary" label="Cancel" onClick={onClose} />
        <Button label="Apply" onClick={onSubmit} />
      </div>
    </FormLines>
  );
}

type DynamicSchedulesFilterModalProps = {
  onClose: VoidFunction;
  filter: CustomDynamicSchedulesFilter;
  setFilter: (filter: CustomDynamicSchedulesFilter) => void;
  scheduleView: DynamicScheduleView;
};

export function DynamicSchedulesFilterModal({
  onClose,
  filter,
  setFilter,
  scheduleView,
}: DynamicSchedulesFilterModalProps) {
  const [modalFilter, setModalFilter] = useState<CustomDynamicSchedulesFilter>({ ...mapToState(filter) });

  return (
    <DynamicSchedulesFilterModalWrapper
      scheduleView={scheduleView}
      filter={filter}
      setFilter={(existingFilter) =>
        setFilter({
          ...existingFilter,
          ...modalFilter,
        })
      }
      onClose={onClose}
      additionalFilters={
        <>
          <Checkbox
            label="Completed Tasks"
            selected={!!modalFilter.includeCompletedTasks}
            onChange={(val) => setModalFilter((filter) => ({ ...filter, includeCompletedTasks: val }))}
          />
          {/* Group by is getting moved out of this filter modal SC-58996 */}
          <SelectField
            value={modalFilter.groupBy}
            onSelect={(val) => setModalFilter((filter) => ({ ...filter, groupBy: val! }))}
            label="Group By"
            options={[
              { value: TaskGroupBy.None, label: "None" },
              { value: TaskGroupBy.Stage, label: "Stage" },
            ]}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          <SelectField
            value={modalFilter.tradePartnerStatus}
            onSelect={(val) => setModalFilter((filter) => ({ ...filter, tradePartnerStatus: val! }))}
            label="Trade Partner Status"
            options={[
              { value: TradePartnerStatusFilter.All, label: "All Trade Statuses" },
              { value: TradePartnerStatusFilter.NotScheduled, label: "Trade Not Scheduled" },
              { value: TradePartnerStatusFilter.Scheduled, label: "Trade Scheduled" },
            ]}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </>
      }
    />
  );
}

export type CustomDynamicSchedulesFilter = Pick<PlanTasksFilter, "status"> & {
  startDateRange: DateRange | undefined;
  endDateRange: DateRange | undefined;
  includeCompletedTasks: boolean;
  groupBy: TaskGroupBy;
  tradePartnerStatus: TradePartnerStatusFilter | undefined;
};

export const customDynamicSchedulesFilterDefault: CustomDynamicSchedulesFilter = {
  startDateRange: undefined,
  endDateRange: undefined,
  includeCompletedTasks: false,
  groupBy: TaskGroupBy.None,
  tradePartnerStatus: TradePartnerStatusFilter.All,
};

export function mapToFilter(filter: CustomDynamicSchedulesFilter): PlanTasksFilter {
  const { startDateRange, endDateRange, includeCompletedTasks, status, groupBy, ...otherFilters } = filter;
  // There is no status filter in the UI
  const nonComplete = Object.values(TaskStatus).filter((status) => status !== TaskStatus.Complete);
  return {
    ...otherFilters,
    ...(startDateRange && startDateRange.from && startDateRange.to
      ? {
          startDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(startDateRange.from)),
            value2: new DateOnly(new Date(startDateRange.to)),
          },
        }
      : {}),
    ...(endDateRange && endDateRange.from && endDateRange.to
      ? {
          endDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(endDateRange.from)),
            value2: new DateOnly(new Date(endDateRange.to)),
          },
        }
      : {}),
    status: [...(status ?? nonComplete), ...(includeCompletedTasks ? [TaskStatus.Complete] : [])],
    tradePartnerStatus: TradeStatusFilterToStatuses[filter.tradePartnerStatus ?? TradePartnerStatusFilter.All],
  };
}

export function mapToState<TFilter extends { startDateRange?: DateRange; endDateRange?: DateRange }>(
  filter: TFilter,
): TFilter {
  const { startDateRange, endDateRange } = filter;
  return {
    ...filter,
    ...(startDateRange && startDateRange.from && startDateRange.to
      ? {
          startDateRange: {
            from: new Date(startDateRange.from),
            to: new Date(startDateRange.to),
          },
        }
      : {}),
    ...(endDateRange && endDateRange.from && endDateRange.to
      ? {
          endDateRange: {
            from: new Date(endDateRange.from),
            to: new Date(endDateRange.to),
          },
        }
      : {}),
  };
}

const filterStorageKey = "dynamicSchedulesFilter";

export function FilterSubHeader({
  scheduleParentId,
  rightMenuEl,
}: {
  scheduleParentId: string;
  rightMenuEl?: ReactNode;
}) {
  const scheduleView = useScheduleRouteMatch();
  const history = useHistory();
  const [, setSearch] = useState("");

  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: filterStorageKey,
    initialQueryStorage: customDynamicSchedulesFilterDefault,
  });

  const showFilterModal = useCallback(
    (close: VoidFunction) => {
      return (
        <DynamicSchedulesFilterModal
          filter={queryStorage}
          setFilter={setQueryStorage}
          onClose={close}
          scheduleView={scheduleView}
        />
      );
    },
    [queryStorage, setQueryStorage, scheduleView],
  );

  const handleClear = useCallback(() => {
    setQueryStorage({} as CustomDynamicSchedulesFilter);
  }, [setQueryStorage]);

  const hasFilter = useMemo(() => Object.values(queryStorage).some(Boolean), [queryStorage]);

  const menuItems: MenuItem[] = [
    {
      label: "List View",
      onClick: createListViewScheduleUrl(scheduleParentId),
    },
    {
      label: "Gantt View",
      onClick: createGanttViewScheduleUrl(scheduleParentId),
    },
    {
      label: "Calendar View",
      onClick: createCalendarViewScheduleUrl(scheduleParentId),
    },
    {
      label: "Milestone View",
      onClick: () => {
        // remove query param filters for milestone view
        // Note we could move this in to an effect within `useDynamicSchedulesFilter`
        history.push(createMilestoneViewScheduleUrl(scheduleParentId));
        setQueryStorage({} as CustomDynamicSchedulesFilter);
      },
    },
  ];

  return (
    <TableActions>
      <div css={Css.df.gap1.$}>
        <ButtonModal content={showFilterModal} trigger={{ label: "Filter" }} />
        <SearchBox clearable onSearch={setSearch} />
        {hasFilter && <Button variant="text" label="Clear" onClick={handleClear} />}
      </div>
      <div css={Css.df.gap1.$}>
        <ButtonMenu items={menuItems} trigger={{ label: `View: ${scheduleView}` }} />
        {rightMenuEl && rightMenuEl}
      </div>
    </TableActions>
  );
}

export function useDynamicSchedulesFilter() {
  const { queryStorage: allFilters } = useQueryStorage({
    storageKey: filterStorageKey,
    initialQueryStorage: customDynamicSchedulesFilterDefault,
  });
  const [{ search }] = useQueryParams({ search: StringParam });
  const maybeSearchFilter = isDefined(search) && search !== "" ? { search } : {};

  return {
    allFilters,
    search,
    // queryFilter can be passed directly to any query input expecting `PlanTasksFilter`
    queryFilter: { ...mapToFilter(allFilters), ...maybeSearchFilter },
  };
}

const TradeStatusFilterToStatuses = {
  [TradePartnerStatusFilter.All]: undefined,
  [TradePartnerStatusFilter.NotScheduled]: [
    TradePartnerTaskStatus.NeedsConfirmation,
    TradePartnerTaskStatus.NeedsReconfirmation,
    TradePartnerTaskStatus.Unavailable,
  ],
  [TradePartnerStatusFilter.Scheduled]: [TradePartnerTaskStatus.CompletedJob, TradePartnerTaskStatus.Confirmed],
};
