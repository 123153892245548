import { Checkbox, DateRange, MultiSelectField } from "@homebound/beam";
import { useState } from "react";
import { DateOperation, PlanMilestoneFilter, useBusinessFunctionTypeQuery } from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { DynamicScheduleView } from "../utils";
import { DynamicSchedulesFilterModalWrapper, mapToState } from "./DynamicSchedulesFilterModal";

type DynamicSchedulesMilestoneFilterModalProps = {
  onClose: VoidFunction;
  filter: CustomDynamicSchedulesMilestoneFilter;
  setFilter: (filter: CustomDynamicSchedulesMilestoneFilter) => void;
};

export function DynamicSchedulesMilestoneFilterModal({
  onClose,
  filter,
  setFilter,
}: DynamicSchedulesMilestoneFilterModalProps) {
  const [modalFilter, setModalFilter] = useState<CustomDynamicSchedulesMilestoneFilter>({ ...mapToState(filter) });

  const { data } = useBusinessFunctionTypeQuery();
  const enums = data?.enumDetails.businessFunctionType;

  return (
    <DynamicSchedulesFilterModalWrapper
      scheduleView={DynamicScheduleView.Milestone}
      filter={filter}
      setFilter={(existingFilter) =>
        setFilter({
          ...existingFilter,
          ...modalFilter,
        })
      }
      onClose={onClose}
      additionalFilters={
        <>
          <MultiSelectField
            values={modalFilter.team ?? []}
            label="Show Milestones by Team"
            options={enums ?? []}
            onSelect={(val) => setModalFilter({ ...filter, team: val.isEmpty ? undefined : val })}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ code }) => code}
          />
          <Checkbox
            label="Hide Non-Default Milestones"
            selected={!!modalFilter.isDefault}
            onChange={(val) => setModalFilter((filter) => ({ ...filter, isDefault: val }))}
          />
        </>
      }
    />
  );
}

export type CustomDynamicSchedulesMilestoneFilter = Pick<PlanMilestoneFilter, "team" | "isDefault"> & {
  startDateRange: DateRange | undefined;
  endDateRange: DateRange | undefined;
};

export const customDynamicSchedulesMilestoneFilterDefault: CustomDynamicSchedulesMilestoneFilter = {
  startDateRange: undefined,
  endDateRange: undefined,
  team: undefined,
  isDefault: undefined,
};

export function mapToFilter(filter: CustomDynamicSchedulesMilestoneFilter): PlanMilestoneFilter {
  const { startDateRange, endDateRange, isDefault, ...otherFilters } = filter;
  return {
    isDefault: isDefault || undefined,
    ...otherFilters,
    ...(startDateRange && startDateRange.from && startDateRange.to
      ? {
          startDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(startDateRange.from)),
            value2: new DateOnly(new Date(startDateRange.to)),
          },
        }
      : {}),
    ...(endDateRange && endDateRange.from && endDateRange.to
      ? {
          endDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(endDateRange.from)),
            value2: new DateOnly(new Date(endDateRange.to)),
          },
        }
      : {}),
  };
}
