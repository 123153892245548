import {
  BoundTextField,
  Button,
  ButtonMenu,
  Css,
  SuperDrawerContent,
  SuperDrawerHeader,
  TabsWithContent,
  Tag,
  useSessionStorage,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import {
  MaterialCatalog_ItemFragment,
  MaterialCatalog_MaterialBrandFragment,
  useMaterialCatalogMetadataQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { MaterialOverviewTab } from "./MaterialOverviewTab";
import { MaterialSuperDrawerContextProvider, useMaterialSuperDrawerContext } from "./MaterialSuperDrawerContext";

export type MaterialSuperDrawerProps = {
  variantId: string;
  readOnly?: boolean;
};

export function MaterialSuperDrawer({ variantId, readOnly = true }: MaterialSuperDrawerProps) {
  const query = useMaterialCatalogMetadataQuery();

  return queryResult(query, ({ materialBrands, items }) => (
    <MaterialSuperDrawerContextProvider variantId={variantId} readOnly={readOnly}>
      <MaterialSuperDrawerContent items={items} brands={materialBrands} />
    </MaterialSuperDrawerContextProvider>
  ));
}

type MaterialSuperDrawerContentProps = {
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

function MaterialSuperDrawerContent({ items, brands }: MaterialSuperDrawerContentProps) {
  const { variant, formState, save, cancel, readOnly, toggleReadOnly, createCopy } = useMaterialSuperDrawerContext();
  const [selectedTab, setSelectedTab] = useSessionStorage("materialSuperDrawerTab", "overview");

  return (
    <SuperDrawerContent>
      <SuperDrawerHeader
        title={(readOnly && variant?.displayName) || ""}
        hideControls
        left={
          readOnly ? (
            <div css={Css.df.aic.gap1.$}>
              <span>({variant?.code})</span>
              <div css={Css.df.jcc.mwPx(65).$}>
                <Tag
                  type={variant?.isArchived ? "caution" : "success"}
                  text={variant?.isArchived ? "Archived" : "Active"}
                />
              </div>
            </div>
          ) : (
            <BoundTextField field={formState.materialName} label="Name" labelStyle="hidden" />
          )
        }
        right={
          <div css={Css.df.aic.gap1.$}>
            <Observer>
              {() => (
                <div css={Css.mla.df.gap1.pr1.$}>
                  {readOnly ? (
                    <Button label="Edit" variant="secondary" onClick={toggleReadOnly} />
                  ) : (
                    <>
                      <Button label="Cancel" variant="tertiary" onClick={cancel} />
                      <Button label="Save" disabled={!formState.dirty} variant="primary" onClick={save} />
                    </>
                  )}
                </div>
              )}
            </Observer>
            {readOnly && (
              <ButtonMenu
                trigger={{ icon: "verticalDots" }}
                items={[
                  {
                    label: "Make a copy",
                    onClick: createCopy,
                  },
                ]}
              />
            )}
          </div>
        }
      />
      <TabsWithContent
        tabs={[
          {
            name: "Overview",
            value: "overview",
            render: () => <MaterialOverviewTab items={items} brands={brands} />,
          },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </SuperDrawerContent>
  );
}
