import FullCalendar from "@fullcalendar/react";

import { Css, useSnackbar } from "@homebound/beam";
import { createRef, useMemo } from "react";
import {
  ScheduleDraftMode_PlanTaskFragment,
  SchedulingExclusionDatesFragment,
  TaskStatus,
} from "src/generated/graphql-types";
import { DynamicSchedulesCalendarWrapper } from "../calendar/DynamicSchedulesCalendar";
import {
  getPlanTaskData,
  onDraftTaskEventDrop,
  onDraftTaskEventResize,
  renderTaskEventContent,
} from "../calendar/dynamicSchedulesCalendarUtils";
import { useDraftScheduleStore } from "./scheduleDraftStore";

export function DraftScheduleCalendar({
  planTasks,
  schedulingExclusionDates,
  loading,
}: {
  planTasks: ScheduleDraftMode_PlanTaskFragment[];
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
  loading: boolean;
}) {
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const lastUpdatedTaskId = useDraftScheduleStore((state) => state.lastUpdatedTaskId);

  const { triggerNotice } = useSnackbar();

  const scheduleExcludedDates = useMemo(
    () => schedulingExclusionDates.map(({ date }) => date),
    [schedulingExclusionDates],
  );

  const { events, planTasksById } = useMemo(() => {
    const incompleteTasks = planTasks.filter((t) => t.status.code !== TaskStatus.Complete);
    return getPlanTaskData(incompleteTasks);
  }, [planTasks]);

  // FullCalendar still uses legacy refs
  const calendarRef = createRef<FullCalendar>();

  return (
    <div css={Css.mr3.h100.pb3.$} data-testid="calendarViewContent">
      <DynamicSchedulesCalendarWrapper
        events={events}
        calendarRef={calendarRef}
        editable={true}
        loading={loading}
        // don't allow users to click into a task if the calendar is in edit mode
        eventClick={undefined}
        eventContent={(event) => renderTaskEventContent(event, planTasksById, true, lastUpdatedTaskId)}
        eventResize={({ event, revert }) =>
          onDraftTaskEventResize(
            event,
            planTasksById[event.id],
            scheduleExcludedDates,
            revert,
            setDraftTaskChanges,
            triggerNotice,
          )
        }
        eventDrop={({ event, revert }) =>
          onDraftTaskEventDrop(
            event,
            planTasksById[event.id],
            scheduleExcludedDates,
            revert,
            setDraftTaskChanges,
            triggerNotice,
          )
        }
      />
    </div>
  );
}
