import { Route, Switch, useParams } from "react-router";
import { ProjectParams, dynamicSchedulesPath } from "src/routes/routesDef";
import { DynamicSchedulesCalendar } from "./calendar/DynamicSchedulesCalendar";
import { DynamicSchedulesHeader } from "./components/DynamicSchedulesHeader";
import { DynamicSchedulesGantt } from "./gantt/DynamicSchedulesGantt";
import { DynamicSchedulesList } from "./list-view/DynamicSchedulesList";
import { DynamicSchedulesMilestone } from "./milestone-view/DynamicSchedulesMilestone";

export function DynamicSchedulesPage() {
  const { projectId } = useParams<ProjectParams>();

  return (
    <>
      <DynamicSchedulesHeader scheduleParentId={projectId} />
      <Switch>
        <Route component={DynamicSchedulesList} path={dynamicSchedulesPath.listView} />
        <Route component={DynamicSchedulesGantt} path={dynamicSchedulesPath.ganttView} />
        <Route component={DynamicSchedulesCalendar} path={dynamicSchedulesPath.calendarView} />
        <Route component={DynamicSchedulesMilestone} path={dynamicSchedulesPath.milestoneView} />
      </Switch>
    </>
  );
}
