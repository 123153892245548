import { ButtonModal, Css, IconButton, LoadingSkeleton, Palette, useBreakpoint, useTestIds } from "@homebound/beam";
import { PropsWithChildren, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { BillStatus, BillType, ProjectRole, useBillsSummaryHeaderQuery } from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { personalDashboardPaths } from "src/routes/routesDef";
import { useDashboardFilterContext } from "../DashboardFilterContext";

export function BillsSummaryHeader() {
  const currentUser = useCurrentUser();
  const { filter } = useDashboardFilterContext();

  const { data, loading } = useBillsSummaryHeaderQuery({
    variables: {
      unreleasedClickToPayBillsFilter: {
        projectId: filter.project,
        requesterId: filter.internalUser,
        type: [BillType.Deferred],
        status: [BillStatus.Draft, BillStatus.Unreleased],
      },
      pendingReviewBillsFilter: {
        assignees: {
          users: filter.internalUser ?? [],
          roles: [ProjectRole.PurchasingOne, ProjectRole.PurchasingTwo],
        },
        projectId: filter.project,
        pendingReview: true,
      },
    },
    skip: !currentUser.id,
  });
  const { sm } = useBreakpoint();
  const testIds = useTestIds({});

  const summaryData = useMemo(() => {
    const totalUnreleasedClickToPayBills = data?.unreleasedClickToPayBills.length ?? 0;
    const totalPendingReviewBills = data?.pendingReviewBills.length ?? 0;
    return {
      totalUnreleasedClickToPayBills,
      totalPendingReviewBills,
      totalBills: totalUnreleasedClickToPayBills + totalPendingReviewBills,
    };
  }, [data]);

  if (loading) return <LoadingSkeleton rows={1} columns={1} />;

  return (
    <ButtonModal
      content={<BillsSummaryLayout summaryData={summaryData} />}
      trigger={{
        label: (
          <span css={Css.if(sm).xs.gray900.else.df.aic.gap2.$} {...testIds.billsTotal}>
            <span css={Css.blue700.if(sm).smBd.else.xl4.$}>{summaryData.totalBills}</span> Bills
          </span>
        ),
      }}
      variant={sm ? "text" : "textSecondary"}
      placement="right"
    />
  );
}

const atomWidth = 127;
const Label = ({ children }: PropsWithChildren<unknown>) => <div css={Css.smBd.mb1.$}>{children}</div>;

function BillsSummaryLayout({
  summaryData,
}: {
  summaryData: {
    totalUnreleasedClickToPayBills: number;
    totalPendingReviewBills: number;
    totalBills: number;
  };
}) {
  const { totalUnreleasedClickToPayBills, totalPendingReviewBills } = summaryData;
  const testIds = useTestIds({}, "billsSummaryLayout");
  const history = useHistory();

  return (
    <div css={Css.mwPx(335).$} {...testIds}>
      <Row>
        <div css={Css.wPx(atomWidth).$}>
          <Label>{"Unreleased Click to Pay Bills"}</Label>
          <div css={Css.df.fdr.aic.gap1.jcsb.$} {...testIds.totalUnreleasedClickToPayBills}>
            <span css={Css.xl3Bd.red600.$}>{totalUnreleasedClickToPayBills}</span>
            <span css={Css.smSb.$}>Bills</span>
            <IconButton
              icon="chevronRight"
              inc={3}
              color={Palette.Gray700}
              onClick={() => history.push(personalDashboardPaths.actions)}
            />
          </div>
        </div>
        <div css={Css.wPx(atomWidth).$}>
          <Label>{"Pending Review Bills"}</Label>
          <div css={Css.df.fdr.aic.gap1.jcsb.$} {...testIds.totalPendingReviewBills}>
            <span css={Css.xl3Bd.red600.$}>{totalPendingReviewBills}</span>
            <span css={Css.smSb.$}>Bills</span>
            <IconButton
              icon="chevronRight"
              inc={3}
              color={Palette.Gray700}
              onClick={() => history.push(personalDashboardPaths.actions)}
            />
          </div>
        </div>
      </Row>
    </div>
  );
}

type RowProps = {
  breakpoint?: boolean;
};

const Row = ({ children, breakpoint }: PropsWithChildren<RowProps>) => (
  <div css={Css.df.fdr.jcfs.aifs.w100.gap4.if(!!breakpoint).fdc.$}>{children}</div>
);
