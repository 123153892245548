import { Global } from "@emotion/react";
import { Css, useTestIds } from "@homebound/beam";
import { LienWaiverCondition, LienWaiverType, PandaDocRole } from "src/generated/graphql-types";
import { Signature, SignatureDate } from "../../components/PandaDocField";
import { LienWaiverProps } from "../LienWaiverRouter";
import { amountInCents, buildAddress, claimantName, getTitleField, invoiceNumber, owner } from "../utils";

const TX_OWNER = "MF-HB Texas PropCo II, LLC";

export function TXLienWaiver({ data }: LienWaiverProps) {
  const lienWaiver = data.lienWaiver!;
  const projectName = lienWaiver.project.name;
  const conditional = lienWaiver.condition.code;
  const type = lienWaiver.type.code;
  const isConditional = conditional === LienWaiverCondition.Conditional;
  const isFinal = lienWaiver.type.code === LienWaiverType.Final;
  const testIds = useTestIds({}, "TXLienWaiver");

  return (
    <>
      <Global
        styles={{
          "@page": { size: "legal" },
          li: Css.my2.$,
          p: Css.base.$,
          span: Css.tdu.$,
        }}
      />

      <div {...testIds.container} css={Css.p2.$}>
        {!isConditional && (
          <div css={Css.mb2.$}>
            <p css={Css.smSb.$}>
              NOTICE: This document waives rights unconditionally and states that you have been paid for giving up those
              rights. It is prohibited for a person to require you to sign this document if you have not been paid the
              payment amount set forth below. If you have not been paid, use a conditional release form.
            </p>
          </div>
        )}
        <div css={Css.lgSb.tdu.tac.$}>
          {conditional} WAIVER AND RELEASE ON {type} PAYMENT
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb2.fwb.$}>
            Project <span>{projectName}</span>
          </p>
          <p css={Css.mb2.fwb.$}>Job No.</p>
        </div>

        <div css={Css.mb2.$}>
          <div data-testid="" css={Css.mb2.$}>
            {isConditional && (
              <p css={Css.indent.mb2.$}>
                On receipt by the signer of this document of a payment from (maker of check) in the sum of{" "}
                {amountInCents(lienWaiver)} payable to {claimantName(lienWaiver, "checkReceiver")} (payee or payees of
                check) for invoice number {invoiceNumber(lienWaiver)} and when the check has been properly endorsed and
                has been paid by the bank on which it is drawn, this document becomes effective to release any
                mechanic's lien right, any right arising from a payment bond that complies with a state or federal
                statute, any common law payment bond right, any claim for payment, and any rights under any similar
                ordinance, rule, or statute related to claim or payment rights for persons in the signer's position that
                the signer has on the property of {owner(lienWaiver, TX_OWNER)}
                (owner) located at {buildAddress(lienWaiver)} (location) to the following extent: {""} (job description)
              </p>
            )}
            {!isConditional && (
              <p css={Css.indent.mb2.$}>
                The signer of this document has been paid{" "}
                {!isFinal ? (
                  <>
                    and has received a progress payment for invoice number {invoiceNumber(lienWaiver)} in the sum of{" "}
                    {amountInCents(lienWaiver)}
                  </>
                ) : (
                  <>in full</>
                )}{" "}
                for all labor, services, equipment, or materials furnished to the property or to (person with whom
                signer contracted) on the property of {owner(lienWaiver, TX_OWNER)} (owner) located at{" "}
                {buildAddress(lienWaiver)} (location) to the following extent: (job description). The signer therefore
                waives and releases any mechanic's lien right, any right arising from a payment bond that complies with
                a state or federal statute, any common law payment bond right, any claim for payment, and any rights
                under any similar ordinance, rule, or statute related to claim or payment rights for persons in the
                signer's position
                {!isFinal && <>that the signer has on the above referenced project to the following extent</>}.
              </p>
            )}

            {!isFinal && (
              <>
                <p css={Css.indent.mb2.$}>
                  This release covers a progress payment for all labor, services, equipment, or materials furnished to
                  the property or to {""} (person with whom signer contracted) as indicated in the attached statement(s)
                  or progress payment request(s). except for unpaid retention, pending modifications and changes, or
                  other items furnished.
                </p>
                <p css={Css.indent.mb2.$}>
                  Before any recipient of this document relies this document, the recipient should verify evidence of
                  payment to the signer.
                </p>
              </>
            )}

            {isFinal && isConditional && (
              <>
                <p css={Css.indent.mb2.$}>
                  This release covers the final payment for all labor, services, equipment, or materials furnished to
                  the property or to {""} (person with whom signer contracted).
                </p>
                <p css={Css.indent.mb2.$}>
                  Before any recipient of this document relies this document, the recipient should verify evidence of
                  payment to the signer.
                </p>
              </>
            )}

            <p css={Css.indent.mb2.$}>
              The signer warrants that the signer has already paid or will use the funds received from this progress
              payment to promptly pay in full all of the signer's laborers, subcontractors, materialmen, and suppliers
              for all work, materials, equipment, or services provided for or the above referenced project in regard to
              the attached statement(s) or progress payment request(s).
            </p>
            <div css={Css.tdu.$}>
              <SignatureDate role={PandaDocRole.Signatory} prefix="Date:" />
              <p>
                <span>{lienWaiver.claimant?.name} </span>
                (Company Name)
              </p>
              {!isConditional && isFinal && (
                <p>
                  <span>{invoiceNumber(lienWaiver)} </span>
                  (Invoice Number)
                </p>
              )}
              <Signature prefix="By" role={PandaDocRole.Signatory}></Signature>
              <p>
                {getTitleField()}
                <span css={Css.tdn.$}>(Title)</span>
              </p>
            </div>
          </div>
        </div>

        <hr></hr>
        <p css={Css.sm.$}>
          <strong>NOTE</strong>: Section 53.281(b)(2), Texas Property Code, requires that the above form be notarized.
          See Chapter 121, Texas Civil Practice & Remedies Code, regarding Acknowledgments & Proofs of Written
          Instruments, or consult an attorney. For short acknowledgement forms that might be suitable, see Section
          121.008 in Chapter 121. Click here to go there.
        </p>
      </div>
    </>
  );
}
