import { Css, useModal } from "@homebound/beam";
import { useCallback } from "react";
import { topNavHeight } from "src/routes/layout/GlobalNav";

/**
 * Wraps Beam's useModal with an absolutely-positioned fullscreen div offset by the NavBar
 * height. Gives an easy way to drop into a fullscreen "Wizard" or "Selector" flow.
 *
 * ```jsx
 * onClick={() => openFullscreen(<SomeComponent someId={id} />)}
 * ```
 *
 * Keep in mind regular Modal foibles exist, like no access to Router, Context, etc. Error
 * messages will also be hidden behind the modal until it closes, which may confuse users.
 */
export function useFullscreenModal() {
  const { openModal, closeModal } = useModal();

  const openFullscreen = useCallback(
    (content: React.ReactNode) => {
      closeModal(); // Since we're about to open our own modal, close any others so there aren't issues
      openModal({
        content: <div css={Css.absolute.topPx(topNavHeight).left0.bottom0.right0.bgWhite.$}>{content}</div>,
      });
    },
    [closeModal, openModal],
  );

  return { openFullscreen };
}
