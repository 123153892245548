import {
  BoundRichTextField,
  BoundSelectField,
  BoundTextField,
  Css,
  FormLines,
  Icon,
  Palette,
  SubmitButton,
  ToggleButton,
  useComputed,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import omit from "lodash/omit";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { createMaterialCatalogUrl, createMaterialDetailsUrl } from "src/RouteUrls";
import { SelectMaybeNewField } from "src/components/selectMaybeNew/SelectMaybeNewField";
import {
  InputMaybe,
  MaterialCatalogDocument,
  MaterialCatalogMetadataDocument,
  MaterialCatalog_ItemFragment,
  MaterialCatalog_MaterialBrandFragment,
  MaterialType,
  PotentialOperation2,
  SaveMaterialVariantImageInput,
  SaveMaterialVariantInput,
  useMaterialCatalogMetadataQuery,
  useSaveMaterialBrandMutation,
  useSaveMaterialVariantMutation,
} from "src/generated/graphql-types";
import { useReaction } from "src/hooks";
import { useMaterialTypes } from "src/hooks/enums/useMaterialTypes";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils";
import { MaterialDimensionsForm } from "./components/MaterialDimensionsForm";
import { MaterialImageEditor } from "./components/MaterialImageEditor";
import { MaterialAssemblyForm } from "./components/material-assembly-form/MaterialAssemblyForm";

/**
 * The "Add Material" page in the Material Catalog.
 *
 * Editing is done via a super-drawer, but we share `MaterialAssemblyForm`s.
 */
export function MaterialPage() {
  const query = useMaterialCatalogMetadataQuery();
  return queryResult(query, ({ items, materialBrands }) => <MaterialEditor items={items} brands={materialBrands} />);
}

type MaterialEditorProps = {
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

function MaterialEditor({ items, brands }: MaterialEditorProps) {
  const [saveMaterialVariant, { loading }] = useSaveMaterialVariantMutation();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const formState = useFormState({ config: MaterialPageFormConfig, loading });

  const handleSave = async () => {
    const { images, components, ...input } = formState.value;
    const { data } = await saveMaterialVariant({
      variables: {
        input: {
          ...input,
          images: images?.map((i) => omit(i, "downloadUrl", "attachmentUrl")),
          components: components?.map(({ componentId, componentItemId, componentValues, ...otherOpts }) => ({
            ...otherOpts,
            ...(componentItemId ? { componentItemId, componentValues } : { componentId }), // Take out the component Id since it references the placeholder used as base for the new MV
          })),
        },
      },
      refetchQueries: [MaterialCatalogDocument],
    });

    const savedMaterial = data?.saveMaterialVariant.materialVariant;
    if (savedMaterial) {
      triggerNotice({
        message: `Material: ${savedMaterial.code} created`,
        icon: "success",
      });
      history.push(createMaterialDetailsUrl(savedMaterial.id));
    }
  };

  return (
    <div>
      <PageHeader title="Add a New Material" backButton={createMaterialCatalogUrl()} />
      <div css={Css.df.$}>
        <div css={Css.w100.pb8.mx2.$}>
          <MaterialForm isNew={true} items={items} brands={brands} formState={formState} />
        </div>
      </div>
      <footer
        css={Css.df.fixed.z999.bottom0.left0.right0.bgGray100.p4.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$}
      >
        <div css={Css.mla.$}>
          <SubmitButton form={formState} label="Add Material" onClick={handleSave} />
        </div>
      </footer>
    </div>
  );
}

export type VariantImageAsset = SaveMaterialVariantImageInput & {
  downloadUrl?: string;
  attachmentUrl?: string;
};

export type MaterialFormValue = SaveMaterialVariantInput & {
  images?: InputMaybe<Array<VariantImageAsset>>;
};

export type MaterialPageFormState = ObjectState<MaterialFormValue>;

export const MaterialPageFormConfig: ObjectConfig<MaterialFormValue> = {
  id: { type: "value" },
  codeOverride: { type: "value" },
  type: { type: "value", rules: [required] },
  itemId: { type: "value", rules: [required] },
  brandId: { type: "value" },
  modelNumber: { type: "value" },
  materialName: { type: "value" },
  manufacturerUrl: { type: "value" },
  designFeedback: { type: "value" },
  isArchived: { type: "value" },
  mavIds: { type: "value" },
  components: {
    type: "list",
    config: {
      id: { type: "value" },
      assemblyId: { type: "value" },
      componentId: { type: "value" },
      componentItemId: { type: "value" },
      componentValues: { type: "value" },
      qualifier: { type: "value" },
    },
  },
  images: {
    type: "list",
    config: {
      id: { type: "value" },
      sortOrder: { type: "value" },
      attachmentUrl: { type: "value" },
      downloadUrl: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          s3Key: { type: "value" },
          fileName: { type: "value" },
          contentType: { type: "value" },
          sizeInBytes: { type: "value" },
          delete: { type: "value" },
        },
      },
    },
  },
};

type MaterialFormProps = {
  isNew: boolean;
  hideNameField?: boolean;
  canEditItem?: PotentialOperation2;
  formState: MaterialPageFormState;
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

/** Used by both add `MaterialPage` + edit `MaterialOverviewTab` drawer. */
export function MaterialForm(props: MaterialFormProps) {
  const { isNew, canEditItem, hideNameField = false, formState, items, brands } = props;
  const types = useMaterialTypes();
  const [saveMaterialBrand] = useSaveMaterialBrandMutation();
  // Get the current item to later show its dimensions
  const item = useComputed(() => items.find((i) => i.id === formState.itemId.value), [formState.itemId, items]);

  async function saveBrand(newBrand: string | undefined) {
    if (newBrand) {
      const { data } = await saveMaterialBrand({
        variables: { input: { name: newBrand } },
        refetchQueries: [MaterialCatalogMetadataDocument],
      });
      if (data) {
        formState.brandId.set(data.saveMaterialBrand.materialBrand.id);
      }
    }
  }

  // Locks name to the item's name for Placeholder materials (and it's ignored by the backend)
  useReaction(
    () => [formState.type.value, formState.itemId.value],
    ([type, itemId]) => {
      if (type === MaterialType.Placeholder && !formState.readOnly) {
        const name = items.find((i) => i.id === itemId)?.name;
        formState.materialName.set(name);
      }
    },
    [formState],
  );

  // Clear any unsaved components for non-Placeholder materials
  useReaction(
    () => [formState.type.value, formState.components.dirty] as const,
    ([type, dirtyComponents]) => {
      const shouldResetComponents = type && !canHaveComponents(type) && !formState.readOnly && dirtyComponents;
      if (shouldResetComponents) formState.components.set([]);
    },
    [formState],
  );

  const [type, readOnly] = useComputed(() => [formState.type.value, formState.readOnly], [formState]);

  return (
    <div css={Css.df.jcsb.$}>
      <FormLines labelStyle="above" width="lg">
        <div css={Css.df.fdc.gap2.$}>
          <div css={Css.df.gap2.$}>
            <div css={Css.df.aic.gap2.$}>
              <div css={Css.mwPx(172).$}>
                <BoundSelectField readOnly={!isNew} field={formState.type} options={types} label="Type" />
              </div>
              {type === MaterialType.Construction && (
                <div css={Css.mwPx(172).$}>
                  <BoundTextField label="Code Override" field={formState.codeOverride} />
                </div>
              )}
            </div>
            {!hideNameField && <BoundTextField field={formState.materialName} label="Name" />}
          </div>
        </div>
        <div css={Css.df.aic.gap2.$}>
          <BoundSelectField
            label="Item"
            field={formState.itemId}
            readOnly={readOnly}
            options={items}
            disabled={!canEditItem?.allowed ? canEditItem?.disabledReasons.map((r) => r.message).join(" ") : undefined}
          />
          {/* Placeholder can't have brands or model numbers. */}
          {type !== MaterialType.Placeholder && (
            <>
              <div css={Css.mwPx(172).$}>
                <Observer>
                  {() => (
                    <SelectMaybeNewField
                      label="Brand"
                      options={brands}
                      readOnly={readOnly}
                      value={formState.brandId.value}
                      onSelect={(brandId) => formState.brandId.set(brandId)}
                      getOptionLabel={(brand) => brand.name}
                      getOptionValue={(brand) => brand.id}
                      onAdd={saveBrand}
                    />
                  )}
                </Observer>
              </div>
              <BoundTextField field={formState.modelNumber} label="SKU / Model Number" />
            </>
          )}
        </div>
        {type !== MaterialType.Placeholder && (
          <>
            {/* When we're readOnly, render a link--ideally we'd have a LinkField in Beam. */}
            {readOnly ? (
              <div css={Css.df.fdc.gap1.$}>
                <label css={Css.gray700.sm.$}>Manufacturer Url: </label>
                <div css={Css.df.gap1.aic.$}>
                  <Icon icon="linkExternal" color={Palette.Blue600} />
                  <a
                    css={Css.truncate.smMd.gray100.$}
                    target="_blank"
                    href={formState.manufacturerUrl.value!}
                    rel="noreferrer"
                  >
                    {formState.manufacturerUrl.value}
                  </a>
                </div>
              </div>
            ) : (
              <BoundTextField
                startAdornment={<Icon icon="linkExternal" />}
                field={formState.manufacturerUrl}
                label="Manufacturer Url"
              />
            )}
          </>
        )}
        <BoundRichTextField field={formState.designFeedback} label="Design Feedback" />
        {item?.materialAttributeDimensions.nonEmpty && (
          <div css={Css.df.fdc.gap2.$}>
            <span css={Css.baseMd.$}>Item Properties</span>
            <div css={Css.df.fdc.gap1.$}>
              <MaterialDimensionsForm formState={formState} dimensions={item.materialAttributeDimensions} />
            </div>
          </div>
        )}
        {type && canHaveComponents(type) && (
          <div css={Css.df.fdc.gap2.$}>
            <span css={Css.baseMd.$}>Assembly</span>
            <MaterialAssemblyForm formState={formState} />
          </div>
        )}
      </FormLines>
      <FormLines labelStyle="above" width="sm">
        <div css={Css.df.aic.gap2.$}>
          <span css={Css.baseMd.$}>Status:</span>
          <Observer>
            {() => (
              <ToggleButton
                tooltip={<span>`Click to ${formState.isArchived.value ? "Activate" : "Archive"}`</span>}
                disabled={formState.readOnly}
                label={formState.isArchived.value === true ? "Archived" : "Active"}
                onChange={() => formState.isArchived.set(!formState.isArchived.value)}
                selected={!formState.isArchived.value}
                icon={formState.isArchived.value ? "archive" : undefined}
              />
            )}
          </Observer>
        </div>
        <span css={Css.baseMd.$}>Material Images</span>
        <MaterialImageEditor formState={formState} />
      </FormLines>
    </div>
  );
}

function canHaveComponents(type: MaterialType): boolean {
  return type === MaterialType.Placeholder || type === MaterialType.Construction;
}
