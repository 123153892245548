import {
  Css,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  SimpleHeaderAndData,
  Tag,
  column,
  dateColumn,
  numericColumn,
  simpleHeader,
  useTestIds,
} from "@homebound/beam";
import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { createProjectExpensesUrl } from "src/RouteUrls";
import { FormattedDate, priceCell } from "src/components";
import { billStatusTagMapper, getBillLabel } from "src/components/detailPane/bill/utils";
import {
  BillType,
  CommitmentBillFragment,
  CommitmentEditor_ExpenseAllocationFragment,
} from "src/generated/graphql-types";
import { ProjectParams } from "src/routes/routesDef";
import { TagTypeMapper } from "src/utils";
import { DateOnly } from "src/utils/dates";

export type CommitmentBillsTableV4Props = {
  bills: CommitmentBillFragment[];
  expenseAllocations?: CommitmentEditor_ExpenseAllocationFragment[];
};

export function CommitmentBillsTableV4(props: CommitmentBillsTableV4Props) {
  const { projectId } = useParams<ProjectParams>();
  const { bills, expenseAllocations = [] } = props;
  const tid = useTestIds({});
  const lookup = useRef<GridRowLookup<Row>>();

  return (
    <GridTable
      columns={createBillColumns(projectId, tid)}
      rows={createRows(bills, expenseAllocations)}
      fallbackMessage="Add bills after this commitment has been signed."
      style={{ bordered: true, allWhite: true }}
      rowLookup={lookup}
    />
  );
}

type RowData = {
  __typename: "Expense" | "Bill";
  entityNumber: string;
  tradePartnerNumber?: string;
  status: TagTypeMapper;
  amount: number;
  type: string;
  createdDate: DateOnly;
  releasedDate: DateOnly | undefined;
  approvedDate: DateOnly | undefined;
  processedDate: DateOnly | undefined;
  url: string;
};

type Row = SimpleHeaderAndData<RowData>;

function createRows(
  bills: CommitmentBillFragment[],
  expenseAllocations: CommitmentEditor_ExpenseAllocationFragment[],
): GridDataRow<Row>[] {
  const groupedByExpense = expenseAllocations.groupBy((ea) => ea.expense.id);
  const expenseRows = Object.values(groupedByExpense).map((group) => {
    const expense = group.first!.expense;
    return {
      id: expense.id,
      kind: "data" as const,
      data: {
        __typename: "Expense",
        entityNumber: `${expense.id} - ${expense.intacctApBillId}`,
        status: expenseStatusTagMapper(expense),
        amount: group.sum((ea) => ea.amountInCents),
        type: "expense",
        createdDate: expense.createdAt,
        processedDate: expense.expenseDate,
      },
    };
  }) as GridDataRow<Row>[];

  const billRows = bills.map((bill) => ({
    id: bill.id,
    kind: "data" as const,
    data: {
      __typename: "Bill",
      tradePartnerNumber: bill.tradePartnerNumber,
      entityNumber: getBillLabel(bill),
      status: billStatusTagMapper(bill),
      amount: bill.isTradePartnerCredit ? -bill.billedInCents : bill.billedInCents,
      type: [BillType.Immediate, BillType.Deferred, BillType.Deposit].includes(bill.type.code) ? "auto" : "manual",
      createdDate: bill.billDate,
      releasedDate: bill.sentDate,
      approvedDate: bill.approvedAt,
      processedDate: bill.paidDate,
      url: bill.blueprintUrl.path,
    },
  })) as GridDataRow<Row>[];

  return [simpleHeader, ...billRows, ...expenseRows];
}

function createBillColumns(projectId: string, tid: Record<string, object>): GridColumn<Row>[] {
  const billNumberColumn = column<Row>({
    header: () => "Bill #",
    data: (row, data) => ({
      content() {
        if (row.__typename === "Bill") {
          return (
            <Link
              target="_blank"
              {...tid.entityNumber}
              style={Css.blue700.add("fontSize", "inherit").fw5.$}
              to={row.url}
            >
              {row.entityNumber}
            </Link>
          );
        }

        return (
          <Link
            target="_blank"
            {...tid.entityNumber}
            style={Css.blue700.add("fontSize", "inherit").fw5.$}
            to={createProjectExpensesUrl(projectId)}
          >
            {row.entityNumber}
          </Link>
        );
      },
      value: Number(row.tradePartnerNumber),
    }),
    clientSideSort: true,
    w: 1.2,
  });
  const statusColumn = column<Row>({
    header: () => "Status",
    data: ({ status: [type, text] }) => (
      <div {...tid.status}>
        <Tag type={type} text={text} />
      </div>
    ),
    w: 1.3,
  });
  const amountColumn = numericColumn<Row>({
    header: () => "Amount",
    data: (row) => priceCell({ valueInCents: row.amount }),
    w: 1,
  });
  const typeColumn = numericColumn<Row>({
    header: () => ({ alignment: "center", content: "Type" }),
    data: (row) => ({
      alignment: "center",
      content: () => <span {...tid.type}>{row.type}</span>,
    }),
    w: 1.1,
  });
  const createdDateColumn = dateColumn<Row>({
    header: () => "Created on",
    data: (row) => <FormattedDate date={row.createdDate} />,
    w: 1,
  });
  const releasedDateColumn = dateColumn<Row>({
    header: () => "Released On",
    data: (row) => <FormattedDate date={row.releasedDate} />,
    w: 1,
  });

  const approvedDateColumn = dateColumn<Row>({
    header: () => "Approved On",
    data: (row) => <FormattedDate date={row.approvedDate} />,
    w: 1,
  });
  const processedDateColumn = dateColumn<Row>({
    header: () => "Processed On",
    data: (row) => row.processedDate && <FormattedDate date={row.processedDate} />,
    w: 1,
  });
  return [
    billNumberColumn,
    statusColumn,
    amountColumn,
    typeColumn,
    createdDateColumn,
    releasedDateColumn,
    approvedDateColumn,
    processedDateColumn,
  ];
}

export function expenseStatusTagMapper(expense: CommitmentEditor_ExpenseAllocationFragment["expense"]): TagTypeMapper {
  const isFullyAllocated = expense.unallocatedAmountInCents === 0;
  if (isFullyAllocated) {
    return ["success", "Fully Allocated"];
  }

  return ["neutral", "Partially Allocated"];
}
