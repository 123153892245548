import { Button, Css, IconButton, Palette, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  AssetInfoFragment,
  DocumentDetailDocumentTypeFragment,
  ProjectScheduleDocumentDetailFragment,
  SavePlanTaskInput,
  TaskDetailPaneFragment,
  TaskDetailsPage_PlanTaskFragment,
  TaskStatus,
  useSavePlanTaskMutation,
} from "src/generated/graphql-types";
import { RequiredTaskDocuments } from "src/routes/projects/schedule-v2/detail-pane/RequiredTaskDocuments";
import { isDefined, sortBy, truncateString } from "src/utils";
import { FormValue } from "./TaskDetailPane";
import { TaskDocumentsModal } from "./TaskDocumentsModal";

type TaskDocumentsProps = {
  formState: ObjectState<SavePlanTaskInput> | FormValue;
  documents: ProjectScheduleDocumentDetailFragment[];
  documentTypes: DocumentDetailDocumentTypeFragment[];
  parentId: string;
  task: TaskDetailsPage_PlanTaskFragment | TaskDetailPaneFragment;
  onPlanTask?: boolean;
  taskStatus?: TaskStatus;
};

export function TaskDocuments({
  documentTypes,
  documents,
  formState,
  parentId,
  task,
  onPlanTask,
  taskStatus,
}: TaskDocumentsProps) {
  const { openModal } = useModal();
  const [savePlanTask] = useSavePlanTaskMutation();
  const taskDocumentIds = formState.documents.value || [];
  const sortedDocuments = sortBy(documents || [], ({ name }) => name);
  const rtdDocuments = task.requiredTaskDocuments.map(({ document }) => document?.id).filter(isDefined);
  const nonRtdDocuments = sortedDocuments.filter((document) => {
    return rtdDocuments.indexOf(document.id) < 0;
  });
  const taskDocuments = sortedDocuments.filter(({ id }) => taskDocumentIds.includes(id) && !rtdDocuments.includes(id));

  function DocumentsSection() {
    return (
      <div css={Css.smMd.mb1.$}>
        <span css={Css.df.aic.jcsb.baseSb.gray900.$}>
          Documents
          <Button
            label="Link Documents"
            variant="tertiary"
            onClick={() => {
              openModal({
                content: (
                  <TaskDocumentsModal
                    documentTypes={documentTypes}
                    formState={formState}
                    documents={nonRtdDocuments}
                    taskName={task.name}
                    parentId={parentId}
                    requiredTaskDocuments={rtdDocuments}
                    onPlanTask={onPlanTask}
                  />
                ),
              });
            }}
          />
        </span>
      </div>
    );
  }

  return (
    <>
      {task.requiredTaskDocuments.nonEmpty && (
        <RequiredTaskDocuments
          formState={formState}
          parentId={parentId}
          requiredTaskDocuments={task.requiredTaskDocuments}
          documentTypes={documentTypes}
          taskStatus={taskStatus}
          onPlanTask={onPlanTask}
        />
      )}
      {taskDocuments.nonEmpty ? (
        <div data-testid="taskDocuments" css={Css.df.fdc.$}>
          <DocumentsSection />
          {taskDocuments.map((document) => {
            const { id, name, asset } = document;
            const buttonLabel = createAssetLabel(name, asset);
            return (
              <div css={Css.df.fdc.br4.$} key={id}>
                <div css={Css.df.jcsb.aic.xs.pt1.$} key={id}>
                  <div css={Css.df.aic.gap1.$}>
                    <div data-testid={`${id}-document`}>
                      <AssetGallery assets={[asset]} key={id}>
                        {(openGallery) => (
                          <Button label={buttonLabel} variant="text" onClick={() => openGallery(asset)} />
                        )}
                      </AssetGallery>
                    </div>
                  </div>
                  <IconButton
                    data-testid="deleteTaskDocument"
                    icon="x"
                    color={Palette.Gray600}
                    disabled={taskStatus === TaskStatus.Complete}
                    onClick={async () => {
                      formState.documents.set(taskDocumentIds.filter((documentId) => documentId !== id));
                      // manually calling savePlanTask here as we don't want to auto save the form right now
                      if (onPlanTask) {
                        const formDocuments = formState.documents.value ?? [];
                        await savePlanTask({
                          variables: {
                            input: {
                              id: formState.id.value,
                              // Spread in RTD documents to ensure they are not removed
                              documents: [...formDocuments, ...rtdDocuments],
                            },
                          },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <DocumentsSection />
          <div data-testid="emptyState" css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
            There are no linked documents
          </div>
        </>
      )}
    </>
  );
}

export function createAssetLabel(name: string, asset: AssetInfoFragment, length = 40) {
  const { contentType } = asset;
  const contentTypeName = contentType.split("/")[1].toUpperCase();
  return `${truncateString(name, length)} (${contentTypeName})`;
}
