import {
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SimpleHeaderAndData,
  column,
  simpleDataRows,
  useModal,
} from "@homebound/beam";
import { useMemo } from "react";
import { dateCell, priceCell } from "src/components";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  InvoceScheduleTab_InvoiceScheduleFragment,
  InvoceScheduleTab_InvoiceScheduleItemFragment,
} from "src/generated/graphql-types";
import { InvoiceTermsForm } from "src/routes/billing/invoice-terms/components/InvoiceTermsForm";

type InvoiceScheduleItemsTableProps = {
  invoiceSchedule: InvoceScheduleTab_InvoiceScheduleFragment;
};

export function InvoiceScheduleTab({ invoiceSchedule }: InvoiceScheduleItemsTableProps) {
  const { openModal, closeModal } = useModal();
  const rows = useMemo(() => createRows(invoiceSchedule.items), [invoiceSchedule]);
  const columns = useMemo(() => createColumns(), []);

  return (
    <div>
      <div css={Css.df.ml1.mb4.$}>
        <div css={Css.base.mr1.$}> The contract billing schedule determines when the Propco is invoiced. </div>
        <div css={Css.sm.mya.$}>
          <Button
            variant="text"
            onClick={() =>
              openModal({
                content: (
                  <>
                    <ModalHeader>Invoice Terms</ModalHeader>
                    <ModalBody>
                      <InvoiceTermsForm invoiceScheduleTemplateId={invoiceSchedule?.invoiceScheduleTemplate.id} />
                    </ModalBody>
                    <ModalFooter>
                      <Button label="Close" variant="primary" onClick={closeModal} />
                    </ModalFooter>
                  </>
                ),
                drawHeaderBorder: true,
                size: "lg",
              })
            }
            label="View Terms"
          />
        </div>
      </div>

      <div css={Css.bgWhite.p2.br8.bshBasic.$}>
        <GridTable
          rows={rows}
          columns={columns}
          style={{ allWhite: true }}
          fallbackMessage="No Invoice Schedule Items found for the project"
        />
      </div>
    </div>
  );
}

type Row = SimpleHeaderAndData<InvoceScheduleTab_InvoiceScheduleItemFragment>;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({ header: "Order", data: (row) => row.order + 1, w: "60px" }),
    column<Row>({ header: "Name", data: (row) => row.parent.name, w: 2 }),
    column<Row>({
      header: "Status",
      data: (row) => {
        const status =
          row.parent.__typename === "PlanMilestone"
            ? row.parent.taskStatusRollup
            : row.parent.__typename === "ScheduleTask"
              ? row.parent.statusDetail
              : undefined;
        return (
          status && (
            <div css={Css.df.aic.$}>
              <StatusIndicator status={status.code} />
              <div css={Css.ml1.$}>{status.name}</div>
            </div>
          )
        );
      },
    }),
    column<Row>({ header: "Invoice Date", data: (row) => dateCell(row.invoiceDate) }),
    column<Row>({ header: "Original Amount", data: (row) => priceCell({ valueInCents: row.originalAmountInCents }) }),
    column<Row>({
      header: "Change Order Amount",
      data: (row) => priceCell({ valueInCents: row.changeOrderAmountInCents }),
    }),
    column<Row>({ header: "Revised Amount", data: (row) => priceCell({ valueInCents: row.revisedAmountInCents }) }),
    column<Row>({
      header: "Invoice Status",
      data: (row) =>
        row.invoiceScheduledLineItems.first ? (
          <div css={Css.df.aic.$}>
            <StatusIndicator status={row.invoiceScheduledLineItems.first.invoice.status.code} />
            <div css={Css.ml1.$}>{row.invoiceScheduledLineItems.first.invoice.status.name}</div>
          </div>
        ) : undefined,
    }),
    column<Row>({ header: "Invoice Identifier", data: (row) => row.invoiceScheduledLineItems.first?.invoice.title }),
  ];
}

function createRows(data: InvoceScheduleTab_InvoiceScheduleItemFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(data);
}
