import {
  Button,
  HasIdAndName,
  IconProps,
  NestedOption,
  SuperDrawerContent,
  SuperDrawerHeader,
  SuperDrawerWidth,
  useComputed,
  useSnackbar,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import pick from "lodash/pick";
import { Observer } from "mobx-react";
import { useCallback, useMemo, useState } from "react";
import {
  AssetTreeSelectValuesFragment,
  ChangeRequestDetailsFragment,
  ChangeRequestDrawer_ChangeRequestGroupFragment,
  ChangeRequestPriority,
  ChangeRequestSource,
  ChangeType,
  CreateChangeRequestMetadataQuery,
  Development,
  IncrementalCollectionOp,
  InputMaybe,
  ProjectTreeSelectValuesFragment,
  SaveChangeRequestInput,
  useChangeRequestDetailsQuery,
  useCreateChangeRequestMetadataQuery,
  useSaveChangeRequestGroupMutation,
  useSaveChangeRequestsMutation,
} from "src/generated/graphql-types";
import { cannotBeEmpty, foldEnum, isDefined, queriesResult } from "src/utils";
import { SaveAttachmentModel, attachmentConfig } from "../boundAttachments/BoundAttachments";
import { AddAdditionalChangeRequestPage } from "./components/AddAdditionalChangeRequestPage";
import { ChangeRequestFormDetails } from "./components/ChangeRequestFormDetails";
import { ReviewGroupedChangeRequestsPage } from "./components/ReviewGroupedChangeRequestsPage";

type CreateChangeRequestButtonProps = {
  projectId?: string;
  developmentId?: string;
  copyViaChangeRequestId?: string;
};

enum ChangeRequestDrawerViews {
  Create,
  BulkCreate,
  Review,
}

export function CreateChangeRequestButton(props: CreateChangeRequestButtonProps) {
  const { developmentId, projectId, copyViaChangeRequestId } = props;
  const { openInDrawer } = useSuperDrawer();

  const onClick = useCallback(
    () =>
      openInDrawer({
        content: (
          <CreateChangeRequestDrawer
            projectId={projectId}
            developmentId={developmentId}
            copyViaChangeRequestId={copyViaChangeRequestId}
          />
        ),
        width: SuperDrawerWidth.Normal,
      }),
    [openInDrawer, developmentId, projectId, copyViaChangeRequestId],
  );

  return <Button data-testid="openChangeLogModalButton" label="Create New" onClick={onClick} />;
}

// Thin wrapping component that allows us to defer loading the metadata until "Create New" is clicked.
export function CreateChangeRequestDrawer(props: CreateChangeRequestButtonProps) {
  const { developmentId, projectId, copyViaChangeRequestId } = props;
  const metaQuery = useCreateChangeRequestMetadataQuery();
  const copyViaQuery = useChangeRequestDetailsQuery({
    variables: { id: copyViaChangeRequestId ?? "" },
    skip: !copyViaChangeRequestId,
  });

  return queriesResult([metaQuery, copyViaQuery] as const, {
    data: (metaResult, copyViaResult) => {
      const {
        enumDetails,
        projectsPage: { entities: projects },
        developments,
        changeRequestAssets: { entities: changeRequestAssets },
        changeRequestGroups,
      } = metaResult;
      return (
        <ChangeRequestFormContent
          enums={enumDetails}
          projects={projects}
          developments={developments}
          assets={changeRequestAssets}
          projectId={projectId}
          developmentId={developmentId}
          copyViaChangeRequest={copyViaResult?.changeRequest}
          changeRequestGroups={changeRequestGroups}
        />
      );
    },
  });
}

type ChangeRequestFormContentProps = {
  enums: CreateChangeRequestMetadataQuery["enumDetails"];
  projects: ProjectTreeSelectValuesFragment[];
  developments: Pick<Development, "id" | "name">[];
  assets: AssetTreeSelectValuesFragment[];
  projectId?: string;
  developmentId?: string;
  copyViaChangeRequest?: ChangeRequestDetailsFragment;
  changeRequestGroups: ChangeRequestDrawer_ChangeRequestGroupFragment[];
};

export function ChangeRequestFormContent(props: ChangeRequestFormContentProps) {
  const { enums, projects, developments, assets, projectId, developmentId, copyViaChangeRequest, changeRequestGroups } =
    props;
  const [saveChangeRequests, { loading }] = useSaveChangeRequestsMutation();
  const [saveChangeRequestGroup] = useSaveChangeRequestGroupMutation();
  const { closeDrawer } = useSuperDrawer();
  const { triggerNotice } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(0);
  const [newGroupId, setNewGroupId] = useState<string | undefined>(undefined);

  const [view, setView] = useState<ChangeRequestDrawerViews>(
    // Default to create
    ChangeRequestDrawerViews.Create,
  );

  const scopeSelectValues = useMemo(() => getProjectTreeSelectValues(projects, developments), [projects, developments]);

  const sortedChangeTypes = useMemo(() => enums.changeType.sortByKey("sortOrder"), [enums.changeType]);

  const formState = useFormState({
    config: formConfig,
    init: mapToForm(copyViaChangeRequest, projectId, developmentId),
  });

  const handleSubmit = useCallback(async () => {
    const { additionalChangeRequests, ...others } = formState.value;

    await saveChangeRequests({
      variables: {
        input: {
          changeRequests: [
            mapToInput(others, newGroupId, formState.changedValue.newGroup),
            // merge in the additional change requests if we have them
            ...(additionalChangeRequests?.nonEmpty
              ? additionalChangeRequests.map((value) => {
                  return {
                    ...mapToInput(value, newGroupId),
                  };
                })
              : []),
          ],
        },
      },
      refetchQueries: ["ChangeLogTable", "ChangeLogMetadata"],
    });
    triggerNotice({ message: "Change Request Created" });
    closeDrawer();
  }, [formState.value, formState.changedValue.newGroup, saveChangeRequests, triggerNotice, closeDrawer, newGroupId]);

  const actionsDisabled = useComputed(() => {
    // Only check for a form being dirty if we're not copying a change request and we don't have any additional rows
    if (!copyViaChangeRequest && !formState.dirty) return true;

    return !formState.valid || loading;
  }, [formState.dirty, formState.valid, loading, copyViaChangeRequest, currentPage]);

  const totalChangeRequestCount = useComputed(
    () => formState.additionalChangeRequests.rows.length + (formState.value ? 1 : 0),
    [formState],
  );

  const handleBackSelection = useCallback(() => {
    // Decrement the current page count when going back
    setCurrentPage((prevPage) => prevPage - 1);
    // if we go back to the the first page, reset the view and pageIndex
    if (currentPage === 1) {
      setView(ChangeRequestDrawerViews.Create);
      setCurrentPage(0);
      return;
    }

    if (view === ChangeRequestDrawerViews.Review) {
      // we will always go back to the bulkCreate view from review
      setView(ChangeRequestDrawerViews.BulkCreate);
    }
  }, [currentPage, view]);

  const handleReview = useCallback(async () => {
    setView(ChangeRequestDrawerViews.Review);
    setCurrentPage(totalChangeRequestCount);
    // We need to save the new group(s) before saving all of the change requests to avoid unique constraint errors with the backend
    if (formState.changedValue.newGroup) {
      await saveChangeRequestGroup({
        variables: { input: { name: formState.value.newGroup } },
        onCompleted: ({ saveChangeRequestGroup }) => {
          setNewGroupId(saveChangeRequestGroup.changeRequestGroup.id);
        },
      });
    }
  }, [totalChangeRequestCount, formState, saveChangeRequestGroup]);

  // this sets the view to Bulk Create, adds the form fields and resets the form when adding another request
  const handleAdditionalRequest = useCallback(() => {
    setView(ChangeRequestDrawerViews.BulkCreate);
    formState.additionalChangeRequests.add({
      title: "",
      rationale: "",
      source: ChangeRequestSource.Na,
      priority: undefined,
      scopes: formState.scopes.value,
      changeTypes: [],
      assets: [],
      attachments: [],
      isUnderContract: false,
      productSelection: undefined,
      isStandardOffering: undefined,
      salesforceOpportunityUrl: undefined,
      groups: formState.groups.value,
      newGroup: formState.newGroup.value,
    });
    // set the page count to the total number of change requests
    setCurrentPage(totalChangeRequestCount);
  }, [formState, totalChangeRequestCount]);

  const changeRequestGroupNames = useComputed(
    () => getChangeRequestGroupNames(changeRequestGroups, formState),
    [changeRequestGroups, formState],
  );

  return (
    <>
      <SuperDrawerHeader
        title={
          view === ChangeRequestDrawerViews.Review
            ? "Review Grouped Requests"
            : `${copyViaChangeRequest ? "Duplicate" : "Create a New"} Change Request`
        }
        right={
          formState.valid &&
          // only show the back button if we're not on the first page
          view !== ChangeRequestDrawerViews.Create && (
            <Observer>{() => <Button label="Back" onClick={handleBackSelection} variant="text" />}</Observer>
          )
        }
      />
      <Observer>
        {() => (
          <SuperDrawerContent
            actions={[
              {
                label: "Cancel",
                onClick: closeDrawer,
                variant: "text",
              },
              {
                label: "Add New Request",
                onClick: handleAdditionalRequest,
                disabled: actionsDisabled,
                tooltip: !formState.valid ? formState.errors.join("\n") : undefined,
                variant: "secondary",
              },
              // if we only have 1 change request, then submit as usual
              !formState.additionalChangeRequests.rows.length || view === ChangeRequestDrawerViews.Review
                ? {
                    label: "Submit",
                    disabled: actionsDisabled,
                    onClick: handleSubmit,
                    tooltip: !formState.valid ? formState.errors.join("\n") : undefined,
                  }
                : {
                    label: "Review",
                    disabled: actionsDisabled,
                    onClick: handleReview,
                    tooltip: !formState.valid ? formState.errors.join("\n") : undefined,
                  },
            ]}
          >
            {foldEnum(view, {
              [ChangeRequestDrawerViews.Create]: () => (
                <ChangeRequestFormDetails
                  assets={assets}
                  changeRequestGroups={changeRequestGroups}
                  enums={enums}
                  formState={formState}
                  currentPage={currentPage}
                  scopeSelectValues={scopeSelectValues}
                  sortedChangeTypes={sortedChangeTypes}
                  changeRequestGroupNames={changeRequestGroupNames}
                />
              ),
              [ChangeRequestDrawerViews.BulkCreate]: () => (
                <AddAdditionalChangeRequestPage
                  assets={assets}
                  changeRequestGroups={changeRequestGroups}
                  enums={enums}
                  formState={formState}
                  scopeSelectValues={scopeSelectValues}
                  sortedChangeTypes={sortedChangeTypes}
                  currentPage={currentPage}
                />
              ),
              [ChangeRequestDrawerViews.Review]: () => (
                <ReviewGroupedChangeRequestsPage
                  changeRequestGroups={changeRequestGroups}
                  formState={formState}
                  titles={[
                    ...formState.additionalChangeRequests.rows.map((changeRequest) => changeRequest.title.value),
                    formState.title.value,
                  ]}
                />
              ),
            })}
          </SuperDrawerContent>
        )}
      </Observer>
    </>
  );
}

export type CreateChangeRequestForm = {
  title: InputMaybe<string>;
  rationale: InputMaybe<string>;
  source: InputMaybe<ChangeRequestSource>;
  priority: InputMaybe<ChangeRequestPriority>;
  scopes: InputMaybe<string[]>;
  changeTypes: InputMaybe<ChangeType[]>;
  assets: InputMaybe<string[]>;
  attachments: SaveAttachmentModel[];
  isStandardOffering: InputMaybe<boolean>;
  salesforceOpportunityUrl: InputMaybe<string>;
  groups: InputMaybe<string[]>;
  newGroup: InputMaybe<string>;
  additionalChangeRequests: CreateAdditionalChangeRequestForm[];
  isUnderContract?: InputMaybe<boolean>;
  productSelection: InputMaybe<string>;
};

// create a new type that doesn't have the additionalChangeRequests field
export type CreateAdditionalChangeRequestForm = Omit<CreateChangeRequestForm, "additionalChangeRequests">;

const additionalChangeRequest: ObjectConfig<CreateAdditionalChangeRequestForm> = {
  title: { type: "value", rules: [required] },
  rationale: { type: "value", rules: [required] },
  source: { type: "value", rules: [required] },
  priority: { type: "value", rules: [required] },
  scopes: {
    type: "value",
    rules: [required, cannotBeEmpty],
  },
  changeTypes: { type: "value", rules: [required] },
  assets: { type: "value", rules: [required, cannotBeEmpty] },
  attachments: { type: "list", config: attachmentConfig },
  isStandardOffering: { type: "value" },
  salesforceOpportunityUrl: { type: "value" },
  isUnderContract: { type: "value" },
  productSelection: { type: "value" },
  groups: { type: "value" },
  newGroup: { type: "value" },
};

export const formConfig: ObjectConfig<CreateChangeRequestForm> = {
  title: { type: "value", rules: [required] },
  rationale: { type: "value", rules: [required] },
  source: { type: "value", rules: [required] },
  priority: { type: "value", rules: [required] },
  scopes: {
    type: "value",
    rules: [required, cannotBeEmpty],
  },
  changeTypes: { type: "value", rules: [required, cannotBeEmpty] },
  assets: { type: "value", rules: [required, cannotBeEmpty] },
  attachments: { type: "list", config: attachmentConfig },
  isStandardOffering: { type: "value" },
  salesforceOpportunityUrl: { type: "value" },
  isUnderContract: { type: "value" },
  productSelection: { type: "value" },
  groups: { type: "value" },
  newGroup: { type: "value" },
  additionalChangeRequests: { type: "list", config: additionalChangeRequest },
};

function mapToForm(
  copyViaChangeRequest?: ChangeRequestDetailsFragment,
  projectId?: string,
  developmentId?: string,
): CreateChangeRequestForm {
  if (copyViaChangeRequest) {
    const {
      name,
      rationale,
      source,
      priority,
      changeTypes,
      changeRequestAssets,
      scopes,
      isStandardOffering,
      salesforceOpportunityUrl,
      isUnderContract,
      productSelection,
      groups,
    } = copyViaChangeRequest;
    return {
      title: `${name} (Copy)`,
      rationale: rationale,
      source: source.code,
      priority: priority.code,
      changeTypes: changeTypes,
      assets: changeRequestAssets.map((asset) => asset.id),
      scopes: scopes.map((s) => s.target.id),
      // File attachments are intentionally not copied over
      attachments: [],
      isStandardOffering,
      salesforceOpportunityUrl,
      isUnderContract,
      productSelection,
      groups: groups.map((group) => group.id),
      newGroup: undefined,
      additionalChangeRequests: [],
    };
  }

  return {
    ...getEmptyChangeRequestConfig(projectId, developmentId),
    additionalChangeRequests: [],
  };
}

export function getProjectTreeSelectValues(
  projects: ProjectTreeSelectValuesFragment[],
  developments: Pick<Development, "id" | "name">[],
): NestedOption<HasIdAndName>[] {
  // Legacy projects did not have a cohort/development, we're ignoring that use case for now as change requests are only for new projects.
  // If we need to support standalone projects, we can add a new grouping option to the tree.
  const projectsWithDevelopment = projects.filter((p) => !!p.cohort?.development?.id);
  const projectsByDevelopment = projectsWithDevelopment.groupBy((p) => p.cohort?.development?.id!);

  const developmentOptions = developments.map((development) => {
    return {
      id: development.id,
      name: development.name,
      children: projectsByDevelopment[development.id]?.map((project) => ({
        id: project.id,
        name: project.buildAddress.street1,
      })),
    };
  });

  return developmentOptions.sortBy((d) => d.name);
}

export function getAssetTreeSelectOptions(assets: AssetTreeSelectValuesFragment[]) {
  // group assets by their business function
  const assetsByBusinessFunction = assets.groupBy((a) => a.functionType.name);
  return Object.keys(assetsByBusinessFunction)
    .map((busFunction) => ({
      id: busFunction,
      name: busFunction,
      children: assetsByBusinessFunction[busFunction].map((a) => ({
        id: a.id,
        name: a.name,
      })),
    }))
    .sortByKey("name");
}

export const ChangeTypeToIcon: Record<ChangeType, IconProps["icon"]> = {
  [ChangeType.ProgramChange]: "programChange",
  [ChangeType.ArchitechturalDesign]: "architectural",
  [ChangeType.StructuralDesign]: "structural",
  [ChangeType.MepDesign]: "mep",
  [ChangeType.AddRemoveModifyProducts]: "add",
  [ChangeType.NewDesignPackage]: "designPackage",
  [ChangeType.UpdateExistingDesignPackage]: "updateDesignPackage",
  [ChangeType.ModifyOptionsProgram]: "cart",
  [ChangeType.NewExteriorStyle]: "exteriorStyle",
  [ChangeType.ImplementationError]: "error",
  [ChangeType.ProductRefresh]: "refresh",
  [ChangeType.Na]: "remove",
};

function mapToInput(
  formValue: Omit<CreateChangeRequestForm, "additionalChangeRequests">,
  groupId: string | undefined,
  newGroupName?: InputMaybe<string>,
): SaveChangeRequestInput {
  // removing newGroup as we don't want to send it to the backend
  const { assets, attachments, scopes, groups, newGroup, ...others } = formValue;

  const maybeNewGroup = isDefined(groupId)
    ? [{ id: groupId, op: IncrementalCollectionOp.Include }]
    : isDefined(newGroupName)
      ? [{ name: newGroupName, op: IncrementalCollectionOp.Include }]
      : [];

  const addedGroups =
    groups?.map((id) => ({
      id,
      op: IncrementalCollectionOp.Include,
    })) ?? [];

  return {
    ...others,
    changeRequestAssets: assets?.map((asset) => ({ id: asset, op: IncrementalCollectionOp.Include })) ?? [],
    attachments: attachments?.map(({ asset }) => ({
      asset: pick(asset, [
        // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
        "contentType",
        "fileName",
        "id",
        "s3Key",
        "sizeInBytes",
        "delete",
      ]),
    })),
    scopes: scopes?.map((scope) => ({
      targetId: scope,
      op: IncrementalCollectionOp.Include,
    })),
    groups: [...maybeNewGroup, ...addedGroups],
  };
}

function getEmptyChangeRequestConfig(
  projectId: string | undefined,
  developmentId: string | undefined,
  formState?: CreateChangeRequestForm,
) {
  return {
    title: "",
    rationale: "",
    source: ChangeRequestSource.Na,
    priority: undefined,
    scopes: projectId ? [projectId] : developmentId ? [developmentId] : [],
    changeTypes: [],
    assets: [],
    attachments: [],
    isUnderContract: false,
    productSelection: undefined,
    isStandardOffering: undefined,
    salesforceOpportunityUrl: undefined,
    groups: formState ? formState.groups : [],
    newGroup: formState ? formState.newGroup : "",
  };
}

export function getChangeRequestGroupNames(
  changeRequestGroups: ChangeRequestDrawer_ChangeRequestGroupFragment[],
  formState: ObjectState<CreateChangeRequestForm> | ObjectState<CreateAdditionalChangeRequestForm>,
) {
  const groups = changeRequestGroups.filter((group) => formState.value.groups?.includes(group.id)).map((g) => g.name);
  const newGroup = formState.value.newGroup;
  // remove any undefined values
  return [newGroup, ...groups].filter(Boolean);
}

export function getRequiredChangeSourceAssets(
  assets: AssetTreeSelectValuesFragment[],
  changeRequestSource: InputMaybe<ChangeRequestSource>,
) {
  if (!changeRequestSource) return [];
  // look through the required assets to match to the current change source
  const requiredAssets = assets
    .filter(
      (asset) =>
        asset.requiredForChangeRequestSource.nonEmpty &&
        asset.requiredForChangeRequestSource.includes(changeRequestSource),
    )
    .map((asset) => asset.id);

  return requiredAssets;
}
