import {
  ButtonMenu,
  CollapseToggle,
  Css,
  GridDataRow,
  GridSortConfig,
  GridTable,
  Icon,
  IconButton,
  MenuItem,
  Palette,
  ScrollableContent,
  Tooltip,
  collapseColumn,
  column,
  emptyCell,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { createTaskDetailsPageUrl } from "src/RouteUrls";
import { formatDate } from "src/components";
import { CommentCountBubble } from "src/components/comments/CommentCountBubble";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  DynamicSchedulesEnums_EnumDetailsFragment,
  DynamicSchedulesList_PlanTaskFragment,
  FeatureFlagType,
  Order,
  PlanTasksOrder,
  SavePlanTaskInput,
  TaskStatus,
  TradePartnerTaskStatus,
  TradePartnerTaskStatusDetail,
  useDynamicSchedulesListQuery,
  useSavePlanTaskListMutation,
} from "src/generated/graphql-types";
import { assertNever, pluralize, queryResult } from "src/utils";
import { parseOrder, toOrder } from "src/utils/ordering";
import { TaskStatusSelect } from "../../schedule-v2/components/TaskStatusSelect";
import { FilterSubHeader, TaskGroupBy, useDynamicSchedulesFilter } from "../components/DynamicSchedulesFilterModal";
import { PlanScheduleConfidence } from "../components/PlanScheduleConfidence";
import { PlanTaskProgress } from "../components/PlanTaskProgress";
import { ScheduleDateVariance } from "../components/ScheduleVariance";
import {
  SendBulkTradePartnerAvailabilityRequestEmailModal,
  SendTradePartnerAvailabilityRequestEmailModal,
} from "../components/SendTradePartnerAvailabilityRequestEmailModal";
import { ScheduleSnapshotsHookResult, useScheduleSnapshots } from "../components/useScheduleSnapshots";
import { TaskDetailCardType } from "../task-details/TaskDetailCard";
import { ScheduleContainer } from "../utils";

export function DynamicSchedulesList() {
  const { projectId } = useParams<{ projectId: string }>();
  const [orderBy, setOrderBy] = useState<PlanTasksOrder>({ startDate: Order.Asc });

  const { allFilters, queryFilter } = useDynamicSchedulesFilter();

  const query = useDynamicSchedulesListQuery({
    variables: {
      filter: {
        scheduleParent: [projectId],
        ...queryFilter,
      },
      order: orderBy,
      page: {
        offset: 0,
        limit: 250,
      },
    },
  });

  const maybeFetchMore = useCallback(async () => {
    await query.fetchMore({
      variables: {
        filter: {
          scheduleParent: [projectId],
          ...queryFilter,
        },
        order: orderBy,
        page: {
          offset: query.data?.planTasks.entities.length,
          limit: 100,
        },
      },
    });
  }, [query, projectId, queryFilter, orderBy]);

  return (
    <>
      <ScheduleContainer>
        <FilterSubHeader scheduleParentId={projectId} rightMenuEl={<RightSubHeader scheduleParentId={projectId} />} />
      </ScheduleContainer>
      <div data-testid="listViewContent">
        {queryResult(query, ({ planTasks, enumDetails }) => {
          return (
            <DynamicScheduleTable
              planTasks={planTasks.entities}
              maybeFetchMore={maybeFetchMore}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              parentId={projectId}
              enumDetails={enumDetails}
              groupBy={allFilters.groupBy}
            />
          );
        })}
      </div>
    </>
  );
}

type DynamicScheduleTableProps = {
  planTasks: DynamicSchedulesList_PlanTaskFragment[];
  maybeFetchMore: () => void;
  orderBy: PlanTasksOrder;
  setOrderBy: (order: PlanTasksOrder) => void;
  parentId: string;
  enumDetails: DynamicSchedulesEnums_EnumDetailsFragment;
  groupBy: TaskGroupBy;
};

function DynamicScheduleTable(props: DynamicScheduleTableProps) {
  const { planTasks, maybeFetchMore, orderBy, setOrderBy, parentId, enumDetails, groupBy = TaskGroupBy.None } = props;
  const [savePlanTask] = useSavePlanTaskListMutation();
  const isTradeCommsEnabled = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);

  const { getFormState } = useFormStates<FormInput, DynamicSchedulesList_PlanTaskFragment>({
    config: formConfig,
    map: (task) => ({ id: task.id, tradePartnerStatus: task.tradePartnerStatus.code, status: task.status.code }),
    getId: (t) => t.id,
    autoSave: async ({ value }) => {
      await savePlanTask({ variables: { input: value } });
    },
  });

  const { getTaskSnapshot } = useScheduleSnapshots(parentId);

  const history = useHistory();
  const onTaskDetailLink = useCallback(
    (taskId: string, scrollIntoView?: TaskDetailCardType) => {
      history.push(createTaskDetailsPageUrl(parentId, taskId, { scrollIntoView }));
    },
    [history, parentId],
  );

  const rows = useMemo(() => createRows(planTasks, groupBy), [planTasks, groupBy]);
  const columns = useMemo(
    () => createColumns(getFormState, enumDetails, getTaskSnapshot, groupBy, onTaskDetailLink, isTradeCommsEnabled),
    [getFormState, enumDetails, getTaskSnapshot, groupBy, onTaskDetailLink, isTradeCommsEnabled],
  );

  const initSortState: GridSortConfig = useMemo(
    () => ({
      on: "server",
      onSort: (key, direction) => setOrderBy(toOrder(key, direction)),
      value: parseOrder(orderBy),
    }),
    [orderBy, setOrderBy],
  );

  return (
    <ScrollableContent virtualized>
      <ScheduleContainer>
        <GridTable
          as="virtual"
          columns={columns}
          rows={rows}
          rowStyles={{
            task: {
              // onClick instead of rowLink to prevent clicks within inputs from bubbling to the link
              onClick: (row) => onTaskDetailLink(row.data.id),
              cellCss: (row) => (row.data.isStale ? Css.bgYellow50.$ : Css.$),
            },
          }}
          infiniteScroll={{ onEndReached: maybeFetchMore }}
          sorting={initSortState}
          stickyHeader
        />
      </ScheduleContainer>
    </ScrollableContent>
  );
}

type FormInput = Pick<SavePlanTaskInput, "id" | "tradePartnerStatus" | "status">;
type GetFormState = (row: DynamicSchedulesList_PlanTaskFragment) => ObjectState<FormInput>;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  tradePartnerStatus: { type: "value" },
  status: { type: "value" },
};

type HeaderRow = { kind: "header" };
type StageRow = { kind: "stage"; data: { name: string } };
type TaskRow = { kind: "task"; data: DynamicSchedulesList_PlanTaskFragment };
type Row = HeaderRow | StageRow | TaskRow;

function createColumns(
  getFormState: GetFormState,
  enumDetails: DynamicSchedulesEnums_EnumDetailsFragment,
  getTaskSnapshot: ScheduleSnapshotsHookResult["getTaskSnapshot"],
  groupBy: TaskGroupBy,
  onTaskDetailLink: (taskId: string, scrollIntoView: TaskDetailCardType) => void,
  isTradeCommsEnabled: boolean,
) {
  return [
    ...(groupBy === TaskGroupBy.Stage
      ? [
          collapseColumn<Row>({
            header: emptyCell,
            stage: (_, { row }) => <CollapseToggle row={row} />,
            task: emptyCell,
            w: "40px",
          }),
        ]
      : []),
    column<Row>({
      header: () => <span css={Css.lgSb.$}>Tasks</span>,
      stage: ({ name }) => <span css={Css.smSb.$}>{name}</span>,
      task: ({ name }) => <span css={Css.xs.maxwPx(300).$}>{name}</span>,
      mw: "100px",
      w: 3,
    }),
    column<Row>({
      header: "Start",
      stage: emptyCell,
      task: ({ startDate, id }) => (
        <>
          <Tooltip title={formatDate(startDate, "long")}>
            <span>{formatDate(startDate, "weekDayMonthShort")}</span>
          </Tooltip>
          <ScheduleDateVariance baseline={getTaskSnapshot(id)?.startDate} current={startDate} />
        </>
      ),
      serverSideSortKey: "startDate",
      w: "140px",
    }),
    column<Row>({
      header: "End",
      stage: emptyCell,
      task: ({ endDate }) => (
        <Tooltip title={formatDate(endDate, "long")}>
          <span>{formatDate(endDate, "weekDayMonthShort")}</span>
        </Tooltip>
      ),
      serverSideSortKey: "endDate",
      w: "120px",
    }),
    column<Row>({
      header: "Progress",
      stage: emptyCell,
      task: (task) => <PlanTaskProgress task={task} />,
      w: "120px",
    }),
    column<Row>({
      header: () => <PlanTaskConfidenceHeader />,
      stage: emptyCell,
      task: ({ simulationProbability, status }) => (
        <PlanScheduleConfidence
          probabilityBasisPoints={simulationProbability?.endDateBasisPoints}
          isComplete={status.code === TaskStatus.Complete}
          entityType="task"
        />
      ),
      w: "90px",
    }),
    column<Row>({
      header: "Duration",
      stage: emptyCell,
      task: ({ durationInDays }) => {
        return (
          <span>
            {durationInDays} {pluralize(durationInDays, "day", "days")}
          </span>
        );
      },
      w: "90px",
    }),
    column<Row>({
      header: "Trade Scheduled",
      stage: emptyCell,
      task: (task) => <TradeCell task={task} getFormState={getFormState} enumDetails={enumDetails} />,
      mw: "120px",
      w: 2,
    }),
    column<Row>({
      header: "Status",
      stage: emptyCell,
      task: (task) => (
        // Todo figure out the hight issue
        <div css={Css.maxhPx(32).$}>
          <TaskStatusSelect
            hideLabel
            options={enumDetails.taskStatus}
            canComplete={task.canComplete}
            canStart={task.canStart}
            statusField={getFormState(task).status}
            disabled={false}
          />
        </div>
      ),
      w: "150px",
    }),
    column<Row>({
      header: emptyCell,
      stage: emptyCell,
      task: (task) => (
        <IconCell task={task} isTradeCommsEnabled={isTradeCommsEnabled} onTaskDetailLink={onTaskDetailLink} />
      ),
      w: isTradeCommsEnabled ? "80px" : "48px",
    }),
  ];
}

function IconCell({
  task,
  isTradeCommsEnabled,
  onTaskDetailLink,
}: {
  task: DynamicSchedulesList_PlanTaskFragment;
  isTradeCommsEnabled: boolean;
  onTaskDetailLink: (taskId: string, scrollIntoView: TaskDetailCardType) => void;
}) {
  const { streams, id } = task;
  const { openModal } = useModal();

  return (
    <div css={Css.df.jcsb.aic.gap1.$}>
      <CommentCountBubble
        streams={streams}
        onClick={() => onTaskDetailLink(id, TaskDetailCardType.Comments)}
        size={2.3}
      />
      {isTradeCommsEnabled && (
        <IconButton
          icon="email"
          onClick={() =>
            openModal({
              content: <SendTradePartnerAvailabilityRequestEmailModal planTasks={[task]} />,
              size: "xxl",
            })
          }
          disabled={!task.tradePartner || task.status.code === TaskStatus.Complete}
          inc={2}
        />
      )}
    </div>
  );
}

function TradeCell({
  task,
  getFormState,
  enumDetails,
}: {
  task: DynamicSchedulesList_PlanTaskFragment;
  getFormState: GetFormState;
  enumDetails: DynamicSchedulesEnums_EnumDetailsFragment;
}) {
  const uniqueCommittedTrades = task.committedTradePartners.uniqueBy((tp) => tp.id);
  if (!task.tradePartner) return null;

  return (
    <>
      <TradeStatusCell task={task} enumDetails={enumDetails} getFormState={getFormState} />
      <div css={Css.df.gap1.aic.jcc.$}>
        {uniqueCommittedTrades.length > 1 && (
          <Icon
            icon="error"
            color={Palette.Red600}
            tooltip="Only 1 trade partner per task is recommended. The trade partner schedule status only applies to the first trade partner."
            inc={2.3}
          />
        )}
        <span css={Css.xs.lineClamp2.pr1.$}>{task.tradePartner?.name}</span>
      </div>
    </>
  );
}

function TradeStatusCell({
  task,
  getFormState,
  enumDetails,
}: {
  task: DynamicSchedulesList_PlanTaskFragment;
  getFormState: GetFormState;
  enumDetails: DynamicSchedulesEnums_EnumDetailsFragment;
}) {
  const os = getFormState(task);

  // Don't show the trade status option if there is no trade
  if (!task.tradePartner) return null;
  const { tradePartnerStatus } = task;

  const { color, icon } = getIconConfig(tradePartnerStatus);

  return (
    <>
      <div css={Css.df.jcc.aic.mwPx(20).cursor("default").$}>
        <Icon inc={2.3} tooltip={tradePartnerStatus.name} icon={icon} color={color} />
      </div>
      <ButtonMenu
        trigger={{ icon: "triangleDown" }}
        items={enumDetails.tradePartnerTaskStatus.sortByKey("name").map((status) => ({
          label: status.name,
          onClick: () => os.tradePartnerStatus.set(status.code),
        }))}
        placement="right"
      />
    </>
  );
}

function createRows(planTasks: DynamicSchedulesList_PlanTaskFragment[], groupBy: TaskGroupBy): GridDataRow<Row>[] {
  switch (groupBy) {
    case TaskGroupBy.Stage:
      return createStageRows(planTasks);
    case TaskGroupBy.None:
      return createFlatRows(planTasks);
    default:
      assertNever(groupBy);
  }
}

function createFlatRows(planTasks: DynamicSchedulesList_PlanTaskFragment[]): GridDataRow<Row>[] {
  return [simpleHeader, ...planTasks.map((task) => ({ kind: "task" as const, id: task.id, data: task }))];
}

function createStageRows(planTasks: DynamicSchedulesList_PlanTaskFragment[]): GridDataRow<Row>[] {
  const stageGroups = planTasks.groupBy((task) => task.stage?.id ?? "no-stage");
  return [
    simpleHeader,
    ...Object.entries(stageGroups)
      .sortBy(([_, tasks]) => {
        const earliestDate = tasks.min((t) => t.startDate);
        return earliestDate?.getTime() ?? 0;
      })
      .flatMap(([stageId, tasks]) => [
        {
          kind: "stage" as const,
          id: stageId,
          data: {
            name: tasks.first?.stage?.name ?? "No Stage",
          },
          children: tasks.map((task) => ({ kind: "task" as const, id: task.id, data: task })),
        },
      ]),
  ];
}

export function PlanTaskConfidenceHeader() {
  return (
    <Tooltip title="The certainty of the task date ending on the estimated end date. High >= 80%, Medium >= 50%, Low < 50%.">
      Confidence
    </Tooltip>
  );
}

type IconConfig = {
  color: Palette;
  icon: "checkCircle" | "infoCircle";
};

function getIconConfig(status: TradePartnerTaskStatusDetail): IconConfig {
  switch (status.code) {
    case TradePartnerTaskStatus.CompletedJob:
      return { color: Palette.Green500, icon: "checkCircle" };
    case TradePartnerTaskStatus.Confirmed:
      return { color: Palette.Gray600, icon: "checkCircle" };
    default:
      return { color: Palette.Orange500, icon: "infoCircle" };
  }
}

function RightSubHeader({ scheduleParentId }: { scheduleParentId: string }) {
  const showTradeComms = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);
  const { openModal } = useModal();

  const actionsMenuItems: MenuItem[] = useMemo(
    () => [
      {
        label: "Bulk Send Scheduling Emails",
        onClick: () =>
          openModal({
            content: <SendBulkTradePartnerAvailabilityRequestEmailModal scheduleParentId={scheduleParentId} />,
            size: "xxl",
          }),
      },
    ],
    [openModal, scheduleParentId],
  );

  if (!showTradeComms) return null;

  return <ButtonMenu items={actionsMenuItems} trigger={{ label: "Actions" }} />;
}
