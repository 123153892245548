import { Button, Css, Filters, ScrollableContent, usePersistedFilter } from "@homebound/beam";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { createWorkAuthorizationUrl } from "src/RouteUrls";
import { SingleStageFilter, useProjectStageSingleFilter } from "src/hooks/useProjectStageSingleFilter";
import { useStripStage } from "src/hooks/useStripStage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams } from "src/routes/routesDef";
import { HomeownerContractsTable } from "../homeowner-contracts/components/HomeownerContractsTable";
import { WorkAuthorizationsTable } from "../work-authorizations/components/WorkAuthorizationsTable";

export function ProjectContractsAuthorizationsPage() {
  useStripStage();
  const { projectId } = useParams<ProjectParams>();
  const { clientContract, latestActiveStage } = useProjectContext();
  const { filterDefs } = useProjectStageSingleFilter(latestActiveStage);
  const { setFilter, filter } = usePersistedFilter<SingleStageFilter>({
    storageKey: "contractsWorkAuthorizationsFilter",
    filterDefs,
  });

  return (
    <Fragment>
      <PageHeader title={`${clientContract}s & Authorizations`} />
      <TableActions>
        <Filters<SingleStageFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
      </TableActions>
      <ScrollableContent>
        <h1 css={Css.lgSb.blue700.$}>Contracts</h1>
        <HomeownerContractsTable projectId={projectId} filter={filter} />
        <div css={Css.df.jcsb.mt6.mb1.$}>
          <h1 css={Css.lgSb.blue700.$}>Work Authorizations</h1>
          <Button label="Add Work Auth" onClick={createWorkAuthorizationUrl(projectId, "add")} />
        </div>
        <WorkAuthorizationsTable projectId={projectId} filter={filter} />
      </ScrollableContent>
    </Fragment>
  );
}
