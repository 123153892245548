import {
  BoundTextAreaField,
  BoundTextField,
  Css,
  GridTable,
  RowStyles,
  useModal,
  useRightPane,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { computed } from "mobx";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { createCommitmentsUrl } from "src/RouteUrls";
import { UppyUploader } from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  ApprovalSubject_BillFragment,
  BillDetailPaneDocument,
  BillDetailPane_AdditionalCommitments_CommitmentsFragment,
  BillDetailPane_CommitmentChangeOrderFragment,
  BillDetailPane_CommitmentFragment,
  BillDetailPane_CommitmentLineItemsFragment,
  BillStatus,
  BillType,
  CommitmentChangeOrderPageDocument,
  CommitmentStatus,
  CommitmentWithProjectItemsDocument,
  DocumentType,
  SaveDocumentInput,
  useBillDetailPane_AdditionalCommitmentsQuery,
  useBillDetailPane_NewBillCommitmentLikeDataQuery,
  useBillDetailPane_SaveApprovalMutation,
  useBillPaneDetail_SaveBillDocumentMutation,
  useDeleteBillMutation,
  useSaveBillMutation,
} from "src/generated/graphql-types";
import { useModeParam } from "src/hooks";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { DeleteBillConfirmationModal } from "src/routes/projects/bills/components/DeleteBillConfirmationModal";
import { ProjectParams } from "src/routes/routesDef";
import { isDefined, queriesResult } from "src/utils";
import { DetailPaneActions, DetailPaneButton } from "../DetailPaneActions";
import { BillDetailDocumentsList } from "./BillDetailDocumentList";
import { BillPaneViews, useBillPaneContext } from "./BillDetailPane";
import { BillPaneHeader, LoadError } from "./BillPaneHeader";
import {
  ComputedTotals,
  FormInput,
  Row,
  calcLineItemValues,
  createLineItemColumns,
  createLineItemRows,
  emptyInput,
  getCtalabel,
  mapToForm,
  mapToInput,
} from "./utils";

type CommitmentPageLocationState = {
  projectItemIds: string[];
};

export function BillDetailForm() {
  const { idOrAdd: commitmentLikeId } = useParams<{ idOrAdd: string }>();
  const { bill, changeOrder, commitment } = useBillPaneContext();
  const isCo = (commitmentLikeId ?? changeOrder?.id ?? commitment?.id)?.startsWith("cco:");

  const allSignedCommitmentsQuery = useBillDetailPane_AdditionalCommitmentsQuery({
    variables: {
      // Query all billable commitments if the status is changeRequested
      // This allows billing against new POs/Cos to address the changeRequested by a trade
      filter: { tradePartners: [bill?.tradePartner.id ?? ""], status: [CommitmentStatus.Signed] },
      skipAllCommitmentsQuery:
        !bill?.tradePartner.id ||
        bill?.type.code === BillType.Standard ||
        bill.status.code !== BillStatus.ChangesRequested,
    },
  });

  // C2P-TODO: fastfollow up remove this query completely since we query the direct parent in `BillDetailPane`
  const directParentQuery = useBillDetailPane_NewBillCommitmentLikeDataQuery({
    // For all other bills, skip querying all active commitments & just query the direct parent
    variables: {
      commitmentId: isCo ? "" : commitmentLikeId,
      changeOrderId: isCo ? commitmentLikeId : "",
      // Use a skip directive inline to only query a commitment/cco depending on which page we've requested
      skipCommitment: isCo,
      skipChangeOrder: !isCo,
    },
    skip: !commitmentLikeId,
  });

  return queriesResult([allSignedCommitmentsQuery, directParentQuery] as const, {
    data: (commitmentsData, directParentData) => (
      <BillDetailFormView
        itemsFromAllActiveCommitments={
          commitmentsData?.commitments
            ?.concat(
              commitmentsData?.commitments.flatMap(
                (c) => c.changeOrders as BillDetailPane_AdditionalCommitments_CommitmentsFragment[],
              ),
            )
            .flatMap((c) => c.lineItems)
            // only return billable items
            .filter((li) => li.unbilledInCents > 0) || []
        }
        changeOrder={directParentData?.commitmentChangeOrder}
      />
    ),
    error: () => <BillDetailFormView itemsFromAllActiveCommitments={[]} />, // Let our BillDetailForm handle logic when no commitments data is returned
  });
}

export function BillDetailFormView({
  itemsFromAllActiveCommitments = [],
  changeOrder,
}: {
  itemsFromAllActiveCommitments: BillDetailPane_CommitmentLineItemsFragment[];
  changeOrder?: BillDetailPane_CommitmentChangeOrderFragment;
}) {
  const { projectId: paramProjectId, commitmentId } = useParams<ProjectParams & { commitmentId: string }>();
  const { bill, view, commitment, setView, setBillId } = useBillPaneContext();
  const { openModal } = useModal();
  const { closeRightPane } = useRightPane();

  const isNew = !bill;
  const isCo = isDefined(changeOrder);
  const isManual = bill?.type.code === BillType.Standard;
  const projectId =
    changeOrder?.commitment.projectStage.project.id ?? commitment?.projectStage.project.id ?? paramProjectId;
  const isChangesRequestedAutoBill =
    bill?.type.code !== BillType.Standard && bill?.status.code === BillStatus.ChangesRequested;
  const isManualDraft = isManual && bill.status.code === BillStatus.Draft;
  const isChangesRequested = bill?.status.code === BillStatus.ChangesRequested;
  const commitmentLike = useMemo(() => changeOrder ?? commitment, [changeOrder, commitment]);

  // Data needed for cco page refetch, once bill mutations complete
  const location = useLocation<CommitmentPageLocationState>();
  const projectItemIds = useMemo(() => location.state?.projectItemIds || [], [location.state?.projectItemIds]);
  const onCompleteRedirectUrl = projectId && createCommitmentsUrl(projectId);
  const { isNew: isNewCommitmentLike } = useModeParam({
    listPageUrl: onCompleteRedirectUrl,
  });

  const refetchQueries = useMemo(
    () => [
      ...(isDefined(commitment)
        ? [{ query: CommitmentWithProjectItemsDocument, variables: { commitmentId: commitment.id } }]
        : []),
      ...(isCo
        ? [
            {
              query: CommitmentChangeOrderPageDocument,
              variables: {
                isNew: isNewCommitmentLike,
                commitmentId,
                coId: commitmentLike?.id,
                projectItemFilter: { id: projectItemIds },
              },
            },
          ]
        : []),
      ...[{ query: BillDetailPaneDocument, variables: { billId: bill?.id }, skip: !bill?.id }],
    ],

    [bill, commitment, commitmentId, commitmentLike?.id, isCo, isNewCommitmentLike, projectItemIds],
  );

  const [save] = useSaveBillMutation();
  const [saveBillDocument] = useBillPaneDetail_SaveBillDocumentMutation();
  const [deleteBill] = useDeleteBillMutation();
  const [saveBillApproval] = useBillDetailPane_SaveApprovalMutation();
  const { triggerNotice } = useSnackbar();
  const tid = useTestIds({});

  const formState = useFormState({
    config: formConfig,
    init: {
      input: bill,
      map: (bill) =>
        mapToForm(bill, isChangesRequestedAutoBill ? itemsFromAllActiveCommitments : commitmentLike?.lineItems || []),
      ifUndefined: emptyInput(
        isChangesRequestedAutoBill ? itemsFromAllActiveCommitments : commitmentLike?.lineItems || [],
      ),
    },
  });

  const totalsRowData: ComputedTotals = useMemo(
    () =>
      computed(() =>
        formState.lineItems.rows.reduce(
          (acc, li) => {
            const { otherBilledInCents, thisBilledInCents, unBilledInCents } = calcLineItemValues(
              li,
              !!formState.isTradePartnerCredit.value,
            );
            return {
              otherBilledInCents: acc.otherBilledInCents + otherBilledInCents,
              thisBilledInCents: acc.thisBilledInCents + thisBilledInCents,
              unBilledInCents: acc.unBilledInCents + unBilledInCents,
              costChangeInCents: acc.costChangeInCents + (li.costChangeInCents.value || 0),
            };
          },
          { thisBilledInCents: 0, unBilledInCents: 0, otherBilledInCents: 0, costChangeInCents: 0 },
        ),
      ),
    [formState],
  );

  if (!commitmentLike?.tradePartner || !commitmentLike) return <LoadError />;
  const { tradePartner } = commitmentLike;
  const projectStage = isCo
    ? (commitmentLike as BillDetailPane_CommitmentChangeOrderFragment).commitment.projectStage
    : (commitmentLike as BillDetailPane_CommitmentFragment).projectStage;

  async function saveDocumentToForm(file: UppyFile) {
    const { meta, name, type, size } = file;
    const { s3Key } = meta;
    const billDocumentInput: SaveDocumentInput = {
      documentType: DocumentType.Bill,
      name: name,
      sizeInBytes: size,
      parentId: projectId,
      asset: {
        contentType: type,
        fileName: name,
        s3Key: s3Key as string,
        sizeInBytes: size,
      },
    };
    const { data } = await saveBillDocument({ variables: { input: billDocumentInput } });
    const newDocument = data?.saveDocuments.documents.first;
    if (!newDocument) return triggerNotice({ message: `failed to save ${file.name}` });
    formState.documents.add(newDocument);
  }

  return (
    <div css={Css.bgWhite.oa.px4.py3.f1.$}>
      <BillPaneHeader />

      {!!formState.documents.value.first?.asset && (
        <div css={Css.mb6.$}>
          <AssetGallery assets={[formState.documents.value.first!.asset]}>
            {(openGallery) => (
              <AssetPreview
                asset={formState.documents.value.first!.asset}
                onClick={() => openGallery(formState.documents.value.first!.asset)}
              />
            )}
          </AssetGallery>
        </div>
      )}

      <div {...tid.details} css={Css.dg.gtc("1fr 1fr").gap(3).mt2.$}>
        <div css={Css.gc("1/3").$}>
          <BoundTextField
            field={formState.tradePartnerNumber}
            label={formState.isTradePartnerCredit.value ? "Credit #" : "Bill #"}
          />
        </div>
        <BoundBeamDateField
          field={formState.billDate}
          format="short"
          label={formState.isTradePartnerCredit.value ? "Credit Date" : "Bill Date"}
        />
        <BoundBeamDateField data-testid="dueDate" field={formState.dueDate} format="short" label="Due Date" />
        <div css={Css.gc("1/3").$}>
          <BoundTextAreaField
            field={formState.internalNote}
            label={`Internal Description${!formState.readOnly ? " (optional)" : ""}`}
          />
        </div>
      </div>

      <div data-testid="bill_fileUploader" css={Css.my2.df.fdc.gap1.$}>
        <label>Trade Bill</label>
        {formState.documents.value.isEmpty ? (
          <div css={Css.$}>
            <UppyUploader
              onFinish={saveDocumentToForm}
              dragDropText="Drag & Drop or Click to Upload Attachment"
              maxNumberOfFiles={10}
              dragDropWidth={"100%"}
            />
          </div>
        ) : (
          <BillDetailDocumentsList documents={formState.documents} />
        )}
      </div>

      <div css={Css.mt6.mb1.$}>Project Items</div>
      <GridTable
        rowStyles={panelInlineTable}
        columns={createLineItemColumns(formState)}
        rows={createLineItemRows(formState, totalsRowData, isChangesRequestedAutoBill)}
        style={{ bordered: false }}
      />

      <DetailPaneActions
        buttons={[
          {
            label: getCtalabel(view, bill?.status.code),
            variant: "primary",
            actionFn: async () => {
              // C2P TODO: seperate and cleanup async ops
              if (isNew) {
                const { data } = await save({
                  variables: { input: mapToInput(formState.value, tradePartner.id, projectStage.id, isNew) },
                  refetchQueries,
                });
                if (data?.saveBill.bill) {
                  triggerNotice({
                    message: `bill #${formState.tradePartnerNumber.value} successfully created`,
                  });
                  // After creation, rehydrate the bill pane context with the new bill data & pane view
                  setBillId(data.saveBill.bill.id);
                  setView(BillPaneViews.Edit);
                }
              }

              if (!isNew && isManualDraft) {
                const { data } = await saveBillApproval({
                  variables: { input: { subjectId: bill?.id } },
                  refetchQueries,
                });
                const billApprovalSubject = data?.saveApproval.approval.subject as ApprovalSubject_BillFragment;
                if (billApprovalSubject) {
                  triggerNotice({
                    message: `bill #${bill.tradePartnerNumber} submitted for approval`,
                  });

                  // rehydrate the bill pane context with the new bill data
                  setBillId(billApprovalSubject.id);
                }
              }

              if (!isNew && isChangesRequested) {
                const { data } = await saveBillApproval({
                  variables: {
                    input: {
                      id: bill.approval?.id,
                      reopen: true,
                    },
                  },
                  refetchQueries,
                });
                if (data?.saveApproval.approval) {
                  triggerNotice({
                    message: `bill #${bill.tradePartnerNumber} resubmitted for approval`,
                  });
                  // rehydrate the bill pane context with the new bill data
                  setBillId(bill.id);
                  setView(BillPaneViews.View);
                }
              }
            },
            disabled:
              (isManual &&
                bill.status.code === BillStatus.Draft &&
                bill.canSubmitForApproval.disabledReasons.nonEmpty &&
                bill.canSubmitForApproval.disabledReasons.map((dr) => dr.message).join(" ")) ||
              (bill?.status.code === BillStatus.PendingApproval &&
                bill.type.code === BillType.Standard &&
                "bill already submitted for approval") ||
              (!isNew && !formState.valid),
          },
          ...(!isNew
            ? [
                {
                  label: "Save Draft",
                  variant: "secondary",
                  actionFn: async () => {
                    const { data } = await save({
                      variables: { input: mapToInput(formState.value, tradePartner.id, projectStage.id) },
                      // Rehydrate commitment bills table with newly saved bill data
                      refetchQueries,
                    });
                    triggerNotice({
                      message: `bill #${formState.tradePartnerNumber.value} successfully saved`,
                    });
                    if (data?.saveBill.bill) {
                      // Update bill pane context with the saved bill data
                      setBillId(data.saveBill.bill.id);
                    }
                  },
                },
              ]
            : ([] as DetailPaneButton[])),

          ...(!isNew
            ? [
                {
                  label: "Delete",
                  variant: "tertiaryDanger",
                  disabled: disableBasedOnPotentialOperation(bill?.canDelete),
                  actionFn: () => {
                    openModal({
                      content: (
                        <DeleteBillConfirmationModal
                          bill={bill}
                          onDelete={async () => {
                            const { data } = await deleteBill({
                              variables: { input: { id: bill.id } },
                              refetchQueries,
                            });
                            if (data?.deleteBill) {
                              triggerNotice({
                                message: `bill #${formState.tradePartnerNumber.value} successfully deleted`,
                              });
                              closeRightPane();
                            }
                          }}
                        />
                      ),
                    });
                  },
                },
              ]
            : ([] as DetailPaneButton[])),
        ]}
      />
    </div>
  );
}

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  tradePartnerNumber: { type: "value", rules: [required] },
  billDate: { type: "value", rules: [required] },
  dueDate: { type: "value" },
  postedDate: { type: "value" },
  paidDate: { type: "value" },
  paidInCents: { type: "value" },
  internalNote: { type: "value" },
  documents: {
    type: "list",
    rules: [required],
    config: {
      id: { type: "value" },
      name: { type: "value" },
      isDeleted: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          attachmentUrl: { type: "value" },
          contentType: { type: "value" },
          createdAt: { type: "value" },
          downloadUrl: { type: "value" },
          version: { type: "value" },
        },
      },
    },
  },
  isTradePartnerCredit: { type: "value" },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      displayName: { type: "value" },
      costChangeInCents: { type: "value" },
      commitmentLineItemId: { type: "value" },
      amountInCents: { type: "value" },
      billedInCents: { type: "value" },
      owner: { type: "object", config: { id: { type: "value" }, accountingNumber: { type: "value" } } },
    },
    rules: [
      ({ value }) => {
        return value.some((li) => li.amountInCents.value !== undefined) ? undefined : "At least one line item required";
      },
    ],
  },
};

const panelInlineTable: RowStyles<Row> = {
  header: { cellCss: Css.tiny.bgWhite.$ },
  lineItem: { cellCss: Css.tiny.$ },
  totals: { cellCss: Css.tiny.$ },
};
