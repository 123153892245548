import { Css, FilterDefs, Filters, multiFilter, singleFilter, toggleFilter, useTestIds } from "@homebound/beam";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  MaterialCatalog_ItemFragment,
  MaterialCatalog_MaterialAttributeDimensionFragment,
  MaterialCatalog_MaterialBrandFragment,
  MaterialType,
  MaterialTypeDetail,
  useMaterialCatalogMetadataQuery,
} from "src/generated/graphql-types";
import { useMaterialTypes } from "src/hooks/enums/useMaterialTypes";
import { queryResult } from "src/utils";
import { MaterialCatalogQueryParams } from "./useMaterialCatalog";

type MaterialCatalogFilterProps = {
  settings: MaterialCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<MaterialCatalogQueryParams>>;
};

export function MaterialCatalogFilter({ settings, setSettings }: MaterialCatalogFilterProps) {
  const result = useMaterialCatalogMetadataQuery();
  return queryResult(result, ({ items, materialBrands }) => (
    <MaterialCatalogFilterView items={items} brands={materialBrands} settings={settings} setSettings={setSettings} />
  ));
}

type FilterProps = {
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
  settings: MaterialCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<MaterialCatalogQueryParams>>;
};

type LocalFilters = {
  brand?: string[];
  item?: string;
  type?: MaterialType[];
  includeArchived?: boolean;
  attributeIds?: string[];
};

function MaterialCatalogFilterView({ items, brands, settings, setSettings }: FilterProps) {
  const testIds = useTestIds({});
  const [localFilters, setLocalFilters] = useState<LocalFilters>({});
  const types = useMaterialTypes();

  const filterDefs = useMemo(() => {
    const item = items.find((i) => i.id === localFilters.item);
    return createFilterDefs(brands, items, types, item?.materialAttributeDimensions ?? []);
  }, [brands, items, types, localFilters.item]);

  useEffect(() => {
    // Reset the current page to 1 if the filters change
    setSettings(mapToProviderFilters(localFilters, settings.perPage));
  }, [localFilters, setLocalFilters, setSettings, settings.perPage]);

  return (
    <div css={Css.mwPx(250).h100.bgGray100.p2.$} {...testIds}>
      <h1 css={Css.xlMd.pb2.$}>Filter</h1>
      <Filters filterDefs={filterDefs} filter={localFilters} onChange={setLocalFilters} vertical />
    </div>
  );
}

function createFilterDefs(
  brands: MaterialCatalog_MaterialBrandFragment[],
  items: MaterialCatalog_ItemFragment[],
  types: MaterialTypeDetail[],
  dimensions: MaterialCatalog_MaterialAttributeDimensionFragment[],
): FilterDefs<LocalFilters> {
  const type = multiFilter({
    label: "Material Type",
    options: types,
    getOptionValue: (o) => o.code,
    getOptionLabel: (o) => o.name,
  });

  const item = singleFilter({
    label: "Item",
    options: items,
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.name,
  });

  const brand = multiFilter({
    label: "Brand",
    options: brands,
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.name,
  });

  const includeArchived = toggleFilter({ label: "Show Archived" });

  const dimensionFilters = dimensions.keyBy(
    ({ name }) => name,
    ({ name, values }) =>
      multiFilter({
        label: name,
        options: values ?? [],
        getOptionValue: (o) => o.id,
        // NOTE: This field might need to be updated to handle range values
        //    SC-55230
        getOptionLabel: (o) => o?.value ?? "",
      }),
  );

  return { type, item, brand, ...dimensionFilters, includeArchived };
}

function mapToProviderFilters(localFilters: LocalFilters, perPage: number): MaterialCatalogQueryParams {
  // map `localFilters` to the ProductCatalogProvider `filters` state object
  const { item, type, brand, attributeIds, includeArchived, ...others } = localFilters;
  const dynamicFilters = Object.values(others).flatMap((v) => v) as string[];
  return {
    itemId: localFilters.item ? [localFilters.item] : undefined,
    type: localFilters.type,
    brandId: localFilters.brand,
    ...(dynamicFilters.nonEmpty ? { attributeIds: dynamicFilters } : {}),
    includeArchived,
    page: 1,
    perPage,
  };
}
