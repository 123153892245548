import {
  Button,
  ButtonMenu,
  Css,
  FullBleed,
  Icon,
  Palette,
  useModal,
  useSessionStorage,
  useTestIds,
} from "@homebound/beam";
import { ReactNode, useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { createDraftCalendarUrl, createDraftScheduleUrl, createProjectDashboardUrl } from "src/RouteUrls";
import { FormattedDate } from "src/components";
import {
  DynamicSchedulesHeader_PlanScheduleFragment,
  DynamicSchedulesHeader_ProjectFragment,
  Maybe,
  useDynamicSchedulesHeaderQuery,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PageHeaderBreadcrumbs } from "src/routes/layout/PageHeader";
import { dynamicSchedulesPath, projectsPath } from "src/routes/routesDef";
import { isDefined, queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";

export function DynamicSchedulesHeader({
  scheduleParentId,
  isDraftMode,
}: {
  scheduleParentId: string;
  isDraftMode?: boolean;
}) {
  const query = useDynamicSchedulesHeaderQuery({ variables: { projectId: scheduleParentId } });
  return queryResult(query, ({ project }) => {
    return <DynamicSchedulesHeaderView project={project} isDraftMode={isDraftMode} />;
  });
}

export function DynamicSchedulesHeaderView({
  project,
  isDraftMode,
}: {
  project: DynamicSchedulesHeader_ProjectFragment;
  isDraftMode?: boolean;
}) {
  const { buildAddress, planSchedule, id } = project;

  const { openModal } = useModal();
  const history = useHistory();
  const isCalendarView = !!useRouteMatch<{ projectid: string }>([dynamicSchedulesPath.calendarView])?.isExact;

  const headerBreadcrumbs = [
    { label: "Projects", href: projectsPath },
    { label: buildAddress.street1, href: createProjectDashboardUrl(id) },
  ];

  const openEditModalConfirmation = useCallback(
    () =>
      openModal({
        content: (
          <ConfirmationModal
            confirmationMessage="You're entering a schedule simulation. Feel free to make any changes you'd like. You will not change the schedule until you click 'confirm'."
            onConfirmAction={() =>
              isCalendarView ? history.push(createDraftCalendarUrl(id)) : history.push(createDraftScheduleUrl(id))
            }
            title="Enter Draft Mode?"
            label="Continue"
          />
        ),
      }),
    [id, openModal, history, isCalendarView],
  );

  return !isDraftMode ? (
    // Customized header since the PageHeader component limits ability to render header styles per the figma
    <FullBleed>
      <header css={Css.df.aic.py2.mhPx(64).bb.bw1.bcGray200.bgWhite.mb3.$} data-testid="headerTitle">
        <div css={Css.df.jcsb.w100.$}>
          <div css={Css.df.fdc.$}>
            <PageHeaderBreadcrumbs breadcrumb={headerBreadcrumbs} linkXss={Css.blue700.cursorPointer.truncate.$} />
            <h1 css={Css.mr2.xlSb.$}>Schedule</h1>
          </div>
          <div css={Css.df.w100.jcc.$}>
            <ScheduleHeaderDetails planSchedule={planSchedule} />
          </div>
          <div css={Css.asc.$}>
            <Button label="Edit Schedule" onClick={openEditModalConfirmation} />
          </div>
        </div>
      </header>
    </FullBleed>
  ) : (
    <div css={Css.bgWhite.br8.p2.boxShadow("0px 0px 13px 0px rgba(0, 0, 0, 0.05)").ma.$}>
      <ScheduleHeaderDetails planSchedule={planSchedule} />
    </div>
  );
}

type ScheduleHeaderFieldProps = {
  label: string;
  children: string | ReactNode;
  textColor?: string;
  endAdornment?: ReactNode;
};

function ScheduleHeaderField({ textColor = Palette.Gray900, children, label, endAdornment }: ScheduleHeaderFieldProps) {
  const tid = useTestIds({}, `${label}Header`);
  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.df.gap1.aic.gray600.xsMd.$}>
        {label}
        {endAdornment}
      </div>
      <div css={Css.smSb.color(`${textColor}`).$} {...tid}>
        {children}
      </div>
    </div>
  );
}

type ScheduleHeaderDetailsProps = {
  planSchedule: DynamicSchedulesHeader_PlanScheduleFragment | null | undefined;
};

// Avoid value renames here since these will be persisted in session storage
enum CycleTimeType {
  WorkingDays = "WorkingDays",
  CalendarDays = "CalendarDays",
  CalendarDaysByMonth = "CalendarDaysByMonth",
}

export function ScheduleHeaderDetails({ planSchedule }: ScheduleHeaderDetailsProps) {
  // We want this selection to be a sticky user preference across schedules, but don't need it encoded in the URL
  const [cycleTimeType, setCycleTimeType] = useSessionStorage(
    "dynamicSchedulesHeaderCycleTime",
    CycleTimeType.CalendarDaysByMonth,
  );

  const { actualCycleTime, targetCycleTime } = useMemo(
    () => displayCycleTimes(planSchedule, cycleTimeType),
    [planSchedule, cycleTimeType],
  );

  return (
    <div css={Css.df.aic.jcsa.gapPx(53).$}>
      <ScheduleHeaderField label="Ready to Start">
        <FormattedDate date={planSchedule?.readyToStartDate || undefined} dateFormatStyle="medium" />
      </ScheduleHeaderField>
      <ScheduleHeaderField label="Vertical Complete">
        <FormattedDate date={planSchedule?.verticalCompleteDate || undefined} dateFormatStyle="medium" />
      </ScheduleHeaderField>
      <ScheduleHeaderField label="Target Vertical Complete" textColor={Palette.Red600}>
        <div css={Css.df.aic.gap1.$}>
          <TargetVerticalCompleteDate
            targetEndDateProbability={planSchedule?.targetEndDateProbability}
            targetEndDate={planSchedule?.targetEndDate}
          />
          <Icon
            icon="infoCircle"
            tooltip="Based on The Ready to Start Date + Target Cycle Time."
            inc={2}
            color={Palette.Gray600}
          />
        </div>
      </ScheduleHeaderField>
      <ScheduleHeaderField label="Cycle Time">{actualCycleTime}</ScheduleHeaderField>
      <ScheduleHeaderField
        label="Target Cycle Time"
        endAdornment={
          <ButtonMenu
            data-testid="cycleTimeType"
            trigger={{ icon: "triangleDown", compact: true }}
            items={[
              {
                label: "Calendar Days (in Months)",
                onClick: () => setCycleTimeType(CycleTimeType.CalendarDaysByMonth),
              },
              { label: "Calendar Days", onClick: () => setCycleTimeType(CycleTimeType.CalendarDays) },
              { label: "Working Days", onClick: () => setCycleTimeType(CycleTimeType.WorkingDays) },
            ]}
            placement="right"
          />
        }
      >
        <div css={Css.df.aic.gap1.$}>
          <div>{targetCycleTime}</div>
          <Icon
            icon="infoCircle"
            tooltip="Based on Target Cycle Time, set in Project Settings."
            inc={2}
            color={Palette.Gray600}
          />
        </div>
      </ScheduleHeaderField>
    </div>
  );
}

export function TargetVerticalCompleteDate({
  targetEndDateProbability,
  targetEndDate,
}: {
  targetEndDateProbability: number | null | undefined;
  targetEndDate: DateOnly | undefined;
}) {
  if (!isDefined(targetEndDateProbability) || !isDefined(targetEndDate)) return null;

  // Likely (more than 75%)
  if (targetEndDateProbability >= 75_00) {
    return (
      <span css={Css.green600.$}>
        <FormattedDate date={targetEndDate} dateFormatStyle="medium" />
      </span>
    );
  }

  // Somewhat likely (25-75%)
  if (targetEndDateProbability < 75_00 && targetEndDateProbability >= 25_00) {
    return (
      <span css={Css.orange600.$}>
        <FormattedDate date={targetEndDate} dateFormatStyle="medium" />
      </span>
    );
  }

  // Unlikely (less than 25%)
  if (targetEndDateProbability < 25_00)
    return (
      <span css={Css.red600.$}>
        <FormattedDate date={targetEndDate} dateFormatStyle="medium" />
      </span>
    );
}

function displayCycleTimes(
  planSchedule: Maybe<DynamicSchedulesHeader_PlanScheduleFragment>,
  cycleTimeType: CycleTimeType,
) {
  const { cycleTimeInWorkingDays, targetCycleTimeInDays, cycleTimeInCalendarDays, targetCycleTimeInCalendarDays } =
    planSchedule ?? {};

  switch (cycleTimeType) {
    case CycleTimeType.WorkingDays:
      return {
        actualCycleTime: toDaysDisplay(cycleTimeInWorkingDays),
        targetCycleTime: toDaysDisplay(targetCycleTimeInDays),
      };
    case CycleTimeType.CalendarDays:
      return {
        actualCycleTime: toDaysDisplay(cycleTimeInCalendarDays),
        targetCycleTime: toDaysDisplay(targetCycleTimeInCalendarDays),
      };
    // Default is CalendarDaysByMonth, left explicity as the default case in the event `cycleTimeType` gets a corrupted value in session storage
    default:
      return {
        actualCycleTime: toMonthDisplay(cycleTimeInCalendarDays, 30),
        targetCycleTime: toMonthDisplay(targetCycleTimeInCalendarDays, 30),
      };
  }
}

function toDaysDisplay(days: Maybe<number>) {
  if (!isDefined(days)) return "--";
  return `${days} days`;
}

function toMonthDisplay(days: Maybe<number>, daysPerMonth: number) {
  if (!isDefined(days)) return "--";

  const months = days / daysPerMonth;
  // Return the value rounded to the nearest tenth
  return `${parseFloat(months.toFixed(1))} months`;
}
