import { Css } from "@homebound/beam";
import { useRouteMatch } from "react-router";
import {
  ScheduleDraftMode_TradePartnerMarketContactFragment,
  TradePartnerMarketRole,
} from "src/generated/graphql-types";
import { dynamicSchedulesPath } from "src/routes/routesDef";

// shared utility function to get specific market contacts for scheduling
export function getSchedulingOrOtherMarketContactsForTradePartners(
  marketContacts: ScheduleDraftMode_TradePartnerMarketContactFragment[] | undefined,
) {
  if (!marketContacts) return { schedulingContacts: [], otherContacts: [] };

  const [schedulingContacts, otherContacts] = marketContacts
    .partition(
      (contact) =>
        contact.role.code === TradePartnerMarketRole.Scheduling ||
        contact.role.code === TradePartnerMarketRole.SchedulingRecipient_2,
    )
    .map((contacts) => contacts.map(({ contact }) => contact.id).unique());
  return { schedulingContacts, otherContacts };
}

export function ScheduleContainer({ children }: { children: React.ReactNode }) {
  return <div css={Css.h100.w100.pr3.maxwPx(1450).ma.$}>{children}</div>;
}

export enum DynamicScheduleView {
  Calendar = "Calendar",
  Gantt = "Gantt",
  List = "List",
  Milestone = "Milestone",
}

export function useScheduleRouteMatch() {
  const isGanttView = !!useRouteMatch(dynamicSchedulesPath.ganttView);
  const isCalendarView = !!useRouteMatch(dynamicSchedulesPath.calendarView);
  const isMilestoneView = !!useRouteMatch(dynamicSchedulesPath.milestoneView);
  const isListView = !!useRouteMatch(dynamicSchedulesPath.listView);

  if (isGanttView) return DynamicScheduleView.Gantt;
  if (isCalendarView) return DynamicScheduleView.Calendar;
  if (isMilestoneView) return DynamicScheduleView.Milestone;
  if (isListView) return DynamicScheduleView.List;

  throw new Error("Unknown schedule view");
}
