import {
  Button,
  ButtonProps,
  ConfirmCloseModal,
  Css,
  IconButton,
  Palette,
  SuperDrawerContent,
  SuperDrawerHeader,
  TabsWithContent,
  TabWithContent,
  useComputed,
  useModal,
  useSuperDrawer,
  useTestIds,
} from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import { isEqual, subDays } from "date-fns";
import { Observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CommentFeed } from "src/components";
import {
  DateOperation,
  LotDetailDrawerQuery,
  LotType,
  Order,
  ProgramDataForLotSequenceFragment,
  ProgramDataLike,
  ProgramDataMatchState,
  ProjectCollaboratorRole,
  ProjectReadyPlanConfigForLotSequenceFragment,
  ProjectsForLotSequenceFragment,
  SaveProjectReadyPlanConfigInput,
  useComputedProgramDataPreviewLazyQuery,
  useLotDetailDrawerQuery,
  UserEventsQuery,
  useSaveProjectLotDetailMutation,
  useSaveProjectReadyPlanConfigMutation,
  useUserEventsQuery,
} from "src/generated/graphql-types";
import { UserEventsTable } from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { createLotSummaryDetailsPdfPath, createProjectUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { openNewTab } from "src/utils/window";
import { useQueryParam } from "use-query-params";
import {
  ClearAutoAddedOptionsComp,
  FormInput,
  hblReadyPlanFormConfig,
  LOT_SEQUENCE_MAX_DAYS_TO_SHOW_TAGS,
  lotConfig,
  LotDetailDrawerMetadata,
  LotFormInput,
  mapUpdatedFields,
  readyPlanFormConfig,
  useOptionNotifications,
} from "../utils";
import { LotInformationTab } from "./LotInformationTab";
import { OptionsTab } from "./OptionsTab";
import { PlanInformationTab } from "./PlanInformationTab";

type LotDetailDrawerProps = {
  configId?: string;
  developmentId: string;
  disableEdit?: boolean;
};

export function LotDetailDrawer({ configId, developmentId, disableEdit = false }: LotDetailDrawerProps) {
  const query = useLotDetailDrawerQuery({ variables: { id: configId!, development: developmentId } });
  return queryResult(query, (data) => (
    <LotDetailDrawerContent data={data} disableEdit={disableEdit || !data.projectReadyPlanConfig.canEdit.allowed} />
  ));
}

type LotDetailDrawerContentProps = {
  data: LotDetailDrawerQuery;
  disableEdit: boolean;
};

function LotDetailDrawerContent({ data, disableEdit }: LotDetailDrawerContentProps) {
  const { addCanCloseDrawerCheck, isDrawerOpen } = useSuperDrawer();
  const testIds = useTestIds(data, "LotDetailDrawerContent");
  const [tab] = useQueryParam<string>("tab");
  const [activeTab, setActiveTab] = useState(tab ?? planInformationTabId);
  const [saveConfig] = useSaveProjectReadyPlanConfigMutation();
  const [saveProjectLotDetail] = useSaveProjectLotDetailMutation();
  const { openModal } = useModal();

  const isHBL = data.projectReadyPlanConfig.project.lotType?.code === LotType.Hbl;
  const isBoyl = data.projectReadyPlanConfig.project.lotType?.code === LotType.Boyl;

  const formState = useFormState({
    config: isHBL ? hblReadyPlanFormConfig : readyPlanFormConfig,
    init: {
      input: data.projectReadyPlanConfig,
      map: mapToForm,
      options: [],
    },
    readOnly: true,
  });
  const lotFormState = useFormState({
    config: lotConfig,
    init: { input: data.projectReadyPlanConfig.project, map: mapLotToForm },
    readOnly: true,
  });

  const { pendingNotifications, programDataMatchState, RenderAndClearNotifications, clearLastUpdated } =
    useProgramDataDeviations({
      formState,
      prpc: data.projectReadyPlanConfig,
    });

  const { selectedPlanId, selectedElevationId, dirty, valid, readOnly } = useComputed(
    () => ({
      selectedPlanId: formState.readyPlanId.value,
      selectedElevationId: formState.elevationOptionId.value,
      dirty: formState.dirty || lotFormState.dirty,
      valid: formState.valid && lotFormState.valid,
      readOnly: formState.readOnly && lotFormState.readOnly,
    }),
    [formState, lotFormState],
  );
  const filterByDate = useMemo(() => new DateOnly(subDays(new Date(), LOT_SEQUENCE_MAX_DAYS_TO_SHOW_TAGS)), []);
  const { data: events, refetch: refetchEvents } = useUserEventsQuery({
    variables: {
      filter: {
        parentType: ["prpc", "pld"],
        project: [formState.projectId.value!],
        createdAt: { op: DateOperation.After, value: filterByDate },
      },
      limit: 1000,
      order: { createdAt: Order.Desc },
    },
  });
  const metadata = useMemo(
    () => mapToMetadata(data, selectedPlanId, selectedElevationId, events?.userEvents),
    [data, selectedPlanId, selectedElevationId, events],
  );
  const { autoAddedRpos, clearAutoAddedRpos } = useOptionNotifications({ formState, metadata, isBoyl });

  // implement super drawer confirmation modal
  useEffect(
    () => {
      if (isDrawerOpen) {
        addCanCloseDrawerCheck(() => !formState.dirty);
        addCanCloseDrawerCheck(() => !lotFormState.dirty);
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState, lotFormState],
  );

  useEffect(() => {
    if (!formState.readOnly) {
      formState.set({
        options: [],
        specOptionId: undefined,
        elevationOptionId: undefined,
        exteriorPaletteOptionId: undefined,
        programData: {
          netBuildableSqft: undefined,
          permittableSqft: undefined,
          grossBuildableSqft: undefined,
          sellableAboveGroundSqft: undefined,
          sellableBelowGroundSqft: undefined,
          grossBelowGroundSqft: undefined,
          stories: undefined,
          bedrooms: undefined,
          fullBaths: undefined,
          halfBaths: undefined,
          basementConfig: undefined,
          garageAttached: undefined,
          garageDetached: undefined,
          garagePort: undefined,
          garageConfiguration: undefined,
          windowColor: undefined,
        },
      });
    }
  }, [formState, selectedPlanId]);

  useEffect(() => {
    if (!formState.readOnly) {
      formState.set({ exteriorPaletteOptionId: undefined });
    }
  }, [formState, selectedElevationId]);

  const activeEditMode = useCallback(() => {
    formState.readOnly = false;
    lotFormState.readOnly = false;
  }, [formState, lotFormState]);

  const tabs: TabWithContent[] = useMemo(
    () => [
      {
        name: "Plan Info",
        endAdornment: pendingNotifications && activeTab !== planInformationTabId && (
          <div css={Css.h1.w1.br100.bgRed600.mr1.$} {...testIds.planInfoNotification} />
        ),
        value: planInformationTabId,
        render: () => (
          <>
            <RenderAndClearNotifications />
            <PlanInformationTab
              formState={formState}
              metadata={metadata}
              readOnly={readOnly}
              activeEditMode={activeEditMode}
              programDataMatchState={programDataMatchState}
              isHBL={isHBL}
              disableEdit={disableEdit}
            />
          </>
        ),
      },
      {
        name: "Lot Info",
        value: "lotInformation",
        render: () => (
          <LotInformationTab
            formState={lotFormState}
            metadata={metadata}
            readOnly={readOnly}
            activeEditMode={activeEditMode}
            disableEdit={disableEdit}
          />
        ),
      },
      {
        name: "Options",
        value: optionsTabId,
        endAdornment: autoAddedRpos.nonEmpty && activeTab !== optionsTabId && (
          <div css={Css.h1.w1.br100.bgRed600.mr1.$} {...testIds.optionsNotification} />
        ),
        render: () => (
          <>
            <ClearAutoAddedOptionsComp clearAutoAddedRpos={clearAutoAddedRpos} />
            <OptionsTab
              formState={formState}
              metadata={metadata}
              readOnly={readOnly}
              activeEditMode={activeEditMode}
              disableEdit={disableEdit}
            />
          </>
        ),
      },
      {
        name: "Activity",
        value: "activity",
        render: () => (
          <>
            <div css={Css.gray900.baseBd.$}>Recent activity</div>
            <UserEventsTable
              currentUser={{ id: metadata.currentUserId }}
              queryFilter={{
                projectId: formState.projectId.value!,
                parentType: ["p", "prpc", "pld"],
                allEvents: true,
              }}
              embedded
            />
          </>
        ),
      },
      {
        name: "Comments",
        value: "comments",
        render: () => (
          <CommentFeed
            commentable={data.projectReadyPlanConfig.project}
            showFollowers={true}
            showCommentTitle={false}
          />
        ),
      },
    ],
    [
      activeTab,
      testIds,
      RenderAndClearNotifications,
      activeEditMode,
      data.projectReadyPlanConfig.project,
      formState,
      lotFormState,
      metadata,
      readOnly,
      pendingNotifications,
      programDataMatchState,
      isHBL,
      disableEdit,
      autoAddedRpos,
      clearAutoAddedRpos,
    ],
  );

  const onSave = useCallback(
    async () => {
      if (formState.dirty) {
        const { data: response } = await saveConfig({
          variables: {
            input: { projectReadyPlanConfigs: [mapToInput(formState, data)] },
          },
        });
        formState.commitChanges();
        // Now that we did a real update to the computed program data, the server will send back its updated record
        // updating out entity cache, so we need to also update our local cache of the last updated date to be the one comming from the response
        // so the validation of last update stills equals the new computed program data last updated
        clearLastUpdated(
          response?.saveProjectReadyPlanConfigs.projectReadyPlanConfigs[0]?.computedProgramData.updatedAt,
        );
      }
      if (lotFormState.dirty) {
        await saveProjectLotDetail({
          variables: {
            input: { projectLotDetails: [mapToLotInput(lotFormState.changedValue)] },
          },
        });
        lotFormState.commitChanges();
      }

      formState.readOnly = true;
      lotFormState.readOnly = true;
      await refetchEvents();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, formState, lotFormState, refetchEvents, saveConfig, saveProjectLotDetail],
  );

  const onCancel = () => {
    formState.readOnly = true;
    formState.revertChanges();
    lotFormState.readOnly = true;
    lotFormState.revertChanges();
    clearLastUpdated();
    clearAutoAddedRpos();
  };

  const onCancelDirty = () => {
    openModal({
      content: <ConfirmCloseModal onClose={onCancel} />,
    });
  };

  const drawerActions: ButtonProps[] = [
    {
      label: "Cancel",
      onClick: () => (dirty ? onCancelDirty() : onCancel()),
      variant: "tertiary",
    },
    { label: "Save", onClick: onSave, disabled: !dirty || !valid },
  ];

  return (
    <Observer>
      {() => (
        <SuperDrawerContent actions={!readOnly ? drawerActions : undefined}>
          <SuperDrawerHeader
            title={data.projectReadyPlanConfig.project.buildAddress.street1}
            left={
              readOnly && (
                <IconButton
                  data-testid="goToProject"
                  color={Palette.Blue700}
                  icon="linkExternal"
                  onClick={createProjectUrl(data.projectReadyPlanConfig.project.id)}
                />
              )
            }
            right={
              <Button
                size="sm"
                label="Export"
                variant="secondary"
                onClick={() => openNewTab(createLotSummaryDetailsPdfPath(data.projectReadyPlanConfig.id))}
              />
            }
            hideControls={!readOnly}
          />
          <div css={Css.p3.pt1.$}>
            <TabsWithContent includeBottomBorder tabs={tabs} selected={activeTab} onChange={setActiveTab} />
          </div>
        </SuperDrawerContent>
      )}
    </Observer>
  );
}

function mapToForm(projectReadyPlanConfig: ProjectReadyPlanConfigForLotSequenceFragment): FormInput {
  return {
    id: projectReadyPlanConfig?.id,
    projectId: projectReadyPlanConfig?.project.id,
    readyPlanId: projectReadyPlanConfig?.readyPlan?.id,
    orientation: projectReadyPlanConfig?.orientationDetail.code,
    specOptionId: projectReadyPlanConfig?.specOption?.readyPlanOption.id,
    elevationOptionId: projectReadyPlanConfig?.elevationOption?.readyPlanOption.id,
    exteriorPaletteOptionId: projectReadyPlanConfig?.exteriorPaletteOption?.readyPlanOption.id,
    internalNote: projectReadyPlanConfig?.internalNote,
    options:
      projectReadyPlanConfig?.options
        ?.filter(
          ({ readyPlanOption }) =>
            !readyPlanOption.type.isElevation &&
            !readyPlanOption.type.isExteriorPalette &&
            !readyPlanOption.type.isSpecLevel,
        )
        .map(({ updatedAt, readyPlanOption }) => ({
          id: readyPlanOption.id,
          updatedAt,
        })) ?? [],
    programData: {
      ...(projectReadyPlanConfig?.programData ?? {}),
      garageConfiguration: projectReadyPlanConfig?.programData?.garageConfiguration?.code,
      basementConfig: projectReadyPlanConfig.programData?.basementConfig?.code,
      primaryBedroom: projectReadyPlanConfig.programData?.primaryBedroom?.code,
      windowColor: projectReadyPlanConfig.programData?.windowColor?.code,
    },
    computedProgramData: projectReadyPlanConfig?.computedProgramData,
  };
}

function mapLotToForm(project: ProjectsForLotSequenceFragment): LotFormInput {
  return {
    // Project Lot Information
    id: project.lotDetail?.id,
    projectId: project.id,
    lotType: project.lotType?.code,
    clients:
      project.collaborators
        ?.filter(({ role }) => role === ProjectCollaboratorRole.Homeowner)
        .map(({ collaborator }) => ({ id: collaborator.id, fullName: collaborator.fullName })) ?? [],
    lotNumber: project.lotDetail?.lotNumber,
    block: project.lotDetail?.block,
    section: project.lotDetail?.section,
    lotSquareFootage: project.lotDetail?.lotSquareFootage,
    floodPlain: project.lotDetail?.floodPlain?.code,
    floodZone: project.lotDetail?.floodZone?.code,
    setBackFrontInFeet: project.lotDetail?.setBackFrontInFeet,
    setBackRightInFeet: project.lotDetail?.setBackRightInFeet,
    setBackLeftInFeet: project.lotDetail?.setBackLeftInFeet,
    setBackRearInFeet: project.lotDetail?.setBackRearInFeet,
    hoa: project.lotDetail?.hoa,
    hoaSpecificModifications: project.lotDetail?.hoaSpecificModifications,
    siteSpecificModifications: project.lotDetail?.siteSpecificModifications,
    constructionType: project.lotDetail?.constructionType?.code,
    foundationType: project.lotDetail?.foundationType?.code,
    fireSprinklersRequired: project.lotDetail?.fireSprinklersRequired,
  };
}

function mapToMetadata(
  data?: LotDetailDrawerQuery,
  selectedPlanId?: string | null,
  selectedElevationId?: string | null,
  events?: UserEventsQuery["userEvents"],
): LotDetailDrawerMetadata {
  const options = data?.readyPlans.find(({ id }) => id === selectedPlanId)?.options ?? [];

  return {
    updatedFields: mapUpdatedFields(events ?? []),
    currentUserId: data?.currentUser?.id,
    internalUsers: data?.internalUsers ?? [],
    readyPlans: data?.readyPlans?.map(({ id, name, displayCode }) => ({ id, name, displayCode })) ?? [],
    elevationOptions: options.filter(({ type }) => type.isElevation),
    exteriorSchemeOptions: options.filter(
      ({ type, optionPrerequisites }) =>
        type.isExteriorPalette &&
        selectedElevationId &&
        optionPrerequisites.map((rpo) => rpo.id).includes(selectedElevationId),
    ),
    specOptions: options.filter(({ type }) => type.isSpecLevel),
    otherOptions: options.filter(({ type }) => !type.isElevation && !type.isExteriorPalette && !type.isSpecLevel),
    globalOptionTypes: data?.globalOptionTypes ?? [],
    enumDetails: data?.enumDetails,
    canEdit: data?.projectReadyPlanConfig.canEdit,
  };
}

function mapToInput(formState: ObjectState<FormInput>, data: LotDetailDrawerQuery) {
  const { elevationOptionId, exteriorPaletteOptionId, specOptionId, options } = formState.value;
  const {
    elevationOptionId: elevationOptionIdChanged,
    exteriorPaletteOptionId: exteriorPaletteOptionIdChanged,
    specOptionId: specOptionIdChanged,
    options: optionsChanged,
    computedProgramData,
    ...rest
  } = formState.changedValue;
  // we extract the derived fields that are setted on the form state to be displayed, but should not be sent to the backend
  const { sellableSqft, unfinishedBelowGroundSqft, ...pdFields } = rest.programData ?? {};
  const saveProjectReadyPlanConfigInput: SaveProjectReadyPlanConfigInput = {
    ...rest,
    programData: pdFields,
    ...((optionsChanged || elevationOptionIdChanged || exteriorPaletteOptionIdChanged || specOptionIdChanged) && {
      options: [
        ...(options?.map(({ id }) => {
          const existing = data?.projectReadyPlanConfig.options.find(
            ({ readyPlanOption }) => readyPlanOption.id === id,
          );
          return existing ? { id: existing.id } : { readyPlanOptionId: id };
        }) ?? []),
        ...(elevationOptionId
          ? [
              (() => {
                const existing = data?.projectReadyPlanConfig.options.find(
                  ({ readyPlanOption }) => readyPlanOption.id === elevationOptionId,
                );
                return existing ? { id: existing.id } : { readyPlanOptionId: elevationOptionId };
              })(),
            ]
          : []),
        ...(exteriorPaletteOptionId
          ? [
              (() => {
                const existing = data?.projectReadyPlanConfig.options.find(
                  ({ readyPlanOption }) => readyPlanOption.id === exteriorPaletteOptionId,
                );
                return existing ? { id: existing.id } : { readyPlanOptionId: exteriorPaletteOptionId };
              })(),
            ]
          : []),
        ...(specOptionId
          ? [
              (() => {
                const existing = data?.projectReadyPlanConfig.options.find(
                  ({ readyPlanOption }) => readyPlanOption.id === specOptionId,
                );
                return existing ? { id: existing.id } : { readyPlanOptionId: specOptionId };
              })(),
            ]
          : []),
      ],
    }),
  };
  return saveProjectReadyPlanConfigInput;
}

function mapToLotInput(form: LotFormInput) {
  const {
    id,
    projectId,
    lotNumber,
    block,
    section,
    lotSquareFootage,
    setBackFrontInFeet,
    setBackRightInFeet,
    setBackLeftInFeet,
    setBackRearInFeet,
    hoa,
    hoaSpecificModifications,
    siteSpecificModifications,
    fireSprinklersRequired,
    constructionType,
    floodPlain,
    floodZone,
    foundationType,
  } = form;

  return {
    lotNumber,
    block,
    section,
    lotSquareFootage,
    setBackFrontInFeet,
    setBackRightInFeet,
    setBackLeftInFeet,
    setBackRearInFeet,
    hoa,
    hoaSpecificModifications,
    siteSpecificModifications,
    fireSprinklersRequired,
    constructionType,
    floodPlain,
    floodZone,
    foundationType,
    projectId,
    id,
  };
}

export function useProgramDataDeviations(props: {
  formState: ObjectState<FormInput>;
  prpc: LotDetailDrawerQuery["projectReadyPlanConfig"];
}) {
  const { formState, prpc } = props;
  const [queryComputedPDPreview, { data: computedPreview, loading: loadingProgramDataPreview }] =
    useComputedProgramDataPreviewLazyQuery();
  // The last PD update is the cached value of the updatedAt property for the computed program data
  // When we get new computed values triggered by user action, we check this date vs the new computed program data date
  // if the computed is greater, means there are updates to show, when we render the plan info tab again
  // the RenderAndClearNotifications set the value of pdSeen to be the newly updatedAt of the computed program data
  // pdSeen === computed, means, no new updates to show
  const [pdSeen, setPdSeen] = useState<Pick<ProgramDataLike, "updatedAt">>({
    updatedAt: prpc.computedProgramData.updatedAt,
  });
  const { selectedPlan, selectedElevation, readOnly, selectedScheme, options, programDataDirty } = useComputed(
    () => ({
      selectedPlan: formState.readyPlanId.value,
      selectedElevation: formState.elevationOptionId.value,
      selectedScheme: formState.exteriorPaletteOptionId.value,
      readOnly: formState.readOnly,
      options: formState.options.value,
      programDataDirty: formState.programData.dirty,
    }),
    [formState],
  );
  // pdSeen can only have the original program data updated at or the previous computed preview updated at
  const pendingNotifications = pdSeen && formState.computedProgramData.updatedAt?.value > pdSeen.updatedAt;
  const programDataMatchState =
    (!isEqual(pdSeen.updatedAt, prpc.computedProgramData.updatedAt) || programDataDirty) &&
    !readOnly &&
    !prpc.autoPopulateWithComputedProgramData
      ? ProgramDataMatchState.DeviationAcknowledged
      : prpc.programDataMatchState.code;

  useEffect(() => {
    const debounce = setTimeout(async () => {
      if (!formState.readOnly && selectedPlan) {
        await queryComputedPDPreview({
          variables: {
            input: {
              readyPlan: selectedPlan,
              readyPlanOptions: [selectedElevation, selectedScheme, ...options.map((rpo) => rpo.id)].compact(),
            },
          },
        });
      }
    }, 300);
    return () => clearTimeout(debounce);
  }, [selectedPlan, selectedElevation, selectedScheme, options, formState, queryComputedPDPreview]);

  useEffect(() => {
    // If the computed preview was updated and we are not longer waiting for it
    if (!loadingProgramDataPreview && computedPreview?.computeProgramData) {
      // we store in our local cache the CURRENT computedProgramData updatedAt, might be the original record or the last preview result
      setPdSeen({ updatedAt: formState.computedProgramData?.updatedAt?.value });
      // now that the date is save, we update the form state with the new computed preview values, so the UI can show deviations
      formState.computedProgramData.set(computedPreview.computeProgramData);
      // if the auto populate is set to true, we override, only when the feature flag is enabled
      if (prpc.autoPopulateWithComputedProgramData) {
        const { __typename, ...rest } = computedPreview.computeProgramData;
        formState.programData.set({
          ...rest,
          // all enum values should be mapped in here to prevent sending the detail object to the save mutation
          // TODO: Think on a dynamic way to identify enum fields and map the correct value without manual mapping
          garageConfiguration: rest.garageConfiguration?.code,
          basementConfig: rest.basementConfig?.code,
          primaryBedroom: rest.primaryBedroom?.code,
          windowColor: rest.windowColor?.code,
        } as any);
      }
    }
  }, [formState, prpc, computedPreview, loadingProgramDataPreview]);

  const RenderAndClearNotifications = useMemo(
    () => () => (
      <ClearNotifications computedProgramData={formState.computedProgramData} pdSeen={pdSeen} setPdSeen={setPdSeen} />
    ),
    [formState.computedProgramData, pdSeen, setPdSeen],
  );

  const clearLastUpdated = (newDate?: Date) => {
    setPdSeen({ updatedAt: newDate ?? prpc.computedProgramData.updatedAt });
  };

  return {
    pendingNotifications,
    programDataMatchState,
    RenderAndClearNotifications,
    clearLastUpdated,
  };
}

function ClearNotifications(props: {
  children?: React.ReactElement;
  computedProgramData: ObjectState<ProgramDataForLotSequenceFragment>;
  pdSeen: Pick<ProgramDataLike, "updatedAt">;
  setPdSeen: (value: Pick<ProgramDataLike, "updatedAt">) => void;
}) {
  const { children, computedProgramData, pdSeen, setPdSeen } = props;
  useEffect(() => {
    computedProgramData?.updatedAt?.value > pdSeen.updatedAt &&
      setPdSeen({ updatedAt: computedProgramData.updatedAt?.value });
  }, [computedProgramData.updatedAt, pdSeen.updatedAt, setPdSeen]);

  return children ?? <></>;
}

const planInformationTabId = "planInformation";
const optionsTabId = "options";
