import { Avatar, Css, TabsWithContent, TabWithContent, useSessionStorage, useTestIds } from "@homebound/beam";
import startCase from "lodash/startCase";
import React from "react";
import { Link } from "react-router-dom";
import { CommentFeed, FormattedDate } from "src/components";
import { ApprovalSubject_ChangeRequestFragment } from "src/generated/graphql-types";
import { daysAgo, foldEnum, foldGqlUnion } from "src/utils";
import { ApprovalActivityTab } from "./ApprovalActivityTab";
import { ApprovalApprovers } from "./ApprovalApprovers";
import {
  approvalTabXss,
  OverviewForm,
  OverviewFormGap,
  OverviewFormLabel,
  OverviewLeft,
  OverviewRight,
  OverviewWrapper,
  useApprovalBreakpoint,
} from "./approvalAtoms";
import { ApprovalReviewButtons } from "./ApprovalReviewButtons";
import { ApprovalSummary } from "./ApprovalSummary";
import { useApprovalContext } from "./ApprovalSuperDrawer";
import { ApprovalOverviewBillExtension } from "./bills/ApprovalOverviewBillExtension";
import { ApprovalChangeEventBreakDown } from "./changeEvents/ApprovalChangeEventBreakDown";
import { ApprovalChangeEventImpactedPosTable } from "./changeEvents/ApprovalChangeEventImpactedPosTable";
import { ApprovalOverviewChangeEventExtension } from "./changeEvents/ApprovalOverviewChangeEventExtension";
import { ApprovalOverviewChangeRequestExtension } from "./changeRequests/ApprovalOverviewChangeRequestExtension";
import { ChangeRequestApprovalSummary } from "./changeRequests/ChangeRequestApprovalSummary";
import { ApprovalOverviewInvoiceExtension } from "./invoices/ApprovalOverviewInvoiceExtension";

export function ApprovalOverviewAdapter() {
  const { approvals = [] } = useApprovalContext();
  const [selectedTab, setSelectedTab] = useSessionStorage("approvalSuperDrawer", "overview");

  const typeOfFirst = approvals.first?.subject.__typename;
  const firstApproval = approvals.first;
  if (!typeOfFirst || !firstApproval) return <div>ERR: No approval loaded.</div>;

  return (
    <>
      <div css={Css.ifMdAndDown.add("height", "calc(100% - 88px)").$}>
        <TabsWithContent
          tabs={[
            {
              name: "Overview",
              value: "overview",
              render: () => (
                <ApprovalOverview>
                  {foldGqlUnion(firstApproval.subject, {
                    ChangeEvent: () => <ApprovalOverviewChangeEventExtension />,
                    Bill: () => <ApprovalOverviewBillExtension />,
                    Invoice: () => <ApprovalOverviewInvoiceExtension />,
                    ChangeRequest: () => <ApprovalOverviewChangeRequestExtension />,
                  })}
                </ApprovalOverview>
              ),
            },
            ...foldEnum<typeof typeOfFirst, () => TabWithContent[]>(typeOfFirst, {
              ChangeEvent: () => [
                {
                  name: "Change Breakdown",
                  value: "changebreakdown",
                  render: () => <ApprovalChangeEventBreakDown changeEventId={approvals.first?.subject.id} />,
                },
                {
                  name: "Impacted Trades",
                  value: "impactedtrades",
                  render: () => <ApprovalChangeEventImpactedPosTable changeEventId={approvals.first?.subject.id} />,
                },
              ],
              Bill: () => [],
              Invoice: () => [],
              ChangeRequest: () => [],
            }),
            {
              name: "Activity",
              value: "activity",
              render: () => <ApprovalActivityTab />,
            },
          ]}
          includeBottomBorder
          contentXss={approvalTabXss(selectedTab)}
          selected={selectedTab}
          onChange={setSelectedTab}
        />
      </div>
      <ApprovalReviewButtons />
    </>
  );
}

type ApprovalsOverviewProps = {
  /**
   * Allows per-subject "Extensions" to be added to this "form-like" view. For
   * example, a ChangeEvent Approval can show Reason Code, Internal Note, etc.
   * which aren't directly associated with an Approval.
   */
  children: React.ReactNode;
};

function ApprovalOverview({ children }: ApprovalsOverviewProps) {
  const { approvals } = useApprovalContext();
  const [approval] = approvals || [];
  const tid = useTestIds({}, "approvalOverview");
  const desktop = useApprovalBreakpoint();

  if (!approval) return <div>ERR: No approval loaded.</div>;

  const isChangeRequestApproval = approval.subject.__typename === "ChangeRequest";

  return (
    <OverviewWrapper>
      <OverviewLeft>
        {foldGqlUnion(approval.subject, {
          ChangeEvent: (ce) => <ApprovalSummary subjects={[ce]} />,
          ChangeRequest: (cr) => <ChangeRequestApprovalSummary changeRequest={cr} />,
          else: () => null,
        })}
        <OverviewForm>
          {approval.rootProject?.name && (
            <>
              <OverviewFormLabel>Project</OverviewFormLabel>
              <Link {...tid.project} to={approval.subject.blueprintUrl.path}>
                {approval.rootProject?.name}
              </Link>
            </>
          )}
          {approval.subject.__typename === "ChangeRequest" && (
            <>
              <OverviewFormLabel>Scope</OverviewFormLabel>
              <div>
                {approval.subject.scopes.map((s) => (
                  <div key={s.target.id} css={Css.df.fdc.$}>
                    <Link to={s.target.blueprintUrl.path}>{s.target.name}</Link>
                  </div>
                ))}
              </div>
            </>
          )}
          {foldGqlUnion(approval.source!, {
            BidContractRevision: (bcr) => (
              <>
                <OverviewFormLabel>Development</OverviewFormLabel>
                <Link {...tid.development} to={bcr.bidContract.parent.blueprintUrl.path}>
                  {bcr.bidContract.parent.name}
                </Link>
              </>
            ),
            ItemTemplate: (it) => (
              <>
                <OverviewFormLabel>Item Template</OverviewFormLabel>
                {it.displayName}
              </>
            ),
            else: () => null,
          })}
          <OverviewFormLabel>Source</OverviewFormLabel>
          {foldGqlUnion(approval.source!, {
            BidContractRevision: (bcr) => (
              <Link {...tid.source} to={bcr.bidContract.parent.blueprintUrl.path}>
                {bcr.bidContract.name}
              </Link>
            ),
            ItemTemplate: (it) => it.displayName,
            else: () =>
              foldGqlUnion(approval.subject, {
                ChangeEvent: (ce) => (
                  <Link {...tid.source} to={ce.blueprintUrl.path}>
                    {ce.name}
                  </Link>
                ),
                Bill: (b) => (
                  <Link {...tid.source} to={b.blueprintUrl.path}>
                    {b.name}
                  </Link>
                ),
                Invoice: () => <></>,
                ChangeRequest: (cr) => (
                  <Link {...tid.source} to={cr.blueprintUrl.path}>
                    {cr.name}
                  </Link>
                ),
              }),
          })}
          <OverviewFormLabel>Requested By</OverviewFormLabel>
          <Avatar src={approval.requestedBy?.avatar} name={approval.requestedBy?.name} showName size="sm" />
          <OverviewFormLabel>Request Date</OverviewFormLabel>
          <FormattedDate date={approval.requestedAt || undefined} dateFormatStyle="short" timeFormatStyle="short" />
          {approval.dueOn && (
            <>
              <OverviewFormLabel>Due date</OverviewFormLabel>
              <div css={Css.df.fdr.$}>
                <FormattedDate date={approval.dueOn || undefined} dateFormatStyle="short" timeFormatStyle="short" />
                <div css={Css.ml1.red400.sm.$}>{daysAgo(approval.dueOn?.date)}</div>
              </div>
            </>
          )}
          {isChangeRequestApproval && (
            <>
              <OverviewFormLabel>Description</OverviewFormLabel>
              <div>{(approval.subject as ApprovalSubject_ChangeRequestFragment).rationale}</div>
            </>
          )}
          <OverviewFormLabel>Approval Type</OverviewFormLabel>
          <div>{startCase(approval.subject.__typename)}</div>
          <span />
          <OverviewFormGap />
          {children}
        </OverviewForm>
      </OverviewLeft>
      <OverviewRight>
        <ApprovalApprovers subjects={[approval.subject]} />
        {desktop && <div css={Css.bt.bcGray200.w100.$} />}
        <div css={Css.pb2.df.fdc.gap2.if(desktop).ml5.$}>
          {approvals?.length === 1 ? (
            <div css={Css.mr4.$}>
              <CommentFeed commentable={approval} showFollowers={true} />
            </div>
          ) : undefined}
        </div>
      </OverviewRight>
    </OverviewWrapper>
  );
}
