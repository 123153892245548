import {
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  IconButton,
  Palette,
  ResponsiveGrid,
  ResponsiveGridItem,
  SelectField,
  StaticField,
  ToggleChips,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { addEntityParam, createBillPageUrl, createBillsAndCreditsPageUrl } from "src/RouteUrls";
import { CommentFeed, DocumentUploader, Icon, PdfViewer } from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { ProjectAutocompleteField } from "src/components/autoPopulateSelects/ProjectAutocompleteField";
import { TradePartnerAutocompleteField } from "src/components/autoPopulateSelects/TradePartnerAutocompleteField";
import {
  BillEditor_BillFragment,
  BillEditor_BillLineItemFragment,
  BillEditor_CommitmentLikeFragment,
  BillEditor_CommitmentLineItemFragment,
  BillEditor_ProjectItemFragment,
  BillPage_DocumentFragment,
  BillReviewReason,
  BillStatus,
  BillType,
  CommitmentStatus,
  DocumentType,
  InputMaybe,
  Maybe,
  ProjectAutocomplete_ProjectFragment,
  SaveBillInput,
  SaveBillLineItemInput,
  SaveCommentInput,
  Stage,
  TradePartnerAutocomplete_TradePartnerFragment,
  useBillEditor_BillQuery,
  useBillEditor_CalculateDueDateQuery,
  useBillEditor_CommitmentLikesQuery,
  useBillEditor_ReviewReasonsDetailsQuery,
  useBillEditor_SaveBillMutation,
  useDeleteBillMutation,
  useSubmitApprovalMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { fail, formatCentsToPrice, isDefined, isNumber, queryResult } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { PageHeader } from "../layout/PageHeader";
import { IdOrAddParams } from "../routesDef";
import { BillPageV2 } from "./BillPageV2";
import { AddToBillReviewLogModal } from "./components/AddToBillReviewLogModal";
import { BillCommitmentsModal, getToggleChips, handleToggle } from "./components/BillCommitmentsModal";
import { BillLineItemsTable, Row } from "./components/BillLineItemsTable";
import { BilledInCentsField } from "./components/BilledInCentsField";
import { getDynamicPdfVierwerWidth } from "./utils";

function isDraft(billStatus: BillStatus) {
  return billStatus === BillStatus.Draft;
}

export function BillEditorV3() {
  const { idOrAdd } = useParams<IdOrAddParams>();

  const [{ commitmentLikeId, edit }] = useQueryParams({
    commitmentLikeId: StringParam,
    edit: BooleanParam,
  });

  const isChangeOrderId = !!(commitmentLikeId && commitmentLikeId.includes("cco:"));
  const isNew = idOrAdd === addEntityParam;
  const billQuery = useBillEditor_BillQuery({ variables: { id: idOrAdd }, skip: isNew });

  const commitmentLikeQuery = useBillEditor_CommitmentLikesQuery({
    variables: { commitmentLikeId: commitmentLikeId ?? "", isChangeOrderId },
    skip: !commitmentLikeId,
  });

  if (commitmentLikeId) {
    return queryResult(commitmentLikeQuery, {
      data: (data) => (
        <BillEditorForm commitmentLike={isChangeOrderId ? data.commitmentChangeOrder : data.commitment} />
      ),
    });
  }

  return isNew ? (
    <BillEditorForm />
  ) : (
    queryResult(billQuery, {
      data: ({ bill }) => {
        // Open bill editor by default for draft bills that are not Click-To-Pay
        return (isDraft(bill.status.code) && bill.canEdit.allowed) || edit ? (
          <BillEditorForm bill={bill} />
        ) : (
          <BillPageV2 billId={bill.id} />
        );
      },
    })
  );
}

type CreateBillEditorProps = {
  commitmentLike?: BillEditor_CommitmentLikeFragment;
  bill?: BillEditor_BillFragment;
};

function BillEditorForm({ commitmentLike, bill }: CreateBillEditorProps) {
  const [saveBill] = useBillEditor_SaveBillMutation();
  const [saveApproval] = useSubmitApprovalMutation();
  const [deleteBill] = useDeleteBillMutation();
  const { openModal, closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const tableApi = useGridTableApi<Row>();
  const [selectedCommitments, setSelectedCommitments] = useState<BillEditor_CommitmentLikeFragment[]>(
    bill?.parents || [commitmentLike].compact(),
  );
  const [userHasEditedTotal, setUserHasEditedTotal] = useState<boolean>(false);
  const [amountInCents, setAmountInCents] = useState<Maybe<number>>(() => {
    if (!bill) return undefined;
    return bill.lineItems.sum((li) => li.amountInCents);
  });
  const [editReviewReason, toggleEditReviewReason] = useToggle(false);

  const isEdit = !!bill;
  const isDraft = bill && bill.status.code === BillStatus.Draft;

  const formState = useFormState({
    config: formConfig,
    init: bill ? mapBillToForm(bill) : commitmentLike ? mapCommitmentLikeToForm(commitmentLike) : undefined,
    addRules(state) {
      state.lineItems.rules.push(() => {
        const lineItems = filteredLineItems(state.lineItems.value ?? []);
        // For standard bills, all selected line items should come from signed commitments
        return lineItems.find((li) => li.owner?.status && li.owner.status !== CommitmentStatus.Signed)
          ? "Selected commitments must be signed"
          : undefined;
      });
    },
  });
  const calculatedDueDateQuery = useBillEditor_CalculateDueDateQuery({ skip: true });
  const reviewReasonsQuery = useBillEditor_ReviewReasonsDetailsQuery({
    skip: !bill?.pendingReview,
    nextFetchPolicy: "cache-first",
  });

  function mapToInput(formStateValue: BillFormInput): SaveBillInput {
    const { documents, dueDate, billDate, tradePartner, project, ...others } = formStateValue;
    const lineItems = filteredLineItems(formStateValue.lineItems);

    // Commitments and project items are filtered to be from the same project stage
    const projectStageId = selectedCommitments.first
      ? "projectStage" in selectedCommitments.first
        ? selectedCommitments.first.projectStage.id
        : selectedCommitments.first.commitment.projectStage.id
      : lineItems.first?.projectStage?.id;

    return {
      ...others,
      tradePartnerId: tradePartner?.id,
      projectStageId:
        projectStageId ||
        // Find the construction stage for repair and warranty bills
        project?.stages.find((ps) => ps.stage.code === Stage.Construction)?.id ||
        fail("A construction project stage is required for this bill"),
      documents: documents?.map(({ id }) => id),
      dueDate: dueDate && new DateOnly(dueDate),
      billDate: billDate && new DateOnly(billDate),
      lineItems: lineItems.map((li) => ({
        id: li.bliId ?? undefined,
        commitmentLineItemId: li.commitmentLineItemId,
        projectItemId: li.projectItemId,
        amountInCents: li.amountInCents,
      })),
    };
  }

  const updateTotals = (val: Maybe<number>) => {
    if (!userHasEditedTotal) {
      formState.proposedAmountInCents.set(val);
    }
    setAmountInCents(val);
  };

  const handleLineItemsAmountUpdate = () => {
    const totalAmountInCents = filteredLineItems(formState.lineItems.value || []).sum((li) => li.amountInCents ?? 0);
    updateTotals(totalAmountInCents);
  };

  const filteredLineItems = (lineItems: BillFormLineItem[]) =>
    lineItems.filter(
      (li) =>
        isNumber(li.amountInCents) &&
        // We should only filter by selected rows on standard bills
        (formState.type.value !== BillType.Standard ||
          // If it is in edit mode you should evaluate commitmentLineItemId
          (bill?.id
            ? li.commitmentLineItemId && tableApi.getSelectedRowIds().includes(li.commitmentLineItemId)
            : li.id && tableApi.getSelectedRowIds().includes(li.id))),
    );

  // Map selected commitments to remove or add line items
  useEffect(() => {
    const lineItems = formState.lineItems.value ?? [];
    const removelineItems = isHeadless(formState.type.value)
      ? []
      : lineItems.filter((bli) => !selectedCommitments.map((c) => c.id).includes(bli.owner!.id));
    removelineItems.forEach((bli) => {
      // Remove selected rows from the API to prevent them from being marked as hidden rows
      tableApi.selectRow(bli.commitmentLineItemId!, false);
      formState.lineItems.remove(bli);
    });
    // Skip line items that we've already added, so they don't lose their current state
    const existingCommitments = lineItems.map((bli) => bli.owner?.id).unique();
    const addLineItems = selectedCommitments
      .filter((c) => !existingCommitments.includes(c.id))
      .flatMap((c) => c.lineItems)
      .map((cli) => ({
        id: cli.id,
        amountInCents: undefined,
        displayName: cli.projectItem.displayName,
        commitmentLineItemId: cli.id,
        costChangeInCents: cli.costChangeInCents,
        pendingBilledInCents: cli.pendingBilledInCents,
        owner: cli.owner,
        revisedApprovedBudgetInCents: cli.projectItem.revisedApprovedBudgetInCents,
        bills: cli.projectItem.bills,
        task: cli.projectItem.task,
      }));
    addLineItems.map((bli) => formState.lineItems.add(bli));
  }, [selectedCommitments, formState.lineItems, tableApi, formState.type.value]);

  // Clear selected rows when the create bill type changes
  useEffect(() => {
    if (formState.type.value !== BillType.Standard) {
      tableApi.clearSelections();
    }
  }, [formState.type.value, tableApi]);

  // If user has manually set total to a draft bill before editing line items, we should not automatically update total anymore
  useEffect(() => {
    if (isEdit && isDraft && amountInCents !== formState.proposedAmountInCents.value) {
      setUserHasEditedTotal(true);
    }
  }, [isEdit, isDraft, amountInCents, formState.proposedAmountInCents.value]);

  const handleSave = async (pendingReview?: boolean, comment?: SaveCommentInput) => {
    const { data } = await saveBill({
      variables: {
        input: {
          ...mapToInput({ ...formState.value, pendingReview: pendingReview ?? formState.value.pendingReview }),
          comment,
        },
      },
    });
    formState.commitChanges();
    triggerNotice({
      message: `Bill successfully ${bill ? `edited` : pendingReview ? `added to the Bill Review log` : `created`}`,
    });
    if (!bill) {
      history.push(createBillPageUrl({ idOrAdd: data?.saveBill.bill?.id }));
    }
  };

  const handleDelete = async (bill: BillEditor_BillFragment) => {
    await deleteBill({ variables: { input: { id: bill.id } } });
    triggerNotice({ message: "Bill successfully deleted" });
    history.push(createBillsAndCreditsPageUrl());
  };

  const showFormStateErrors = (errorMessages: string[]) => (
    <>
      {errorMessages.map((err) => (
        <div key={err}>{err}</div>
      ))}
    </>
  );

  const validateTotalsError = () =>
    amountInCents !== formState.proposedAmountInCents.value ? "The line items do not match the total" : false;

  return (
    <Observer>
      {() => (
        <div>
          <PageHeader
            title="Create Bill"
            breadcrumb={{ href: createBillsAndCreditsPageUrl(), label: "Bills & Credits" }}
            right={
              <div css={Css.df.cg1.$}>
                {!bill ? (
                  <>
                    <Button
                      variant="secondary"
                      label="Cancel"
                      onClick={() =>
                        openModal({
                          content: (
                            <ConfirmationModal
                              confirmationMessage="Are you sure you want to discard these changes?"
                              onConfirmAction={() => history.push(createBillsAndCreditsPageUrl())}
                              title="Discard Changes"
                              label="Confirm"
                            />
                          ),
                        })
                      }
                    />
                    <Button
                      variant="secondary"
                      label="Add to Bill Review Log"
                      disabled={!formState.valid ? showFormStateErrors(formState.errors) : false}
                      onClick={() =>
                        openModal({
                          content: (
                            <AddToBillReviewLogModal
                              handleSave={handleSave}
                              formState={formState}
                              selectedLineItems={filteredLineItems(formState.lineItems.value)}
                            />
                          ),
                        })
                      }
                    />
                  </>
                ) : (
                  <>
                    {/* Non-draft bills should be canceled/reversed/deleted from the view page */}
                    {isDraft && (
                      <Button
                        variant="danger"
                        label="Delete"
                        onClick={() =>
                          openModal({
                            content: (
                              <ConfirmationModal
                                confirmationMessage="Are you sure you want to delete this bill?"
                                onConfirmAction={() => handleDelete(bill)}
                                title="Delete Bill"
                                label="Confirm"
                              />
                            ),
                          })
                        }
                      />
                    )}
                    <Button
                      variant="secondary"
                      label="Save changes"
                      disabled={!formState.dirty}
                      onClick={() =>
                        formState.type.value === BillType.Standard &&
                        (filteredLineItems(formState.lineItems.value).isEmpty ||
                          hasUpdatedLineItems(bill.lineItems, filteredLineItems(formState.lineItems.value)))
                          ? openModal({
                              content: (
                                <ConfirmationModal
                                  confirmationMessage={
                                    <div>
                                      {filteredLineItems(formState.lineItems.value).isEmpty ? (
                                        <div>
                                          <div>This bill will be saved without line items. </div>
                                          <div css={Css.mt1.$}>
                                            If you want to include line items, you must select and input the amount for
                                            each.
                                          </div>
                                        </div>
                                      ) : (
                                        <div css={Css.mb2.$}>
                                          This bill will be saved with the following line items:
                                        </div>
                                      )}
                                      {filteredLineItems(formState.lineItems.value).map((li) => (
                                        <li key={li.id}>
                                          <span css={Css.smBd.$}>{li.displayName}</span>:
                                          {` ${formatCentsToPrice(li.amountInCents!)}`}
                                        </li>
                                      ))}
                                    </div>
                                  }
                                  onConfirmAction={() => handleSave()}
                                  title="Save changes"
                                  label="Confirm"
                                />
                              ),
                            })
                          : handleSave()
                      }
                    />
                  </>
                )}
                {!isEdit ? (
                  <Button
                    variant="primary"
                    label="Create"
                    disabled={
                      !formState.valid
                        ? showFormStateErrors(formState.errors)
                        : isRepairOrWarranty(formState.type.value)
                          ? !formState.billedInCents.value
                            ? "Bill amount is required"
                            : validateTotalsError()
                          : filteredLineItems(formState.lineItems.value).isEmpty
                            ? "Bill amount is required for the selected line items"
                            : validateTotalsError()
                    }
                    onClick={() => {
                      openModal({
                        content: (
                          <ConfirmationModal
                            confirmationMessage={
                              formState.billedInCents.value ? (
                                <div>
                                  A {capitalCase(formState.type.value!)} bill for{" "}
                                  {formatCentsToPrice(formState.billedInCents.value)} will be created and submitted for
                                  approval.
                                </div>
                              ) : (
                                <div>
                                  <div css={Css.mb2.$}> A Bill will be created with the following line items:</div>
                                  {filteredLineItems(formState.lineItems.value).map((li) => (
                                    <li key={li.id}>
                                      <span css={Css.smBd.$}>{li.displayName}</span>:
                                      {` ${formatCentsToPrice(li.amountInCents!)}`}
                                    </li>
                                  ))}
                                </div>
                              )
                            }
                            onConfirmAction={() => handleSave()}
                            title="Create Bill"
                            label="Confirm"
                          />
                        ),
                      });
                    }}
                  />
                ) : isDraft ? (
                  <Button
                    variant="primary"
                    label="Submit for approval"
                    disabled={
                      formState.dirty || !formState.valid || !bill.canSubmitForApproval.allowed
                        ? showFormStateErrors(bill.canSubmitForApproval.disabledReasons.map((r) => r.message))
                        : validateTotalsError()
                    }
                    onClick={() => {
                      openModal({
                        content: (
                          <ConfirmationModal
                            confirmationMessage="Do you want to submit this Bill for an approval?"
                            onConfirmAction={async () => {
                              if (bill.pendingReview) {
                                await saveBill({ variables: { input: { id: bill.id, pendingReview: false } } });
                              } else {
                                await saveApproval({ variables: { input: { subjectId: bill.id } } });
                              }
                              triggerNotice({
                                message: "Bill submitted for approval",
                                action: {
                                  label: "Go to Bill",
                                  onClick: createBillPageUrl({ idOrAdd: bill.id }),
                                  variant: "tertiary",
                                },
                              });
                              closeModal();
                              history.push(createBillsAndCreditsPageUrl());
                            }}
                            title="Submit for Approval"
                            label="Confirm"
                          />
                        ),
                      });
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            }
          />
          {bill?.reviewReasonDetail && (
            <>
              {editReviewReason ? (
                <div css={Css.df.jcc.$}>
                  <div>
                    <BoundSelectField
                      field={formState.reviewReason}
                      options={(reviewReasonsQuery?.data?.enumDetails.billReviewReason || [])
                        // "Insufficient budget" should only be used for overhead bills
                        .filter((val) => val.code !== BillReviewReason.InsufficientBudget)
                        .map((val) => ({ id: val.code, name: val.name }))}
                      placeholder="Select a reason"
                    />
                  </div>
                </div>
              ) : (
                <div css={Css.df.jcc.aic.w100.$} data-testid="review-reason-banner">
                  <div css={Css.df.jic.p2.bgYellow200.gray900.br12.wfc.gapPx(2).$}>
                    <span>
                      This bill is under review. <strong>Reason:</strong>
                    </span>
                    <span>{bill.reviewReasonDetail.name}</span>
                  </div>
                  {bill.type.code !== BillType.Overhead && bill.changeEvents.isEmpty && (
                    <div css={Css.pl1.$}>
                      <IconButton color={Palette.Gray600} onClick={toggleEditReviewReason} icon="pencil" />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <ResponsiveGrid columns={20} minColumnWidth={40} gap={30}>
            <ResponsiveGridItem colSpan={1} />
            <ResponsiveGridItem colSpan={18}>
              <div css={Css.df.$}>
                <div css={Css.w50.pr2.$}>
                  <div css={Css.gray700.$}>Now please add all information related to the bill document.</div>
                  <div css={Css.mt3.mb3.$}>
                    <FormLines width="full" labelSuffix={{ required: "*" }}>
                      <div>
                        <div css={Css.df.cg2.$}>
                          <div css={Css.w50.pr1.$}>
                            <ProjectAutocompleteField
                              required
                              disabled={selectedCommitments.nonEmpty || isEdit}
                              onSelect={(val) => formState.project.set(val)}
                              autofillValue={formState.project.value?.name}
                            />
                          </div>
                          <div css={Css.w50.pr1.$}>
                            <BoundNumberField
                              required
                              disabled={isEdit && !isDraft}
                              label="Total"
                              field={formState.proposedAmountInCents}
                              onChange={(val) => {
                                formState.proposedAmountInCents.set(val);
                                setUserHasEditedTotal(true);
                              }}
                            />
                          </div>
                        </div>
                        {formState.project.value && (
                          <div css={Css.cg2.bgGray200.p1.mt2.br8.$}>
                            <div css={Css.df.jcsb.mx2.my1.$}>
                              <StaticField label="Project Status" value={formState.project.value.status.name} />
                              <StaticField
                                label="Start Date"
                                value={formatWithYear(formState.project.value.startDate)}
                              />
                              <StaticField
                                label="Vertical Complete Date"
                                value={
                                  formState.project.value.verticalCompleteDate
                                    ? formatWithYear(formState.project.value.verticalCompleteDate)
                                    : undefined
                                }
                              />
                              <StaticField
                                label="Warranty Start Date"
                                value={
                                  formState.project.value.warrantyStartDate
                                    ? formatWithYear(formState.project.value.warrantyStartDate)
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div css={Css.df.cg2.$}>
                        <SelectField
                          label="Bill Type"
                          required
                          value={formState.type.value}
                          disabled={!formState.project.value || (isEdit && !isDraft)}
                          onSelect={(val) => {
                            formState.type.set(val);
                            // Clear out added commitments/PIs if the bill type changes
                            setSelectedCommitments([]);
                            formState.lineItems.set([]);
                            formState.billedInCents.set(null);
                            updateTotals(null);
                          }}
                          options={[
                            BillType.Standard,
                            BillType.Overhead,
                            BillType.RepairAndMaintenance,
                            BillType.Warranty,
                          ].map((type) => ({
                            id: type,
                            name: capitalCase(type),
                          }))}
                        />
                        <TradePartnerAutocompleteField
                          required
                          disabled={!formState.type.value || selectedCommitments.nonEmpty}
                          onSelect={async (val) => {
                            formState.tradePartner.set(val);
                          }}
                          autofillValue={formState.tradePartner.value?.name}
                        />
                      </div>
                      <div css={Css.df.cg2.$}>
                        <div css={Css.w50.$}>
                          <BoundTextField label="Invoice Number" field={formState.tradePartnerNumber} />
                        </div>
                        <div css={Css.w50.$}>
                          <div css={Css.gray700.ptPx(4).$}>Commitments</div>
                          {selectedCommitments.nonEmpty && (
                            <ToggleChips
                              values={getToggleChips(selectedCommitments)}
                              getLabel={({ accountingNumber }) => `#${accountingNumber}`}
                              onRemove={(item) => handleToggle(item, setSelectedCommitments)}
                              xss={Css.my1.$}
                            />
                          )}
                          <div css={Css.pyPx(9).mlPx(2).$}>
                            <Button
                              label="+ Add Commitment"
                              disabled={
                                !formState.project.value ||
                                !formState.tradePartner.value ||
                                formState.type.value !== BillType.Standard
                              }
                              onClick={() => {
                                openModal({
                                  content: (
                                    <BillCommitmentsModal
                                      selectedProject={formState.project.value}
                                      selectedTradePartner={formState.tradePartner.value}
                                      selectedCommitments={selectedCommitments}
                                      setSelectedCommitments={setSelectedCommitments}
                                    />
                                  ),
                                  size: "xl",
                                });
                              }}
                              size="sm"
                              variant="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div css={Css.df.cg2.$}>
                        <BoundBeamDateField
                          label="Bill Date"
                          required
                          field={formState.billDate}
                          onChange={async (val) => {
                            if (!bill && val) {
                              const billDate = new DateOnly(val);
                              formState.billDate.set(billDate);
                              const { data } = await calculatedDueDateQuery.refetch({
                                tradePartnerId: formState.tradePartner.value?.id,
                                billDate,
                              });
                              formState.dueDate.set(new DateOnly(data.tradePartner.billDueDateFor));
                            }
                          }}
                        />
                        <BoundBeamDateField label="Due Date" required field={formState.dueDate} />
                      </div>
                      <BoundTextAreaField label="Internal Description (optional)" field={formState.internalNote} />
                    </FormLines>
                  </div>
                  <div css={Css.bsDashed.gray300.bw1.my4.$} />
                  {!formState.type.value ||
                  !formState.project.value ||
                  (formState.type.value === BillType.Standard && selectedCommitments.isEmpty) ? (
                    <div>
                      <div css={Css.red600.mb2.$}>
                        To select or add line items, first select the bill type and project.
                      </div>
                      <div css={Css.smBd.gray600.$}>Line Items</div>
                      <div css={Css.gray400.$}> Available options will be loaded after commitments are selected. </div>
                    </div>
                  ) : isRepairOrWarranty(formState.type.value) ? (
                    <BilledInCentsField formState={formState} onChange={updateTotals} />
                  ) : (
                    <BillLineItemsTable
                      tableApi={tableApi}
                      formState={formState}
                      isEdit={isEdit}
                      onUpdateLineItemAmount={handleLineItemsAmountUpdate}
                    />
                  )}
                </div>
                <div css={Css.w50.$}>
                  <div css={Css.gray700.mt5.$}>Add bill attachments here</div>
                  {formState.documents.value?.nonEmpty ? (
                    <div css={Css.bgWhite.bshBasic.br8.mhPx(400).oa.$}>
                      <PdfViewer
                        hasHeader
                        assets={formState.documents.value.map(({ asset }) => asset)}
                        handlePdfDelete={(assetId) =>
                          formState.documents.remove(formState.documents.value.findIndex((d) => d.asset.id === assetId))
                        }
                        pdfPageWidth={getDynamicPdfVierwerWidth()}
                      />
                    </div>
                  ) : formState.project.value ? (
                    <DocumentUploader
                      xss={Css.mt1.bgBlue50.bcBlue700.h100.maxh("90vh").$}
                      documentType={DocumentType.Bill}
                      message="Drag & drop file"
                      multiple={true}
                      file={undefined}
                      onFinish={(file) => {
                        if (file && !formState.documents.value?.some(({ asset }) => asset.id === file.asset.id)) {
                          formState.documents.add(file);
                        }
                      }}
                      projectId={formState.project.value.id}
                      error={formState.documents.touched ? formState.documents.errors.join(" ") : undefined}
                    />
                  ) : (
                    // TODO: Remove this once we enable OCR for Bill PDF upload
                    <div css={Css.df.br8.mt1.bgBlue50.bcBlue700.h100.w100.bsDashed.bw1.$}>
                      <div css={Css.ma.df.cg1.red400.$}>
                        <Icon color={Palette.Red400} icon="cloud" />
                        <div css={Css.mya.$}>Select a Project to enable file upload</div>
                      </div>
                    </div>
                  )}
                  {bill && (
                    <div css={Css.mt3.ml2.$}>
                      <CommentFeed commentable={bill} />
                    </div>
                  )}
                </div>
              </div>
            </ResponsiveGridItem>
            <ResponsiveGridItem colSpan={1} />
          </ResponsiveGrid>
        </div>
      )}
    </Observer>
  );
}

export type BillFormLineItemOtherBills = {
  id: string;
  name: string;
  dueDate?: DateOnly | null | undefined;
  billedInCents: number;
  lineItems: {
    id: string;
    amountInCents: number;
    projectItem: {
      id: string;
      displayName: string;
    };
  }[];
  paidInCents: number;
  status: {
    __typename?: "BillStatusDetail";
    code: BillStatus;
  };
  type: {
    __typename?: "BillTypeDetail";
    code: BillType;
  };
};

export type BillFormLineItem = Omit<SaveBillLineItemInput, "costCode"> &
  Partial<Pick<BillEditor_CommitmentLineItemFragment, "pendingBilledInCents" | "costChangeInCents" | "owner">> &
  Partial<
    Pick<
      BillEditor_ProjectItemFragment,
      "uncommittedBudgetAmountInCents" | "revisedApprovedBudgetInCents" | "projectStage" | "task"
    >
  > & {
    displayName: string;
    bliId?: string;
    isPendingOrBilled?: boolean;
    bills?: BillFormLineItemOtherBills[];
  };

export type BillFormInput = Omit<
  SaveBillInput,
  "tradePartnerId" | "balanceInCents" | "documents" | "quickbooksId" | "lineItems" | "dueDate" | "billDate"
> & {
  documents: BillPage_DocumentFragment[];
  lineItems: BillFormLineItem[];
  project: ProjectAutocomplete_ProjectFragment;
  dueDate?: DateOnly | null;
  billDate?: DateOnly | null;
  tradePartner?: TradePartnerAutocomplete_TradePartnerFragment;
};

export type CreateBillFormState = ObjectState<BillFormInput>;

export const formConfig: ObjectConfig<BillFormInput> = {
  id: { type: "value" },
  project: { type: "value", rules: [required] },
  type: { type: "value", rules: [required] },
  tradePartner: { type: "value", rules: [required] },
  tradePartnerNumber: { type: "value", rules: [required] },
  billDate: { type: "value", rules: [required] },
  dueDate: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  proposedAmountInCents: {
    type: "value",
    rules: [({ value }) => (isDefined(value) ? undefined : "Total is required")],
  },
  documents: {
    type: "list",
    rules: [({ value }) => (value.nonEmpty ? undefined : "At least one document is required")],
    config: {
      id: { type: "value" },
      name: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          attachmentUrl: { type: "value" },
          contentType: { type: "value" },
          createdAt: { type: "value" },
          downloadUrl: { type: "value" },
          version: { type: "value" },
        },
      },
    },
  },
  isTradePartnerCredit: { type: "value" },
  billedInCents: { type: "value" },
  reviewReason: { type: "value" },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      bliId: { type: "value", readOnly: true },
      displayName: { type: "value" },
      costChangeInCents: { type: "value" },
      commitmentLineItemId: { type: "value" },
      projectItemId: { type: "value" },
      amountInCents: { type: "value" },
      pendingBilledInCents: { type: "value" },
      isPendingOrBilled: { type: "value" },
      uncommittedBudgetAmountInCents: { type: "value" },
      revisedApprovedBudgetInCents: { type: "value" },
      task: { type: "value" },
      owner: {
        type: "object",
        config: { id: { type: "value" }, accountingNumber: { type: "value" }, status: { type: "value" } },
      },
      bills: {
        type: "list",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          dueDate: { type: "value" },
          billedInCents: { type: "value" },
          lineItems: {
            type: "list",
            config: {
              id: { type: "value" },
              amountInCents: { type: "value" },
              projectItem: {
                type: "object",
                config: {
                  id: { type: "value" },
                  displayName: { type: "value" },
                },
              },
            },
          },
          paidInCents: { type: "value" },
          status: { type: "value" },
          type: { type: "value" },
        },
      },
    },
  },
};

export function mapBillToForm(bill: BillEditor_BillFragment): BillFormInput {
  const { lineItems, type, status, ...others } = bill;

  return {
    ...others,
    type: type.code,
    lineItems: isHeadless(type.code)
      ? lineItems.map((li) => ({
          id: li.id,
          amountInCents: li.amountInCents,
          projectItem: li.projectItem,
          bliId: li.id,
          displayName: li.projectItem.displayName,
          pendingBilledInCents: 0,
          isPendingOrBilled: bill.isPendingOrBilled,
          costChangeInCents: 0,
          projectItemId: li.projectItem.id,
          revisedApprovedBudgetInCents: li.projectItem.revisedApprovedBudgetInCents,
        }))
      : lineItems.map((bli) => ({
          ...bli,
          bliId: bli.id,
          displayName: bli.projectItem.displayName,
          pendingBilledInCents: bli.commitmentLineItem?.pendingBilledInCents,
          isPendingOrBilled: bill.isPendingOrBilled,
          commitmentLineItemId: bli.commitmentLineItem?.id,
          costChangeInCents: bli.commitmentLineItem?.costChangeInCents,
          owner: bli.commitmentLineItem?.owner,
          projectItemId: bli.projectItem.id,
          revisedApprovedBudgetInCents: bli.projectItem.revisedApprovedBudgetInCents,
          bills: bli.projectItem.bills,
          task: bli.projectItem.task,
        })),
  };
}

function mapCommitmentLikeToForm(commitmentLike: BillEditor_CommitmentLikeFragment): BillFormInput {
  return {
    type: BillType.Standard,
    project:
      "commitment" in commitmentLike
        ? commitmentLike.commitment.projectStage.project
        : commitmentLike.projectStage.project,
    tradePartner:
      ("commitment" in commitmentLike ? commitmentLike.commitment.tradePartner : commitmentLike.tradePartner) ||
      undefined,
    lineItems: [],
    documents: [],
  };
}

function isHeadless(billType: InputMaybe<BillType>) {
  return billType && [BillType.Overhead, BillType.RepairAndMaintenance, BillType.Warranty].includes(billType);
}

function isRepairOrWarranty(billType: InputMaybe<BillType>) {
  return billType === BillType.RepairAndMaintenance || billType === BillType.Warranty;
}

function hasUpdatedLineItems(
  billLineItems: BillEditor_BillLineItemFragment[],
  formStateLineItems: BillFormLineItem[],
): boolean {
  return formStateLineItems.some((fsli) => {
    const matchinglineItem = billLineItems.find((bli) => bli.id === fsli.id);
    return !matchinglineItem || matchinglineItem.amountInCents !== fsli.amountInCents;
  });
}
