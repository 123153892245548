import { Avatar, Css, IconButton, Palette, Tag } from "@homebound/beam";
import { formatDistance } from "date-fns";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { CommentStreamVisibility, Inbox_CommentFragment, InternalUser, UserType } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { sanitizeHtml } from "src/utils";

export type CommentCardProps = {
  comment: Inbox_CommentFragment;
  currentUser: InternalUser | null | undefined;
  isEditing: boolean;
  deleteComment: (comment: Inbox_CommentFragment) => void;
  editComment: (comment: Inbox_CommentFragment) => void;
  visibility?: CommentStreamVisibility;
};

export function CommentCard({ comment, currentUser, isEditing, deleteComment, editComment }: CommentCardProps) {
  const { id, author, html, createdAt, attachments } = comment;
  const canEditOrDelete = (author.internalUser && currentUser?.id === author.internalUser.id) || false;

  return (
    <div css={Css.df.fdc.py1.gap1.$}>
      <div data-testid={`commentCard:${id}`} css={Css.df.fdr.aic.gap1.smMd.$}>
        <div css={Css.df.aic.fg1.gap1.$}>
          <Avatar src={author.avatarUrl} name={author.name} />
          <span>{author.internalUser?.id === currentUser?.id ? "You" : author.name}</span>
          {author.type === UserType.TradePartner && <Tag type="caution" text="TRADE" />}
          <span css={Css.sm.gray700.$}>{formatDistance(createdAt, new Date(), { addSuffix: false })}</span>
        </div>
        <div css={Css.df.gap1.$}>
          {canEditOrDelete && (
            <IconButton
              compact
              icon="pencil"
              tooltip={isEditing ? "Editing" : "Edit"}
              color={isEditing ? Palette.Blue700 : Palette.Gray900}
              data-testid={`comment-${id}-edit`}
              onClick={() => editComment(comment)}
            />
          )}

          {/* TODO add a confirm delete modal*/}
          {canEditOrDelete && (
            <IconButton
              compact
              icon="trash"
              tooltip="Delete"
              disabled={isEditing}
              data-testid={`comment-${id}-delete`}
              onClick={() => deleteComment(comment)}
            />
          )}
        </div>
      </div>
      <div css={Css.wbbw.$} dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} />
      {attachments.nonEmpty && (
        <div css={Css.df.fdc.$}>
          <div css={Css.gray700.sm.my1.$}>Attachments ({attachments?.length})</div>
          <div>
            <AssetGallery assets={attachments.map((i) => i.asset)} display="vertical">
              {(openGallery) => (
                <>
                  {attachments.map(({ id, asset }) => (
                    <div key={id} css={Css.df.relative.mr1.mt1.$}>
                      <AssetPreview
                        asset={asset}
                        dimensions={{ width: 100, height: 100 }}
                        onClick={() => openGallery(asset)}
                      />
                    </div>
                  ))}
                </>
              )}
            </AssetGallery>
          </div>
        </div>
      )}
    </div>
  );
}
