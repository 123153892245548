import { Css } from "@homebound/beam";
import { useLayoutEffect, useRef } from "react";
import useQueryString from "src/hooks/useQueryString";

export enum TaskDetailCardType {
  Reference = "Reference",
  Comments = "Comments",
  Scheduling = "Scheduling",
  History = "History",
}

type TaskDetailCardProps = {
  children: React.ReactNode;
  gridRow: number;
  gridColumn: number;
  cardType: TaskDetailCardType;
  maxHeight?: number;
};

export function TaskDetailCard(props: TaskDetailCardProps) {
  const { children, gridRow, gridColumn, cardType, maxHeight } = props;

  const [{ scrollIntoView }] = useQueryString<{ scrollIntoView: TaskDetailCardType }>();
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (scrollIntoView === cardType && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [cardType, scrollIntoView]);

  const maybeMaxHeight = maxHeight ? { ...Css.maxhPx(maxHeight).$ } : {};

  return (
    <div ref={ref} css={{ ...Css.gc(gridColumn).gr(gridRow).df.fdc.bgWhite.br8.p3.$, ...maybeMaxHeight }}>
      {children}
    </div>
  );
}
