import { Css, useComputed } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { UppyUploader } from "src/components";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { WithActions } from "src/components/to-dos/WithActions";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { MaterialPageFormState, VariantImageAsset } from "../MaterialPage";

export type MaterialImageUploaderProps = {
  formState: MaterialPageFormState;
};

export function MaterialImageEditor({ formState }: MaterialImageUploaderProps) {
  const readOnly = useComputed(() => formState.readOnly, [formState]);

  function saveImage(file: UppyFile) {
    const downloadUrl = file.meta.downloadUrl as string;
    formState.images.add({
      sortOrder: (formState.images.value?.length ?? 0) + 1,
      downloadUrl,
      attachmentUrl: downloadUrl,
      asset: {
        fileName: file.name,
        s3Key: file.meta.s3Key as string,
        contentType: file.type,
        sizeInBytes: file.size,
      },
    });
  }

  function featureImage(os: ObjectState<VariantImageAsset>) {
    const others = formState.images.rows.filter((i) => i !== os);
    os.sortOrder.set(1);
    others.forEach((im, i) => im.sortOrder.set(i + 2));
  }

  const images = useComputed(
    () =>
      (formState.images.rows ?? [])
        .map((i) => ({
          image: i,
          asset: {
            ...i.value.asset,
            attachmentUrl: i.attachmentUrl.value,
            downloadUrl: i.downloadUrl.value,
          } as AssetInfoFragment,
        }))
        .sortBy((i) => i.image.sortOrder.value ?? 0),
    [formState],
  );

  const featured = images.first;

  return (
    <div css={Css.df.fdc.aifs.$}>
      <div css={Css.df.fdc.$}>
        {(featured || !images.isEmpty) && (
          <div css={Css.mb2.df.cg1.$}>
            <AssetGallery assets={images.map((i) => i.asset)} display="horizontal">
              {(openGallery) => (
                <div css={Css.df.fdc.gap1.$}>
                  {featured && (
                    <WithActions
                      right={{ icon: "starFilled", tooltip: "Featured", action: () => {} }}
                      footer={{ text: featured.image.asset.fileName.value! }}
                      onClick={() => openGallery(featured.asset)}
                    >
                      <AssetPreview asset={featured.asset} dimensions={{ width: 330, height: 240 }} />
                    </WithActions>
                  )}
                  <div css={Css.df.maxwPx(330).oya.$}>
                    {images.map(({ image, asset }, i) => {
                      return (
                        <div key={asset.id}>
                          <WithActions
                            left={
                              readOnly
                                ? undefined
                                : {
                                    icon: "trash",
                                    action: () => formState.images.remove(image.value),
                                  }
                            }
                            right={
                              readOnly && image.sortOrder.value !== 1
                                ? undefined
                                : {
                                    icon: image.sortOrder.value === 1 ? "starFilled" : "star",
                                    tooltip: image.sortOrder.value === 1 ? "Featured" : "Feature",
                                    action: readOnly ? () => {} : () => featureImage(image),
                                  }
                            }
                            footer={{ text: image.asset.fileName.value! }}
                            onClick={() => openGallery(asset)}
                          >
                            <AssetPreview asset={asset} dimensions={{ width: 160, height: 180 }} />
                          </WithActions>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </AssetGallery>
          </div>
        )}
        {!readOnly && (
          <div css={Css.mxa.$}>
            <UppyUploader
              onFinish={saveImage}
              allowedFileTypes={["image/jpeg", "image/png", "image/gif"]}
              dragDropWidth={330}
              dragDropHeight={165}
            />
          </div>
        )}
      </div>
    </div>
  );
}
