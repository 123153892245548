import { Button, Css, Icon, ModalBody, ModalFooter, ModalHeader, Tag, Tooltip, useModal } from "@homebound/beam";
import { FormattedDate } from "src/components";
import {
  UpdatesAvailableProductOfferingFragment,
  useAcceptProductOfferingUpdatesMutation,
} from "src/generated/graphql-types";
import { pluralize } from "src/utils";

type UpdatesAvailableButtonProps = {
  productOfferingId: string;
  updates: UpdatesAvailableProductOfferingFragment[];
  copyInProgress?: boolean;
  showText?: boolean;
};

export function UpdatesAvailableButton({
  copyInProgress,
  updates,
  productOfferingId,
  showText = true,
}: UpdatesAvailableButtonProps) {
  const { openModal } = useModal();

  if (copyInProgress || updates.length === 0) {
    return null;
  }

  return (
    <button
      data-testid="updatesAvailableButton"
      onClick={() =>
        openModal({
          content: <UpdatesAvailableModal updates={updates} productOfferingId={productOfferingId} />,
        })
      }
    >
      <Tooltip
        title={updates.map((u) => `${u.source.readyPlan.name} - v${u.source.version}`).join(", ")}
        placement="bottom"
      >
        <Tag
          type="caution"
          text={
            showText ? <div css={Css.ttn.smSb.$}>Updates available</div> : <Icon icon="arrowFromBottom" inc={1.5} />
          }
          icon={showText ? "arrowFromBottom" : undefined}
          preventTooltip
        />
      </Tooltip>
    </button>
  );
}

type UpdatesAvailableModalProps = UpdatesAvailableButtonProps;

export function UpdatesAvailableModal({ updates, productOfferingId }: UpdatesAvailableModalProps) {
  const [acceptProductOfferingUpdates] = useAcceptProductOfferingUpdatesMutation();
  const { closeModal } = useModal();

  async function onAcceptUpdate() {
    await acceptProductOfferingUpdates({
      variables: { input: { productOfferingId, productOfferingUpdateIds: updates.map((u) => u.id) } },
    });
    closeModal();
  }
  return (
    <>
      <ModalHeader>Updates Available</ModalHeader>
      <ModalBody>
        <div css={Css.mb3.$}>
          Would you like to pull {pluralize(updates.length, "this update", "these updates")} updates into a new draft
          for you to review and edit?
        </div>
        <div css={Css.df.fdc.gap2.$}>
          {updates.map((u) => (
            <div key={u.id}>
              <div css={Css.sm.df.fdr.gap1.$}>
                <span css={Css.smMd.$}>{u.source.readyPlan.name}</span>
                <span>v{u.source.version} -</span>
                <FormattedDate date={u.createdAt} dateFormatStyle="long" />
              </div>
              {u.source.versionNotes && <div css={Css.sm.$}>"{u.source.versionNotes}"</div>}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="secondary" />
        <Button label={`Accept ${pluralize(updates.length, "Update")}`} onClick={onAcceptUpdate} />
      </ModalFooter>
    </>
  );
}
