import { ApolloQueryResult } from "@apollo/client";
import {
  Avatar,
  collapseColumn,
  column,
  Css,
  dateColumn,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  selectColumn,
  simpleHeader,
  useBreakpoint,
  useComputed,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { dateCell, emptyCellDash, priceCell, tagCell } from "src/components";
import {
  ApprovalFilter,
  ApprovalsDashboard_ApprovalFragment,
  ApprovalsDashboard_ApproverFragment,
  ApprovalsDashboardQuery,
  ApprovalStatus,
  ApproverStatus,
  DateOperation,
  useApprovalsDashboardQuery,
} from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { ApprovalReviewActionButtons } from "src/routes/components/Approval/ApprovalReviewButtons";
import {
  MonitorApprovalSuperdrawerDeepLinks,
  useApprovalSuperDrawer,
} from "src/routes/components/Approval/ApprovalSuperDrawer";
import { TableActions } from "src/routes/layout/TableActions";
import {
  approvalIsOverdue,
  approvalStatusToTagTypeMapper,
  count,
  daysAgo,
  foldEnum,
  foldGqlUnion,
  queryResult,
} from "src/utils";
import { DateOnly } from "src/utils/dates";
import { PersonalDashboardTitle } from "../../components/PersonalDashboardTitle";
import { useDashboardFilterContext } from "../../DashboardFilterContext";
import { BulkApprovalAction } from "./BulkApprovalAction";

export function ApprovalsDashboard() {
  const filter = useDashboardFilterToApprovalFilters();
  const query = useApprovalsDashboardQuery({ variables: { filter } });
  return queryResult(query, ({ approvals }) => (
    <ApprovalTable approvals={approvals?.approvals ?? []} refetch={query.refetch} />
  ));
}

type ApprovalTableProps = {
  approvals: ApprovalsDashboard_ApprovalFragment[];
  refetch: () => Promise<ApolloQueryResult<ApprovalsDashboardQuery>>;
};

export function ApprovalTable({ approvals, refetch }: ApprovalTableProps) {
  const api = useGridTableApi<Row>();
  const { sm } = useBreakpoint();
  const rows = useCreateRows(approvals);
  const columns = useCreateColumns();
  const { openModal } = useModal();
  const { id: currentUserId } = useCurrentUser();

  const selectedApprovalApprovers = useComputed(
    () =>
      api
        .getSelectedRows("approval")
        // The actual approvAL is moot. We act on approvERs
        .flatMap((row) => row.data.approvers)
        // But only on "my" Approvers
        .filter((approver) => approver.user.id === currentUserId)
        // And sanity check we're acting only on Approvers in non-finalized states
        .filter((approver) => [ApproverStatus.ActionRequired, ApproverStatus.Pending].includes(approver.status.code))
        // And disallow approval actions for approvers that must sign in panda doc
        .filter((approver) => !approver.requireSignature),
    [api],
  );

  const changeRequestApprovalApproversSelected = useComputed(
    () =>
      api
        .getSelectedRows("approval")
        // The actual approvAL is moot. We act on approvERs
        .flatMap((row) => row.data.approvers)
        // But only on "my" Approvers
        .filter((approver) => approver.user.id === currentUserId)
        // And check if any selected Approvals are Change Requests
        .filter((approver) => approver.approval.subject.__typename === "ChangeRequest").nonEmpty,
    [api],
  );

  const refetchAndClearSelections = async () => {
    await refetch();
    api.clearSelections();
  };

  return (
    <div css={Css.df.fdc.h100.p1.maxwPx(1500).ma.if("sm").pt1.$}>
      <div css={Css.df.br0.pb2.gap1.if("sm").pb1.$}>
        <PersonalDashboardTitle title="Approvals" hideIcon />
        <MonitorApprovalSuperdrawerDeepLinks />
        <TableActions onlyRight xss={Css.pb0.$}>
          <ApprovalReviewActionButtons
            disabled={selectedApprovalApprovers.isEmpty}
            onApprove={() =>
              openModal({
                content: <BulkApprovalAction.Approve approvers={selectedApprovalApprovers} />,
                onClose: refetchAndClearSelections,
              })
            }
            onReject={() =>
              openModal({
                content: <BulkApprovalAction.Reject approvers={selectedApprovalApprovers} />,
                onClose: refetchAndClearSelections,
              })
            }
            onRequestChanges={() =>
              openModal({
                content: <BulkApprovalAction.RequestChanges approvers={selectedApprovalApprovers} />,
                onClose: refetchAndClearSelections,
              })
            }
            disableRequestChanges={
              changeRequestApprovalApproversSelected ? "Cannot Request Changes on Change Request Approvals" : undefined
            }
          />
        </TableActions>
      </div>
      <div css={Css.fg1.$}>
        <GridTable
          as="virtual"
          api={api}
          columns={columns}
          rows={rows}
          style={{ rowHeight: sm ? "flexible" : "fixed", bordered: true, allWhite: true }}
          sorting={{ on: "client", initial: ["name", "ASC"] }}
          fallbackMessage="There are no approvals that match your current filter selection."
        />
      </div>
    </div>
  );
}

type Header = { kind: "header" };
type Approval = { kind: "approval"; data: ApprovalsDashboard_ApprovalFragment };
type ApprovalGroup = { kind: "approvalGroup"; data: ApprovalsDashboard_ApprovalFragment[] };
type Row = Header | Approval | ApprovalGroup;

const useCreateColumns = (): GridColumn<Row>[] => {
  const openApproval = useApprovalSuperDrawer();
  const { sm } = useBreakpoint();
  return useMemo(
    () =>
      [
        collapseColumn<Row>({
          header: emptyCell,
          // approvalGroup: default collapseColumn behavior
          approval: emptyCell,
          initExpanded: false,
        }),
        selectColumn({}),
        column<Row>({
          header: "No.",
          approvalGroup: emptyCellDash,
          approval: (approval) => ({
            content: () => approval.id.split(":")[1],
            // Radhi wants both `No.` and `Name` to open the Superdrawer
            onClick: () => openApproval(approval.id),
          }),
          initVisible: false,
          w: "60px",
          align: "left",
        }),
        column<Row>({
          header: "Name",
          id: "name",
          approvalGroup: ([a]) =>
            foldGqlUnion(a.source!, {
              BidContractRevision: (bcr) => `${bcr.bidContract.name} V${bcr.version}`,
              ItemTemplate: (it) => `${it.name} ${it.version}`,
              else: () => a.id,
            }),
          approval: (a) => ({
            content: () => a.subject.name,
            // Radhi wants both `No.` and `Name` to open the Superdrawer
            onClick: () => openApproval(a.id),
            value: a.subject.name,
          }),
          mw: "120px",
        }),
        !sm &&
          column<Row>({
            header: "Project",
            approvalGroup: (approvals) => count(approvals, "Project"),
            approval: (a) =>
              a.subject.__typename === "ChangeRequest" && a.subject.scopes.length === 1
                ? a.subject.scopes.first?.target.name
                : a.rootProject?.name,
            mw: "120px",
          }),
        !sm &&
          column<Row>({
            header: "Partner",
            clientSideSort: false,
            approvalGroup: () => emptyCell,
            approval: (a) =>
              foldGqlUnion(a.subject, {
                ChangeEvent: (ce) =>
                  ce.impactedTrades
                    .flatMap((tp) => tp.name)
                    .unique()
                    .join(", "),
                Bill: (b) => b.tradePartner.name,
                Invoice: (inv) => inv.project.primaryHomeowner?.fullName,
                ChangeRequest: () => emptyCellDash,
              }) || emptyCellDash,
            mw: "120px",
          }),
        !sm &&
          column<Row>({
            header: "Type",
            approvalGroup: ([a]) => capitalCase(a.source?.__typename!),
            approval: (a) => capitalCase(a.subject.__typename ?? "Change Event"),
            mw: "120px",
          }),
        !sm &&
          column<Row>({
            header: "Requested by",
            approvalGroup: (approvals) => ({
              content: () => <ApproverAvatar user={approvals.first?.requestedBy} />,
              value: approvals.first?.requestedBy?.name,
            }),
            approval: (a) => ({
              content: () => <ApproverAvatar user={a.requestedBy} />,
              value: a.requestedBy?.name,
            }),
            mw: "160px",
          }),
        !sm &&
          dateColumn<Row>({
            header: "Requested date",
            approvalGroup: (approvals) => dateCell(approvals.first?.requestedAt),
            approval: (a) => dateCell(a.requestedAt, { xss: Css.xsMd.$ }),
            mw: "140px",
          }),
        !sm &&
          dateColumn<Row>({
            header: "Due date",
            approvalGroup: (approvals) => dateCell(approvals.first?.dueOn),
            approval: (a) => {
              const cell = dateCell(a.dueOn, { xss: Css.xsMd.$ });
              const content = cell.content;
              return {
                ...cell,
                content: () => (
                  <div css={Css.df.fdr.gap1.$}>
                    {content()}
                    {a.dueOn && approvalIsOverdue(a) && <span css={Css.tinyMd.red600.$}>{daysAgo(a.dueOn.date)}</span>}
                  </div>
                ),
              };
            },
            mw: "140px",
          }),
        column<Row>({
          header: "Waiting on",
          approvalGroup: (approvals) => {
            const user = approvals.everyHasSame((approval) => waitingOnApproverUser(approval)?.id)
              ? waitingOnApproverUser(approvals.first)
              : undefined;
            return user
              ? {
                  content: () => <ApproverAvatar user={user} />,
                  sortValue: user.name,
                }
              : "Mixed";
          },
          approval: (a) => ({
            content: () => <ApproverAvatar user={waitingOnApproverUser(a)} />,
            value: waitingOnApproverUser(a)?.name,
          }),
          mw: "160px",
        }),
        column<Row>({
          header: "Budget impact",
          approvalGroup: (approvals) =>
            priceCell({
              // "amount" is aliased via the GQL fragment to whatever subject-specific field needs to be shown in this col
              valueInCents: approvals.sum((a) => a.subject.amount),
              displayDirection: true,
              invertColors: true,
              zeroIs: "neutral",
            }),
          approval: (approval) =>
            priceCell({
              // "amount" is aliased via the GQL fragment to whatever subject-specific field needs to be shown in this col
              valueInCents: approval.subject.amount,
              displayDirection: true,
              invertColors: true,
              zeroIs: "neutral",
            }),
          align: "right",
          mw: "120px",
        }),
        column<Row>({
          header: "Status",
          approvalGroup: (approvals) =>
            approvals.everyHasSame((a) => a.status.code)
              ? tagCell(...approvalStatusToTagTypeMapper(approvals.first!))
              : emptyCellDash,
          approval: (approval) => tagCell(...approvalStatusToTagTypeMapper(approval)),
          mw: "120px",
        }),
      ].filter(Boolean) as GridColumn<Row>[],
    [openApproval, sm],
  );
};

const useCreateRows = (approvals: ApprovalsDashboard_ApprovalFragment[]): GridDataRow<Row>[] =>
  useMemo(() => {
    const { undefined: ungroupedApprovals = [], ...rest } = approvals.groupBy((a) => a.source?.id!);
    return [
      simpleHeader,
      ...ungroupedApprovals.map<GridDataRow<Approval>>((a) => ({
        data: a,
        id: a.id,
        kind: "approval",
      })),
      ...Object.values(rest).map<GridDataRow<ApprovalGroup>>((groupedApprovals) => ({
        kind: "approvalGroup",
        id: groupedApprovals.first?.source?.id ?? `group-${groupedApprovals.first?.id}`,
        data: groupedApprovals,
        children: groupedApprovals.map<GridDataRow<Approval>>((a) => ({
          data: a,
          id: a.id,
          kind: "approval",
        })) as any,
        initCollapsed: true,
      })),
    ].sortBy((el) =>
      foldEnum(el.kind, {
        approval: () => (el.data as ApprovalsDashboard_ApprovalFragment).dueOn?.valueOf() ?? Number.NEGATIVE_INFINITY,
        approvalGroup: () =>
          (el.data as ApprovalsDashboard_ApprovalFragment[]).first?.dueOn?.valueOf() ?? Number.NEGATIVE_INFINITY,
        else: () => Number.NEGATIVE_INFINITY,
      }),
    );
  }, [approvals]);

type ApproverAvatarProps = {
  user:
    | ApprovalsDashboard_ApproverFragment["user"] // Requestor
    | ApprovalsDashboard_ApprovalFragment["requestedBy"] // Approver user
    | undefined; // possibly undefined if it's a system-generated approval
};

function ApproverAvatar({ user }: ApproverAvatarProps) {
  return !user ? (
    (emptyCellDash as any)
  ) : (
    <Avatar
      name={user.name}
      // @ts-ignore
      src={user.avatar}
      size="sm"
      showName
    />
  );
}

function useDashboardFilterToApprovalFilters(): ApprovalFilter {
  const { id: currentUserId } = useCurrentUser();
  const {
    filter: { internalUser, project, requested, approvalStatus, approvalSubjectType },
  } = useDashboardFilterContext();

  return useMemo<ApprovalFilter>(() => {
    // filters are undefined if unset or cleared, even for `Assigned To: Nikki (Me)`. So see if any are defined:
    const internalUserIsDefault = isEqual(internalUser, [currentUserId]) || !internalUser;
    const isDefaultFilters = !(!internalUserIsDefault || project || requested || approvalStatus || approvalSubjectType);
    const isOverdue = approvalStatus === "overdue";

    return isDefaultFilters
      ? { relevantTo: [currentUserId] }
      : {
          ...(project?.nonEmpty && { rootProject: project }),
          ...(internalUser?.nonEmpty && { approvers: { user: internalUser } }),
          ...(requested?.nonEmpty && { requestedBy: requested }),
          ...(approvalStatus && { status: [approvalStatus as ApprovalStatus] }),
          ...(approvalSubjectType && { subjectType: approvalSubjectType }),
          ...(isOverdue && {
            status: [ApprovalStatus.Requested],
            dueOn: { op: DateOperation.OnOrBefore, value: new DateOnly(new Date()) },
          }),
        };
  }, [approvalStatus, approvalSubjectType, internalUser, currentUserId, project, requested]);
}

function waitingOnApproverUser(approval: ApprovalsDashboard_ApprovalFragment | undefined) {
  if (!approval) return;
  return approval.approvers.find(findActionReqApprover)?.user;
}

function findActionReqApprover(approver: ApprovalsDashboard_ApproverFragment): boolean {
  return approver.status.code === ApproverStatus.ActionRequired;
}
