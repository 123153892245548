import { Button, Checkbox, Css, HasIdAndName, MultiSelectField, useSnackbar } from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { createListViewScheduleUrl } from "src/RouteUrls";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  DraftPlanTaskInput,
  Maybe,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleDraftMode_TradePartnerContactFragment,
  ScheduleDraftMode_TradePartnerFragment,
  TradePartnerTaskStatus,
  useSaveDraftPlanScheduleMutation,
} from "src/generated/graphql-types";
import { getSchedulingOrOtherMarketContactsForTradePartners } from "../utils";
import { useDraftScheduleStore } from "./scheduleDraftStore";

type TradeConfirmPublishStepProps = {
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
  scheduleParentId: string;
};

export function TradeConfirmPublishStep({ scheduleParentId, draftTasks }: TradeConfirmPublishStepProps) {
  const { triggerNotice } = useSnackbar();
  const { prevStep } = useStepperContext();
  const history = useHistory();

  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const resetRequiredScheduleFlags = useDraftScheduleStore((state) => state.resetRequiredTaskScheduleFlag);
  const requiredScheduleFlags = useDraftScheduleStore((state) => state.requiredScheduleFlags);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const [saveDraftSchedule] = useSaveDraftPlanScheduleMutation();
  // local state to handle selecting/unselecting trade partner contacts to send emails to
  const [selectedTradePartnerContacts, setSelectedTradePartnersContacts] = useState<Record<string, string[]>>({});

  // tasks that have been manually scheduled but not confirmed by the trade partner will be used to send tradePartnerAvailabilityRequests
  const unConfirmedPinnedTasks = useMemo(() => {
    return getTasksForTradePartnerAvailabilityRequests(
      draftTasks,
      draftTaskChanges,
      (draftTask) => draftTask.tradePartnerStatus.code !== TradePartnerTaskStatus.Confirmed,
      (taskInput, draftTask) => taskInput.isManuallyScheduled && taskInput.id === draftTask.id,
    );
  }, [draftTasks, draftTaskChanges]);

  // tasks that have been confirmed and have a new start date will be used to send tradePartnerAvailabilityRequests
  const confirmedTasksToBeRescheduled = useMemo(() => {
    return getTasksForTradePartnerAvailabilityRequests(
      draftTasks,
      draftTaskChanges,
      (draftTask) => draftTask.tradePartnerStatus.code === TradePartnerTaskStatus.Confirmed,
      (taskInput, draftTask) => taskInput.id === draftTask.id && taskInput.earliestStartDate !== draftTask.startDate,
    );
  }, [draftTasks, draftTaskChanges]);

  // Create a map of trade partners with two lists (tasks and trade partners) to easily pluck out data
  const groupedTasks: GroupedTasks = useMemo(() => {
    const tasksToBeSent = [...unConfirmedPinnedTasks, ...confirmedTasksToBeRescheduled];
    // first group by the trade partner id
    const groupedById = tasksToBeSent.groupBy((task) => task.tradePartner?.id ?? "");
    // then create two children arrays, one for the trade partner and one for the tasks
    return Object.entries(groupedById).map(([tradePartnerId, tasks]) => ({
      tradePartner: tasks.find((task) => task.tradePartner?.id === tradePartnerId)?.tradePartner,
      tasks: tasks.map((task) => ({
        id: task.id,
        name: task.name,
      })),
    }));
  }, [unConfirmedPinnedTasks, confirmedTasksToBeRescheduled]);

  const tradePartnerAvailabilityRequestInput = useMemo(
    () =>
      groupedTasks.flatMap(({ tasks, tradePartner }) => {
        const contactIds = selectedTradePartnerContacts[tradePartner?.id ?? ""];

        if (contactIds && contactIds.nonEmpty) {
          // Map tasks to create trade partner availability request input shape
          return tasks.map((task) => ({
            taskId: task.id,
            tradePartnerId: tradePartner?.id ?? "",
            tradePartnerContactIds: contactIds,
          }));
        }

        // Return an empty array if no contacts are selected for the trade partner
        return [];
      }),
    [groupedTasks, selectedTradePartnerContacts],
  );

  const draftTaskChangesInput = useMemo(
    () => [
      ...draftTaskChanges,
      ...requiredScheduleFlags,
      // remove unnecessary fields from sending to the backend, merge the user added flags with draft changes
      ...userAddedScheduleFlags.map(({ taskId, taskName, title, scheduleFlagReasonType, ...others }) => ({
        id: taskId,
        scheduleFlags: [{ ...others }],
      })),
    ],
    [draftTaskChanges, requiredScheduleFlags, userAddedScheduleFlags],
  );

  const saveAndPublishSchedule = useCallback(
    async (withTradeUpdates = false) => {
      await saveDraftSchedule({
        variables: {
          input: {
            scheduleParentId,
            draftTaskChanges: draftTaskChangesInput,
            ...(withTradeUpdates && {
              tradePartnerAvailabilityRequests: tradePartnerAvailabilityRequestInput,
            }),
          },
        },
      });
      history.push(createListViewScheduleUrl(scheduleParentId));
      triggerNotice({ message: "Draft schedule changes have been saved" });
      if (withTradeUpdates) {
        triggerNotice({ message: "Scheduling emails have been sent to Trade Partners." });
      }
    },
    [
      saveDraftSchedule,
      scheduleParentId,
      history,
      triggerNotice,
      draftTaskChangesInput,
      tradePartnerAvailabilityRequestInput,
    ],
  );

  const onBackSelect = useCallback(() => {
    resetRequiredScheduleFlags(requiredScheduleFlags);
    prevStep();
  }, [prevStep, resetRequiredScheduleFlags, requiredScheduleFlags]);

  // Handler function to update trade partner contacts on select
  const handleSelectedTradePartner = useCallback(
    (selected: boolean, tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined) => {
      if (!tradePartner) return;

      const { schedulingContacts, otherContacts } = getSchedulingOrOtherMarketContactsForTradePartners(
        tradePartner.marketContacts,
      );

      setSelectedTradePartnersContacts((prevState) => {
        const newState = { ...prevState };
        if (selected) {
          newState[tradePartner?.id ?? ""] = schedulingContacts.nonEmpty ? schedulingContacts : otherContacts;
        } else {
          // Remove the trade partner key/value from local state
          delete newState[tradePartner?.id ?? ""];
        }

        return newState;
      });
    },
    [setSelectedTradePartnersContacts],
  );

  function shouldDisableTradePartnerButton() {
    // first rule is if there are no selected trade partners then disable
    if (Object.keys(selectedTradePartnerContacts).isEmpty) return true;

    const hasAllContacts = Object.values(selectedTradePartnerContacts).every((contactIds) => contactIds.nonEmpty);
    // Disable the button if any selected trade partner is missing contacts
    return !hasAllContacts;
  }

  return (
    <div css={Css.maxwPx(1200).ma.tac.mt6.$} data-testid="tradeConfirmPublishStep">
      <h1 css={Css.xl3Sb.$}>Select Trades to Email</h1>
      <p css={Css.base.my2.mb5.$}>
        The following Trade Partners are associated to the tasks that have been updated. Select the ones that you want
        to send emails to. The emails will inform trades of the date that they should be available for their assigned
        task(s).
      </p>
      {groupedTasks.isEmpty ? (
        <TradePartnerAvailabilityRequestEmptyState />
      ) : (
        <div css={Css.dg.gtc("1fr 1fr").bgWhite.p4.wPx(731).br8.ma.gap2.$}>
          <span css={Css.smSb.tal.$}>Trade Partner</span>
          <span css={Css.smSb.tal.$}>Contact</span>
          {groupedTasks.map(({ tasks, tradePartner }) => {
            const tradePartnerContacts = tradePartner?.contacts.unique() ?? [];
            return (
              <>
                <div css={Css.df.fdc.gap2.$} key={tradePartner?.id}>
                  <TradePartnerTaskColumn
                    tasks={tasks}
                    tradePartner={tradePartner}
                    handleSelectedTradePartner={handleSelectedTradePartner}
                    tradePartnerContacts={tradePartnerContacts}
                    selectedTradePartnerContacts={selectedTradePartnerContacts}
                  />
                </div>
                <div css={Css.df.fdc.gap2.$} key={`${tradePartner?.id}-contact`}>
                  <TradePartnerContactColumn
                    tradePartner={tradePartner}
                    selectedTradePartnerContacts={selectedTradePartnerContacts}
                    setSelectedTradePartnersContacts={setSelectedTradePartnersContacts}
                    tradePartnerContacts={tradePartnerContacts}
                  />
                </div>
              </>
            );
          })}
        </div>
      )}
      <StepActions>
        <>
          <Button label="Back" onClick={onBackSelect} variant="secondary" />
          <Button label="Skip and Publish" onClick={() => saveAndPublishSchedule()} variant="tertiary" />
          <Button
            label="Send Emails to Trades"
            onClick={() => saveAndPublishSchedule(true)}
            disabled={shouldDisableTradePartnerButton()}
            size="md"
          />
        </>
      </StepActions>
    </div>
  );
}

function TradePartnerAvailabilityRequestEmptyState() {
  return (
    <div
      css={
        Css.df.jcc.aic.bgGray200.gray700.p4
          .wPx(731)
          .hPx(515)
          .br8.boxShadow("0px 4px 8px 0px rgba(53, 53, 53, 0.08), 0px 2px 16px 0px rgba(53, 53, 53, 0.03)").ma.gap3.smMd
          .$
      }
      data-testid="tradePartnerAvailabilityRequestEmptyState"
    >
      The changes made do not require any Trade <br /> Partner availability updates.
    </div>
  );
}

type GroupedTasks = {
  tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined;
  tasks: HasIdAndName[];
}[];

type TradePartnerTaskColumnProps = {
  tasks: HasIdAndName[];
  tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined;
  handleSelectedTradePartner: (
    selected: boolean,
    tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined,
    tasks: HasIdAndName[],
  ) => void;
  tradePartnerContacts: ScheduleDraftMode_TradePartnerContactFragment[];
  selectedTradePartnerContacts: Record<string, string[]>;
};

function TradePartnerTaskColumn({
  tasks,
  tradePartner,
  handleSelectedTradePartner,
  tradePartnerContacts,
  selectedTradePartnerContacts,
}: TradePartnerTaskColumnProps) {
  return (
    <div css={Css.tal.dg.gtc("auto").gap2.$}>
      <div css={Css.gap1.df.fdc.$} key={tradePartner?.id}>
        <label css={Css.df.aic.gap1.sm.gray900.$}>
          <Checkbox
            label=""
            checkboxOnly
            selected={!!(tradePartner && selectedTradePartnerContacts[tradePartner.id])}
            onChange={(selected) =>
              // handles adding the trade partner availability request for the (grouped) tasks
              handleSelectedTradePartner(selected, tradePartner, tasks)
            }
            disabled={tradePartnerContacts.isEmpty}
          />
          {tradePartner?.name ? <span css={Css.sm.gray900.$}>{tradePartner.name}</span> : "No Trade Partner"}
        </label>
        {tasks.map((task) => (
          <div css={Css.sm.gray700.ml3.truncate.$} key={task.id}>
            {task.name}
          </div>
        ))}
      </div>
    </div>
  );
}

type TradePartnerContactColumnProps = {
  tradePartner: ScheduleDraftMode_TradePartnerFragment | null | undefined;
  selectedTradePartnerContacts: Record<string, string[]>;
  setSelectedTradePartnersContacts: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  tradePartnerContacts: ScheduleDraftMode_TradePartnerContactFragment[];
};

function TradePartnerContactColumn({
  tradePartner,
  setSelectedTradePartnersContacts,
  selectedTradePartnerContacts,
  tradePartnerContacts,
}: TradePartnerContactColumnProps) {
  return (
    <div css={Css.tal.df.fdc.gap2.$}>
      {tradePartnerContacts?.nonEmpty ? (
        <MultiSelectField
          label="tradePartnerContactIds"
          labelStyle="hidden"
          getOptionLabel={({ name, email }) => `${name}, (${email})`}
          getOptionValue={({ id }) => id}
          onSelect={(selectedContacts) => {
            setSelectedTradePartnersContacts((prevState) => ({
              ...prevState,
              [tradePartner?.id ?? ""]: selectedContacts,
            }));
          }}
          options={tradePartnerContacts ?? []}
          values={tradePartner?.id ? selectedTradePartnerContacts[tradePartner.id] : []}
          compact
        />
      ) : (
        <span>No contact information available for this trade partner</span>
      )}
    </div>
  );
}

// helper function to filter out tasks that need to be sent a trade partner availability request
function getTasksForTradePartnerAvailabilityRequests(
  draftTasks: ScheduleDraftMode_PlanTaskFragment[],
  draftTaskChanges: DraftPlanTaskInput[],
  statusCheck: (draftTask: ScheduleDraftMode_PlanTaskFragment) => Maybe<boolean>,
  draftChangesCheck: (taskInput: DraftPlanTaskInput, draftTask: ScheduleDraftMode_PlanTaskFragment) => Maybe<boolean>,
) {
  return draftTasks.filter(
    (draftTask) =>
      statusCheck(draftTask) && draftTaskChanges.some((taskInput) => draftChangesCheck(taskInput, draftTask)),
  );
}
