// FullCalendar@5 imports are order dependent. We should upgrade to FullCalendar@6, which fixes this issue. Once upgraded
// we can remove this workaround and organize imports normally.
import "@fullcalendar/react/dist/vdom"; // organize-imports-ignore
import FullCalendar, { CalendarOptions } from "@fullcalendar/react";

// eslint-disable-next-line prettier/prettier
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Css, Palette } from "@homebound/beam";

import { ReactNode, RefObject } from "react";

type WrappedFullCalendarProps = Pick<
  CalendarOptions,
  | "dateClick"
  | "events"
  | "eventClick"
  | "eventContent"
  | "eventResize"
  | "eventDrop"
  | "selectable"
  | "unselectAuto"
  | "unselectCancel"
  | "dayMaxEvents"
  | "editable"
> & {
  calendarRef: RefObject<FullCalendar>;
  legend?: ReactNode;
};

/** This is a thin wrapper around FullCalendar which standardizes blueprint specific styles/behavior for "Month" and "Week" calendar views */
export function WrappedFullCalendar(props: WrappedFullCalendarProps) {
  const {
    dateClick,
    events,
    eventClick,
    eventContent,
    eventResize,
    eventDrop,
    selectable,
    unselectAuto,
    unselectCancel,
    calendarRef,
    legend,
    dayMaxEvents,
    editable,
  } = props;

  const heightWithLegendOffset = legend ? "calc(100% - 30px)" : "100%";

  return (
    <div data-testid={"scheduleCalendarStyleContainer"} css={baseStyles}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, ...(editable ? [interactionPlugin] : [])]}
        headerToolbar={{ start: "prev,next today", center: "title", end: "dayGridWeek,dayGridMonth" }}
        dateClick={dateClick}
        events={events}
        eventClick={eventClick}
        height={heightWithLegendOffset}
        dayCellContent={({ dayNumberText, isOther }) => {
          return (
            <div>
              <div css={Css.xs.if(isOther).gray600.else.blue700.$}>{dayNumberText}</div>
            </div>
          );
        }}
        dayHeaders={true}
        dayHeaderContent={({ text }) => {
          return <div css={Css.smMd.gray700.$}>{text}</div>;
        }}
        eventContent={eventContent}
        editable={editable}
        eventResize={eventResize}
        eventDrop={eventDrop}
        eventDidMount={(element) => {
          element.el.setAttribute("data-id", element.event.id);
        }}
        selectable={selectable}
        unselectAuto={unselectAuto}
        unselectCancel={unselectCancel}
        dayMaxEvents={dayMaxEvents}
        views={{ week: { dayMaxEvents: 8 } }}
      />
      {legend && legend}
    </div>
  );
}

// Copied from Beam to make FullCalendar's button groups match Beam's button styles
// TODO: Maybe on a hackday, create a fully custom view to allow using Beam components in lieu of FullCalendar defaults
const buttonStyles = {
  ...Css.z1.px2.bgWhite.bcGray300.bw1.ba.gray900.br0.$,
  "&:disabled": Css.gray400.cursorNotAllowed.bcGray300.$,
  // Our first button should have a rounded left border
  "&:first-of-type": Css.add("borderRadius", "4px 0 0 4px").$,
  // Our last button should have a rounded right border
  "&:last-of-type": Css.add("borderRadius", "0 4px 4px 0").$,
  // Nudge buttons one pixel to the left so they visually share a border
  "&:not(:first-of-type)": Css.mlPx(-1).$,
};

const baseStyles = {
  ...Css.h100.bgWhite
    .addIn(".fc-theme-standard", Css.bgWhite.bcGray100.$)
    .addIn(".fc .fc-daygrid-day.fc-day-today", Css.bgBlue50.bcGray300.$)
    .addIn(".fc-highlight", Css.bgWhite.outline(`2px solid ${Palette.Blue700}`).add("outlineOffset", "-1px").$)
    .addIn(".fc", Css.p3.br8.$)
    .addIn("table.fc-scrollgrid, .fc .fc-scrollgrid-section-liquid > td", Css.br8.$)
    .addIn("div.fc-toolbar-chunk > button.fc-today-button", Css.smMd.bgWhite.bcGray300.gray900.br4.$)
    .addIn("div.fc-toolbar-chunk > button.fc-today-button:disabled", Css.bgWhite.gray400.cursorNotAllowed.$)
    .addIn("div.fc div.fc-button-group > button, .fc .fc-button-primary:not(:disabled).fc-button-active", {
      ...Css.smMd.bgWhite.bcGray300.gray900.$,
      ...buttonStyles,
    })
    .addIn("button.fc-prev-button", {
      ...Css.bgWhite.bcGray300.gray900.$,
      ...buttonStyles,
    })
    .addIn("button.fc-next-button", {
      ...Css.bgWhite.bcGray300.gray900.$,
      ...buttonStyles,
    })
    .addIn("button.fc-button:active:not(.fc-today-button:disabled)", Css.bgGray300.bcGray300.gray900.important.$)
    .addIn("button.fc-button:focus, button.fc-button:active", Css.important.bsh0.$).$,
};
