import { BoundTextAreaField, BoundTextField, Css, FormLines, ToggleChipGroup } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { LotType, ReadyPlanAssetType } from "src/generated/graphql-types";
import { CoverPhotoUploader } from "src/routes/libraries/plan-package/stepper/components/CoverPhotoUploader";
import { PlanPackageDetailsForm } from "../utils";
import { CreatePlanPackageCode } from "./CreatePlanPackageCode";
import { PlanPackageDetailsCard } from "./PlanPackageDetailsCard";

export type PlanOverviewCardProps = {
  formState: ObjectState<PlanPackageDetailsForm>;
  lotTypes: LotType[] | undefined | null;
  coverPhotoUrl: string | null | undefined;
  saveCoverPhoto: (file: UppyFile) => void;
};

export function PlanOverviewCard(props: PlanOverviewCardProps) {
  const { formState, coverPhotoUrl, saveCoverPhoto, lotTypes } = props;

  return (
    <PlanPackageDetailsCard title="Plan Overview" width={731}>
      <div css={Css.bt.bcGray200.pt3.pb1.bn.pt0.$}>
        <FormLines width="full" labelStyle="left" gap={3} labelLeftFieldWidth="65%">
          <BoundTextField
            field={formState.name}
            label="Plan Name*"
            placeholder="i.e. The Everett..."
            compact
            required
          />

          <ToggleChipGroup
            label="Product Types(s)"
            options={Object.values(LotType).map((lotType) => ({
              label: lotType,
              value: lotType,
            }))}
            values={lotTypes ?? []}
            onChange={(values) => {
              formState.lotTypes.set(values as LotType[]);
            }}
          />

          <BoundTextAreaField
            labelStyle="left"
            field={formState.description}
            label="Sales Description"
            placeholder="i.e. This large single family residence is ideal for those looking for a first-level primary suite and the perfect balance between an open layout and private spaces. ..."
            compact
          />
          <CreatePlanPackageCode codeField={formState.code} typeField={formState.type} />
          <div css={Css.df.$}>
            <div css={Css.w("35%").fs0.$}>Plan Cover Photo</div>
            <CoverPhotoUploader
              coverPhotoUrl={coverPhotoUrl}
              saveCoverPhoto={(file) => {
                if (file) {
                  saveCoverPhoto(file);
                  return;
                }

                // Remove from form state as well which will delete the asset when we save the plan package
                formState.set({
                  assets: [
                    ...formState.assets.value.filter(
                      (readyPlanAsset) => readyPlanAsset.type !== ReadyPlanAssetType.CoverPhoto,
                    ),
                  ],
                });
              }}
            />
          </div>
        </FormLines>
      </div>
    </PlanPackageDetailsCard>
  );
}
