import { useSnackbar } from "@homebound/beam";
import { Redirect, useParams } from "react-router-dom";
import { createInvoicesUrl } from "src/RouteUrls";
import {
  InvoiceEditorInvoiceFragment,
  SaveInvoiceInput,
  useDeleteInvoiceMutation,
  useInvoiceEditQuery,
  useNewInvoiceDataQuery,
  useSaveInvoiceMutation,
  useSubmitApprovalMutation,
} from "src/generated/graphql-types";
import { useModeParam } from "src/hooks/useModeParam";
import { useStripStage } from "src/hooks/useStripStage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProjectParams } from "src/routes/routesDef";
import { fail } from "src/utils";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";
import { InvoiceEditor } from "./InvoiceEditor";
import { InvoiceEditorV2 } from "./invoice-v2/InvoiceEditorV2";

type InvoicePageProps = {
  invoiceV2?: boolean;
};

export function InvoicePage() {
  return <InvoicePageOld invoiceV2 />;
}

export function InvoicePageOld({ invoiceV2 }: InvoicePageProps) {
  useStripStage();
  const { projectId } = useParams<ProjectParams>();
  const { mode, isNew, id, onCancel, onEdit, onSave } = useModeParam({
    listPageUrl: createInvoicesUrl(projectId),
  });

  const newInvoiceQuery = useNewInvoiceDataQuery({ variables: { projectId }, skip: !isNew });
  const invoiceQuery = useInvoiceEditQuery({ variables: { invoiceId: id! }, skip: isNew });
  const [saveInvoice] = useSaveInvoiceMutation();
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [saveApproval] = useSubmitApprovalMutation();
  const { triggerNotice } = useSnackbar();

  if (!isNew && !hasData(invoiceQuery)) {
    return renderLoadingOrError(invoiceQuery);
  } else if (isNew && !hasData(newInvoiceQuery)) {
    return renderLoadingOrError(newInvoiceQuery);
  }

  const invoice: InvoiceEditorInvoiceFragment | undefined = !isNew ? invoiceQuery.data!.invoice : undefined;
  const projectStages = isNew
    ? newInvoiceQuery.data!.project.stages
    : invoiceQuery.data!.invoice.projectStage.project.stages;

  // If the user changes the URL to have a different project ID than the one this invoice is attached to
  // we want to redirect them back to the invoices page for that project.
  if (invoice && projectId !== invoice.projectStage.project.id) {
    return <Redirect to={createInvoicesUrl(projectId)} />;
  }

  const handleSave = async (input: SaveInvoiceInput | undefined, redirect?: boolean, submitForApproval?: boolean) => {
    if (input) {
      const { data } = await saveInvoice({ variables: { input } });
      const invoice = data?.saveInvoice.invoice;
      // Submit the invoice for approval upon creation
      if (invoice && !invoice.approval && submitForApproval) {
        await saveApproval({ variables: { input: { subjectId: invoice.id } } });
        triggerNotice({ message: `Invoice #${invoice.invoiceNumber} submitted for approval` });
      }
    }

    if (redirect) {
      onSave();
    } else {
      await invoiceQuery.refetch();
    }
  };

  const handleDelete = async () => {
    await deleteInvoice({
      variables: { input: { id: id || fail("Attempting to delete invoice without an invoice id") } },
    });
    onSave();
  };

  return (
    <>
      <PageHeader
        title={invoice ? invoice.name : "Create Invoice"}
        breadcrumb={{
          label: "All Invoices",
          href: createInvoicesUrl(projectId),
        }}
      />
      {invoice && invoiceV2 ? (
        <InvoiceEditorV2
          invoice={invoice}
          projectId={projectId}
          projectStages={projectStages}
          mode={mode}
          onCancel={onCancel}
          onEdit={onEdit}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      ) : (
        <InvoiceEditor
          invoice={invoice}
          invoiceV2={invoiceV2}
          projectId={projectId}
          projectStages={projectStages}
          currentUserId={invoiceQuery?.data?.currentInternalUser?.id || ""}
          mode={mode}
          onCancel={onCancel}
          onEdit={onEdit}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
