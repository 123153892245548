import { BoundNumberField, Button, Css, IconButton, StaticField, TabsWithContent, useRightPane } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import {
  BidPackageBidItemFragment,
  BidPackageDetailPage_BidContractLineItemFragment,
  SaveBidContractLineItemInput,
  useEditBidLineItemMutation,
} from "src/generated/graphql-types";

type EditBidRightPaneProps = {
  bidItem: BidPackageBidItemFragment;
  bidContractLineItem: BidPackageDetailPage_BidContractLineItemFragment;
  tradePartnerName: string;
};

type TabValue = "details" | "history";

export function EditBidRightPane({ bidItem, bidContractLineItem, tradePartnerName }: EditBidRightPaneProps) {
  const { closeRightPane } = useRightPane();
  const [selectedTab, setSelectedTab] = useState<TabValue>("details");

  return (
    <div css={Css.p4.$}>
      <div css={Css.lgSb.mb1.$}>
        Edit Bid <span css={Css.gray700.$}>— {tradePartnerName}</span>
      </div>
      <div css={Css.absolute.right3.top3.$}>
        <IconButton data-testid="closePane" icon="x" onClick={closeRightPane} />
      </div>
      <TabsWithContent
        tabs={[
          {
            name: "Details",
            value: "details",
            render: () => <DetailsTab bidItem={bidItem} bidContractLineItem={bidContractLineItem} />,
          },
          { name: "History", value: "history", render: () => <HistoryTab /> },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </div>
  );
}

function DetailsTab({ bidContractLineItem, bidItem }: Pick<EditBidRightPaneProps, "bidItem" | "bidContractLineItem">) {
  const { closeRightPane } = useRightPane();
  const [editBidLineItem] = useEditBidLineItemMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      input: { bidContractLineItem },
      map: (data) => {
        return {
          id: data.bidContractLineItem.id,
          totalCostInCents: data.bidContractLineItem.totalCostInCents,
          quantity: 1,
        };
      },
    },
  });

  return (
    <div>
      <div css={Css.xsSb.mb2.$}>Item Details:</div>
      <div css={Css.df.gap4.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xsMd.$}>Code</div>
          <div css={Css.xs.$}>{bidItem.items[0].costCode.number}</div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xsMd.$}>Name</div>
          <div css={Css.xs.$}>{bidItem.displayName}</div>
        </div>
      </div>
      <div css={Css.my4.w100.hPx(1).bgGray200.$} />
      <div css={Css.xsSb.mb2.$}>Change Details:</div>
      <div css={Css.df.gap1.$}>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.gray700.$}>UoM</div>
          <StaticField labelStyle="hidden" label="" value={bidItem.unitOfMeasure.shortName} />
        </div>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.$}>Unit Cost</div>
          <BoundNumberField type="cents" compact field={formState.totalCostInCents} labelStyle="hidden" />
        </div>
      </div>

      <div css={Css.absolute.bottom0.left0.right0.bt.bcGray200.df.$}>
        <div css={Css.ma.py("18px").$}>
          <Observer>
            {() => (
              <Button
                label="Save Updates"
                disabled={!formState.canSave()}
                variant="text"
                onClick={async () => {
                  const { id, totalCostInCents } = formState.value;
                  await editBidLineItem({ variables: { input: { id, totalCostInCents } } });
                  closeRightPane();
                }}
              />
            )}
          </Observer>
        </div>
      </div>
    </div>
  );
}

type FormValues = Pick<SaveBidContractLineItemInput, "id" | "totalCostInCents">;

const formConfig: ObjectConfig<FormValues> = {
  id: { type: "value", rules: [required] },
  totalCostInCents: { type: "value", rules: [required] },
};

function HistoryTab() {
  return <div>History</div>;
}
