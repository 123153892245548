import { Avatar, Button, ButtonVariant, Chip, Css, Icon, IconButton, ScrollShadows } from "@homebound/beam";
import { sentenceCase } from "change-case";
import { formatDistance } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  DashboardComments_CommentFragment,
  DashboardComments_CommentableFragment,
  DashboardComments_StreamFragment,
  OriginalDashboardCommentsQuery,
  useOriginalDashboardCommentsQuery,
} from "src/generated/graphql-types";
import { IconData } from "src/routes/my-blueprint/activity-feed/UserEventImage";
import { EmptyStateWrapper } from "src/routes/personal-dashboard/components/EmptyStateWrapper";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { pluralize, sanitizeHtml, sortBy } from "src/utils";
import { useDashboardFilterContext } from "../DashboardFilterContext";

type OriginalDashboardCommentsWidgetProps = {
  isExpanded: boolean;
  onExpand: (x: boolean) => void;
};
export function OriginalDashboardCommentsWidget({ isExpanded, onExpand }: OriginalDashboardCommentsWidgetProps) {
  const { filter } = useDashboardFilterContext();

  const users = filter.internalUser ?? [];

  const query = useOriginalDashboardCommentsQuery({ variables: { internalUser: users }, skip: users.isEmpty });

  return <OriginalDashboardCommentsWidgetDataView data={query.data} isExpanded={isExpanded} onExpand={onExpand} />;
}

function OriginalDashboardCommentsWidgetDataView({
  data,
  isExpanded,
  onExpand,
}: OriginalDashboardCommentsWidgetProps & {
  data: OriginalDashboardCommentsQuery | undefined;
}) {
  const [openStream, setOpenStream] = useState<DashboardComments_StreamFragment | null>(null);

  const orderedStreams = useMemo(
    () =>
      sortBy(
        data?.userRelevantCommentStreams.filter(({ comments }) => comments.nonEmpty) ?? [],
        (s) =>
          // Sort by the latest reply
          Math.max(...s.comments.map(({ createdAt }) => createdAt.getTime())),
        "DESC",
      ),
    [data?.userRelevantCommentStreams],
  );

  const expandIcon = useMemo(
    () => (
      <IconButton
        onClick={() => onExpand(!isExpanded)}
        icon={!isExpanded ? "expand" : "collapse"}
        tooltip={!isExpanded ? "Expand" : "Collapse"}
        inc={2}
      />
    ),
    [isExpanded, onExpand],
  );

  const width = 435;
  const motionPx = 50;

  return (
    <div css={Css.h100.$}>
      <AnimatePresence>
        {openStream ? (
          <motion.div
            layout="position"
            css={Css.h100.wPx(width).$}
            initial={{ x: width + motionPx, opacity: 0, height: "100%" }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <DashboardCommentsStream stream={openStream} goBack={() => setOpenStream(null)} expandIcon={expandIcon} />
          </motion.div>
        ) : (
          <motion.div
            initial={{ y: "50%", opacity: 0, height: "100%" }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <DashboardCommentsStreams
              currentInternalUser={data?.currentInternalUser?.id}
              streams={orderedStreams}
              onOpenStream={setOpenStream}
              expandIcon={expandIcon}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function DashboardCommentsStream({
  stream: { commentable, comments },
  goBack,
  expandIcon,
}: {
  stream: DashboardComments_StreamFragment;
  goBack: () => void;
  expandIcon: JSX.Element;
}) {
  return (
    <div css={Css.bgWhite.p3.br8.df.fdc.gap1.h100.$}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.df.aic.gap1.cursorPointer.$} onClick={goBack}>
          <IconButton inc={2} icon="arrowBack" onClick={goBack} />
          <h1 css={Css.smMd.$}>Back</h1>
        </div>
        {expandIcon}
      </div>

      <EmptyStateWrapper title="comments">
        <div css={Css.df.fdc.gap1.oya.$}>
          {comments.map((comment, i) => (
            <div key={comment.id} css={Css.if(i !== comments.length - 1).bb.bcGray300.$}>
              <CommentCard
                key={comment.id}
                commentable={i === 0 ? commentable : undefined}
                comment={comment}
                variant="secondary"
                onView={commentable ? () => window.open(commentable.blueprintUrl.path, "_blank") : undefined}
                showAttachments
              />
            </div>
          ))}
        </div>
      </EmptyStateWrapper>
    </div>
  );
}

function DashboardCommentsStreams({
  currentInternalUser,
  streams,
  onOpenStream,
  expandIcon,
}: {
  currentInternalUser: string | undefined;
  streams: DashboardComments_StreamFragment[];
  onOpenStream: (s: DashboardComments_StreamFragment) => void;
  expandIcon: JSX.Element;
}) {
  return (
    <div css={Css.bgWhite.p3.br8.df.fdc.gap1.h100.$}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.df.aic.gap1.$}>
          <h1 css={Css.xlBd.$}>Comments</h1>
          <Icon
            inc={2}
            icon="infoCircle"
            tooltip="These are comments that you are tagged in, a watcher of, or that you made."
          />
        </div>
        {expandIcon}
      </div>

      <EmptyStateWrapper title="comments">
        <ScrollShadows xss={Css.df.fdc.gap1.oya.$}>
          {streams.map((stream, i) => {
            return (
              <div key={stream.id} css={Css.if(i !== streams.length - 1).bb.bcGray300.$}>
                <CommentStreamCard
                  key={stream.id}
                  currentInternalUser={currentInternalUser}
                  stream={stream}
                  onOpenStream={onOpenStream}
                />
              </div>
            );
          })}
        </ScrollShadows>
      </EmptyStateWrapper>
    </div>
  );
}

function CommentStreamCard({
  currentInternalUser,
  stream,
  onOpenStream,
}: {
  currentInternalUser: string | undefined;
  stream: DashboardComments_StreamFragment;
  onOpenStream: (s: DashboardComments_StreamFragment) => void;
}) {
  const { commentable, comments, commentCount, unreadCount } = stream;
  const sortedComments = useMemo(() => sortBy(comments, ({ createdAt }) => createdAt, "DESC"), [comments]);

  return (
    <CommentCard
      commentable={commentable}
      comment={sortedComments.first!}
      variant={unreadCount > 0 ? "primary" : "secondary"}
      viewTooltip={
        unreadCount > 0
          ? `${unreadCount} unread ${pluralize(unreadCount, "comment")}`
          : `${commentCount} ${pluralize(commentCount, "comment")}`
      }
      onView={() => onOpenStream(stream)}
    />
  );
}

function CommentCard({
  commentable,
  comment: { id, author, html, createdAt, attachments },
  variant,
  viewTooltip,
  onView,
  showAttachments,
}: {
  commentable?: DashboardComments_CommentableFragment;
  comment: DashboardComments_CommentFragment;
  variant: ButtonVariant;
  viewTooltip?: string;
  onView?: () => void;
  showAttachments?: boolean;
}) {
  return (
    <div css={Css.df.gap2.pt1.pb2.pr1.$}>
      <div>
        <Avatar src={author.avatarUrl} />
      </div>
      <div css={Css.df.fdc.w100.gap1.$}>
        <div data-testid={`commentCard:${id}`} css={Css.df.fdr.aic.gap1.$}>
          {author.name}&nbsp;&nbsp;{formatDistance(createdAt, new Date())}
        </div>
        <div css={Css.wbbw.$} dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} />
        {commentable && (
          <div css={Css.df.aic.gap1.$}>
            <div>
              <Icon
                inc={2}
                icon={entityToImageMap[commentable.__typename]?.iconKey}
                tooltip={sentenceCase(commentable.__typename)}
              />
            </div>
            <Chip text={commentable.name} title={commentable.name} />
          </div>
        )}
        {showAttachments && attachments.nonEmpty && (
          <div css={Css.df.fdc.$}>
            <div css={Css.gray700.sm.my1.$}>Attachments ({attachments?.length})</div>
            <div>
              <AssetGallery assets={attachments.map((i) => i.asset)} display="vertical">
                {(openGallery) => (
                  <>
                    {attachments.map(({ id, asset }) => (
                      <div key={id} css={Css.df.relative.mr1.mt1.$}>
                        <AssetPreview
                          asset={asset}
                          dimensions={{ width: 100, height: 100 }}
                          onClick={() => openGallery(asset)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </AssetGallery>
            </div>
          </div>
        )}
      </div>
      {onView && (
        <div css={Css.df.aic.$}>
          <Button variant={variant} tooltip={viewTooltip} label="View" onClick={onView} />
        </div>
      )}
    </div>
  );
}

const entityToImageMap: Record<string, IconData> = {
  ScheduleSubPhase: { iconKey: "criticalPath" },
  Approval: { iconKey: "bill" },
  BidContract: { iconKey: "bill" },
  BidItem: { iconKey: "bill" },
  Bill: { iconKey: "bill" },
  ChangeEvent: { iconKey: "changeEvent" },
  Commitment: { iconKey: "commitment" },
  DevelopmentCommitment: { iconKey: "commitment" },
  Document: { iconKey: "document" },
  Estimate: { iconKey: "bill" },
  Invoice: { iconKey: "bill" },
  ItemTemplateItemVersion: { iconKey: "projectItem" },
  JobLogNote: { iconKey: "hardHat" },
  Project: { iconKey: "house" },
  Product: { iconKey: "projectItem" },
  ProjectItem: { iconKey: "projectItem" },
  Task: { iconKey: "task" },
  ToDo: { iconKey: "todo" },
  SchedulePhase: { iconKey: "criticalPath" },
};
