import { AutoSaveIndicator, TabsWithContent } from "@homebound/beam";
import { RouteTabWithContent } from "@homebound/beam/dist/components/Tabs";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { createProjectSettingsUrl } from "src/RouteUrls";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProjectParams, projectPaths } from "src/routes/routesDef";
import { HomeownersTab } from "./HomeownersTab";
import { InvoicesTab } from "./InvoicesTab";
import { OverallTab } from "./OverallTab";
import { ScheduleTab } from "./ScheduleTab";
import { SetUpTab } from "./SetUpTab";
import { TeamMembersTab } from "./TeamMembersTab";

export function ProjectSettingsPage() {
  const { projectId } = useParams<ProjectParams>();

  const tabs: RouteTabWithContent[] = useMemo(
    () => [
      {
        name: "Overall",
        href: createProjectSettingsUrl(projectId),
        path: projectPaths.settings,
        render: () => <OverallTab />,
      },
      {
        name: "Invoices",
        href: createProjectSettingsUrl(projectId, "invoices"),
        path: `${projectPaths.settings}/invoices`,
        render: () => <InvoicesTab />,
      },
      {
        name: "Set Up",
        href: createProjectSettingsUrl(projectId, "setup"),
        path: `${projectPaths.settings}/setup`,
        render: () => <SetUpTab />,
      },
      {
        name: "Team Members",
        href: createProjectSettingsUrl(projectId, "teamMembers"),
        path: `${projectPaths.settings}/teamMembers`,
        render: () => <TeamMembersTab />,
      },
      {
        name: "Homeowner(s)",
        href: createProjectSettingsUrl(projectId, "homeowners"),
        path: `${projectPaths.settings}/homeowners`,
        render: () => <HomeownersTab />,
      },
      {
        name: "Schedule",
        href: createProjectSettingsUrl(projectId, "schedule"),
        path: `${projectPaths.settings}/schedule`,
        render: () => <ScheduleTab />,
      },
    ],
    [projectId],
  );

  return (
    <>
      <PageHeader title="Project Settings" left={<AutoSaveIndicator />} />
      <TabsWithContent tabs={tabs} />
    </>
  );
}
