import { Global } from "@emotion/react";
import { Css, IconButton, Palette, px, useTestIds } from "@homebound/beam";
import { Price } from "src/components";
import { useInvoicePandaDocPdfQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils";
import { StringParam, useQueryParam } from "use-query-params";

export function InvoicePdf() {
  const [invoiceId] = useQueryParam("invoiceId", StringParam);
  const query = useInvoicePandaDocPdfQuery({ variables: { invoiceId: invoiceId! } });
  const testIds = useTestIds({}, "invoicePdf");

  return queryResult(query, ({ invoice }) => {
    const { primaryHomeowner, customer } = invoice.project;
    return (
      <>
        <Global
          styles={{
            "@page": { size: "letter" },
            ul: Css.ml1.my2.add({ listStyleType: "disc" }).$,
            li: Css.my2.$,
          }}
        />

        <div css={Css.p2.$}>
          <PageHeader
            xss={Css.bn.$}
            left={
              <div>
                <img src="/wordmark.svg" alt="Homebound" css={Css.h(px(20)).$} />
                <div {...testIds.invoiceNumber} css={Css.xl2Md.tal.mt2.$}>
                  Invoice #{invoice.invoiceNumber}
                </div>
              </div>
            }
            right={
              <>
                {invoice.document?.asset.downloadUrl && (
                  <IconButton
                    color={Palette.Blue500}
                    inc={3}
                    icon="download"
                    onClick={invoice.document?.asset.downloadUrl}
                  />
                )}
              </>
            }
          />

          <div css={Css.mt4.df.jcsb.$}>
            <div>
              <div {...testIds.projectAddress}>{invoice.project.buildAddress.street1}</div>
              <div {...testIds.invoiceDate}>Invoice Date: {invoice.invoiceDate.toLocaleDateString()}</div>
              <div {...testIds.invoiceDueDate}>Due Date: {invoice.dueDate.toLocaleDateString()}</div>
              <div>finance@homebound.com</div>
            </div>

            <div css={Css.df.$}>
              <div css={Css.mr2.$}>Bill to:</div>
              <div>
                <div {...testIds.nameAndAddress} css={Css.smBd.$}>
                  {customer?.name ?? primaryHomeowner?.fullName ?? "Client"} - {invoice.project.buildAddress.street1}
                </div>
                <div {...testIds.streetAddress}>
                  {invoice.project.buildAddress.street1}, {invoice.project.buildAddress.street2 ?? ""}
                </div>
                <div {...testIds.cityStateAndZip}>
                  {invoice.project.buildAddress.city}, {invoice.project.buildAddress.state}{" "}
                  {invoice.project.buildAddress.postalCode}
                </div>
                {!customer && primaryHomeowner && <div {...testIds.homeownerEmail}>{primaryHomeowner.email}</div>}
              </div>
            </div>

            <div>
              <div css={Css.gray600.tar.$}>Total amount due</div>
              <div css={Css.xl3Md.$}>
                <Price
                  id="totalAmountDue"
                  valueInCents={invoice.amountInCents - (invoice.creditForInvoice?.amountInCents ?? 0)}
                />
              </div>
            </div>
          </div>

          <div css={Css.xlMd.my5.$}>Items</div>
          <div css={Css.px4.bb.bcGray200.pb2.mb2.$}>
            <div css={Css.w100.gray600.df.jcsb.mb2.$}>
              <div>Description</div>
              <div>Total Cost</div>
            </div>
            {invoice.lineItems.map((li) => (
              <div key={li.id} css={Css.df.jcsb.$}>
                <div {...testIds.lineItemName}>{li.homeownerContractLineItem.projectItem.name}</div>
                <div>
                  <Price id="lineItemAmount" valueInCents={li.amountInCents} />
                </div>
              </div>
            ))}
            {invoice.drawLineItems.map((dli) => (
              <div key={dli.id} css={Css.df.jcsb.py1.$}>
                <div {...testIds.drawLineItemName}>{dli.draw.description}</div>
                <div>
                  <Price id="drawLineItemAmount" valueInCents={dli.draw.amountInCents} />
                </div>
              </div>
            ))}
          </div>

          <div css={Css.df.$}>
            <div css={Css.w50.tar.smMd.$}>Total Amount Due</div>
            <div css={Css.w50.tar.pr4.smMd.$}>
              <Price id="lineItemsTotalAmountDue" valueInCents={invoice.amountInCents} />
            </div>
          </div>

          {/* Terms */}
          <div css={Css.mt8.$} style={{ pageBreakBefore: "avoid" }}>
            <p css={Css.mb1.$}>GC# 1043883 Fed ID#824219797</p>

            <p css={Css.mb1.$}>
              As a reminder, all invoices are due within 30 days of receipt. If your invoice is not paid within 30 days,
              Homebound will issue you a written notice that your account is overdue. Within three business days after
              receiving this written notice from Homebound that your account is overdue, interest will begin to accrue
              at the rate of 15% or the statutory maximum interest rate per annum on all outstanding sums. If payment is
              not received within 30 days, Homebound reserves the right to stop work until payment is received.{" "}
            </p>

            <p>Please make your ACH payment via the payment link above. </p>
            <p>Or send us a wire transfer to – </p>
            <p>JPMorgan Chase Bank</p>
            <p>270 Park Avenue</p>
            <p css={Css.mb1.$}>New York, NY 10017</p>

            <p>Account Name: Homebound Technologies, Inc.</p>
            <p>Account Number: 932551119 </p>
            <p>Routing Number (Wires): 021000021</p>
            <p>Routing Number (ACH): 322271627 </p>
            <p>Swift Code: CHASUS33</p>
          </div>
        </div>
      </>
    );
  });
}
