import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { AuthViewProps, signOut } from "@homebound/auth-components";
import { ButtonMenu, Css, IconButton, NavLink, Palette, px, useBreakpoint, useTestIds } from "@homebound/beam";
import { Link, useLocation } from "react-router-dom";
import { HomeboundIcon } from "src/components";
import { openZendesk } from "src/components/ZendeskWidget";
import { useAppNotificationsContext } from "src/contexts/AppNotificationsContext";
import { useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { FeatureFlagType } from "src/generated/graphql-types";
import { GlobalSearchBox } from "src/routes/layout/GlobalSearchBox";
import {
  billsPath,
  changeLogPaths,
  developmentCommitmentsPath,
  developmentsPath,
  invoiceTermsPath,
  invoicesPath,
  librariesPath,
  personalDashboardPaths,
  projectsPath,
  purchaseOrdersPath,
  tradePartnersPath,
  tradesPath,
  warrantyPaths,
} from "src/routes/routesDef";
import { InboxBell } from "../inbox/components/InboxBell";
import { TradesNavMenu } from "../libraries/global-options/TradesNavMenu";
import { LibrariesNavMenu } from "./LibrariesNavMenu";

type GlobalNavProps = Pick<AuthViewProps, "user">;

export function GlobalNav({ user }: GlobalNavProps) {
  const location = useLocation();
  const testIds = useTestIds({}, "globalNav");
  const { notifications, clear } = useAppNotificationsContext();
  const { featureIsEnabled } = useFeatureFlags();
  const isTradeCommsFeatureEnabled =
    featureIsEnabled(FeatureFlagType.TradePartnerCommunications) ||
    featureIsEnabled(FeatureFlagType.DynamicSchedulesTradeComms);

  const { sm } = useBreakpoint();

  const toolbarLinks: {
    // What is shown as a label
    label: string;
    // Which path or paths will activate the label
    path: string | string[];
    // What path to redirect the user when clicking on the label
    value?: string;
    // Allow render of custom nav components like ButtonMenu
    customJSX?: ReactJSXElement;
    // Feature flag to show/hide the nav item
    featureFlag?: FeatureFlagType;
  }[] = [
    { label: "Dashboard", path: personalDashboardPaths.base },
    { label: "Projects", path: [projectsPath, developmentsPath], value: projectsPath },
    { label: "Finances", path: [billsPath, invoicesPath, purchaseOrdersPath, invoiceTermsPath] },
    { label: "Libraries", path: librariesPath, customJSX: LibrariesNavMenu() },
    { label: "Trade Partners", path: tradePartnersPath },
    {
      label: "Trades",
      path: tradesPath,
      customJSX: TradesNavMenu(),
      featureFlag: FeatureFlagType.ProductConfig,
    },
    { label: "Group Commitments", path: developmentCommitmentsPath },
    { label: "Change Log", path: changeLogPaths.base, featureFlag: FeatureFlagType.ChangeRequestMvp },
    { label: "Warranty", path: warrantyPaths.base },
  ];

  const helpOptions = [
    {
      label: "Contact Support",
      onClick: () => {
        openZendesk();
      },
    },
    {
      label: "Support Guide",
      onClick: () => {
        window.open("https://support.homebound.com/hc/", "_blank", "noopener,noreferrer");
      },
    },
  ];

  const profileOptions = [
    {
      label: "Profile",
      onClick: "https://myaccount.google.com/personal-info",
    },
  ];

  return (
    <nav css={Css.bgGray800.fs0.w100.sticky.top0.z999.h(px(topNavHeight)).df.aic.jcsb.$} {...testIds}>
      <div css={Css.mx3.$}>
        <Link to={personalDashboardPaths.base} {...testIds.homeLink}>
          <HomeboundIcon width={45} color={Palette.White} />
        </Link>
      </div>

      {sm ? (
        <ButtonMenu
          trigger={{ icon: "menu", contrast: true }}
          contrast
          items={toolbarLinks.map(({ label, path, value = Array.isArray(path) ? path[0] : path }) => ({
            label,
            onClick: () => {
              window.open(value, "_self");
            },
          }))}
        />
      ) : (
        <>
          <div css={Css.df.aic.mx1.fg1.gap2.$}>
            {toolbarLinks
              .filter(({ featureFlag }) => !featureFlag || featureIsEnabled(featureFlag))
              .map(({ label, path, value = Array.isArray(path) ? path[0] : path, customJSX }) => {
                return (
                  customJSX ?? (
                    <NavLink
                      key={value}
                      href={value}
                      label={label}
                      active={
                        Array.isArray(path)
                          ? path.some((p) => location.pathname.startsWith(p))
                          : location.pathname.startsWith(path)
                      }
                      variant="global"
                      {...testIds.link}
                    />
                  )
                );
              })}
          </div>
          <GlobalSearchBox />
          <div css={Css.df.gap1.$}>
            {isTradeCommsFeatureEnabled && <InboxBell />}
            <div css={Css.df.gap1.relative.$}>
              <IconButton
                icon="bell"
                disabled={notifications.isEmpty}
                tooltip={notifications[0]}
                color={Palette.Gray500}
                contrast
                onClick={() => {
                  clear();
                  openZendesk();
                }}
              />
              {notifications.nonEmpty && (
                <div css={Css.absolute.top(1).left(0.8).bgYellow500.wPx(5).hPx(5).borderRadius("50%").$} />
              )}
              <ButtonMenu
                items={helpOptions}
                trigger={{ icon: "helpCircle", color: Palette.Gray500, contrast: true }}
              />
            </div>
          </div>
          {user?.name && (
            <div css={Css.bgGray800.mtPx(4).ml2.mr3.fs0.$}>
              <ButtonMenu
                items={profileOptions}
                trigger={{ src: user.picture, name: user.name }}
                placement="right"
                persistentItems={[
                  {
                    label: "Sign Out",
                    onClick: () => {
                      signOut().catch(console.error);
                    },
                  },
                ]}
              />
            </div>
          )}
        </>
      )}
    </nav>
  );
}

export const topNavHeight = 48;
