// FullCalendar@5 imports are order dependent. We should upgrade to FullCalendar@6, which fixes this issue. Once upgraded
// we can remove this workaround and organize imports normally.
import "@fullcalendar/react/dist/vdom"; // organize-imports-ignore
import FullCalendar, { CalendarOptions, createRef, EventApi } from "@fullcalendar/react";

import {
  Button,
  ButtonModal,
  Css,
  IconButton,
  Palette,
  RightPaneLayout,
  ScrollableContent,
  TriggerNoticeProps,
  useRightPane,
  useSnackbar,
} from "@homebound/beam";
import { differenceInBusinessDays } from "date-fns";
import { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { formatDate, Loading } from "src/components";
import {
  SaveScheduleTaskInput,
  ScheduleDetailsFragment,
  ScheduleSettingDetailsFragment,
  TaskAssigneeFragment,
  TaskCalendarDetailsFragment,
  TaskStatus,
  TaskStatusesFragment,
  TaskTradePartnerFragment,
  useScheduleCalendarTasksQuery,
} from "src/generated/graphql-types";
import { calcDependencyLag } from "src/routes/projects/schedule-v2/calcDependencyLag";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { ScheduleViewTabs } from "src/routes/projects/schedule-v2/ScheduleViewTabs";
import { getDisabledDaysByScheduleSetting } from "src/routes/projects/schedule-v2/table/DateCellField";
import { DateOnly } from "src/utils/dates";
import { TaskColorLegend, TaskColorLegendItem } from "../components/TaskColorLegend";
import { AddScheduleTaskPane } from "../table/AddScheduleTaskPane";
import {
  CustomTaskFilter,
  customTaskFilterDefault,
  definedFilterValues,
  mapToFilter,
  useQueryStorage,
} from "../table/filterUtils";
import { ScheduleType } from "../table/ScheduleType";
import { getBusinessDayHelpersForSchedule } from "../table/utils";
import { createTaskCalendarEvents, renderTaskEventContent, TASK_ICON_CONFIG } from "./calendarTaskUtils";
import { ScheduleCalendarFilterModal } from "./ScheduleCalendarFilterModal";
import { ScheduleCalendarView, ScheduleCalendarViewModal } from "./ScheduleCalendarViewModal";
import { SearchScheduleCalendar } from "./SearchScheduleCalendar";
import { WrappedFullCalendar } from "./WrappedFullCalendar";

type ScheduleCalendarDataProps = {
  tasks: TaskCalendarDetailsFragment[];
  onTaskSave: (input: SaveScheduleTaskInput) => void;
  onEventClick: (taskId: any, scrollIntoViewType?: any) => void;
  calendarRef: RefObject<FullCalendar>;
  searchedTaskId: string | undefined;
  schedule: ScheduleDetailsFragment;
  view: ScheduleCalendarView;
};

type ScheduleCalendarProps = Omit<ScheduleCalendarDataProps, "tasks" | "onEventClick" | "calendarRef" | "view"> & {
  onEventClick: (taskId: any, scrollIntoViewType?: any) => void;
  schedule: ScheduleDetailsFragment;
  onTaskSave: (input: SaveScheduleTaskInput) => void;
  fullScreen?: boolean;
  toggleFullScreen?: () => void;
  filterOptions: {
    assignees: TaskAssigneeFragment[];
    tradePartners: TaskTradePartnerFragment[];
    taskStatuses: TaskStatusesFragment[];
  };
};

export function ScheduleCalendar({
  schedule,
  onTaskSave,
  onEventClick,
  fullScreen,
  toggleFullScreen,
  filterOptions,
}: ScheduleCalendarProps) {
  const { stage } = schedule;
  const [searchedTaskId, setSearchedTaskId] = useState<string | undefined>(undefined);
  const [view, setView] = useState<ScheduleCalendarView>(ScheduleCalendarView.Completion);
  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: "scheduleCalendarFilter",
    initialQueryStorage: customTaskFilterDefault,
  });
  const taskFilter = useMemo(() => mapToFilter(queryStorage), [queryStorage]);
  const isTaskFilterDefined = useMemo(() => {
    return Object.keys(definedFilterValues(taskFilter)).length > 0;
  }, [taskFilter]);
  const { scheduleState } = useScheduleStore();
  const {
    taskPaneState: { taskPaneId },
  } = scheduleState;

  const { data: taskData, loading } = useScheduleCalendarTasksQuery({
    variables: {
      filter: {
        stage: [stage],
        status: [
          TaskStatus.Complete,
          TaskStatus.Delayed,
          TaskStatus.InProgress,
          TaskStatus.NotStarted,
          TaskStatus.OnHold,
        ],
        tradePartner: taskFilter.tradePartner,
        internalUser: taskFilter.internalUser,
        scheduleParent: [schedule.parent.id],
        isGlobalMilestoneTask: taskFilter.isGlobalMilestoneTask,
        isCriticalPath: taskFilter.isCriticalPath,
        stale: taskFilter.stale,
        homeownerVisible: taskFilter.homeownerVisible,
      },
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  // FullCalendar still uses legacy refs
  const calendarRef = createRef<FullCalendar>();

  // memoize the callback function
  const scheduleFilterModal = useCallback(
    (close: VoidFunction) => {
      return (
        <ScheduleCalendarFilterModal
          filterOptions={filterOptions}
          filter={queryStorage}
          setFilter={setQueryStorage}
          onClose={close}
        />
      );
    },
    [filterOptions, queryStorage, setQueryStorage],
  );

  // Wrap the `onEventClick` function to also clear the searched value when the user selects a different task
  const onTaskEventClick = useCallback(
    // Use `any` to match the ScheduleCalendarDataView.onEventClick callback
    (taskId: any, scrollIntoViewType: any) => {
      setSearchedTaskId(undefined);
      return onEventClick(taskId, scrollIntoViewType);
    },
    [setSearchedTaskId, onEventClick],
  );

  const goToTask = useCallback(
    (taskId: string) => {
      const calendarApi = calendarRef.current?.getApi();
      const task = taskData?.scheduleTasks.find((t) => t.id === taskId);
      calendarApi?.gotoDate(task?.interval.startDate?.toString());
    },
    [calendarRef, taskData?.scheduleTasks],
  );

  useEffect(() => {
    taskPaneId && goToTask(taskPaneId);
  }, [goToTask, taskPaneId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div css={Css.df.jcsb.pt3.pb1.$}>
        <ScheduleViewTabs />
        <div css={Css.df.jcfe.aic.mr2.gap1.$}>
          <ButtonModal
            content={<ScheduleCalendarViewModal viewType={view} setViewType={setView} />}
            trigger={{ label: "View" }}
          />
          <ButtonModal content={scheduleFilterModal} trigger={{ label: "Filter" }} />
          {isTaskFilterDefined && (
            <Button label="Clear" onClick={() => setQueryStorage({} as CustomTaskFilter)} variant="tertiary" />
          )}
          <SearchScheduleCalendar
            scheduleTasks={taskData?.scheduleTasks || []}
            onSearch={(task) => {
              goToTask(task.id);
              setSearchedTaskId(task.id);
            }}
          />
        </div>
        <div>
          {toggleFullScreen && (
            <IconButton
              color={Palette.Gray900}
              contrast={true}
              icon={fullScreen ? "collapse" : "expand"}
              onClick={toggleFullScreen}
            />
          )}
        </div>
      </div>
      <ScrollableContent virtualized>
        <div css={Css.mr3.h100.$}>
          <RightPaneLayout>
            <ScheduleCalendarDataView
              tasks={taskData?.scheduleTasks ?? []}
              onTaskSave={onTaskSave}
              onEventClick={onTaskEventClick}
              calendarRef={calendarRef}
              searchedTaskId={searchedTaskId}
              schedule={schedule}
              view={view}
            />
          </RightPaneLayout>
        </div>
      </ScrollableContent>
    </>
  );
}

export function ScheduleCalendarDataView({
  tasks,
  onEventClick,
  onTaskSave,
  calendarRef,
  searchedTaskId,
  schedule,
  view,
}: ScheduleCalendarDataProps) {
  const {
    scheduleState: { taskPaneState, scheduleType },
  } = useScheduleStore();

  const events = createTaskCalendarEvents(tasks, {
    searchedTaskId,
    selectedTaskId: taskPaneState.taskPaneId,
    view,
  });

  const tasksById = tasks.keyBy((t) => t.id);
  const scheduleSetting = schedule.scheduleSetting;

  const { triggerNotice } = useSnackbar();
  const { openRightPane } = useRightPane();

  // This hook ensures the calendar resize function is called when the task detail pane is opened/closed
  // SetTimeout delay is to account for the animation duration
  useLayoutEffect(() => {
    setTimeout(() => {
      calendarRef?.current?.getApi().updateSize();

      if (taskPaneState.taskPaneId) {
        const event = calendarRef?.current?.getApi().getEventById(taskPaneState.taskPaneId);
        event?.setExtendedProp("selected", true);
        // When rerendering, the default calendar behavior is to scroll to the top: https://github.com/fullcalendar/fullcalendar/issues/7063
        // Best we can do for now is scroll the task back into view once opening the sidebar
        scrollTaskIntoView(taskPaneState.taskPaneId);
      }
    }, 400);
  }, [taskPaneState.taskPaneId, calendarRef]);

  useEffect(() => {
    setTimeout(() => {
      if (searchedTaskId) scrollTaskIntoView(searchedTaskId);
      // Need to wait for the task detail pane's animation to end
    }, 400);
  }, [searchedTaskId]);

  const isTemplate = scheduleType === ScheduleType.Template;
  const isDisabledDay = getDisabledDaysByScheduleSetting(scheduleSetting);

  /** These props manage how clicking to select a date behaves. We use this functionality to add a task to a date
   * * `unselectAuto` will de-select a day if another element is clicked on the page
   * * `unselectCancel` overrides `unselectAuto` by passing a CSS selector, in this case, we don't want the
   *    user interacting with the add task form to clear the date selection
   */
  const selectableConfig: CalendarOptions = {
    selectable: true,
    unselectAuto: true,
    unselectCancel: '[data-testid="add_task_pane"], [data-testid="datePickerDay"]',
  };

  const addTask = useCallback(
    async (date: Date) => {
      onEventClick(undefined);
      calendarRef?.current?.getApi().updateSize();

      if (isDisabledDay(date)) {
        return triggerNotice({
          message: `Start Date of ${formatDate(date)} is not a working day for this schedule`,
          icon: "error",
        });
      }

      const { addBusinessDays } = getBusinessDayHelpersForSchedule(schedule?.scheduleSetting || undefined);
      openRightPane({
        content: (
          <AddScheduleTaskPane
            type="task"
            tasks={tasks || []}
            schedule={schedule}
            refetchQueries={["ScheduleCalendarTasks"]}
            formValues={{
              startDate: new DateOnly(date),
              endDate: new DateOnly(addBusinessDays(date, 1)),
            }}
          />
        ),
      });
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks, schedule],
  );

  return (
    <WrappedFullCalendar
      calendarRef={calendarRef}
      dateClick={(actualDate) => addTask(actualDate.date)}
      events={events}
      eventClick={({ event }) => {
        onEventClick(event.id);
      }}
      eventContent={renderTaskEventContent}
      eventResize={({ event, revert }) => {
        onTaskEventResize(event, revert, tasksById, triggerNotice, onTaskSave);
      }}
      eventDrop={({ event, revert }) => {
        onTaskEventDrop(event, revert, tasksById, triggerNotice, onTaskSave, scheduleSetting);
      }}
      editable={true}
      {...selectableConfig}
      legend={
        !isTemplate ? (
          <TaskColorLegend
            title="Color legend:"
            items={
              view === ScheduleCalendarView.TradePartnerScheduled
                ? scheduleCalendarTradePartnerScheduledItems
                : undefined
            }
          />
        ) : undefined
      }
    />
  );
}

export function onTaskEventResize(
  event: EventApi,
  revert: () => void,
  tasksById: Record<string, TaskCalendarDetailsFragment>,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
  onTaskSave: (input: SaveScheduleTaskInput) => void,
) {
  const taskId = event.id;
  const newStartDate = event.start ? new DateOnly(event.start) : undefined;
  const endDate = event.end ? new DateOnly(event.end) : undefined;
  const durationInDays = (newStartDate && endDate && differenceInBusinessDays(endDate, newStartDate)) || undefined;
  const originalTaskData = tasksById[taskId];
  const { canEditDuration } = originalTaskData;
  // if a task duration cannot be modified, then show error message and revert changes
  if (!canEditDuration.allowed) {
    revert();
    return triggerNotice({
      message: canEditDuration.disabledReasons.map((dr) => dr.message).join(", "),
      icon: "error",
    });
  }

  // if duration can be allowed, then save it
  if (newStartDate) {
    return onTaskSave({
      id: taskId,
      durationInDays,
    });
  }
}

export function onTaskEventDrop(
  event: EventApi,
  revert: () => void,
  tasksById: Record<string, TaskCalendarDetailsFragment>,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
  onTaskSave: (input: SaveScheduleTaskInput) => void,
  scheduleSetting: ScheduleSettingDetailsFragment | null | undefined,
) {
  const taskId = event.id;
  const newStartDate = event.start ? new DateOnly(event.start) : undefined;

  // determine if the newStartDate is a non-working day
  const isDisabledDay = getDisabledDaysByScheduleSetting(scheduleSetting);
  const originalTaskData = tasksById[taskId];
  const { canEditStartDate, canEditEndDate } = originalTaskData;

  // If the newStartDate is a non-working day, then show error message and revert changes
  if (newStartDate && isDisabledDay(newStartDate.date)) {
    revert();
    return triggerNotice({
      message: `Start Date of ${formatDate(newStartDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  // If a task startDate or endDate are editable (no predecessors), then we can save the new startDate
  if (canEditStartDate.allowed && canEditEndDate.allowed) {
    return onTaskSave({
      id: taskId,
      startDate: newStartDate,
    });
  }

  // Otherwise, when there are predecessors, calculate the updated lag for each dependency to produce the desired start date
  if (newStartDate) {
    const { mutationInput } = calcDependencyLag({
      newStartDate,
      originalTaskData,
      scheduleSetting,
    });

    return onTaskSave({
      id: taskId,
      predecessorDependencies: mutationInput,
    });
  }
}

/** fullcalendar lib does not expose refs to events or a method to scroll an event into view */
function scrollTaskIntoView(taskId?: string) {
  if (!taskId) return;

  const taskNode = document.querySelector(`[data-id="${taskId}"]`);
  taskNode?.scrollIntoView({ behavior: "smooth", block: "center" });
}

const scheduleCalendarTradePartnerScheduledItems: TaskColorLegendItem[] = [
  {
    label: "Needs Confirmation",
    ...TASK_ICON_CONFIG.needsConfirmation,
  },
  {
    label: "Confirmed",
    ...TASK_ICON_CONFIG.confirmed,
  },
  {
    label: "Needs Reconfirmation",
    ...TASK_ICON_CONFIG.needsConfirmation,
  },
  {
    label: "Unavailable",
    ...TASK_ICON_CONFIG.unavailable,
  },
  {
    label: "Completed Job",
    ...TASK_ICON_CONFIG.completedJob,
  },
];
