import {
  BoundMultiSelectField,
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  Button,
  Css,
  ScrollableContent,
  TabContent,
  TabWithContent,
  Tabs,
  useModal,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useHistory } from "react-router-dom";
import { CommentFeed, HistoryFeed } from "src/components";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { BoundAttachments } from "src/components/boundAttachments/BoundAttachments";
import {
  AvailableTeamMembersFragment,
  InternalUserDetailFragment,
  WarrantyTicketPageDetailsFragment,
  WarrantyTicketTypeDetail,
  useDeleteWarrantyTicketMutation,
} from "src/generated/graphql-types";
import { useTabParam } from "src/hooks/useTabParam";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { AssetPreview } from "../projects/assets/AssetPreview";
import { warrantyPaths } from "../routesDef";
import { TicketFormInput } from "./WarrantyTicketPage";
import { WarrantyAvailabilitySection } from "./components/WarrantyAvailabilitySection";
import { WarrantyContactSection } from "./components/WarrantyContactSection";

enum WarrantySummaryTabs {
  Details = "Details",
  Files = "Files",
  Comments = "Comments",
  History = "History",
}

export type WarrantySummaryViewProps = {
  warrantyTicket: WarrantyTicketPageDetailsFragment;
  formState: ObjectState<Partial<TicketFormInput>>;
  warrantyTicketTypes: WarrantyTicketTypeDetail[];
  teamMembers: InternalUserDetailFragment[];
};

export function WarrantySummaryView(props: WarrantySummaryViewProps) {
  const { formState, warrantyTicket, warrantyTicketTypes, teamMembers } = props;
  const [selectedTab, setSelectedTab] = useTabParam(WarrantySummaryTabs.Details);
  const { openModal } = useModal();
  const history = useHistory();
  const [deleteWarrantyTicket] = useDeleteWarrantyTicketMutation();

  const tabs: TabWithContent<WarrantySummaryTabs>[] = [
    {
      name: "Details",
      value: WarrantySummaryTabs.Details,
      render: () => (
        <WarrantyDetailsTab
          warrantyTicket={warrantyTicket}
          formState={formState}
          warrantyTicketTypes={warrantyTicketTypes}
          teamMembers={teamMembers}
        />
      ),
    },
    {
      name: "Files",
      value: WarrantySummaryTabs.Files,
      render: () => <FilesTab warrantyTicket={warrantyTicket} formState={formState} />,
    },
    {
      name: "Comments",
      value: WarrantySummaryTabs.Comments,
      render: () => <CommentFeed commentable={warrantyTicket} showCommentTitle={false} showFollowers={false} />,
    },
    {
      name: "History",
      value: WarrantySummaryTabs.History,
      render: () => <WarrantyHistoryTab warrantyTicket={warrantyTicket} />,
    },
  ];
  return (
    <>
      <div css={Css.px6.$}>
        <div css={Css.lgSb.pb3.$}>Ticket Summary</div>
        <Tabs tabs={tabs} selected={selectedTab} onChange={(v) => setSelectedTab(v)} includeBottomBorder />

        <ScrollableContent>
          <TabContent contentXss={Css.pt4.px6.pbPx(68).$} tabs={tabs} selected={selectedTab} />
        </ScrollableContent>
      </div>

      <div css={Css.absolute.bgWhite.hPx(68).bottom0.df.aic.bcGray200.bt.z1.w100.jcc.$}>
        <Button
          label="Delete Ticket"
          icon="trash"
          variant="tertiaryDanger"
          onClick={() =>
            openModal({
              content: (
                <ConfirmationModal
                  danger
                  title="Confirm Delete"
                  label="Delete ticket"
                  confirmationMessage="Are you sure you want to delete this ticket? Doing so will also delete all of the associated requests you’ve added to the ticket."
                  onConfirmAction={async () => {
                    await deleteWarrantyTicket({ variables: { input: { id: warrantyTicket.id } } });
                    history.push(warrantyPaths.base);
                  }}
                />
              ),
            })
          }
        />
      </div>
    </>
  );
}

type WarrantyDetailsTabProps = {
  warrantyTicket: WarrantyTicketPageDetailsFragment;
  formState: ObjectState<Partial<TicketFormInput>>;
  warrantyTicketTypes: WarrantyTicketTypeDetail[];
  teamMembers: AvailableTeamMembersFragment[];
};

function WarrantyDetailsTab(props: WarrantyDetailsTabProps) {
  const { formState, warrantyTicketTypes, warrantyTicket, teamMembers } = props;
  return (
    <div css={Css.df.fdc.gap2.w100.oa.$}>
      <BoundTextField field={formState.title} label="Ticket Name" />
      <BoundSelectField
        options={warrantyTicketTypes}
        field={formState.type}
        getOptionValue={(ts) => ts.code}
        getOptionLabel={(ts) => ts.name}
        label="Type"
      />
      <BoundMultiSelectField
        label="Assigned To"
        field={formState.assigneeIds}
        options={teamMembers}
        fieldDecoration={internalUserAvatar}
        getOptionMenuLabel={internalUserMenuLabel}
      />
      <BoundMultiSelectField
        label="Watchers"
        field={formState.followerIds}
        options={teamMembers}
        fieldDecoration={internalUserAvatar}
        getOptionMenuLabel={internalUserMenuLabel}
      />
      <div css={Css.pr1.$}>
        <BoundSwitchField label="H/O Visible" labelStyle="filter" field={formState.homeownerVisible} />
      </div>
      <WarrantyContactSection formState={formState} warrantyTicket={warrantyTicket} />
      <WarrantyAvailabilitySection formState={formState} warrantyTicket={warrantyTicket} />
    </div>
  );
}

type FilesTabProps = {
  warrantyTicket: WarrantyTicketPageDetailsFragment;
  formState: ObjectState<Partial<TicketFormInput>>;
};

function FilesTab({ formState, warrantyTicket }: FilesTabProps) {
  const itemsLevelAttachments = warrantyTicket.items.map((item) => item.attachments).flat();
  return (
    <div>
      <div css={Css.baseSb.mbPx(4).$}>Global Ticket Files</div>
      <div css={Css.sm.gray700.mb1.$}>
        Attaching files at the ticket level defaults them to internal visible only. Attaching files on ticket items
        inherits the associated homeowner visible setting.
      </div>
      <BoundAttachments field={formState.attachments} />
      <div css={Css.baseSb.mt6.mb1.$}>Item Level Files</div>

      <AssetGallery assets={itemsLevelAttachments.map((a) => a.asset)} display="horizontal">
        {(openGallery) => (
          <>
            {itemsLevelAttachments.map(({ asset }) => (
              <div key={asset.id} css={Css.wPx(100).oh.mPx(4).br4.bss.bcGray200.bw1.$}>
                <AssetPreview
                  asset={asset}
                  dimensions={{ width: 100, height: 100 }}
                  onClick={() => openGallery(asset)}
                />
              </div>
            ))}
          </>
        )}
      </AssetGallery>
    </div>
  );
}

export function WarrantyHistoryTab({ warrantyTicket }: { warrantyTicket: WarrantyTicketPageDetailsFragment }) {
  return <HistoryFeed historyItems={warrantyTicket.history} />;
}
