import { EventApi, EventContentArg, EventInput } from "@fullcalendar/react";
import { Css, Palette, Tooltip, TriggerNoticeProps } from "@homebound/beam";
import { addDays, differenceInBusinessDays, isBefore, subDays } from "date-fns";
import { formatDate } from "src/components";
import { Icon, IconKey } from "src/components/Icon";
import {
  DraftPlanTaskInput,
  DynamicSchedulesCalendar_PlanTaskFragment,
  InputMaybe,
  ScheduleDraftMode_PlanTaskFragment,
  TaskStatus,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";

export type DateIconsProps = {
  delayFlag?: string;
  materialDrop?: string;
  tradePartner?: string;
  isCalendarView?: boolean;
  workingDays?: string;
};

/* Maps our DayOfWeek enum to numeric day */
export enum DayOfWeekDayPicker {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

type CalendarPlanTask = DynamicSchedulesCalendar_PlanTaskFragment | ScheduleDraftMode_PlanTaskFragment;

// renders and styles our task "event" content
export function renderTaskEventContent(
  arg: EventContentArg,
  planTasksById: Record<string, CalendarPlanTask>,
  isEditMode?: boolean,
  lastUpdatedTaskId?: InputMaybe<string>,
) {
  const planTask = planTasksById[arg.event.id] ?? [];
  const delayFlags = planTask.scheduleFlags ?? [];
  const materialDrop = planTask.globalPlanTask.developmentDrops.first;
  const workingDays = planTask.workDays.sortBy((wd) => wd.workDay.date);

  const delayFlagTooltip = delayFlags
    .map((d) => `[${d.reason?.title}] created on ${new Date(d.createdAt).toLocaleDateString()}`)
    .join(", ");
  const materialDropTooltip = materialDrop?.name ?? "";
  const workindDaysTooltip = workingDays.map((pt) => formatDate(pt.workDay.date, "short")).join(", ");
  const tradePartnersScheduledTooltip = planTask.tradePartner?.name ?? "";
  const tradeScheduleWarning = getTradeScheduleWarning(planTask);
  const isTaskManuallyScheduled = isTaskDraftMode(planTask) && planTask.isManuallyScheduled;

  return (
    <Tooltip disabled={!tradeScheduleWarning} title={tradeScheduleWarning?.tooltip} placement="top">
      <div
        css={{
          ...Css.df.aic.cursorPointer.gap1.p1
            .hPx(32)
            .transition.if(lastUpdatedTaskId === arg.event.id)
            .bgColor(arg.event.extendedProps.lastUpdatedColor).$, // Highlight the task if it's been recently updated
          // Allow override for "drag" cursor to appear when the event is draggable
          ...(isEditMode && Css.cursor("unset").$),
        }}
      >
        {isTaskManuallyScheduled && <Icon icon="pin" color={Palette.Blue600} inc={2} />}
        {planTask.isCriticalPath && (
          <Tooltip title="Critical Path Task">
            <Icon icon="criticalPath" color={Palette.Gray900} inc={2} />
          </Tooltip>
        )}
        <div css={Css.br4.xsSb.$}>{arg.event.title}</div>
        <DateIcons
          delayFlag={delayFlagTooltip}
          materialDrop={materialDropTooltip}
          isCalendarView
          workingDays={workindDaysTooltip}
          tradePartner={tradePartnersScheduledTooltip}
        />
      </div>
    </Tooltip>
  );
}

export function createTaskCalendarEvents(planTasks: CalendarPlanTask[]) {
  return planTasks.flatMap<EventInput>((pt) => {
    const tradeScheduleWarning = getTradeScheduleWarning(pt);
    return [
      {
        id: pt.id,
        start: pt.startDate,
        // We add one day to the end date since FullCalendar's endDates are not inclusive when using allday events
        end: addDays(pt.endDate, 1),
        allDay: true,
        title: pt.name,
        backgroundColor: tradeScheduleWarning ? tradeScheduleWarning.rowPalette : Palette.Blue100,
        textColor: tradeScheduleWarning ? tradeScheduleWarning.textPalette : Palette.Blue600,
        extendedProps: {
          lastUpdatedColor: tradeScheduleWarning ? tradeScheduleWarning.taskUpdatedPalette : Palette.Blue300,
        },
      },
    ];
  });
}

export function getPlanTaskData(planTasks: CalendarPlanTask[]) {
  const events = createTaskCalendarEvents(planTasks);
  const planTasksById = planTasks.keyBy((pt) => pt.id);
  return { events, planTasksById };
}

export function DateIcons({ delayFlag, materialDrop, tradePartner, isCalendarView, workingDays }: DateIconsProps) {
  const containerStyles = isCalendarView
    ? Css.df.jcc.aic.pPx(3).br100.bgWhite.wPx(18).hPx(18).$
    : Css.df.jcc.aic.pPx(3).br100.bgWhite.wPx(25).hPx(25).$;

  const icons = [
    ...(delayFlag ? [{ icon: "flag", title: `Delay flag for ${delayFlag}.`, color: Palette.Red600, inc: 2 }] : []),
    ...(materialDrop ? [{ icon: "truck", title: `${materialDrop}`, color: Palette.Gray800, inc: undefined }] : []),
    ...(tradePartner ? [{ icon: "hardHat", title: `${tradePartner}`, color: Palette.Gray800, inc: undefined }] : []),
    ...(workingDays
      ? [{ icon: "calendar", title: `Working days: [${workingDays}]`, color: Palette.Blue500, inc: undefined }]
      : []),
  ];

  return isCalendarView ? (
    <div css={Css.df.jcsb.gap1.$}>
      {icons.map((icon, index) => (
        <div key={index}>
          <Tooltip title={icon.title}>
            <div css={containerStyles}>
              <Icon icon={icon.icon as IconKey} color={icon.color} inc={icon.inc} />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  ) : (
    <div css={Css.df.fdrr.fww.h100.w100.jcsb.absolute.gap2.$}>
      {/* The first icon will be at the bottom right */}
      {icons.length > 0 && (
        <div css={Css.absolute.bottomPx(5).rightPx(5).$}>
          {
            <Tooltip title={icons[0].title}>
              <div css={containerStyles}>
                <Icon icon={icons[0].icon as IconKey} color={icons[0].color} inc={icons[0].inc} />
              </div>
            </Tooltip>
          }
        </div>
      )}
      {/* The second icon will be at the top right */}
      {icons.length > 1 && (
        <div css={Css.absolute.topPx(5).rightPx(5).$}>
          <div css={containerStyles}>
            {
              <Tooltip title={icons[1].title}>
                <div css={containerStyles}>
                  <Icon icon={icons[1].icon as IconKey} color={icons[1].color} inc={icons[1].inc} />
                </div>
              </Tooltip>
            }
          </div>
        </div>
      )}
      {/* The last icon will be at the top left */}
      {icons.length > 2 && (
        <div css={Css.absolute.topPx(5).leftPx(5).$}>
          <div css={containerStyles}>
            {
              <Tooltip title={icons[2].title}>
                <div css={containerStyles}>
                  <Icon icon={icons[2].icon as IconKey} color={icons[2].color} inc={icons[2].inc} />
                </div>
              </Tooltip>
            }
          </div>
        </div>
      )}
    </div>
  );
}

// TODO: Inform user if operation failed because of a holiday or task specific working day
export function onDraftTaskEventDrop(
  event: EventApi,
  planTask: CalendarPlanTask,
  scheduleExcludedDates: DateOnly[], // as of now these are holidays set at the development level
  revert: () => void,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
) {
  const newStartDate = event.start ? new DateOnly(event.start) : undefined;
  const isDisabled = isDisabledDay({
    newStartDate,
    totalWorkingDays: planTask?.customWorkableDays.map((d) => DayOfWeekDayPicker[d]),
    scheduleExcludedDates,
  });

  // Show error message if the start date is moved to a non-working day (Saturday or Sunday)
  if (isDisabled) {
    revert();
    return triggerNotice({
      message: `Start Date of ${formatDate(newStartDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  return setDraftTaskChanges([
    {
      id: planTask.id,
      earliestStartDate: newStartDate,
      isManuallyScheduled: true,
    },
  ]);
}

// TODO: Inform user if operation failed because of a holiday or task specific working day
export function onDraftTaskEventResize(
  event: EventApi,
  planTask: CalendarPlanTask,
  scheduleExcludedDates: DateOnly[], // as of now these are holidays set at the development level
  revert: () => void,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
) {
  const startDate = event.start ? new DateOnly(event.start) : undefined;
  // Subtract one day from the end date since we added one day when rendering the event
  const endDate = event.end ? new DateOnly(subDays(event.end, 1)) : undefined;
  // Add one day to the duration difference because the end date is not inclusive
  const durationInDays = (startDate && endDate && differenceInBusinessDays(endDate, startDate) + 1) || undefined;
  const isDisabled = isDisabledDay({
    newStartDate: startDate,
    endDate,
    totalWorkingDays: planTask?.customWorkableDays.map((d) => DayOfWeekDayPicker[d]),
    scheduleExcludedDates,
  });

  // Show error message if endDate is moved to a non-working day (Saturday or Sunday)
  if (isDisabled) {
    revert();
    return triggerNotice({
      message: `End Date of ${formatDate(endDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  return setDraftTaskChanges([
    {
      id: planTask.id,
      knownDurationInDays: durationInDays,
    },
  ]);
}

type isDisabledDayProps = {
  newStartDate: DateOnly | undefined;
  endDate?: DateOnly | undefined;
  totalWorkingDays: number[];
  scheduleExcludedDates?: DateOnly[];
};

function isDisabledDay(props: isDisabledDayProps) {
  const { newStartDate, endDate, totalWorkingDays, scheduleExcludedDates } = props;

  if (
    newStartDate &&
    (!totalWorkingDays.includes(newStartDate.getDay()) ||
      scheduleExcludedDates?.some((d) => d.getTime() === newStartDate.getTime()))
  ) {
    return true;
  }

  if (
    endDate &&
    (!totalWorkingDays.includes(endDate.getDay()) ||
      scheduleExcludedDates?.some((d) => d.getTime() === endDate.getTime()))
  ) {
    return true;
  }
}

// helper type guard function to determine which fragment the planTask is
function isTaskDraftMode(
  planTask: DynamicSchedulesCalendar_PlanTaskFragment | ScheduleDraftMode_PlanTaskFragment,
): planTask is ScheduleDraftMode_PlanTaskFragment {
  return planTask !== undefined && planTask.hasOwnProperty("isManuallyScheduled");
}

type TaskTradeScheduleWarning = {
  tooltip: string;
  rowPalette: Palette; // background color of event
  textPalette: Palette; // inner text color of event
  taskUpdatedPalette: Palette;
};

/**
 * Highlights task set to begin within 2 weeks based on trade partner tradePartnerAvailabilityRequests & tradePartnerStatus confirmation
 */
function getTradeScheduleWarning(planTask: CalendarPlanTask): TaskTradeScheduleWarning | undefined {
  const {
    status: { code: taskStatusCode },
    startDate,
    tradePartner: assignedTradePartner,
    tradePartnerStatus: { code: tradePartnerStatusCode },
    tradePartnerAvailabilityRequests,
  } = planTask;

  if (
    taskStatusCode !== TaskStatus.Complete &&
    isBefore(startDate.date, addDays(new Date(), 14)) &&
    tradePartnerStatusCode === TradePartnerTaskStatus.NeedsConfirmation
  ) {
    return tradePartnerAvailabilityRequests?.filter((tpar) => tpar.tradePartner.id === assignedTradePartner?.id).isEmpty
      ? {
          tooltip: "Trade - Not Sent",
          rowPalette: Palette.Red100,
          textPalette: Palette.Red600,
          taskUpdatedPalette: Palette.Red300,
        }
      : {
          tooltip: "Trade - Sent and Unconfirmed",
          rowPalette: Palette.Yellow100,
          textPalette: Palette.Yellow600,
          taskUpdatedPalette: Palette.Yellow300,
        };
  }
}
