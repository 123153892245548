import {
  Button,
  ButtonMenu,
  Css,
  FullBleed,
  Icon,
  Loader,
  LoadingSkeleton,
  MenuItem,
  Palette,
  ScrollableContent,
  Tooltip,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  createDraftCalendarUrl,
  createDraftScheduleNewTaskUrl,
  createDraftSchedulePublishUrl,
  createDraftScheduleUrl,
  createListViewScheduleUrl,
} from "src/RouteUrls";
import {
  DraftPlanScheduleQueryHookResult,
  DraftPlanTaskInput,
  useDraftPlanScheduleQuery,
} from "src/generated/graphql-types";
import { useHiddenProjectSideNav } from "src/hooks";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { ProjectParams, dynamicSchedulesPath } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { DynamicSchedulesHeader } from "../components/DynamicSchedulesHeader";
import { ScheduleContainer } from "../utils";
import { AddDraftTasks } from "./AddDraftTasks";
import { DraftScheduleCalendar } from "./DraftScheduleCalendar";
import { DraftScheduleTable } from "./DraftScheduleTable";
import { PublishDraftScheduleStepper } from "./PublishDraftScheduleStepper";
import { ScheduleDraftModeCalendarDelayFlagModal } from "./ScheduleDraftModeCalendarDelayFlagModal";
import { DraftScheduleStoreProvider, useDraftScheduleStore } from "./scheduleDraftStore";

enum ScheduleDraftModeView {
  DraftList = "List",
  DraftCalendar = "Calendar",
}

export function ScheduleDraftMode() {
  return (
    <DraftScheduleStoreProvider>
      <ScheduleDraftModeContent />
    </DraftScheduleStoreProvider>
  );
}

function ScheduleDraftModeContent() {
  const { projectId } = useParams<ProjectParams>();
  useHiddenProjectSideNav();

  const tid = useTestIds({}, "scheduleDraftMode");

  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const maybeSetInitialValues = useDraftScheduleStore((state) => state.maybeSetInitialValues);

  const query = useDraftPlanScheduleQuery({
    variables: {
      input: {
        scheduleParentId: projectId,
        draftTaskChanges: [
          ...draftTaskChanges,
          ...userAddedScheduleFlags.map(({ taskId, taskName, title, scheduleFlagReasonType, ...others }) => ({
            id: taskId,
            scheduleFlags: [{ ...others }],
          })),
        ],
      },
      projectId,
    },
    onCompleted: (data) => maybeSetInitialValues(data),
  });

  const draftTasks = useMemo(
    () => query.data?.draftPlanSchedule.planTasks ?? [],
    [query.data?.draftPlanSchedule.planTasks],
  );

  return (
    <div {...tid}>
      <Switch>
        <Route
          path={dynamicSchedulesPath.draftMode}
          exact
          render={() => <DraftListingPage query={query} scheduleParentId={projectId} />}
        />
        <Route
          path={dynamicSchedulesPath.draftCalendar}
          exact
          render={() => <DraftListingPage query={query} scheduleParentId={projectId} />}
        />
        <Route
          path={dynamicSchedulesPath.draftNewTask}
          exact
          render={() => <AddDraftTasks draftTasks={draftTasks} />}
        />
        <Route
          path={dynamicSchedulesPath.draftPublish}
          exact
          render={() => <PublishDraftScheduleStepper scheduleParentId={projectId} draftTasks={draftTasks} />}
        />
      </Switch>
    </div>
  );
}

function DraftListingPage({
  query,
  scheduleParentId,
}: {
  query: DraftPlanScheduleQueryHookResult;
  scheduleParentId: string;
}) {
  const history = useHistory();
  const reset = useDraftScheduleStore((state) => state.reset);
  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const draftScheduleView = useScheduleDraftRouteMatch();
  const setUserAddedScheduleFlags = useDraftScheduleStore((state) => state.setUserAddedScheduleFlags);

  const { openModal } = useModal();

  const setIsPublishedMode = useCallback(
    () => history.push(createDraftSchedulePublishUrl(scheduleParentId)),
    [history, scheduleParentId],
  );
  const setAddTaskMode = useCallback(() => {
    history.push(createDraftScheduleNewTaskUrl(scheduleParentId));
  }, [history, scheduleParentId]);

  const onPublishClick = useCallback(
    () =>
      openModal({
        content: (
          <ConfirmationModal
            title="Are you sure you want to Publish?"
            label="Enter Publish Workflow"
            onConfirmAction={setIsPublishedMode}
            confirmationMessage="Publishing will accept the simulated schedule changes and apply them to the existing schedule. Are you sure you want to continue?"
          />
        ),
      }),
    [openModal, setIsPublishedMode],
  );

  const menuItems: MenuItem[] = [
    {
      label: "List View",
      onClick: createDraftScheduleUrl(scheduleParentId),
    },
    {
      label: "Calendar View",
      onClick: createDraftCalendarUrl(scheduleParentId),
    },
  ];

  return (
    <>
      <ScheduleDraftModeHeader
        scheduleParentId={scheduleParentId}
        onPublishClick={onPublishClick}
        draftTaskChanges={draftTaskChanges}
        reset={reset}
        loading={query.loading}
      />
      <ScheduleContainer>
        <div css={Css.df.jcfe.mb2.gap1.$}>
          {draftScheduleView === ScheduleDraftModeView.DraftCalendar && query?.previousData && query.loading && (
            <div css={Css.df.aic.gap1.$}>
              <Loader size="xs" />
              Simulating schedule changes...
            </div>
          )}
          <ButtonMenu items={menuItems} trigger={{ label: `View: ${draftScheduleView}` }} />
          <Button onClick={setAddTaskMode} label="Add Task" variant="secondary" />
          {draftScheduleView === ScheduleDraftModeView.DraftCalendar && (
            <Button
              onClick={() =>
                openModal({
                  content: (
                    <ScheduleDraftModeCalendarDelayFlagModal
                      planTasks={query.data?.draftPlanSchedule.planTasks ?? []}
                      setUserAddedScheduleFlags={setUserAddedScheduleFlags}
                    />
                  ),
                })
              }
              label="Add Delay Flag"
              variant="secondary"
            />
          )}
        </div>
      </ScheduleContainer>
      <ScrollableContent virtualized>
        {queryResult(query, {
          data: ({ draftPlanSchedule, enumDetails, project }) =>
            draftScheduleView === ScheduleDraftModeView.DraftList ? (
              <DraftScheduleTable
                schedulingExclusionDates={project.cohort?.development?.schedulingExclusionDates ?? []}
                tasks={draftPlanSchedule.planTasks}
                enumDetails={enumDetails}
                loading={query.loading}
              />
            ) : (
              <DraftScheduleCalendar
                schedulingExclusionDates={project.cohort?.development?.schedulingExclusionDates ?? []}
                planTasks={draftPlanSchedule.planTasks}
                loading={query.loading}
              />
            ),
          loading: () => (
            <ScheduleContainer>
              <LoadingSkeleton columns={4} rows={10} />
            </ScheduleContainer>
          ),
        })}
      </ScrollableContent>
    </>
  );
}

type ScheduleDraftModeHeaderProps = {
  scheduleParentId: string;
  reset: () => void;
  onPublishClick: () => void;
  draftTaskChanges: DraftPlanTaskInput[];
  loading: boolean;
};

function ScheduleDraftModeHeader({
  scheduleParentId,
  onPublishClick,
  draftTaskChanges,
  reset,
  loading,
}: ScheduleDraftModeHeaderProps) {
  const validationErrors = useDraftScheduleStore((state) => state.validationErrors);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const disabledReasons = useMemo(
    () =>
      [
        ...(userAddedScheduleFlags.isEmpty && draftTaskChanges.isEmpty ? ["Make at least 1 change to publish."] : []),
        ...Object.keys(validationErrors).flatMap((v) => validationErrors[v]),
      ].unique(),
    [draftTaskChanges, validationErrors, userAddedScheduleFlags],
  );
  return (
    <FullBleed>
      <header css={Css.p2.bgGray300.df.fdc.jcsb.mb2.$}>
        <ScheduleContainer>
          <div css={Css.df.jcsb.$}>
            <div css={Css.df.aic.mb2.$}>
              <Button onClick={reset} label="Reset" variant="tertiary" disabled={loading} />
              <Tooltip
                placement="top"
                title="This resets the Draft Schedule Mode to the latest version of the schedule (example: Someone else has made updates)"
              >
                <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
              </Tooltip>
            </div>
            <h1 css={Css.xlSb.$}>Draft Schedule</h1>
            <div css={Css.df.gap1.$}>
              <Button onClick={createListViewScheduleUrl(scheduleParentId)} label="Cancel" variant="tertiary" />
              <Button
                onClick={onPublishClick}
                label="Publish"
                disabled={disabledReasons.isEmpty ? undefined : disabledReasons}
              />
            </div>
          </div>
          <DynamicSchedulesHeader scheduleParentId={scheduleParentId} isDraftMode={true} />
        </ScheduleContainer>
      </header>
    </FullBleed>
  );
}

function useScheduleDraftRouteMatch() {
  const isListViewDraft = !!useRouteMatch(dynamicSchedulesPath.draftMode)?.isExact;
  const isCalendarViewDraft = !!useRouteMatch(dynamicSchedulesPath.draftCalendar)?.isExact;

  if (isListViewDraft) return ScheduleDraftModeView.DraftList;
  if (isCalendarViewDraft) return ScheduleDraftModeView.DraftCalendar;

  throw new Error("Unknown schedule view");
}
